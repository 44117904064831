interface DraggableIconProps {
  width?: string;
  height?: string;
}

const DraggableIcon = ({ width = '1rem', height = '.4375rem' }: DraggableIconProps) => (
  <svg
    className="draggable-icon"
    role="button"
    width={width}
    height={height}
    viewBox="0 0 16 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.75 1H15.25" stroke="#979797" strokeLinecap="square" />
    <path d="M0.75 6H15.25" stroke="#979797" strokeLinecap="square" />
  </svg>
);

export default DraggableIcon;
