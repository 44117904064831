import { useEffect, useState } from 'react';
import MenuSelectionBar from '../MenuSelectionBar';
import MenuSectionSelectionBar from '../MenuSectionSelectionBar';
import useResponsive from '../../hooks/useResponsive';
import MenuItemTable from '../MenuItemTable';
import { useMenuContext } from '../../contexts/MenuContext';
import { DetailedMenuItemInterface } from '../../types/MenuItemInterface';
import LoadingSpinner from '../common/LoadingSpinner';

const MenuListView = () => {
  const [menuItems, setMenuItems] = useState<DetailedMenuItemInterface[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { selectedMenuSectionID: menuSectionID, menu } = useMenuContext();
  const [localMenuSectionID, setLocalMenuSectionID] = useState<number>(menuSectionID);
  const { isDesktop } = useResponsive();

  useEffect(() => {
    const parseMenuItems = () => {
      const index = menu.menuSections.findIndex((menuSection) => menuSection.menuSectionID === menuSectionID);
      setMenuItems(menu.menuSections[index]?.items || []);
    };
    if (menu != null && menu.menuSections.length > 0) {
      if (localMenuSectionID !== menuSectionID) {
        setIsLoading(true);
        setMenuItems([]);
        setTimeout(() => {
          parseMenuItems();
          setIsLoading(false);
          setLocalMenuSectionID(menuSectionID);
        }, 50);
      } else {
        parseMenuItems();
      }
    } else {
      setMenuItems([]);
    }
  }, [localMenuSectionID, menu, menuSectionID]);

  return (
    <div className="menu-list-view-container">
      <div className="menu-list-action-container">
        <MenuSelectionBar />
        <MenuSectionSelectionBar />
      </div>
      {!isLoading ? (
        <MenuItemTable isDesktop={isDesktop} menuItems={menuItems} />
      ) : (
        <div className="menu-list-view-loading">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

export default MenuListView;
