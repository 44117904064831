import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSS } from '@dnd-kit/utilities';
import { formatPriceExact } from '../../utils/priceFormatter';
import { ModifierInterface } from '../../types/ModifierInterface';
import Button from '../common/Button';
import { DraggableIcon } from '../../assets/svgs/icons';

interface SelectedModifierProps {
  allowSorting: boolean;
  modifier: ModifierInterface;
  // eslint-disable-next-line no-unused-vars
  onRemoveModifier: (modifier: ModifierInterface) => void;
}

const SelectedModifier = ({ modifier, onRemoveModifier, allowSorting }: SelectedModifierProps) => {
  const { setNodeRef, listeners, attributes, transform, transition, isDragging } = useSortable({
    id: modifier.modifierID.toString(),
    transition: {
      duration: 150, // milliseconds
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
    }
  });

  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <li
      style={allowSorting ? { ...itemStyle, cursor: isDragging ? 'grab' : 'pointer' } : {}}
      className={`modifier-item ${isDragging ? 'is-dragging' : ''}`}
      key={modifier.modifierID}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...(allowSorting ? attributes : {})}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...(allowSorting ? listeners : {})}
      ref={allowSorting ? setNodeRef : null}
    >
      {allowSorting && <DraggableIcon width="12px" height="6px" />}
      <Button className="remove-modifier-button" onClick={() => onRemoveModifier(modifier)}>
        Remove
      </Button>
      <span className="entity-name">{modifier.name}</span>
      {modifier.price > 0 && <span className="modifier-price">+{formatPriceExact(modifier.price)}</span>}
    </li>
  );
};

export default SelectedModifier;
