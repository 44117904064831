import { createContext, ReactElement, useMemo, useContext, useState, useEffect, useCallback } from 'react';
import { useRestaurantContext } from '../RestaurantContext/RestaurantContext';
import { RestaurantUserEmailInterface } from '../../types/RestaurantInterface';
import { getSubmittedEmails } from '../../api/restaurant';

interface SubmittedEmailsContextInterface {
  hasError: boolean;
  isLoading: boolean;
  emails: RestaurantUserEmailInterface[];
  loadSubmittedEmails: Function;
}

interface SubmittedEmailsProviderInterface {
  children: ReactElement;
}

const SubmittedEmailsContext = createContext<SubmittedEmailsContextInterface>({
  hasError: false,
  isLoading: true,
  emails: null,
  loadSubmittedEmails: () => {}
});

const SubmittedEmailsProvider = ({ children }: SubmittedEmailsProviderInterface) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [emails, setEmails] = useState<RestaurantUserEmailInterface[]>(null);
  const [currentRestaurantID, setCurrentRestaurantID] = useState<number>(-1);

  const { currentRestaurant } = useRestaurantContext();

  useEffect(() => {
    let canceled = false;

    if (initialLoad && currentRestaurant && currentRestaurant.restaurantID !== currentRestaurantID) {
      setIsLoading(true);

      getSubmittedEmails()
        .then((userEmails: RestaurantUserEmailInterface[]) => {
          if (canceled) return;
          setEmails(userEmails);
          setCurrentRestaurantID(currentRestaurant.restaurantID);
          setIsLoading(false);
        })
        .catch((error) => {
          if (canceled) return;
          if (error.response.status !== 401) {
            // on non auth related failure indicate failure has occurred
            setHasError(true);
          }
          setIsLoading(false);
        });
    }

    return () => {
      canceled = true;
    };
  }, [initialLoad, emails, currentRestaurant, currentRestaurantID]);

  const loadSubmittedEmails = useCallback(() => {
    if (!initialLoad && emails == null) {
      setInitialLoad(true);
    }
  }, [initialLoad, emails]);

  const providerValue = useMemo(
    () => ({
      hasError,
      isLoading,
      emails,
      loadSubmittedEmails
    }),
    [hasError, isLoading, emails, loadSubmittedEmails]
  );
  return <SubmittedEmailsContext.Provider value={providerValue}>{children}</SubmittedEmailsContext.Provider>;
};

const useSubmittedEmailsContext = () => {
  const context = useContext(SubmittedEmailsContext);
  if (!context) {
    throw new Error(`SubmittedEmailsContext must be used within the SubmittedEmailsProvider component`);
  }
  return context;
};

export { SubmittedEmailsProvider as default, useSubmittedEmailsContext };
