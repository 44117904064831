import { ReactNode } from 'react';

interface LabelProps {
  htmlFor?: string;
  label: string | ReactNode;
  className?: string;
  icon?: ReactNode;
}

const Label = ({ htmlFor, label, className, icon }: LabelProps) => (
  <label htmlFor={htmlFor} className={`font-semibold text-sm ${className ? ` ${className}` : ''}`}>
    {label} {icon && <span>{icon}</span>}
  </label>
);

export default Label;
