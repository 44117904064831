import { createContext, ReactElement, useMemo, useContext, useState, useEffect, useCallback } from 'react';
import { ManagerInterface } from '../../types/ManagerInterface';
import { getManager } from '../../api/manager';

interface ManagerContextInterface {
  hasError: boolean;
  isLoading: boolean;
  manager: ManagerInterface;
  loadManager: Function;
  updateManager: Function;
}

interface ManagerProviderInterface {
  children: ReactElement;
}

const ManagerContext = createContext<ManagerContextInterface>({
  hasError: false,
  isLoading: false,
  manager: null,
  loadManager: () => {},
  updateManager: () => {}
});

const ManagerProvider = ({ children }: ManagerProviderInterface) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [manager, setManager] = useState<ManagerInterface>(null);

  useEffect(() => {
    let canceled = false;
    if (manager == null && initialLoad) {
      setIsLoading(true);

      getManager()
        .then((response: ManagerInterface) => {
          if (canceled) return;

          setManager(response);
          setIsLoading(false);
        })
        .catch((error) => {
          if (canceled) return;
          if (error.response.status !== 401) {
            // on non auth related failure indicate failure has occurred
            setHasError(true);
          }
          setIsLoading(false);
        });
    }

    return () => {
      canceled = true;
    };
  }, [initialLoad, manager]);

  const loadManager = useCallback(() => {
    if (!initialLoad && manager == null) {
      setInitialLoad(true);
    }
  }, [initialLoad, manager]);

  const updateManager = useCallback((updatedManager: ManagerInterface) => {
    setManager(updatedManager);
  }, []);

  const providerValue = useMemo(
    () => ({ hasError, isLoading, manager, loadManager, updateManager }),
    [hasError, isLoading, manager, loadManager, updateManager]
  );
  return <ManagerContext.Provider value={providerValue}>{children}</ManagerContext.Provider>;
};

const useManagerContext = () => {
  const context = useContext(ManagerContext);
  if (!context) {
    throw new Error(`ManagerContext must be used within the ManagerProvider component`);
  }
  return context;
};

export { ManagerProvider as default, useManagerContext };
