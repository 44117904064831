import React, { useRef, useCallback, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridOptions, RowDragEvent, CsvExportParams } from 'ag-grid-community';
import Button from '../common/Button';

interface ItemTableProps {
  className?: string;
  items: any[];
  columns: ColDef[];
  draggable?: boolean;
  onDragEnd?: Function;
  rowHeight?: number;
  pagination?: boolean;
  filter?: boolean;
  hasExport?: boolean;
  exportParams?: CsvExportParams;
}

export const ROW_HEIGHT = 114;

const ItemTable = ({
  className,
  items,
  columns,
  onDragEnd,
  draggable = true,
  rowHeight = ROW_HEIGHT,
  pagination = false,
  filter = false,
  hasExport = false,
  exportParams
}: ItemTableProps) => {
  const gridRef = useRef<any>(null);
  const gridWrapperRef = useRef<any>(null);

  const containerStyle = useMemo(
    () => ({
      justifySelf: 'center',
      width: '100%',
      padding: '0 30px'
    }),
    []
  );
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%', marginTop: '5px' }), []);

  const defaultColDef = useMemo(
    () => ({
      resizable: false,
      ...(filter && { filter: true }),
      ...(filter && { floatingFilter: true })
    }),
    [filter]
  );

  const onRowDragEnd = useCallback(
    (e: RowDragEvent) => {
      onDragEnd?.(e);
    },
    [onDragEnd]
  );

  const gridOptions: GridOptions = {
    rowClass: 'item-row',
    rowHeight,
    rowSelection: 'single',
    pagination
  };

  const onDataRendered = useCallback(() => {
    gridRef?.current?.api?.sizeColumnsToFit();
  }, []);

  const onGridSizeChanged = useCallback(() => {
    // get the current grids width
    const gridWidth = gridWrapperRef.current.offsetWidth;
    // keep track of which columns to hide/show
    const columnsToShow = [];
    const columnsToHide = [];
    // iterate over all columns (visible or not) and work out
    // now many columns can fit (based on their minWidth)
    let totalColsWidth = 0;
    const allColumns = gridRef.current.columnApi.getAllColumns();
    if (allColumns && allColumns.length > 0) {
      for (let i = 0; i < allColumns.length; i++) {
        const column = allColumns[i];
        totalColsWidth += column.getMinWidth() || 0;
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.getColId());
        } else {
          columnsToShow.push(column.getColId());
        }
      }
    }
    // show/hide columns based on current grid width
    gridRef.current.columnApi.setColumnsVisible(columnsToShow, true);
    gridRef.current.columnApi.setColumnsVisible(columnsToHide, false);
    // fill out any available space to ensure there are no gaps
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const onBtnExport = useCallback(() => {
    gridRef.current!.api.exportDataAsCsv(exportParams);
  }, [exportParams]);

  return (
    <div style={{ ...containerStyle, overflowX: 'scroll', overflowY: 'hidden' }} className={className ?? ''}>
      {hasExport && (
        <Button className="download-csv-button" onClick={onBtnExport}>
          DOWNLOAD CSV
        </Button>
      )}
      <div ref={gridWrapperRef} style={{ minWidth: '1177px', width: '100%' }}>
        <div className="ag-theme-alpine" style={gridStyle}>
          <AgGridReact
            gridOptions={gridOptions}
            domLayout="autoHeight"
            suppressRowDrag={!draggable}
            rowDragEntireRow={draggable}
            animateRows={draggable}
            rowDragManaged={draggable}
            ref={gridRef}
            columnDefs={columns}
            onRowDragEnd={onRowDragEnd}
            rowData={items}
            defaultColDef={defaultColDef}
            onModelUpdated={onDataRendered}
            onFirstDataRendered={onDataRendered}
            onGridSizeChanged={onGridSizeChanged}
            suppressNoRowsOverlay
          />
        </div>
      </div>
    </div>
  );
};

export default ItemTable;
