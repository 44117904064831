import { useRef, useState } from 'react';
import { ShowIcon } from '../../assets/svgs/icons';
import PromotionPreview from '../PromotionPreview';
import { AnnouncementType } from '../../constants/AnnouncementTypeConstants';

interface PromotionPreviewIconButtonProps {
  title?: string;
  description?: string;
  type: AnnouncementType;
  image: string;
}

const PromotionPreviewIconButton = ({ title, description, type, image }: PromotionPreviewIconButtonProps) => {
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const ref = useRef(null);

  return (
    <div ref={ref} className="promotion-preview-action-container">
      <ShowIcon
        onIconClicked={() => setShowPreview(true)}
        width="20"
        height="20"
        onEscape={() => setShowPreview(false)}
      />
      <PromotionPreview
        title={title}
        type={type}
        description={description}
        image={image}
        iconRef={ref}
        hidden={!showPreview}
        onBlur={() => {
          setShowPreview(false);
        }}
      />
    </div>
  );
};

export default PromotionPreviewIconButton;
