import {
  UPDATE_URLS,
  ASSIGN_PACKAGE_TO_RESTAURANT,
  CREATE_RESTAURANT,
  EDIT_RESTAURANT,
  GET_RESTAURANTS,
  UPLOAD_RESTAURANT_IMAGES,
  GET_RESTAURANT_DETAILS,
  GET_SUBMITTED_EMAILS
} from '../constants/UriConstants';
import apiClient from './apiClient';
import {
  CreateRestaurantRequestInterface,
  CreateRestaurantResponseInterface,
  EditRestaurantRequestInterface,
  RestaurantDetailsInterface,
  RestaurantImagesInterface,
  RestaurantsInterface,
  RestaurantUserEmailInterface
} from '../types/RestaurantInterface';

/**
 * provides the package id to be assigned to a restaurant
 * @param managerPackageID - id of package that is currently owned by the user with restaurant (manager)
 * @param restaurantID (optional) - id of restaurant that package will be assigned to,
 * if none provided then will default to the currently selected restaurant
 * @return permissionToken that is generated based on the purchased package for a user
 */
export const assignPackageToRestaurant = (managerPackageID: number, restaurantID?: number): Promise<string> =>
  apiClient
    .post(ASSIGN_PACKAGE_TO_RESTAURANT, { managerPackageID }, { headers: { ...(restaurantID && { restaurantID }) } })
    .then((response) => response.data.permissionToken);

export const createRestaurant = (
  restaurant: CreateRestaurantRequestInterface
): Promise<CreateRestaurantResponseInterface> =>
  apiClient.post(CREATE_RESTAURANT, restaurant).then((response) => response.data);

export const editRestaurant = async (restaurant: EditRestaurantRequestInterface) => {
  await apiClient.put(EDIT_RESTAURANT, restaurant);
};

export const updateURL = async (reservationUrl?: string, orderingUrl?: string) => {
  await apiClient.put(UPDATE_URLS, {
    ...(reservationUrl != null && reservationUrl !== undefined && { reservationUrl }),
    ...(orderingUrl != null && orderingUrl !== undefined && { orderingUrl })
  });
};

export const getRestaurants = (): Promise<RestaurantsInterface> =>
  apiClient.get(GET_RESTAURANTS).then((response) => response.data);

export const getRestaurantDetails = (): Promise<RestaurantDetailsInterface> =>
  apiClient.get(GET_RESTAURANT_DETAILS).then((response) => response.data);

export const getSubmittedEmails = (): Promise<RestaurantUserEmailInterface[]> =>
  apiClient.get(GET_SUBMITTED_EMAILS).then((response) => response.data);

/**
 * uploads images specific to a restaurant domain
 * @param logoImage
 * @param thumbnailImage
 * @param profileImage - profile image being uploaded for a restaurant
 * @param menuCoverImage
 * @param galleryImages
 * @param galleryImagesToDelete
 * @param imagesToDelete - array of image ids to be deleted
 * @param galleryOrder
 * @param restaurantID (optional) - id of restaurant that images will be uploaded/deleted to,
 * if none provided then will default to the currently selected restaurant
 * @return images that have been uploaded for a restaurant
 */
export const uploadRestaurantImages = (
  logoImage: Blob | null,
  thumbnailImage: Blob | null,
  profileImage: Blob | null,
  menuCoverImage: Blob | null,
  galleryImages: Blob[] | null,
  galleryImagesToDelete: number[],
  imagesToDelete: number[],
  galleryOrder: string[],
  restaurantID?: number
): Promise<RestaurantImagesInterface> => {
  const formData = new FormData();
  if (logoImage) {
    formData.append('logo', logoImage);
  }
  if (thumbnailImage) {
    formData.append('thumbnail', thumbnailImage);
  }
  if (profileImage) {
    formData.append('profile', profileImage);
  }
  if (menuCoverImage) {
    formData.append('menuCover', menuCoverImage);
  }

  if (galleryImages?.length > 0) {
    galleryImages?.forEach((image) => {
      formData.append('gallery', image);
    });
  }

  if (imagesToDelete.length > 0) {
    formData.append('imagesToDelete', `[${imagesToDelete}]`);
  } else {
    formData.append('imagesToDelete', '[]');
  }

  if (galleryImagesToDelete.length > 0) {
    formData.append('galleryImagesToDelete', `[${galleryImagesToDelete}]`);
  } else {
    formData.append('galleryImagesToDelete', `[]`);
  }

  if (galleryOrder?.length > 0) {
    formData.append('galleryOrder', `[${galleryOrder.map((order) => `"${order}"`)}]`);
  } else {
    formData.append('galleryOrder', '[]');
  }

  return apiClient
    .post(UPLOAD_RESTAURANT_IMAGES, formData, {
      headers: { 'Content-Type': 'multipart/form-data', ...(restaurantID && { restaurantID }) }
    })
    .then((response) => response.data);
};
