import apiClient from './apiClient';
import { GET_MENU_LAYOUTS, UPDATE_MENU_LAYOUT } from '../constants/UriConstants';
import { MenuLayoutInterface } from '../types/MenuLayoutInterface';

export const getMenuLayouts = (): Promise<MenuLayoutInterface[]> =>
  apiClient.get(GET_MENU_LAYOUTS).then((response) => response.data.layouts);

/**
 * provides the new layout id to be updated for a restaurant
 * @param layoutID - id of layout that restaurant will be updated to
 * @param restaurantID (optional) - id of restaurant that layout will be assigned to,
 * if none provided then will default to the currently selected restaurant
 */
export const updateMenuLayout = async (layoutID: number, restaurantID?: number): Promise<void> => {
  await apiClient.patch(UPDATE_MENU_LAYOUT, { layoutID }, { headers: { ...(restaurantID && { restaurantID }) } });
};
