export const AboutPageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="#000000">
    <mask id="path-1-inside-1_4161_4239" fill="white">
      <path d="M1 5H17V15.5C17 15.7761 16.7761 16 16.5 16H1.5C1.22386 16 1 15.7761 1 15.5V5Z" />
    </mask>
    <path
      d="M1 5H17V15.5C17 15.7761 16.7761 16 16.5 16H1.5C1.22386 16 1 15.7761 1 15.5V5Z"
      fill="#000000"
      stroke="white"
      strokeWidth="1.5"
      mask="url(#path-1-inside-1_4161_4239)"
    />
    <rect x="2.625" y="7.625" width="3.75" height="7.75" fill="white" stroke="#000000" strokeWidth="0.25" />
    <rect x="7.125" y="7.625" width="8.75" height="7.75" fill="white" stroke="#000000" strokeWidth="0.25" />
    <line x1="17.5358" y1="5.1857" x2="15.5358" y2="0.185695" stroke="#000000" />
    <line
      y1="-0.5"
      x2="5.38516"
      y2="-0.5"
      transform="matrix(0.371391 -0.928477 -0.928477 -0.371391 0 5)"
      stroke="#000000"
    />
    <line x1="2" y1="0.5" x2="16" y2="0.5" stroke="#000000" />
    <path d="M0 5H18C18 5.55228 17.5523 6 17 6H1C0.447716 6 0 5.55228 0 5Z" fill="#000000" />
    <rect x="11" y="12" width="1" height="4" fill="#000000" />
    <rect x="11" y="7.5" width="1" height="1" fill="#000000" />
    <rect x="13.5" y="12" width="1" height="4" fill="#000000" />
    <rect x="13.5" y="7.5" width="1" height="1" fill="#000000" />
    <rect x="8.5" y="12" width="1" height="4" fill="#000000" />
    <rect x="8.5" y="7.5" width="1" height="1" fill="#000000" />
    <rect x="8" y="12" width="2" height="0.5" fill="#000000" />
    <rect x="10.5" y="12" width="2" height="0.5" fill="#000000" />
    <rect x="13" y="12" width="2" height="0.5" fill="#000000" />
    <path d="M8 8H10C10 8.55228 9.55228 9 9 9C8.44772 9 8 8.55228 8 8Z" fill="#000000" />
    <path d="M10.5 8H12.5C12.5 8.55228 12.0523 9 11.5 9C10.9477 9 10.5 8.55228 10.5 8Z" fill="#000000" />
    <path d="M13 8H15C15 8.55228 14.5523 9 14 9C13.4477 9 13 8.55228 13 8Z" fill="#000000" />
    <circle cx="5.75" cy="11.75" r="0.25" fill="#000000" />
  </svg>
);

export default AboutPageIcon;
