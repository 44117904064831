import { createContext, ReactElement, useMemo, useContext, useState, useEffect, useCallback } from 'react';
import { CuisineInterface } from '../../types/CuisineInterface';
import { getCuisines } from '../../api/cuisines';

interface CuisinesContextInterface {
  hasError: boolean;
  isLoading: boolean;
  cuisines: CuisineInterface[];
  loadCuisines: Function;
  getCuisine: Function;
}

interface CuisinesProviderInterface {
  children: ReactElement;
}

const CuisinesContext = createContext<CuisinesContextInterface>({
  hasError: false,
  isLoading: false,
  cuisines: [],
  loadCuisines: () => {},
  getCuisine: () => {}
});

const CuisinesProvider = ({ children }: CuisinesProviderInterface) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [cuisines, setCuisines] = useState<CuisineInterface[]>([]);

  useEffect(() => {
    let canceled = false;
    if (cuisines.length === 0 && initialLoad) {
      setIsLoading(true);

      getCuisines()
        .then((result: CuisineInterface[]) => {
          if (canceled) return;

          setCuisines(result);
          setIsLoading(false);
        })
        .catch((error) => {
          if (canceled) return;
          if (error.response.status !== 401) {
            // on non auth related failure indicate failure has occurred
            setHasError(true);
          }
          setIsLoading(false);
        });
    }

    return () => {
      canceled = true;
    };
  }, [initialLoad, cuisines.length]);

  const getCuisine = useCallback(
    (cuisineID: number): CuisineInterface => {
      const cuisineIndex = cuisines.findIndex((cuisine: CuisineInterface) => cuisine.cuisineID === cuisineID);

      if (cuisineID === -1) {
        throw new Error(`Provided menu layout id does not exist`);
      }

      return cuisines[cuisineIndex];
    },
    [cuisines]
  );

  const loadCuisines = useCallback(() => {
    if (!initialLoad && cuisines?.length === 0) {
      setInitialLoad(true);
    }
  }, [initialLoad, cuisines?.length]);

  const providerValue = useMemo(
    () => ({ hasError, isLoading, cuisines, getCuisine, loadCuisines }),
    [hasError, isLoading, cuisines, getCuisine, loadCuisines]
  );
  return <CuisinesContext.Provider value={providerValue}>{children}</CuisinesContext.Provider>;
};

const useCuisinesContext = () => {
  const context = useContext(CuisinesContext);
  if (!context) {
    throw new Error(`CuisinesContext must be used within the CuisinesProvider component`);
  }
  return context;
};

export { CuisinesProvider as default, useCuisinesContext };
