import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../common/Button';
import { PAGE_CREATE_DRINK_ITEM, PAGE_CREATE_MENU_ITEM } from '../../constants/UriConstants';
import { useMenuContext } from '../../contexts/MenuContext';

const AddItemButtons = () => {
  const navigate = useNavigate();
  const { menu, selectedMenuSectionID } = useMenuContext();

  const handleAddMenuItem = useCallback(() => {
    navigate(`/${PAGE_CREATE_MENU_ITEM}`, {
      state: {
        menuItem: { menuItemID: null },
        menuSectionID: selectedMenuSectionID,
        menuID: menu?.menuID
      }
    });
  }, [menu?.menuID, navigate, selectedMenuSectionID]);

  const handleAddDrinkItem = useCallback(() => {
    navigate(`/${PAGE_CREATE_DRINK_ITEM}`, {
      state: {
        menuItem: { menuItemID: null },
        menuSectionID: selectedMenuSectionID,
        menuID: menu?.menuID
      }
    });
  }, [menu?.menuID, navigate, selectedMenuSectionID]);

  return (
    <div className="add-button-container">
      <Button onClick={handleAddMenuItem} className="add-menu-item-button">
        <span>ADD MENU ITEM</span>
      </Button>
      <Button onClick={handleAddDrinkItem} className="add-drink-item-button">
        <span>ADD DRINK</span>
      </Button>
    </div>
  );
};

export default AddItemButtons;
