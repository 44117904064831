const SimpleDateTimeCell = ({ value }: { value: string }) => (
  <span className="custom-cell-container date-time-cell">
    {value ? (
      <>
        <span>{new Date(Date.parse(value)).toLocaleDateString('en-US')}</span>
        <span>{new Date(Date.parse(value)).toLocaleTimeString('en-US', { timeStyle: 'short' })}</span>
      </>
    ) : (
      <span>--</span>
    )}
  </span>
);

export default SimpleDateTimeCell;
