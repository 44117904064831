import { Form, Formik } from 'formik';
import React, { useState, useCallback } from 'react';
import { InferType, object, string } from 'yup';

import { useNavigate } from 'react-router-dom';
import { ErrorText, FormikInput } from '../../components/common/Form';
import logoWithName from '../../images/TapTabLogoWhiteSmallWithName.png';
import { forgotPassword } from '../../api/authentication';
import { PAGE_RESET_PASSWORD, PAGE_LOGIN } from '../../constants/UriConstants';
import LoadingSpinner from '../../components/common/LoadingSpinner';

const ForgotPasswordSchema = object({
  email: string().max(50).default('').email('Must be a valid email').required('Email is required')
});

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [forgotPasswordError, setForgotPasswordError] = useState(null);
  const navigate = useNavigate();

  const handleResetPassword = async ({ email }: InferType<typeof ForgotPasswordSchema>) => {
    if (ForgotPasswordSchema.isValidSync({ email })) {
      try {
        setIsLoading(true);
        await forgotPassword(email);
        navigate(`/${PAGE_RESET_PASSWORD}`, { state: { email } });
      } catch (error) {
        if (error?.response?.status === 401) {
          setForgotPasswordError('The email account provided does not exist');
        } else {
          setForgotPasswordError('Unable to process your authentication request at this time');
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleBackToLoginClicked = useCallback(() => {
    navigate(`/${PAGE_LOGIN}`);
  }, [navigate]);

  const handleBackToLoginKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      if (event.code === 'Enter' || event.code === 'Space') {
        navigate(`/${PAGE_LOGIN}`);
      }
    },
    [navigate]
  );

  return (
    // url value is relative to index.css where tailwind is imported
    <div
      className={`h-screen w-screen bg-[#000]/[0.8] bg-[url('../images/LoginBackground.png')] bg-blend-darken backdrop-blur-sm bg-cover`}
    >
      <div className="h-screen w-screen backdrop-blur-md flex justify-center items-center">
        <div className="inline-block">
          <div className="flex items-center mb-8">
            <img src={logoWithName} className="h-[50px] pr-2" alt="logo" />
            <p className="text-[21px] text-white border-l-2 border-white pl-3">TapManager</p>
          </div>
          <h1 className="text-[20px] text-white font-semibold text-center mb-4">FORGOT YOUR PASSWORD?</h1>
          <div className="text-[12px] text-[#979797] font-medium text-center mb-2">
            Enter your email address here and we will send you a <br /> link where you can reset your password.
          </div>
          <ErrorText error={forgotPasswordError} />
          <Formik
            initialValues={ForgotPasswordSchema.cast({})}
            validationSchema={ForgotPasswordSchema}
            onSubmit={handleResetPassword}
          >
            {() => (
              <Form className="grid mt-1">
                {isLoading ? (
                  <div className="flex justify-center items-center w-full mb-2">
                    <LoadingSpinner primary="white" secondary="black" size={60} />
                  </div>
                ) : (
                  <FormikInput name="email" placeholder="Email" className="w-full mb-2" />
                )}
                <button type="submit" className="w-full h-[50px] mt-2 bg-[#979797] text-white text-[18px]">
                  Reset Password
                </button>
                <a
                  role="link"
                  className="w-full mt-4 text-white text-[12px] text-center cursor-pointer"
                  tabIndex={0}
                  onClick={handleBackToLoginClicked}
                  onKeyPress={handleBackToLoginKeyPress}
                >
                  Back to Login
                </a>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
