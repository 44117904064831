import React, { useState } from 'react';
import { Field, FormikErrors, FormikTouched, useField } from 'formik';
import eyeIcon from '../../../../images/eye-icon.webp';
import ErrorText from '../ErrorText';

interface PasswordInputProps {
  className?: string;
  label?: string;
  name: string;
  placeholder?: string;
  errors?: FormikErrors<{ [field: string]: string }>;
  touched?: FormikTouched<{ [field: string]: boolean }>;
  onBlur?: Function;
  onChange?: Function;
}

const PasswordInput = ({
  className,
  label,
  name,
  placeholder,
  errors,
  touched,
  onChange,
  onBlur
}: PasswordInputProps) => {
  const error = touched[name] ? errors[name] : '';
  const [field] = useField(name);
  const [showPassword, setShowPassword] = useState(false);

  const handleOnBlur = (e: React.FocusEvent<any>) => {
    onBlur?.();
    field.onBlur(e);
  };

  const handleOnChange = (e: React.ChangeEvent<any>) => {
    onChange?.(e.target.value);
    field.onChange(e);
  };

  return (
    <div className={`${className}`}>
      <label htmlFor={name}>{label}</label>
      <div className="flex relative">
        <Field
          type={!showPassword ? 'password' : 'text'}
          className="w-full p-4 text-[14px] rounded-sm"
          name={name}
          autoComplete="current-password"
          placeholder={placeholder}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
        />

        <button type="button" className="absolute right-4 top-[14px]" onClick={() => setShowPassword((prev) => !prev)}>
          <img className={`h-6 opacity-${showPassword ? 100 : 50}`} src={eyeIcon} alt="view password" />
        </button>
      </div>
      <ErrorText error={error} />
    </div>
  );
};

export default PasswordInput;
