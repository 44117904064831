import { ColDef, CsvExportParams, IDateFilterParams } from 'ag-grid-community';
import { useMemo } from 'react';
import { DateTime } from 'luxon';
import ItemTable from '../ItemTable';
import LoadingSpinner from '../common/LoadingSpinner';
import { useSubmittedEmailsContext } from '../../contexts/SubmittedEmailsContext';
import SimpleCell from '../MenuItemTable/cells/SimpleCell';
import SimpleDateTimeCell from '../MenuItemTable/cells/SimpleDateTimeCell';
import MobileItemTable from '../MobileItemTable';

interface SubmittedEmailTableProps {
  isDesktop?: boolean;
}

const SubmittedEmailTable = ({ isDesktop }: SubmittedEmailTableProps) => {
  const { emails, isLoading } = useSubmittedEmailsContext();

  const restaurantEmails = useMemo(() => emails, [emails]);

  const exportParams: CsvExportParams = {
    fileName: `taptab_user_emails_${DateTime.now().toFormat('yyyyMMdd-HHmmss')}.csv`
  };

  const filterParams: Partial<IDateFilterParams> = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
      const cellDate = new Date(cellValue);

      if (filterLocalDateAtMidnight.getDate() === cellDate.getDate()) {
        return 0;
      }

      if (cellDate <= filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate >= filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2022,
    suppressAndOrCondition: true,
    buttons: ['apply', 'clear', 'reset', 'cancel'],
    closeOnApply: true,
    filterOptions: ['lessThanOrEqual', 'greaterThanOrEqual'],
    inRangeInclusive: true
  };

  const headerName = isDesktop ? 'SUBMITTED DATE' : 'DATE';
  const minWidth = isDesktop ? 150 : 200;

  const COLUMNS: ColDef[] = [
    {
      headerName: 'EMAIL',
      field: 'email',
      headerClass: 'name-header',
      cellRenderer: SimpleCell,
      minWidth,
      suppressMovable: true,
      filter: false
    },
    {
      headerName,
      field: 'createdAt',
      headerClass: 'custom-header',
      cellRenderer: SimpleDateTimeCell,
      minWidth: 150,
      suppressMovable: true,
      filter: 'agDateColumnFilter',
      filterParams
    }
  ];

  if (isLoading) {
    return (
      <div className="promotions-page-loading">
        <LoadingSpinner />
      </div>
    );
  }

  return isDesktop ? (
    <ItemTable
      items={restaurantEmails}
      columns={COLUMNS}
      draggable={false}
      rowHeight={30}
      pagination
      filter
      hasExport
      exportParams={exportParams}
    />
  ) : (
    <MobileItemTable
      items={restaurantEmails}
      columns={COLUMNS}
      draggable={false}
      rowHeight={45}
      pagination
      filter
      hasExport
      exportParams={exportParams}
    />
  );
};

export default SubmittedEmailTable;
