import { ColDef, RowDragEvent } from 'ag-grid-community';
import { useCallback } from 'react';
import { reorderMenuItems } from '../../api/menuItem';
import { useMenuContext } from '../../contexts/MenuContext';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { DetailedMenuItemInterface } from '../../types/MenuItemInterface';
import ItemTable from '../ItemTable';
import { SelectCharacteristicsModalRef } from '../SelectCharacteristicsModal';
import CustomizationCell from './cells/CustomizationCell';
import DescriptionCell from './cells/DescriptionCell';
import MenuItemNameCell from './cells/MenuItemNameCell';
import CharacteristicCell from './cells/CharacteristicCell';
import SizePriceCell from './cells/SizePriceCell';
import PairingCell from './cells/PairingCell';
import { SelectPairingsModalRef } from '../SelectPairingsModal';
import ModifierGroupsModalRef from '../ModifierGroupsModal/ModifierGroupsModalRef';
import CaloriesCell from './cells/CaloriesCell';
import MobileItemTable from '../MobileItemTable/MobileItemTable';

interface MenuItemTableProps {
  menuItems: DetailedMenuItemInterface[];
  isDesktop?: boolean;
}

const MenuItemTable = ({ menuItems, isDesktop }: MenuItemTableProps) => {
  const { selectedMenuSectionID: menuSectionID, updateMenuItems, menu } = useMenuContext();
  const { dispatch } = useModalContext();

  const nameCellGetter = (params: any) => ({
    name: params.data.name,
    menuItemID: params.data.menuItemID,
    menuSectionID,
    category: params.data.category,
    media: params.data?.media,
    isHidden: params.data.isHidden,
    tags: params.data.tags
  });

  const modifierGroupsCellGetter = (params: any) => ({
    modifierGroups: params.data?.modifierGroups,
    menuItemID: params.data.menuItemID,
    menuSectionID,
    isHidden: params.data.isHidden,
    name: params.data.name
  });

  const characteristicsCellGetter = (params: any) => ({
    characteristics: params.data.dietaryRestrictions,
    name: params.data.name,
    menuItemID: params.data.menuItemID,
    menuSectionID,
    isHidden: params.data.isHidden
  });

  const pairingsCellGetter = (params: any) => ({
    pairings: params.data.pairings,
    name: params.data.name,
    menuItemID: params.data.menuItemID,
    menuSectionID,
    isHidden: params.data.isHidden
  });

  const isHiddenGetter = (params: any, field: string) => ({
    [field]: params.data?.[field],
    isHidden: params.data.isHidden,
    isPrixFixe: menu.isPrixFixe
  });

  const COLUMNS: ColDef[] = isDesktop
    ? [
        {
          headerName: 'NAME',
          valueGetter: nameCellGetter,
          headerClass: 'name-header',
          cellRenderer: MenuItemNameCell,
          minWidth: 250,
          wrapText: true,
          autoHeight: true,
          suppressMovable: true
        },
        {
          headerName: 'DESCRIPTION',
          valueGetter: (params) => isHiddenGetter(params, 'description'),
          headerClass: 'custom-header',
          cellRenderer: DescriptionCell,
          minWidth: 200,
          suppressMovable: true,
          wrapText: true,
          autoHeight: true
        },
        {
          headerName: 'SIZE / PRICE',
          valueGetter: (params) => isHiddenGetter(params, 'allItemSizes'),
          headerClass: 'custom-header',
          cellRenderer: SizePriceCell,
          minWidth: 80,
          suppressMovable: true
        },
        {
          headerName: 'CALORIES',
          valueGetter: (params) => isHiddenGetter(params, 'calories'),
          headerClass: 'custom-header',
          cellRenderer: CaloriesCell,
          minWidth: 80,
          suppressMovable: true
        },
        {
          headerName: 'MODIFIER GROUPS',
          valueGetter: modifierGroupsCellGetter,
          headerClass: 'custom-header',
          cellRenderer: CustomizationCell,
          minWidth: 150,
          suppressMovable: true,
          editable: true,
          cellEditorPopup: true,
          singleClickEdit: true,
          cellEditor: ModifierGroupsModalRef
        },
        {
          headerName: 'CHARACTERISTICS',
          valueGetter: characteristicsCellGetter,
          headerClass: 'custom-header',
          cellRenderer: CharacteristicCell,
          minWidth: 150,
          suppressMovable: true,
          editable: true,
          cellEditorPopup: true,
          singleClickEdit: true,
          cellEditor: SelectCharacteristicsModalRef
        },
        {
          headerName: 'PAIRS',
          valueGetter: pairingsCellGetter,
          headerClass: 'custom-header',
          cellRenderer: PairingCell,
          minWidth: 80,
          suppressMovable: true,
          editable: (params) => params.data.category === 'food',
          cellEditorPopup: true,
          singleClickEdit: true,
          cellEditor: SelectPairingsModalRef
        }
      ]
    : [
        {
          headerName: 'NAME',
          valueGetter: nameCellGetter,
          headerClass: 'name-header',
          cellRenderer: MenuItemNameCell,
          minWidth: 250,
          wrapText: true,
          autoHeight: true,
          suppressMovable: true,
          rowDrag: true
        },
        {
          headerName: 'SIZE / PRICE',
          valueGetter: (params) => isHiddenGetter(params, 'allItemSizes'),
          headerClass: 'custom-header',
          cellRenderer: SizePriceCell,
          minWidth: 80,
          suppressMovable: true
        }
      ];

  const onRowDragEnd = useCallback(
    async (e: RowDragEvent) => {
      try {
        const reorderedMenuItems = menuItems.slice();
        const movedItemID = e.node.data.menuItemID;
        const fromIndex = reorderedMenuItems.findIndex((menuItem) => menuItem.menuItemID === movedItemID);
        const toIndex = e.overIndex;

        if (fromIndex === toIndex) return;

        const movedMenuItem = reorderedMenuItems[fromIndex];
        reorderedMenuItems.splice(fromIndex, 1);
        reorderedMenuItems.splice(toIndex, 0, movedMenuItem);

        await reorderMenuItems(
          menuSectionID,
          reorderedMenuItems.map((menuItem) => menuItem.menuItemID)
        );
        updateMenuItems(reorderedMenuItems, menuSectionID);
      } catch (error) {
        openModal({ dispatch });
      }
    },
    [menuItems, menuSectionID, updateMenuItems, dispatch]
  );

  if (menuItems.length > 0) {
    return isDesktop ? (
      <ItemTable className="menu-item-table-container" items={menuItems} columns={COLUMNS} onDragEnd={onRowDragEnd} />
    ) : (
      <div className="mobile-menu-item-table-container" style={{ marginTop: '-15px' }}>
        <MobileItemTable items={menuItems} columns={COLUMNS} />
      </div>
    );
  }

  return null;
};

export default MenuItemTable;
