import Button from '../common/Button';
import Modal from '../common/Modal';

interface ErrorModalProps {
  modalTitle?: string;
  modalMessage?: string;
  onDismiss: Function;
}

const ErrorModal = ({ modalTitle, modalMessage, onDismiss }: ErrorModalProps) => {
  const getTitle = (title: string): string => title || 'Oops! Something went wrong!';
  const getMessage = (message: string): string =>
    message || 'If this issue keeps occurring please reach out to the TapTab team.';

  const handleDismiss = () => {
    onDismiss?.();
  };

  return (
    <Modal>
      <div className="modal-header">
        <h1 className="modal-title">{getTitle(modalTitle)}</h1>
      </div>
      <div className="modal-body whitespace-pre-wrap">{getMessage(modalMessage)}</div>
      <div className="modal-footer generic-modal-footer">
        <Button onClick={handleDismiss} className="modal-button">
          DISMISS
        </Button>
      </div>
    </Modal>
  );
};

export default ErrorModal;
