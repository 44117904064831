import React, { useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  TouchSensor,
  MouseSensor,
  ScreenReaderInstructions,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates
} from '@dnd-kit/sortable';
import { restrictToHorizontalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import MultipleMediaPreview from './MultipleMediaPreview';
import MultipleMediaUpload from './MultipleMediaUpload';
import { ErrorText } from '../common/Form';
import { BlobWrapper, MediaInterface, ThumbnailInterface } from '../../types/MediaInterface';

interface MultipleMediaUploaderProps {
  media: (BlobWrapper | MediaInterface)[];
  thumbnail?: Blob | ThumbnailInterface;
  onUpdate: Function;
  onEditVideo?: Function;
  type?: 'image' | 'video' | 'multi';
  index?: number | string;
  multiple?: boolean;
  maxImages?: number;
}

const IMAGE_SIZE_LIMIT = 750000;
const VIDEO_SIZE_LIMIT = 32000000;
const IMAGE_COUNT_LIMIT = 3;
const VIDEO_COUNT_LIMIT = 1;
// 9:16 aspect ratio
const VIDEO_ASPECT_RATIO = 0.5625;
const MEDIA_MIME_TYPES = {
  IMAGE: 'image/.jpg,.jpeg,.png,.gif,.webp',
  VIDEO: 'video/.mp4,.MP4,.mov,.MOV,.webm',
  MULTI: 'image/.jpg,.jpeg,.png,.gif,.webp,video/.mp4,.MP4,.mov,.MOV,.webm'
};

window.URL = window.URL || window.webkitURL;

const MultipleMediaUploader = ({
  media,
  thumbnail,
  onEditVideo,
  onUpdate,
  type = 'multi',
  multiple = true,
  index = 0,
  maxImages = IMAGE_COUNT_LIMIT
}: MultipleMediaUploaderProps) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleDeletePreview = (idOfMedia: number) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    const _media: (BlobWrapper | MediaInterface)[] = media.slice();
    const idx = _media.findIndex((mediaToDelete) => mediaToDelete?.mediaID === idOfMedia);
    _media.splice(idx, 1);
    onUpdate?.(_media);
  };

  const handleEditPreview = (idOfMedia: number) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    onEditVideo?.(idOfMedia);
  };

  /**
   * Validate the meta data for uploaded video files.
   * Performs check on duration limit of video and aspect ratio.
   * @param videos
   */
  const validateVideoMetadata = (videos: Blob[]): Promise<boolean> =>
    new Promise((resolve, reject) => {
      for (let i = 0; i < videos.length; i++) {
        const video = document.createElement('video');
        video.hidden = true;
        video.preload = 'metadata';
        video.id = `video${i}`;

        video.onerror = reject;
        video.onloadedmetadata = () => {
          window.URL.revokeObjectURL(video.src);

          if (Number.isNaN(video.duration)) {
            reject(new Error('Video upload failed due to corrupt media file.'));
          }

          if (Math.floor(video.duration) > 15) {
            reject(new Error('Video duration exceeds limit of 15 seconds.'));
          }

          if (Math.floor(video.duration) < 2) {
            reject(new Error('Video duration must be at least 2 seconds.'));
          }

          if (
            video.videoWidth / video.videoHeight <= VIDEO_ASPECT_RATIO - 0.1 &&
            video.videoWidth / video.videoHeight >= VIDEO_ASPECT_RATIO + 0.1
          ) {
            reject(
              new Error('Video must have an aspect ratio of 9:16, meaning the height should be twice their width.')
            );
          }

          if (i === videos.length - 1) {
            resolve(true);
          }
        };
        video.src = window.URL.createObjectURL(videos[i]);
      }
    });

  const validateMediaUpload = async (fileList: FileList): Promise<boolean> => {
    const videos: Blob[] = [];
    const images: Blob[] = [];

    const validateMediaCount = (
      array: (BlobWrapper | MediaInterface)[],
      uploadedArray: Blob[],
      mediaType: string,
      limit: number
    ): boolean =>
      array.filter(
        (_media) =>
          ('file' in _media && _media?.file?.type?.includes(mediaType)) ||
          ('type' in _media && _media.type === mediaType)
      ).length +
        uploadedArray.length >
      limit;

    for (let i = 0; i < fileList?.length; i++) {
      if (fileList?.item(i).type?.includes('image')) {
        images.push(fileList?.item(i));
      } else if (fileList?.item(i).type?.includes('video')) {
        videos.push(fileList?.item(i));
      }
    }

    if (validateMediaCount(media, images, 'image', maxImages)) {
      setErrorMessage(`Amount of photos uploaded exceeds limit of ${maxImages}.`);
      return false;
    }

    for (let i = 0; i < images?.length; i++) {
      if (images[i]?.size > IMAGE_SIZE_LIMIT) {
        setErrorMessage(`Image: ${(images[i] as File)?.name} size exceeds limit of 750 KB.`);
        return false;
      }
    }

    if (videos.length > 0) {
      if (validateMediaCount(media, videos, 'video', VIDEO_COUNT_LIMIT)) {
        setErrorMessage(`Amount of videos uploaded exceeds limit of ${VIDEO_COUNT_LIMIT}.`);
        return false;
      }

      for (let i = 0; i < videos?.length; i++) {
        if (videos[i]?.size > VIDEO_SIZE_LIMIT) {
          setErrorMessage(`Video: ${(videos[i] as File)?.name} size exceeds limit of 32 MB.`);
          return false;
        }
      }

      try {
        await validateVideoMetadata(videos);
      } catch (error) {
        setErrorMessage(error.message);
        return false;
      }
    }

    if (errorMessage) {
      setErrorMessage('');
    }

    return true;
  };

  const handleMediaUpload = async (fileList: FileList) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    if (await validateMediaUpload(fileList)) {
      const _media: (BlobWrapper | MediaInterface)[] = media.slice();

      for (let i = 0; i < fileList?.length; i++) {
        _media.push({
          mediaID: Date.now() + Math.floor(Math.random() * 1000),
          file: fileList.item(i)
        });
      }

      onUpdate?.(_media);
    }
  };

  const handleMediaReorder = async ({ active, over }: DragEndEvent) => {
    if (!over) {
      return;
    }

    if (media?.length < 2) {
      return;
    }

    if (errorMessage) {
      setErrorMessage('');
    }

    const oldIndex = media.findIndex((_media) => _media.mediaID.toString() === active.id);
    const newIndex = media.findIndex((_media) => _media.mediaID.toString() === over.id);

    const reorderedMedia = arrayMove(media, oldIndex, newIndex);
    onUpdate?.(reorderedMedia);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 2
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 2
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const screenReaderInstructions: ScreenReaderInstructions = {
    draggable: `
    To pick up a sortable item, press the space bar.
    While sorting, use the arrow keys to move the item.
    Press space again to drop the item in its new position, or press escape to cancel.
  `
  };

  const getAcceptType = (): string =>
    // @ts-ignore
    MEDIA_MIME_TYPES[type.toUpperCase()];

  const getThumbnailForPreview = (
    _media: BlobWrapper | MediaInterface,
    _thumbnail: Blob | ThumbnailInterface,
    _type: 'image' | 'video' | 'multi'
  ): Blob | ThumbnailInterface => {
    if ('file' in _media && (_media as BlobWrapper)?.file?.type?.includes('video') && thumbnail && _type !== 'video') {
      return _thumbnail;
    }
    return null;
  };

  return (
    <div className="multiple-media-uploader-container">
      <div className="multiple-media-uploader">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          screenReaderInstructions={screenReaderInstructions}
          onDragEnd={handleMediaReorder}
          modifiers={[restrictToHorizontalAxis, restrictToWindowEdges]}
        >
          {media && (
            <div className="multiple-media-drag-and-drop">
              <SortableContext
                strategy={horizontalListSortingStrategy}
                items={media?.map((_item: BlobWrapper | MediaInterface) => _item.mediaID?.toString())}
              >
                {media?.map((_media) => (
                  <MultipleMediaPreview
                    key={_media.mediaID}
                    id={multiple ? _media.mediaID : index}
                    media={'file' in _media ? _media.file : _media}
                    multiple={multiple}
                    onDelete={handleDeletePreview}
                    onEdit={handleEditPreview}
                    thumbnail={getThumbnailForPreview(_media, thumbnail, type)}
                  />
                ))}
              </SortableContext>
            </div>
          )}
        </DndContext>
        {((multiple && media?.length < maxImages + VIDEO_COUNT_LIMIT) || (!multiple && media?.length === 0)) && (
          <MultipleMediaUpload
            acceptTypes={getAcceptType()}
            onUpload={handleMediaUpload}
            index={index}
            multiple={multiple}
          />
        )}
      </div>
      <ErrorText
        className={`media-upload-error-message ${errorMessage?.length > 0 ? 'has-error' : ''}`}
        error={errorMessage}
      />
    </div>
  );
};

export default MultipleMediaUploader;
