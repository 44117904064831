import React, { useState, useRef, useEffect } from 'react';
import { addMethod, InferType, object, string, StringSchema, TestContext } from 'yup';
import { toast } from 'react-toastify';
import Modal from '../common/Modal';
import ModalAnnouncementForm from './ModalAnnouncementForm';
import { Dropdown, Label } from '../common/Form';
import {
  ANNOUNCEMENT_TYPE_VALUES,
  ANNOUNCEMENT_TYPES,
  AnnouncementType
} from '../../constants/AnnouncementTypeConstants';
import EmbeddedAnnouncementForm from './EmbeddedAnnouncementForm';
import { displayLoading, hideLoading, useAsyncContext } from '../../contexts/AsyncContext';
import {
  AnnouncementStatusInterface,
  CreateAnnouncementRequestInterface,
  EditAnnouncementRequestInterface
} from '../../types/AnnouncementInterface';
import {
  createAnnouncement,
  deleteAnnouncement,
  editAnnouncement,
  linkMediaToAnnouncement
} from '../../api/announcements';
import { useAnnouncementsContext } from '../../contexts/AnnouncementsContext';
import PromotionPreviewIconButton from '../PromotionPreviewIconButton';
import { ClosePopUpIcon } from '../../assets/svgs/icons';
import { PROMOTION_TYPE_VALUES, PROMOTION_TYPES, PromotionType } from '../../constants/PromotionTypeConstants';
import { MenuItemMediaInterface } from '../../types/MediaInterface';
import { useMediaLibraryContext } from '../../contexts/MediaLibraryContext';
import useResponsive from '../../hooks/useResponsive';

interface AnnouncementModalProps {
  announcementID?: number;
  image?: MenuItemMediaInterface;
  modalType?: AnnouncementType;
  promotionType?: PromotionType;
  isEdit: boolean;
  onSubmit: Function;
  onCancel: Function;
}

addMethod<StringSchema>(string, 'after', function isAfterDate(message: string) {
  return this.test(
    'after',
    message,
    (date: string, context: TestContext<any>) => new Date(date) > new Date(context.parent.startDate)
  );
});

export const ModalAnnouncementSchema = object({
  name: string().default('').required('Name is required.'),
  title: string().default('').required('Title is required.').max(70, 'Title must be less than 70 characters.'),
  description: string().default('').required('Description is required.'),
  startDate: string().default('').required('Start date is required.'),
  // @ts-ignore
  endDate: string().default('').after('End date must be after the start date.').required('End date is required.')
});

export const EmbeddedAnnouncementSchema = object({
  name: string().default('').required('Name is required.'),
  startDate: string().default('').required('Start date is required.'),
  // @ts-ignore
  endDate: string().default('').after('End date must be after the start date.').required('End date is required.')
});

const AnnouncementModal = ({
  announcementID,
  image,
  modalType,
  promotionType,
  isEdit,
  onSubmit,
  onCancel
}: AnnouncementModalProps) => {
  const { addAnnouncement, getAnnouncement, updateAnnouncement } = useAnnouncementsContext();
  const { dispatch } = useAsyncContext();
  const { loadMedia } = useMediaLibraryContext();
  const { isDesktop } = useResponsive();

  const formikRef = useRef();

  const [type, setType] = useState<AnnouncementType>(modalType || ANNOUNCEMENT_TYPES.MODAL);
  const [promo, setPromo] = useState<PromotionType>(
    modalType === ANNOUNCEMENT_TYPES.EMBEDDED
      ? PROMOTION_TYPES.ANNOUNCEMENT
      : promotionType || PROMOTION_TYPES.ANNOUNCEMENT
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [media, setMedia] = useState<MenuItemMediaInterface>(image ?? undefined);
  const [hasBeenEdited, setHasBeenEdited] = useState<boolean>(false);
  const [previewTitle, setPreviewTitle] = useState<string>(isEdit ? getAnnouncement(announcementID)?.title || '' : '');
  const [previewDescription, setPreviewDescription] = useState<string>(
    isEdit ? getAnnouncement(announcementID)?.description || '' : ''
  );

  useEffect(() => {
    // only want to load the restaurants user media if manager page is being utilized
    // call to load media on initial load
    loadMedia();
  }, [loadMedia]);

  const handleAnnouncementSubmit = async (
    data: InferType<typeof ModalAnnouncementSchema | typeof EmbeddedAnnouncementSchema>
  ) => {
    const schema =
      type === ANNOUNCEMENT_TYPES.MODAL || type === ANNOUNCEMENT_TYPES.DRAWER
        ? ModalAnnouncementSchema
        : EmbeddedAnnouncementSchema;
    if (schema.isValidSync(data)) {
      try {
        if (type === ANNOUNCEMENT_TYPES.EMBEDDED && !media) {
          setErrorMessage('Image is required.');
          return;
        }

        displayLoading({ dispatch, message: `${isEdit ? 'Updating' : 'Creating'} Promotion...` });
        if (isEdit) {
          const existingAnnouncement = getAnnouncement(announcementID);

          const editAnnouncementRequest: EditAnnouncementRequestInterface = {
            announcementID,
            startDate: data.startDate,
            endDate: data.endDate,
            ...(promo !== promotionType && {
              submitEmail: promo === PROMOTION_TYPES.EMAIL_SUBMISSION && type !== ANNOUNCEMENT_TYPES.EMBEDDED
            })
          };

          if (type !== existingAnnouncement.type) {
            editAnnouncementRequest.type = type;
          }
          if (data.name.trim() !== existingAnnouncement.name) {
            editAnnouncementRequest.name = data.name.trim();
          }

          if (type === ANNOUNCEMENT_TYPES.MODAL || type === ANNOUNCEMENT_TYPES.DRAWER) {
            // @ts-ignore
            if (data.title.trim() !== existingAnnouncement.title) {
              // @ts-ignore
              editAnnouncementRequest.title = data.title.trim();
            }

            // @ts-ignore
            if (data.description.trim() !== existingAnnouncement.description) {
              // @ts-ignore
              editAnnouncementRequest.description = data.description.trim();
            }
          }

          await linkMediaToAnnouncement(announcementID, media ? [media.mediaID] : []);

          const response: AnnouncementStatusInterface = await editAnnouncement(editAnnouncementRequest);

          updateAnnouncement(announcementID, {
            ...editAnnouncementRequest,
            active: response.active,
            image: media ? { imageID: media.mediaID, imageURL: media.mediaURL } : undefined
          });
        } else {
          let createAnnouncementRequest: CreateAnnouncementRequestInterface;
          if (type === ANNOUNCEMENT_TYPES.EMBEDDED) {
            createAnnouncementRequest = {
              name: data.name.trim(),
              startDate: data.startDate,
              endDate: data.endDate,
              type
            };
          } else {
            createAnnouncementRequest = {
              name: data.name.trim(),
              // @ts-ignore
              title: data.title.trim(),
              // @ts-ignore
              description: data.description.trim(),
              startDate: data.startDate,
              endDate: data.endDate,
              type,
              submitEmail: promo === PROMOTION_TYPES.EMAIL_SUBMISSION
            };
          }

          const createdAnnouncement = await createAnnouncement(createAnnouncementRequest);

          try {
            if (media) {
              await linkMediaToAnnouncement(createdAnnouncement.announcementID, media ? [media.mediaID] : []);
            }
          } catch (error) {
            if (error?.response?.status === 413) {
              setErrorMessage(
                'File size of image exceeds limit of 750 KB. Please check the size of the image and try again.'
              );
            } else {
              setErrorMessage(
                `Error occurred while uploading promotion image. If this issue keeps occurring please reach out to the TapTab team.`
              );
            }
            if (!isEdit) {
              await deleteAnnouncement(createdAnnouncement.announcementID);
            }
            hideLoading(dispatch);
            return;
          }

          addAnnouncement({
            ...createdAnnouncement,
            image: media ? { imageID: media.mediaID, imageURL: media.mediaURL } : undefined
          });
        }
        if (errorMessage) {
          setErrorMessage('');
        }
        toast.success(`Successfully ${isEdit ? 'Updated' : 'Created'} Promotion!`, {
          position: toast.POSITION.TOP_RIGHT
        });
        onSubmit();
      } catch (error) {
        if (error.response?.status === 409) {
          if (error.response.data?.errors?.[0]?.message?.includes('overlapping')) {
            setErrorMessage(
              `${error.response.data?.errors?.[0]?.message} Please enter a timespan that starts and ends before this time frame, or starts and ends after this timeframe.`
            );
          } else {
            setErrorMessage('Promotion with provided name already exists for restaurant.');
          }
        } else {
          setErrorMessage(
            `Unexpected error occurred while ${
              isEdit ? 'editing' : 'creating'
            } promotion. Check your input and try again. If this issue keeps occurring please reach out to the TapTab team.`
          );
        }
      } finally {
        hideLoading(dispatch);
      }
    }
  };

  const handleImageUpload = (_media: MenuItemMediaInterface) => {
    if (errorMessage) {
      setErrorMessage('');
    }
    setMedia(_media);
  };

  const handleFormEdited = () => {
    if (!hasBeenEdited) {
      setHasBeenEdited(true);
    }
  };

  const handleTitleChanged = (value: string) => {
    setPreviewTitle(value);
  };

  const handleDescriptionChanged = (value: string) => {
    setPreviewDescription(value);
  };

  const handleLayoutChanged = (value: AnnouncementType) => {
    if (image || hasBeenEdited) {
      setType(value);
    } else {
      setType(value);
      setPromo(PROMOTION_TYPES.ANNOUNCEMENT);
    }
  };

  const handlePromoChanged = (value: PromotionType) => {
    if (value === PROMOTION_TYPES.EMAIL_SUBMISSION && type === ANNOUNCEMENT_TYPES.EMBEDDED) {
      setType(ANNOUNCEMENT_TYPES.MODAL);
    }

    setPromo(value);
  };

  const getForm = (_type: AnnouncementType) => {
    if (_type === ANNOUNCEMENT_TYPES.MODAL || _type === ANNOUNCEMENT_TYPES.DRAWER) {
      return (
        <ModalAnnouncementForm
          announcementID={announcementID}
          image={media}
          isEdit={isEdit}
          errorMessage={errorMessage}
          onSubmit={(data: InferType<typeof ModalAnnouncementSchema>) => handleAnnouncementSubmit(data)}
          onImageUpload={handleImageUpload}
          onTitleChange={handleTitleChanged}
          onDescriptionChange={handleDescriptionChanged}
          onCancel={onCancel}
          onEdited={handleFormEdited}
          formikRef={formikRef}
          isDesktop={isDesktop}
        >
          <div className="announcement-type-container">
            <Label label="TYPE" className="announcement-type-label" />
            <Dropdown
              className="announcement-modal-type-select"
              onChange={handlePromoChanged}
              value={promo}
              values={PROMOTION_TYPE_VALUES}
            />
          </div>
          <div className="announcement-type-container">
            <Label
              label="LAYOUT"
              className="announcement-type-label"
              icon={
                <PromotionPreviewIconButton
                  title={previewTitle}
                  description={previewDescription}
                  type={type}
                  image={media?.mediaURL}
                />
              }
            />
            <Dropdown
              className="announcement-modal-type-select"
              onChange={handleLayoutChanged}
              value={type}
              values={
                promo === PROMOTION_TYPES.EMAIL_SUBMISSION
                  ? ANNOUNCEMENT_TYPE_VALUES.filter((value) => value.value !== ANNOUNCEMENT_TYPES.EMBEDDED)
                  : ANNOUNCEMENT_TYPE_VALUES
              }
            />
          </div>
        </ModalAnnouncementForm>
      );
    }
    return (
      <EmbeddedAnnouncementForm
        announcementID={announcementID}
        image={media}
        isEdit={isEdit}
        errorMessage={errorMessage}
        onSubmit={(data: InferType<typeof EmbeddedAnnouncementSchema>) => handleAnnouncementSubmit(data)}
        onImageUpload={handleImageUpload}
        onCancel={onCancel}
        onEdited={handleFormEdited}
        isDesktop={isDesktop}
      >
        <div className="announcement-type-container">
          <Label label="TYPE" className="announcement-type-label" />
          <Dropdown
            className="announcement-modal-type-select"
            onChange={handlePromoChanged}
            value={promo}
            values={PROMOTION_TYPE_VALUES}
          />
        </div>
        <div className="announcement-type-container">
          <Label
            label="LAYOUT"
            className="announcement-type-label"
            icon={
              <PromotionPreviewIconButton
                title={previewTitle}
                description={previewDescription}
                type={type}
                image={media?.mediaURL}
              />
            }
          />
          <Dropdown
            className="announcement-modal-type-select"
            onChange={handleLayoutChanged}
            value={type}
            values={
              promo === PROMOTION_TYPES.EMAIL_SUBMISSION
                ? ANNOUNCEMENT_TYPE_VALUES.filter((value) => value.value !== ANNOUNCEMENT_TYPES.EMBEDDED)
                : ANNOUNCEMENT_TYPE_VALUES
            }
          />
        </div>
      </EmbeddedAnnouncementForm>
    );
  };

  return (
    <Modal className="announcement-modal">
      <div className="modal-header">
        <h1 className="modal-title">{isEdit ? 'EDIT' : 'CREATE'} PROMOTION</h1>
        <ClosePopUpIcon onIconClicked={onCancel} width="16" height="17" />
      </div>
      <div className="modal-body">{getForm(type)}</div>
    </Modal>
  );
};

export default AnnouncementModal;
