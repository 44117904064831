export const PressPageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14" fill="none">
    <path
      d="M3.5 2C3.5 1.17157 4.17157 0.5 5 0.5H17C17.8284 0.5 18.5 1.17157 18.5 2V9.59537C18.5 11.5284 16.933 13.0954 15 13.0954H3.5V2Z"
      fill="white"
      stroke="#000000"
    />
    <path
      d="M3.5 11.6016C3.5 12.43 2.82843 13.1016 2 13.1016C1.17157 13.1016 0.5 12.43 0.5 11.6016V4.60156C0.5 3.77314 1.17157 3.10156 2 3.10156H3.5V11.6016Z"
      fill="white"
      stroke="#000000"
    />
    <rect x="6.5" y="4" width="3" height="2" fill="#979797" stroke="#979797" />
    <rect x="11.25" y="3.75" width="4.5" height="0.5" rx="0.25" fill="black" stroke="#979797" strokeWidth="0.5" />
    <rect x="11.25" y="5.75" width="4.5" height="0.5" rx="0.25" fill="black" stroke="#979797" strokeWidth="0.5" />
    <rect x="6.25" y="7.75" width="9.5" height="0.5" rx="0.25" fill="black" stroke="#979797" strokeWidth="0.5" />
    <rect x="6.25" y="9.75" width="9.5" height="0.5" rx="0.25" fill="black" stroke="#979797" strokeWidth="0.5" />
    <mask id="path-8-inside-1_4160_4202" fill="white">
      <path d="M2 12.6016H11V13.6016H2V12.6016Z" />
    </mask>
    <path d="M2 12.6016H11V13.6016H2V12.6016Z" fill="black" />
    <path
      d="M2 12.6016V11.6016H1V12.6016H2ZM11 12.6016H12V11.6016H11V12.6016ZM11 13.6016V14.6016H12V13.6016H11ZM2 13.6016H1V14.6016H2V13.6016ZM2 13.6016H11V11.6016H2V13.6016ZM10 12.6016V13.6016H12V12.6016H10ZM11 12.6016H2V14.6016H11V12.6016ZM3 13.6016V12.6016H1V13.6016H3Z"
      fill="#979797"
      mask="url(#path-8-inside-1_4160_4202)"
    />
  </svg>
);

export default PressPageIcon;
