import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { compareArrays } from '../../utils/general';
import EntitySelector from '../EntitySelector';
import SelectorModal from '../SelectorModal';
import { PairingInterface } from '../../types/PairingInterface';
import { useDrinkItemsContext } from '../../contexts/DrinkItemsContext';
import { PAGE_CREATE_DRINK_ITEM } from '../../constants/UriConstants';

interface SelectPairingsModalProps {
  itemName: string;
  originalPairingIDs: number[];
  closeModal: Function;
  handleSubmit: Function;
  isDesktop?: boolean;
}

const SelectPairingsModal = ({
  itemName,
  originalPairingIDs,
  closeModal,
  handleSubmit,
  isDesktop
}: SelectPairingsModalProps) => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>('');
  const [selectedPairings, setSelectedPairings] = useState<number[]>(originalPairingIDs);
  const { drinks, hasError: fetchDrinksError } = useDrinkItemsContext();
  const navigate = useNavigate();

  const handleSelectionToggled = (pairingID: number) => {
    const selected = selectedPairings.slice();
    const idx = selectedPairings.findIndex((item) => item === pairingID);
    if (idx === -1) {
      selected.push(pairingID);
    } else {
      selected.splice(idx, 1);
    }

    setSelectedPairings(selected);
  };

  const onSubmitClicked = async (pairingIDs: number[]) => {
    try {
      if (!compareArrays(originalPairingIDs, pairingIDs)) {
        handleSubmit(pairingIDs);
      } else {
        setSubmitErrorMessage(`Selected pairings must be updated to submit.`);
      }
    } catch (error) {
      setSubmitErrorMessage(`An unexpected error has occurred while adding pairings to ${itemName}.`);
    }
  };

  return (
    <SelectorModal
      title="SELECT A PAIRING"
      submitText={isDesktop ? 'ADD SELECTED PAIRINGS' : 'ADD'}
      createText={isDesktop ? 'CREATE DRINK' : 'CREATE'}
      hasValues={drinks.length > 0}
      onSubmit={() => onSubmitClicked(selectedPairings)}
      onCancel={() => closeModal()}
      onCreate={() => navigate(`/${PAGE_CREATE_DRINK_ITEM}`)}
    >
      {drinks?.length > 0 && <div className="modal-message">Please select any drink to pair with the {itemName}.</div>}
      {fetchDrinksError && <div className="selection-error">An error has occurred while fetching drinks.</div>}
      {!fetchDrinksError && (
        <>
          {submitErrorMessage && <div className="selection-error">{submitErrorMessage}</div>}
          <EntitySelector
            entities={drinks.map((drink: PairingInterface) => ({
              id: drink.drinkItemID,
              ...drink
            }))}
            noContextCopy={
              drinks?.length === 0 ? (
                <div>
                  Sorry, there are no drinks to search for at the moment. Please create a drink to pair with the{' '}
                  {itemName}.
                </div>
              ) : (
                <div>
                  Sorry, no results were found for your search criteria.
                  <Link to="/create-drink-item">Click here</Link>
                  to create a new drink to pair with the {itemName}.
                </div>
              )
            }
            selected={selectedPairings}
            onSelectionToggle={handleSelectionToggled}
          />
        </>
      )}
    </SelectorModal>
  );
};

export default SelectPairingsModal;
