import { createContext, ReactElement, useMemo, useContext, useState, useEffect, useCallback } from 'react';
import { useRestaurantContext } from '../RestaurantContext';
import { sortArrayAlphabetically } from '../../utils/general';
import { ModifierInterface } from '../../types/ModifierInterface';
import { getModifiers } from '../../api/modifiers';

interface ModifiersContextInterface {
  hasError: boolean;
  isLoading: boolean;
  modifiers: ModifierInterface[];
  addModifier: Function;
  removeModifier: Function;
  updateModifier: Function;
}

interface ModifiersProviderInterface {
  children: ReactElement;
}

const ModifiersContext = createContext<ModifiersContextInterface>({
  hasError: false,
  isLoading: false,
  modifiers: null,
  addModifier: () => {},
  removeModifier: () => {},
  updateModifier: () => {}
});

const ModifiersProvider = ({ children }: ModifiersProviderInterface) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [modifiers, setModifiers] = useState<ModifierInterface[]>([]);
  const [currentRestaurantID, setCurrentRestaurantID] = useState<number>(-1);

  const { currentRestaurant } = useRestaurantContext();

  useEffect(() => {
    let canceled = false;
    if (currentRestaurant && currentRestaurant.restaurantID !== currentRestaurantID) {
      setIsLoading(true);

      getModifiers()
        .then((response: ModifierInterface[]) => {
          if (canceled) return;

          setModifiers(response);
          setCurrentRestaurantID(currentRestaurant.restaurantID);
          setIsLoading(false);
        })
        .catch((error) => {
          if (canceled) return;

          if (error.response.status !== 401) {
            // on non auth related failure indicate failure has occurred
            setHasError(true);
          }
          setIsLoading(false);
        });
    }

    return () => {
      canceled = true;
    };
  }, [currentRestaurant, currentRestaurantID]);

  const addModifier = useCallback(
    (modifierArray: ModifierInterface[]) => {
      const _modifiers: ModifierInterface[] = modifiers.slice();
      setModifiers(sortArrayAlphabetically(_modifiers.concat(modifierArray), 'name'));
    },
    [modifiers]
  );

  const removeModifier = useCallback(
    (modifierIDs: number[]) => {
      const _modifiers = modifiers.slice();
      modifierIDs.forEach((modifierID) => {
        const modifierIndex = _modifiers.findIndex((modifier: ModifierInterface) => modifier.modifierID === modifierID);
        if (modifierIndex !== -1) {
          _modifiers.splice(modifierIndex, 1);
        }
      });

      setModifiers(_modifiers);
    },
    [modifiers]
  );

  const updateModifier = useCallback(
    (modifier: ModifierInterface) => {
      const _modifiers: ModifierInterface[] = modifiers.slice();
      const modifierIndex = _modifiers.findIndex((mod: ModifierInterface) => mod.modifierID === modifier.modifierID);
      _modifiers.splice(modifierIndex, 1, { ..._modifiers[modifierIndex], ...modifiers });
      setModifiers(sortArrayAlphabetically(_modifiers, 'name'));
    },
    [modifiers]
  );

  const providerValue = useMemo(
    () => ({ hasError, isLoading, modifiers, addModifier, removeModifier, updateModifier }),
    [hasError, isLoading, modifiers, addModifier, removeModifier, updateModifier]
  );
  return <ModifiersContext.Provider value={providerValue}>{children}</ModifiersContext.Provider>;
};

const useModifiersContext = () => {
  const context = useContext(ModifiersContext);
  if (!context) {
    throw new Error(`ModifiersContext must be used within the ModifiersProvider component`);
  }
  return context;
};

export { ModifiersProvider as default, useModifiersContext };
