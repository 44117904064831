import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Input, Label, SizePriceInput, TextArea } from '../../../components/common/Form';
import Button from '../../../components/common/Button';
import ItemWizardMenuSelector from '../../../components/ItemWizardMenuSelector';
import { MediaLibraryInterface, MenuItemMediaInterface } from '../../../types/MediaInterface';
import MediaLibraryModal from '../../../components/MediaLibraryModal';
import MediaPreview from '../../../components/MediaPreview';
import VideoSelectorModal from '../../../components/VideoSelectorModal';
import imageDisplay from '../../../assets/images/ImageDisplay.png';

interface ItemBasicInfoPageProps {
  menuID: number;
  menuSectionID: number;
  isPrixFixe: boolean;
  defaultIndex: number;
  setDefaultIndex: Function;
  formik: any;
  isMenuItem: boolean;
  showMultiplePrices: boolean;
  media: MenuItemMediaInterface[];
  // eslint-disable-next-line no-unused-vars
  handleMediaUpdate: (media: MenuItemMediaInterface[]) => void;
  handleMenuUpdate: Function;
  handleMenuSectionUpdate: Function;
  handleNextClicked: Function;
  handleDisplayOfPrices: Function;
  handleRemoveSizePrice: Function;
}

const ItemBasicInfoPage = ({
  menuID,
  menuSectionID,
  isPrixFixe,
  defaultIndex,
  setDefaultIndex,
  isMenuItem,
  showMultiplePrices,
  formik,
  media,
  handleMediaUpdate,
  handleMenuUpdate,
  handleMenuSectionUpdate,
  handleNextClicked,
  handleDisplayOfPrices,
  handleRemoveSizePrice
}: ItemBasicInfoPageProps) => {
  const [video, setVideo] = useState<MenuItemMediaInterface>(null);
  const [videoIndex, setVideoIndex] = useState<number>(-1);
  const [openVideoModal, setOpenVideoModal] = useState<boolean>(false);
  const [isEditingVideo, setIsEditingVideo] = useState<boolean>(false);
  const [openMediaLibraryModal, setOpenMediaLibraryModal] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleSizePriceInputChange = (name: string, value: string) => {
    formik.setFieldValue(name, value);
  };

  const handleCancelClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleMediaLibrarySelection = (selectedMedia: MediaLibraryInterface[]) => {
    const _media = media.slice();
    const newMedia = selectedMedia.slice();
    for (let i = 0; i < newMedia.length; i++) {
      if (newMedia[i].type === 'video') {
        setVideo({
          mediaID: newMedia[i].mediaID,
          mediaURL: newMedia[i].mediaUrl,
          type: newMedia[i].type
        });
        newMedia.splice(i, 1);
        setVideoIndex(i);
        setIsEditingVideo(false);
        setOpenVideoModal(true);
      }
    }

    handleMediaUpdate(
      _media.concat(
        newMedia.map(
          (mediaLibrary) =>
            ({
              mediaID: mediaLibrary.mediaID,
              mediaURL: mediaLibrary.mediaUrl,
              type: mediaLibrary.type
            } as MenuItemMediaInterface)
        )
      )
    );
  };

  const handleMediaLibraryRemoval = (mediaID: number) => {
    const _media = media.slice();
    const idx = _media.findIndex((mediaToDelete) => mediaToDelete?.mediaID === mediaID);
    _media.splice(idx, 1);
    handleMediaUpdate(_media);
  };

  const handleEditVideo = (idOfVideo: number) => {
    const idx: number = media.findIndex((_media) => _media.mediaID === idOfVideo);

    const _video: MenuItemMediaInterface = { ...media[idx] };
    setVideo(_video);
    setVideoIndex(idx);
    setIsEditingVideo(true);
    setOpenVideoModal(true);
  };

  const handleVideoConfirmation = (_video: MenuItemMediaInterface, index: number, isEdit: boolean) => {
    const tempMedia = media.slice();
    const tempVideo = { ..._video };

    if (isEdit) {
      tempMedia.splice(index, 1, tempVideo);
    } else {
      tempMedia.push(tempVideo);
    }

    handleMediaUpdate(tempMedia);

    setOpenVideoModal(false);
    setVideo(null);
    setVideoIndex(-1);
  };

  const handleRemoveVideo = (idOfVideo: number) => {
    const _media = media.slice();
    const idx = _media.findIndex((mediaToDelete) => mediaToDelete?.mediaID === idOfVideo);
    _media.splice(idx, 1);
    handleMediaUpdate(_media);

    setVideo(null);
    setOpenVideoModal(false);
    setVideoIndex(-1);
  };

  const handleVideoModalCancel = () => {
    setOpenVideoModal(false);
    setVideo(null);
    setVideoIndex(-1);
  };

  return (
    <>
      <div className="menu-and-section-selection">
        <Label label="MENU & MENU SECTION" />
        <span className="menu-and-section-selection-copy mb-2">
          Your {isMenuItem ? 'menu item' : 'drink'} will be created here:
        </span>
        <ItemWizardMenuSelector
          menuID={menuID}
          menuSectionID={menuSectionID}
          onMenuChange={handleMenuUpdate}
          onMenuSectionChange={handleMenuSectionUpdate}
        />
      </div>
      <Input
        name="name"
        label="NAME"
        className="menu-item-wizard-name-input"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        touched={formik.touched.name}
        error={formik.errors.name}
      />
      <TextArea
        name="description"
        label="DESCRIPTION"
        className="menu-item-wizard-description-input"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.description}
        touched={formik.touched.description}
        error={formik.errors.description}
      />
      <div className={`menu-item-wizard-size-price-container ${isPrixFixe ? 'is-prix-fixe' : ''}`}>
        <Label className="menu-item-wizard-size-price-label" label="SIZE & PRICE" />
        <div className="size-price-check-box-container">
          <Checkbox checked={showMultiplePrices} onChange={handleDisplayOfPrices} disabled={isPrixFixe} />
          <span className="size-price-check-box-copy">Check here if your item comes in more than one size.</span>
        </div>
        <SizePriceInput
          defaultIndex={defaultIndex}
          setDefaultIndex={setDefaultIndex}
          isPrixFixe={isPrixFixe}
          name="price"
          formik={formik}
          values={formik.values.price}
          onChange={handleSizePriceInputChange}
          onBlur={formik.handleBlur}
          onDelete={(index: number) => handleRemoveSizePrice(index)}
          multiple={showMultiplePrices}
          touched={formik.touched.price}
          errors={formik.errors.price}
        />
      </div>
      <div className="menu-item-wizard-image-container">
        <Label className="menu-item-wizard-image-label" label={<span>MEDIA</span>} />
        <p className="photo-prompt">
          TapTab allows you to add up to 3 photos and up to 1 video for each menu item. Videos should be no larger than
          32 MB, have an aspect ratio of 9:16, and should be no longer than 15 seconds. Photos should be no larger than
          750 KB & have an aspect ratio of 1:2, meaning their height should be twice their width.
        </p>
        <div className="media-container">
          <Button className="select-media-button" onClick={() => setOpenMediaLibraryModal(true)}>
            <img className="media-upload-icon" src={imageDisplay} alt="Icon indicating upload" />
            <p>Upload Media</p>
          </Button>
          <MediaPreview media={media} onUpdate={handleMediaUpdate} multiple onEditVideo={handleEditVideo} />
        </div>
        {openMediaLibraryModal && (
          <MediaLibraryModal
            selectedMediaIDs={media.map((_media) => _media.mediaID)}
            onSelect={handleMediaLibrarySelection}
            onRemove={handleMediaLibraryRemoval}
            onClose={() => setOpenMediaLibraryModal(false)}
          />
        )}
        {openVideoModal && (
          <VideoSelectorModal
            video={video}
            index={videoIndex}
            onSubmit={handleVideoConfirmation}
            onCancel={handleVideoModalCancel}
            onRemove={handleRemoveVideo}
            isEdit={isEditingVideo}
          />
        )}
      </div>
      <div className="menu-item-wizard-button-container">
        <Button className="menu-item-wizard-cancel-button" onClick={handleCancelClicked}>
          CANCEL
        </Button>
        <Button className="menu-item-wizard-next-button" onClick={handleNextClicked}>
          NEXT
        </Button>
      </div>
    </>
  );
};

export default ItemBasicInfoPage;
