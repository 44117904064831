const OrderReservationIcon = () => (
  <div className="icon-container">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="256"
      height="256"
      viewBox="0 0 156 156"
      xmlSpace="preserve"
    >
      <defs />
      <g
        style={{
          stroke: 'none',
          strokeWidth: 0,
          strokeDasharray: 'none',
          strokeLinecap: 'butt',
          strokeLinejoin: 'miter',
          strokeMiterlimit: 10,
          fill: 'none',
          fillRule: 'nonzero',
          opacity: 1
        }}
        transform="translate(1.4065934065934016 1.4065934065934016) scale(1.6 1.9)"
      >
        <path
          d="M 81.63 60.863 H 8.37 c -1.433 0 -2.595 -1.162 -2.595 -2.595 V 12.745 c 0 -1.615 1.309 -2.924 2.924 -2.924 h 72.601 c 1.615 0 2.924 1.309 2.924 2.924 v 45.523 C 84.225 59.701 83.063 60.863 81.63 60.863 z M 10.654 55.983 h 68.691 V 14.7 H 10.654 V 55.983 z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: '#000000',
            fillRule: 'nonzero',
            opacity: 1
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
        <path
          d="M 89.762 75.346 L 85.1 63.152 c -0.527 -1.379 -1.85 -2.29 -3.327 -2.29 H 8.226 c -1.476 0 -2.8 0.911 -3.327 2.29 L 0.238 75.346 c -0.891 2.332 0.83 4.833 3.327 4.833 h 82.87 C 88.931 80.179 90.653 77.678 89.762 75.346 z M 35.016 74.774 l 1.62 -3.81 c 0.156 -0.368 0.517 -0.607 0.917 -0.607 h 14.893 c 0.4 0 0.761 0.239 0.917 0.607 l 1.62 3.81 c 0.279 0.657 -0.203 1.387 -0.917 1.387 H 35.934 C 35.219 76.161 34.737 75.432 35.016 74.774 z"
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            fill: 'rgb(0, 0, 0)',
            fillRule: 'nonzero',
            opacity: 1
          }}
          transform=" matrix(1 0 0 1 0 0) "
          strokeLinecap="round"
        />
      </g>
    </svg>
  </div>
);

export default OrderReservationIcon;
