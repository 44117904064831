interface ErrorTextProps {
  error: string;
  className?: string;
}

const ErrorText = ({ error, className }: ErrorTextProps) => (
  <div
    style={{ visibility: error ? 'visible' : 'hidden' }}
    className={`error-text flex h-fit items-center text-red-400${className ? ` ${className}` : ''}`}
  >
    {error && error}
  </div>
);

export default ErrorText;
