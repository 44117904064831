import { forwardRef, useImperativeHandle, useState } from 'react';

import { pairMenuItem } from '../../api/menuItem';
import { useMenuContext } from '../../contexts/MenuContext/MenuContext';
import { PairingInterface } from '../../types/PairingInterface';
import { useDrinkItemsContext } from '../../contexts/DrinkItemsContext';
import SelectPairingsModal from './SelectPairingsModal';

export default forwardRef((props: any, ref) => {
  const [originalPairingIDs] = useState<number[]>(
    props.value?.pairings?.map((selected: PairingInterface) => selected.drinkItemID) || []
  );

  const { drinks } = useDrinkItemsContext();
  const { updateMenuItem } = useMenuContext();

  /* Component Editor Lifecycle methods
   * https://www.ag-grid.com/react-data-grid/component-cell-editor/#configure-popup
   */
  useImperativeHandle(ref, () => ({
    // the final value to send to the grid, on completion of editing
    getValue() {
      return props.value.pairings;
    },

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    isCancelBeforeStart() {
      return false;
    },

    // Gets called once when editing is finished (eg if Enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    isCancelAfterEnd() {
      return true;
    }
  }));

  const handleSubmit = async (pairingIDs: number[]) => {
    await pairMenuItem(props.value.menuItemID, pairingIDs);
    updateMenuItem(props.value.menuItemID, props.value.menuSectionID, {
      pairings: drinks.filter((drink) => pairingIDs.some((id) => id === drink.drinkItemID))
    });
    props.api.stopEditing();
  };

  return (
    <SelectPairingsModal
      itemName={props.value.name}
      originalPairingIDs={originalPairingIDs}
      closeModal={() => props.api.stopEditing()}
      handleSubmit={handleSubmit}
    />
  );
});
