import React from 'react';

interface DayHourDisplayProps {
  day: string;
  hours: string[];
}

const DayHourDisplayItem = ({ day, hours }: DayHourDisplayProps) => (
  <div className="day-hour-display">
    <span className="day-hour-label">{day}:</span>
    {hours?.map((hour) => (
      <span key={hour} className="day-hour-time-value">
        {hour}
      </span>
    ))}
    {hours?.length === 0 && <span className="day-hour-time-value">--</span>}
  </div>
);

export default DayHourDisplayItem;
