import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'universal-cookie';

import { COOKIE_NAME_AUTH, COOKIE_RESTAURANT_ID } from '../constants/AppConstants';
import { CHANGE_PASSWORD } from '../constants/UriConstants';

const cookies = new Cookies();

// TODO: change baseUrl
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json'
  }
});

export const interceptor = (removeToken: Function, showErrorModal: Function) => {
  apiClient.interceptors.request.use((config: AxiosRequestConfig) => {
    const _config = { ...config };
    _config.headers.Authorization = cookies.get(COOKIE_NAME_AUTH);

    // if restaurant id has been overridden by request made
    // then default to already provided restaurant id
    if (!_config?.headers?.restaurantID) {
      _config.headers.restaurantID = cookies.get(COOKIE_RESTAURANT_ID);
    }
    return _config;
  });
  apiClient.interceptors.response.use(
    (next) => Promise.resolve(next),
    (error) => {
      if (error?.response?.status === 401 && !error.response.config.url.includes(CHANGE_PASSWORD)) {
        removeToken();
      } else if (error.response) {
        throw error;
      } else if (error.request) {
        showErrorModal('Network issues. Please try again later.');
        throw error;
      }
    }
  );
};

export default apiClient;
