export const PROMOTION_TYPES = {
  ANNOUNCEMENT: 'announcement',
  EMAIL_SUBMISSION: 'email_submission'
};

// Solution for Types found here:
// https://www.typescriptlang.org/docs/handbook/enums.html#objects-vs-enums
export type PromotionType = typeof PROMOTION_TYPES[keyof typeof PROMOTION_TYPES];

export const PROMOTION_TYPE_VALUES = [
  {
    label: 'Announcement',
    value: PROMOTION_TYPES.ANNOUNCEMENT
  },
  {
    label: 'Email Sign Up',
    value: PROMOTION_TYPES.EMAIL_SUBMISSION
  }
];
