import React, { useEffect, useRef } from 'react';
import imageDisplay from '../../assets/images/ImageDisplay.png';

interface MultipleMediaUploadProps {
  acceptTypes: string;
  index?: number | string;
  multiple?: boolean;
  onUpload: Function;
}

const MultipleMediaUpload = ({ acceptTypes, onUpload, index = 0, multiple = true }: MultipleMediaUploadProps) => {
  const inputRef = useRef(null);

  const handleMediaUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    onUpload(event?.target?.files);
  };

  useEffect(() => {
    const handleDragOver = (event: React.DragEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const handleDrop = (event: React.DragEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();

      const fileList: FileList = event.dataTransfer.files;

      if (fileList.length > 0) {
        onUpload(fileList);
      }
    };

    if (inputRef) {
      inputRef.current.addEventListener('dragover', handleDragOver);
      inputRef.current.addEventListener('drop', handleDrop);
    }
  }, [onUpload]);

  return (
    <label htmlFor={`multipleMediaUploader-${index}`} ref={inputRef}>
      <div className="multiple-media-upload-container">
        <img className="media-upload-icon" src={imageDisplay} alt="Icon indicating upload" />
        <p>Upload Media or Drag and Drop</p>
        <input
          id={`multipleMediaUploader-${index}`}
          type="file"
          accept={acceptTypes}
          multiple={multiple}
          onChange={handleMediaUpload}
          onClick={(event) => {
            // Allows for the same image to be uploaded multiple times (e.g. any time a failure occurs)
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            event.target.value = null;
          }}
        />
      </div>
    </label>
  );
};

export default MultipleMediaUpload;
