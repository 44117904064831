import { useEffect, useState } from 'react';
import { useRestaurantContext } from '../../contexts/RestaurantContext';
import { Dropdown } from '../common/Form';
import { DropdownValue } from '../common/Form/Dropdown/Dropdown';

interface ItemWizardMenuSelectorProps {
  menuID: number;
  menuSectionID: number;
  onMenuChange: Function;
  onMenuSectionChange: Function;
}

const ItemWizardMenuSelector = ({
  menuID,
  menuSectionID,
  onMenuChange,
  onMenuSectionChange
}: ItemWizardMenuSelectorProps) => {
  const { menus } = useRestaurantContext();

  const [menuSections, setMenuSections] = useState([]);

  useEffect(() => {
    const getMenuSectionsForMenu = (): DropdownValue[] => {
      const menuIndex = menus.findIndex((menu) => menu?.menuID === menuID);
      return menus?.[menuIndex]?.menuSections?.map((menuSection) => ({
        label: menuSection?.name,
        value: menuSection?.menuSectionID
      }));
    };
    if (menus.length > 0 && menuID) {
      setMenuSections(getMenuSectionsForMenu());
    }
  }, [menuID, menus]);

  const handleMenuSelection = (value: string) => {
    const id = parseInt(value, 10);
    const menuIndex = menus.findIndex((menu) => menu?.menuID === id);
    const menu = menus?.[menuIndex];
    onMenuChange(id, menu.isPrixFixe);
    onMenuSectionChange(menu?.menuSections?.[0]?.menuSectionID);
  };

  const handleMenuSectionSelection = (value: string) => {
    onMenuSectionChange(parseInt(value, 10));
  };

  return (
    <div className="item-wizard-menu-selector">
      {menuID && (
        <Dropdown
          label="Menu"
          className="!w-fit min-w-[100px]"
          onChange={handleMenuSelection}
          value={menuID}
          values={menus
            .filter((menu) => menu?.menuSections?.length > 0)
            .map((menu) => ({ label: menu?.menuName, value: menu?.menuID }))}
        />
      )}
      {menuSectionID && (
        <Dropdown
          label="Menu Section"
          className="!w-fit min-w-[100px]"
          onChange={handleMenuSectionSelection}
          value={menuSectionID}
          values={menuSections}
        />
      )}
    </div>
  );
};

export default ItemWizardMenuSelector;
