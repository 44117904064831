import React, { useState } from 'react';
import Modal from '../common/Modal';
import { Dropdown } from '../common/Form';
import MediaLibraryUploader from '../MediaLibraryUploader';
import MediaLibraryItem from '../MediaLibraryItem';
import { MediaLibraryInterface } from '../../types/MediaInterface';
import { useMediaLibraryContext } from '../../contexts/MediaLibraryContext';
import { ClosePopUpIcon } from '../../assets/svgs/icons';

interface MediaLibraryModalProps {
  selectedMediaIDs: number[];
  onClose: () => void;
  mediaTypeOverride?: 'all' | 'image' | 'video';
  // eslint-disable-next-line no-unused-vars
  onSelect: (media: MediaLibraryInterface[]) => void;
  // eslint-disable-next-line no-unused-vars
  onRemove: (id: number) => void;
}

const MediaLibraryModal = ({
  selectedMediaIDs,
  onClose,
  mediaTypeOverride,
  onSelect,
  onRemove
}: MediaLibraryModalProps) => {
  const [mediaTypeFilter, setMediaTypeFilter] = useState<'all' | 'image' | 'video'>(mediaTypeOverride ?? 'all');

  const { media, addMedia } = useMediaLibraryContext();

  const handleTypeFilterChange = (type: 'all' | 'image' | 'video') => {
    setMediaTypeFilter(type);
  };

  const handleMediaUpload = (uploaded: MediaLibraryInterface[]) => {
    addMedia(uploaded);
    onSelect(uploaded);
  };

  const handleItemSelection = (_media: MediaLibraryInterface) => {
    if (selectedMediaIDs.includes(_media.mediaID)) {
      onRemove?.(_media.mediaID);
    } else {
      onSelect([_media]);
      onClose();
    }
  };

  const filteredMedia = media.filter(
    (item) =>
      mediaTypeFilter === 'all' ||
      (mediaTypeFilter === 'image' && item.type === 'image') ||
      (mediaTypeFilter === 'video' && item.type === 'video')
  );

  return (
    <Modal className="media-library-modal" onOverlayClicked={onClose}>
      <div className="modal-header">
        {!mediaTypeOverride && (
          <Dropdown
            className="media-type-dropdown"
            onChange={handleTypeFilterChange}
            value={mediaTypeFilter}
            values={[
              { value: 'all', label: 'All Media' },
              { value: 'image', label: 'Images' },
              { value: 'video', label: 'Video' }
            ].map((item) => ({ label: item.label, value: item.value }))}
          />
        )}
        <ClosePopUpIcon onIconClicked={onClose} width="16" height="17" />
      </div>
      <div className="modal-body">
        <MediaLibraryUploader mediaType={mediaTypeFilter} onUpload={handleMediaUpload} />
        {filteredMedia.map((_media) => (
          <MediaLibraryItem
            key={_media.mediaID}
            media={_media}
            isSelected={selectedMediaIDs.includes(_media.mediaID)}
            onSelect={handleItemSelection}
            onDoubleClick={handleItemSelection}
          />
        ))}
      </div>
    </Modal>
  );
};

export default MediaLibraryModal;
