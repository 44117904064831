import { useEffect } from 'react';
import SubmittedEmailTable from '../../../components/SubmittedEmailTable';
import EmailManagerTopBar from '../../../components/EmailManagerTopBar';
import { useSubmittedEmailsContext } from '../../../contexts/SubmittedEmailsContext';
import useResponsive from '../../../hooks/useResponsive';

const EmailManagerPage = () => {
  const { isDesktop } = useResponsive();
  const { loadSubmittedEmails } = useSubmittedEmailsContext();
  useEffect(() => {
    // only want to load the restaurants user emails if manager page is being utilized
    // call to load those announcements on initial load
    loadSubmittedEmails();
  }, [loadSubmittedEmails]);

  return isDesktop ? (
    <div className="email-manager-page-container">
      <EmailManagerTopBar />
      <SubmittedEmailTable isDesktop={isDesktop} />
    </div>
  ) : (
    <div className="email-manager-mobile-container">
      <SubmittedEmailTable isDesktop={isDesktop} />
    </div>
  );
};

export default EmailManagerPage;
