import React, { Component, ErrorInfo, ReactNode } from 'react';
import ErrorBoundaryModal from '../ErrorBoundaryModal';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public handleDismiss() {
    this.setState({ hasError: false });
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorBoundaryModal onDismiss={this.handleDismiss} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
