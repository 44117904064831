import Input from 'react-phone-number-input/input';
import Label from '../Label';
import ErrorText from '../ErrorText';

interface PhoneInputProps {
  className?: string;
  label?: string;
  name: string;
  placeholder?: string;
  onChange: any;
  onBlur: any;
  value: any;
  touched?: boolean;
  error?: string;
  maxLength?: number;
}

const PhoneInput = ({
  value,
  className,
  label,
  name,
  placeholder,
  onChange,
  onBlur,
  touched,
  error,
  maxLength = 14
}: PhoneInputProps) => (
  <div className={className}>
    <Label className="tracking-[1.1375px]" htmlFor={name} label={label} />
    <div className="mt-2">
      <Input
        className="w-full p-4 text-sm rounded-sm border border-neutral-400"
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        maxLength={maxLength}
        country="US"
      />
    </div>
    {touched && error && <ErrorText error={error} />}
  </div>
);

export default PhoneInput;
