import React from 'react';
import { RestaurantHourInterface } from '../../types/RestaurantHourInterface';
import { fullToShortDayName } from '../../constants/weekDayConstants';
import DayHourDisplayItem from './DayHourDisplayItem';
import { compareMilitaryTime, formatMilitaryToStandard } from '../../utils/time';
import { ErrorText } from '../common/Form';

interface DayHourDisplayProps {
  label: string;
  restaurantHours: RestaurantHourInterface[];
  className?: string;
}

const DayHourDisplay = ({ label, restaurantHours, className = '' }: DayHourDisplayProps) => {
  const getHoursForDay = (day: string, hours: RestaurantHourInterface[]): string[] =>
    hours
      ?.sort((a, b) => compareMilitaryTime(a?.start, b?.end))
      ?.map((hour) => {
        const hasHours = hour?.day?.includes(day);
        if (hasHours) {
          return `${formatMilitaryToStandard(hour?.start)} - ${formatMilitaryToStandard(hour?.end)}`;
        }

        return '';
      })
      ?.filter((hour) => hour?.length > 0);

  return (
    <div className={`day-hour-display-container ${className || ''}`}>
      <span className="hours-label">{label}</span>
      {restaurantHours?.length === 0 ||
        (restaurantHours?.[0]?.day?.length === 0 && <ErrorText error="Hours are required." />)}
      {Object.keys(fullToShortDayName).map((day) => (
        <DayHourDisplayItem key={day} day={day} hours={getHoursForDay(day, restaurantHours)} />
      ))}
    </div>
  );
};

export default DayHourDisplay;
