import { Dropdown, Input, PhoneInput, TextArea } from '../../../components/common/Form';
import Button from '../../../components/common/Button';
import { useCuisinesContext } from '../../../contexts/CuisinesContext';
import { handleHttpsInput } from '../../../utils/general';

interface RestaurantBasicInfoPageProps {
  formik: any;
  onNext: Function;
}

const RestaurantBasicInfoPage = ({ formik, onNext }: RestaurantBasicInfoPageProps) => {
  const { cuisines } = useCuisinesContext();

  const handleNextClicked = () => {
    formik.setTouched({ name: true, email: true, phone: true }, true);
    const hasRequiredValues = !!formik.values.name && !!formik.values.phone && !!formik.values.email;
    const hasNoErrors = !formik.errors.name && !formik.errors.phone && !formik.errors.email;
    if (hasRequiredValues && hasNoErrors) {
      onNext?.();
    }
  };

  const handleCuisineChanged = (value: string) => {
    formik.setFieldValue('cuisine', parseInt(value, 10));
  };

  const handleInputChange = (name: string, value: string) => {
    const modifiedValue = handleHttpsInput(value);
    formik.setFieldValue(name, modifiedValue);
  };

  return (
    <div className="restaurant-basic-info-page">
      <Input
        name="name"
        label="RESTAURANT NAME"
        className="restaurant-wizard-name-input"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        touched={formik.touched.name}
        error={formik.errors.name}
      />
      <TextArea
        name="description"
        label={
          <span>
            DESCRIPTION <i>(Optional)</i>
          </span>
        }
        className="restaurant-wizard-description-input"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.description}
        touched={formik.touched.description}
        error={formik.errors.description}
      />
      <PhoneInput
        name="phone"
        label="PHONE NUMBER"
        className="restaurant-wizard-phone-input"
        onBlur={formik.handleBlur}
        touched={formik.touched.phone}
        onChange={(phoneNumber: string) => formik.setFieldValue('phone', phoneNumber)}
        value={formik.values.phone}
        error={formik.errors?.phone}
      />
      <Input
        name="email"
        label="EMAIL ADDRESS"
        type="email"
        className="restaurant-wizard-email-input"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        touched={formik.touched.email}
        error={formik.errors.email}
      />
      {cuisines?.length > 0 && (
        <Dropdown
          label="CUISINE"
          className="restaurant-wizard-cuisine-select"
          onChange={handleCuisineChanged}
          value={formik.values.cuisine}
          values={cuisines?.map((cuisine) => ({
            label: cuisine.name,
            value: cuisine.cuisineID
          }))}
        />
      )}
      <Input
        name="website"
        label={
          <span>
            WEBSITE <i>(Optional)</i>
          </span>
        }
        className="restaurant-wizard-website-input"
        onChange={(event: any) => handleInputChange('website', event.target.value)}
        onBlur={formik.handleBlur}
        value={formik.values.website}
        touched={formik.touched.website}
        error={formik.errors.website}
      />
      <Button className="restaurant-wizard-info-next-button" onClick={handleNextClicked}>
        NEXT
      </Button>
    </div>
  );
};

export default RestaurantBasicInfoPage;
