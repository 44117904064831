interface TableCellProps {
  className?: string;
  value: any;
  mappedFunction: Function;
}

const TableCell = ({ value, className = '', mappedFunction }: TableCellProps) => (
  <span className={`custom-cell-container ${className}`}>
    {value?.length > 0 ? (
      <>
        {value.slice(0, 4).map(mappedFunction)}
        {value.length > 4 && <span>...</span>}
      </>
    ) : (
      <span>--</span>
    )}
  </span>
);

export default TableCell;
