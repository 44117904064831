import {
  CREATE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  EDIT_ANNOUNCEMENT,
  GET_ANNOUNCEMENTS,
  HIDE_SHOW_ANNOUNCEMENT,
  LINK_ANNOUNCEMENTS_MEDIA
} from '../constants/UriConstants';
import {
  AnnouncementInterface,
  AnnouncementStatusInterface,
  CreateAnnouncementRequestInterface,
  EditAnnouncementRequestInterface
} from '../types/AnnouncementInterface';
import apiClient from './apiClient';

export const createAnnouncement = (request: CreateAnnouncementRequestInterface): Promise<AnnouncementInterface> =>
  apiClient.post(CREATE_ANNOUNCEMENT, request).then((response) => response.data);

export const deleteAnnouncement = async (announcementID: number) => {
  await apiClient.delete(DELETE_ANNOUNCEMENT + announcementID);
};

export const editAnnouncement = (request: EditAnnouncementRequestInterface): Promise<AnnouncementStatusInterface> =>
  apiClient.put(EDIT_ANNOUNCEMENT, request).then((response) => response.data);

export const getAnnouncements = (): Promise<AnnouncementInterface[]> =>
  apiClient.get(GET_ANNOUNCEMENTS).then((response) => response.data);

export const showOrHideAnnouncement = async (
  announcementID: number,
  hide: boolean
): Promise<AnnouncementStatusInterface> =>
  apiClient.put(HIDE_SHOW_ANNOUNCEMENT, { announcementID, hide }).then((response) => response.data);

export const linkMediaToAnnouncement = async (announcementID: number, mediaIDs: number[]) => {
  await apiClient.post(LINK_ANNOUNCEMENTS_MEDIA, { announcementID, mediaIDs });
};
