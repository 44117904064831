import React, { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import useOutsideEventSensor from '../../../hooks/useOutsideEventSensor';

interface TooltipProps {
  iconRef: MutableRefObject<any>;
  hidden: boolean;
  onBlur: Function;
  className?: string;
  children: ReactNode;
  width: number;
  offset: number;
  topOffset?: number;
}

/**
 * Wrapper for tooltip functionality - does not come with styling built in
 */
const Tooltip = ({ iconRef, hidden, onBlur, width, offset, children, topOffset = 0, className = '' }: TooltipProps) => {
  const ref = useRef(null);
  const [style, setStyle] = useState<any>(null);
  const hasClickedOutside = useOutsideEventSensor(ref);

  useEffect(() => {
    const showToolTip = () => {
      if (iconRef && iconRef.current != null) {
        const SPACE = 10;
        const _style: any = { width }; // this style object will be passed as the tooltip's 'style' prop

        const dimensions = iconRef.current.getBoundingClientRect();

        // center align the tooltip by taking both the target and tooltip widths into account
        _style.left = offset + dimensions.left + dimensions.width / 2 - width / 2;
        _style.left = Math.max(SPACE, _style.left); // make sure it doesn't poke off the left side of the page
        _style.left = Math.min(_style.left, document.body.clientWidth - width - SPACE); // or off the right

        if (dimensions.top < window.innerHeight / 2) {
          // the top half of the page
          // when on the top half of the page, position the top of the tooltip just below the target (it will stretch downwards)
          _style.top = dimensions.top + dimensions.height + topOffset;
        } else {
          // when on the bottom half, set the bottom of the tooltip just above the top of the target (it will stretch upwards)
          _style.bottom = window.innerHeight - dimensions.top;
        }

        setStyle({
          ..._style,
          visibility: 'visible'
        });
      }
    };

    if (!hidden) {
      showToolTip();
    } else {
      setStyle({
        visibility: 'hidden'
      });
    }

    if (hasClickedOutside && !hidden) {
      onBlur();
    }
  }, [hasClickedOutside, hidden, iconRef, onBlur, width, offset, topOffset]);

  if (hidden) {
    return null;
  }

  return (
    <ul ref={ref} style={style} className={className}>
      {children}
    </ul>
  );
};

export default Tooltip;
