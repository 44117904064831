import { ColDef } from 'ag-grid-community';
import ItemTable from '../ItemTable';
import DescriptionCell from '../MenuItemTable/cells/DescriptionCell';
import { useAnnouncementsContext } from '../../contexts/AnnouncementsContext';
import PromotionNameCell from '../MenuItemTable/cells/PromotionNameCell';
import SingleItemCell from '../MenuItemTable/cells/SingleItemCell';
import DateTimeCell from '../MenuItemTable/cells/DateTimeCell';
import PublishedCell from '../MenuItemTable/cells/PublishedCell';
import LoadingSpinner from '../common/LoadingSpinner';
import { PROMOTION_TYPE_VALUES } from '../../constants/PromotionTypeConstants';
import { ANNOUNCEMENT_TYPE_VALUES } from '../../constants/AnnouncementTypeConstants';
import MobileItemTable from '../MobileItemTable/MobileItemTable';

interface PromotionsTableProps {
  isDesktop?: boolean;
}

const PromotionsTable = ({ isDesktop }: PromotionsTableProps) => {
  const { announcements, isLoading } = useAnnouncementsContext();

  const nameCellGetter = (params: any) => ({
    image: params.data.image && Object.keys(params.data.image).length > 0 ? params.data.image : undefined,
    name: params.data.name,
    type: params.data.type,
    promotionID: params.data.announcementID,
    isHidden: params.data.hidden,
    submitEmail: params.data.submitEmail
  });

  const itemCellGetter = (params: any, field: string) => ({
    item: params.data?.[field],
    isHidden: params.data.hidden
  });

  const dateTimeCellGetter = (params: any, field: string) => ({
    timestamp: params.data?.[field],
    isHidden: params.data.hidden
  });

  const isHiddenGetter = (params: any, field: string) => ({
    [field]: params.data?.[field],
    isHidden: params.data.hidden || false
  });

  const COLUMNS: ColDef[] = isDesktop
    ? [
        {
          headerName: 'NAME',
          valueGetter: nameCellGetter,
          headerClass: 'name-header',
          cellRenderer: PromotionNameCell,
          minWidth: 250,
          sortable: true,
          wrapText: true,
          autoHeight: true,
          suppressMovable: true
        },
        {
          headerName: 'TITLE',
          valueGetter: (params) => itemCellGetter(params, 'title'),
          headerClass: 'custom-header',
          cellRenderer: SingleItemCell,
          minWidth: 90,
          suppressMovable: true
        },
        {
          headerName: 'DESCRIPTION',
          valueGetter: (params) => isHiddenGetter(params, 'description'),
          headerClass: 'custom-header',
          cellRenderer: DescriptionCell,
          minWidth: 200,
          suppressMovable: true,
          wrapText: true,
          autoHeight: true
        },
        {
          headerName: 'TYPE',
          valueGetter: (params) => {
            const item = itemCellGetter(params, 'submitEmail');
            return {
              ...item,
              item: !item.item ? PROMOTION_TYPE_VALUES[0].label : PROMOTION_TYPE_VALUES[1].label
            };
          },
          headerClass: 'custom-header',
          cellRenderer: SingleItemCell,
          minWidth: 80,
          suppressMovable: true
        },
        {
          headerName: 'LAYOUT',
          valueGetter: (params) => {
            const item = itemCellGetter(params, 'type');
            return {
              ...item,
              item: ANNOUNCEMENT_TYPE_VALUES.find((val) => val.value === item.item).label
            };
          },
          headerClass: 'custom-header',
          cellRenderer: SingleItemCell,
          minWidth: 80,
          suppressMovable: true
        },
        {
          headerName: 'START DATE',
          valueGetter: (params) => dateTimeCellGetter(params, 'startDate'),
          headerClass: 'custom-header',
          cellRenderer: DateTimeCell,
          minWidth: 150,
          suppressMovable: true
        },
        {
          headerName: 'END DATE',
          valueGetter: (params) => dateTimeCellGetter(params, 'endDate'),
          headerClass: 'custom-header',
          cellRenderer: DateTimeCell,
          minWidth: 150,
          suppressMovable: true
        },
        {
          headerName: 'ACTIVE?',
          field: 'active',
          headerClass: 'custom-header',
          cellRenderer: PublishedCell,
          minWidth: 80,
          suppressMovable: true
        }
      ]
    : [
        {
          headerName: 'NAME',
          valueGetter: nameCellGetter,
          headerClass: 'name-header',
          cellRenderer: PromotionNameCell,
          minWidth: 230,
          sortable: true,
          wrapText: true,
          autoHeight: true,
          suppressMovable: true
        },
        {
          headerName: 'TITLE',
          valueGetter: (params) => itemCellGetter(params, 'title'),
          headerClass: 'custom-header',
          cellRenderer: SingleItemCell,
          minWidth: 50,
          suppressMovable: true,
          wrapText: true,
          autoHeight: true
        }
      ];

  if (isLoading) {
    return (
      <div className="promotions-page-loading">
        <LoadingSpinner />
      </div>
    );
  }

  return isDesktop ? (
    <ItemTable items={announcements} columns={COLUMNS} draggable={false} />
  ) : (
    <div className="mobile-promotion-item-table-container" style={{ marginTop: '-15px' }}>
      <MobileItemTable items={announcements} columns={COLUMNS} rowHeight={100} />
    </div>
  );
};

export default PromotionsTable;
