import apiClient from './apiClient';
import {
  CREATE_MENU_ITEM,
  DELETE_MENU_ITEM,
  EDIT_MENU_ITEM,
  LINK_MENU_ITEM_RESTRICTIONS,
  REORDER_MENU_ITEMS,
  HIDE_SHOW_MENU_ITEM,
  LINK_MENU_ITEM_TAGS,
  PAIR_MENU_ITEM,
  LINK_MENU_ITEM_MODIFIER_GROUPS,
  LINK_MENU_ITEM_MEDIA
} from '../constants/UriConstants';
import { CreateMenuItemInterface, DetailedMenuItemInterface } from '../types/MenuItemInterface';

export const createMenuItem = (menuItem: CreateMenuItemInterface) =>
  apiClient.post(CREATE_MENU_ITEM, menuItem).then((response) => response.data);

export const deleteMenuItem = async (menuItemID: number) => {
  await apiClient.delete(DELETE_MENU_ITEM + menuItemID);
};

export const editMenuItem = async (menuItem: DetailedMenuItemInterface, menuSectionID: number) => {
  await apiClient.put(EDIT_MENU_ITEM, { ...menuItem, menuSectionID });
};

export const showMenuItem = async (menuItemID: number) => {
  await apiClient.put(HIDE_SHOW_MENU_ITEM, { menuItemID, hide: false });
};

export const hideMenuItem = async (menuItemID: number) => {
  await apiClient.put(HIDE_SHOW_MENU_ITEM, { menuItemID, hide: true });
};

export const pairMenuItem = async (menuItemID: number, pairingItemIDs: number[]) => {
  await apiClient.put(PAIR_MENU_ITEM, { menuItemID, pairingItemIDs });
};

export const linkModifierGroupsToMenuItem = async (menuItemID: number, modifierGroupIDs: number[]) => {
  await apiClient.put(LINK_MENU_ITEM_MODIFIER_GROUPS, { menuItemID, modifierGroupIDs });
};

export const linkRestrictionsToMenuItem = async (menuItemID: number, dietaryRestrictionIDs: number[]) => {
  await apiClient.put(LINK_MENU_ITEM_RESTRICTIONS, { menuItemID, dietaryRestrictionIDs });
};

export const linkTagsToMenuItem = async (menuItemID: number, tagIDs: number[]) => {
  await apiClient.put(LINK_MENU_ITEM_TAGS, { menuItemID, tagIDs });
};

export const reorderMenuItems = async (menuSectionID: number, menuItemsOrder: number[]) => {
  await apiClient.patch(REORDER_MENU_ITEMS, { menuSectionID, menuItemsOrder });
};

export const linkMediaToMenuItem = async (
  menuItemID: number,
  mediaIDs: number[],
  thumbnails: { videoID: number; thumbnailID: number }[]
) => {
  await apiClient.post(LINK_MENU_ITEM_MEDIA, { menuItemID, mediaIDs, thumbnails });
};
