import React, { useEffect, useRef, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSS } from '@dnd-kit/utilities';
import ContextMenuIconButton from '../ContextMenuIconButton';
import { useMenuContext } from '../../contexts/MenuContext';
import { MenuSectionModal } from '../MenuSectionModal';
import { MenuSection } from '../../types/MenuSectionInterface';

interface MenuSectionSelectorProps {
  menuSection: MenuSection;
  onMenuSectionDeletion: Function;
  onMenuSectionHideShow: Function;
  isHidden: boolean;
}

const MenuSectionSelector = ({
  menuSection,
  onMenuSectionDeletion,
  onMenuSectionHideShow
}: MenuSectionSelectorProps) => {
  const [showMenuSectionModal, setShowMenuSectionModal] = useState<boolean>(false);
  const { selectedMenuSectionID, setSelectedMenuSectionID } = useMenuContext();
  const { menuSectionID, name: menuSectionName, isHidden } = menuSection;

  const ref = useRef(null);

  useEffect(() => {
    if (selectedMenuSectionID === menuSectionID) {
      if (ref?.current) {
        ref.current.scrollIntoView();
      }
    }
  }, [menuSectionID, selectedMenuSectionID]);

  const handleMenuSectionSelectionKeyPress = (event: React.KeyboardEvent<HTMLDivElement>, itemID: number) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      setSelectedMenuSectionID(itemID);
    }
  };

  const { setNodeRef, listeners, attributes, transform, transition, isDragging } = useSortable({
    id: menuSectionID.toString(),
    transition: {
      duration: 150, // milliseconds
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
    }
  });

  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div style={itemStyle} ref={setNodeRef}>
      {showMenuSectionModal && (
        <MenuSectionModal
          menuSection={menuSection}
          isEdit
          onSubmit={() => setShowMenuSectionModal(false)}
          onCancel={() => setShowMenuSectionModal(false)}
        />
      )}
      <div
        role="button"
        ref={ref}
        onClick={() => {
          setSelectedMenuSectionID(menuSectionID);
        }}
        onKeyPress={(event) => handleMenuSectionSelectionKeyPress(event, menuSectionID)}
        className={`menu-section-selector ${selectedMenuSectionID === menuSectionID ? 'active' : ''} ${
          !isDragging ? 'non-dragging' : ''
        } ${isHidden ? 'is-hidden' : ''}
        `}
        tabIndex={0}
        style={{ cursor: isDragging ? 'grab' : 'pointer' }}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...attributes}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...listeners}
      >
        <ContextMenuIconButton
          onDeleteClicked={(itemID: number) => onMenuSectionDeletion(itemID)}
          onHideShowClicked={(itemID: number) => onMenuSectionHideShow(itemID)}
          onEditClicked={() => setShowMenuSectionModal(true)}
          itemID={menuSectionID}
          isHidden={isHidden}
        />
        <span>{menuSectionName}</span>
      </div>
    </div>
  );
};

export default MenuSectionSelector;
