import TwoColumnWithTextWhite from '../assets/images/layouts/two_column_w_text_white.png';
import TwoColumnNoText from '../assets/images/layouts/two_column_no_text.png';
import OneColumnWithTextWhite from '../assets/images/layouts/one_column_w_text_white.png';
import TextOnly from '../assets/images/layouts/text_only.png';
import TwoColumnWithTextBlack from '../assets/images/layouts/two_column_w_text_black.png';
import OneColumnWithTextBlack from '../assets/images/layouts/one_column_w_text_black.png';

export const MENU_LAYOUTS = [
  'Two Photo Columns | Text Beneath Photos | White Background',
  'Two-Column Photos | No Text Beneath Photos | Black Background ',
  'One Photo Column | Text Beneath Photos | White Background',
  'Text-Only | Option to Add Photos to Right of Text | White Background',
  'Two Photo Columns | Text Beneath Photos | Black Background',
  'One Photo Column  | Text Beneath Photos | Black Background'
];

export const MENU_LAYOUT_IMAGES = [
  TwoColumnWithTextWhite,
  TwoColumnNoText,
  OneColumnWithTextWhite,
  TextOnly,
  TwoColumnWithTextBlack,
  OneColumnWithTextBlack
];
