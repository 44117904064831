import { useMediaQuery } from 'react-responsive';

interface ResponsiveProps {
  isTabletOrMobile: boolean;
  isDesktop: boolean;
}

const useResponsive = (): ResponsiveProps => {
  const isTabletOrMobile = useMediaQuery({ minWidth: 0, maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return {
    isTabletOrMobile,
    isDesktop
  };
};

export default useResponsive;
