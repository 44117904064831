import { ReactNode } from 'react';
import { FormikTextArea } from '../common/Form';

interface DisclaimerMessageInputProps {
  name: string;
  label: string;
}

const DisclaimerMessageInput = ({ name, label }: DisclaimerMessageInputProps) => {
  const disclaimerSubtext: ReactNode = (
    <>
      Add a message or disclaimer to the {label.toLowerCase()} of your menu. For example, “A service charge of 18% is
      added to every meal” or “Please let your server know of any allergies”.
    </>
  );

  return (
    <FormikTextArea
      name={name}
      label={
        <>
          {label} MENU MESSAGE <em>(Optional)</em>
        </>
      }
      message={disclaimerSubtext}
      placeholder="An automatic gratuity of 20% is added to every check for parties larger than 6..."
      className="h-60 my-4 menu-wizard-text-area"
    />
  );
};

export default DisclaimerMessageInput;
