import React from 'react';
import { MediaLibraryInterface } from '../../types/MediaInterface';
import { PlayIcon, TrashCanIcon } from '../../assets/svgs/icons';

interface MediaLibraryItemProps {
  media: MediaLibraryInterface;
  isSelected?: boolean;
  // eslint-disable-next-line no-unused-vars
  onSelect?: (media: MediaLibraryInterface) => void;
  // eslint-disable-next-line no-unused-vars
  onDoubleClick?: (media: MediaLibraryInterface) => void;
  // eslint-disable-next-line no-unused-vars
  onDelete?: (id: number) => void;
}

const MediaLibraryItem = ({ media, isSelected, onSelect, onDelete, onDoubleClick }: MediaLibraryItemProps) => {
  const clickTimer = React.useRef<number | null>(null);

  const handleClick = () => {
    if (!onSelect) {
      return;
    }
    // If there's already a timer, clear it and consider this a double-click
    if (clickTimer?.current) {
      clearTimeout(clickTimer?.current);
      clickTimer.current = null;
    } else {
      // Set a timer; if not cleared by a double-click, it's a single-click
      clickTimer.current = window.setTimeout(() => {
        onSelect(media);
        clickTimer.current = null;
      }, 200); // 200 ms for double-click threshold
    }
  };

  const handleDoubleClick = () => {
    if (!onDoubleClick) {
      return;
    }

    clearTimeout(clickTimer.current);
    clickTimer.current = null;
    onDoubleClick(media);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={`media-library-item-container ${!!onSelect && isSelected ? 'is-selected' : ''}`}
      onDoubleClick={handleDoubleClick}
    >
      <div className="media-library-item-image-container">
        {media.type === 'image' ? (
          <img className="media-library-item-image" alt={media.mediaUrl.split('/').pop()} src={media.mediaUrl} />
        ) : (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video className="media-library-item-image" src={media.mediaUrl}>
            Your browser does not support the video tag.
          </video>
        )}
        {!!onDelete && media.type !== 'video' && (
          <TrashCanIcon color="white" width="12" onIconClicked={() => onDelete?.(media.mediaID)} />
        )}
        {!!onSelect && (
          <button type="button" onClick={handleClick} className={`select-media-button ${isSelected ? 'selected' : ''}`}>
            {isSelected ? 'REMOVE' : 'SELECT'}
          </button>
        )}
        {media.type === 'video' && <PlayIcon />}
      </div>
      <span className="media-library-name">{media.name}</span>
    </div>
  );
};

export default MediaLibraryItem;
