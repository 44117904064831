import apiClient from './apiClient';
import {
  CREATE_MENU_SECTIONS,
  DELETE_MENU_SECTIONS,
  EDIT_MENU_SECTIONS,
  HIDE_SHOW_MENU_SECTIONS,
  REORDER_MENU_SECTIONS
} from '../constants/UriConstants';
import { CreatedMenuSectionResponse } from '../types/MenuSectionInterface';

export const createMenuSection = (name: string, menuID: number, message: string): Promise<CreatedMenuSectionResponse> =>
  apiClient.post(CREATE_MENU_SECTIONS, { menuID, menuSections: [{ name, message }] }).then((response) => response.data);

export const deleteMenuSection = async (menuSectionID: number) => {
  await apiClient.delete(DELETE_MENU_SECTIONS + menuSectionID);
};

export const editMenuSection = async (
  menuID: number,
  menuSectionID: number,
  menuSectionName: string,
  message: string
) => {
  await apiClient.put(EDIT_MENU_SECTIONS, { menuID, menuSectionID, menuSectionName, message });
};

export const hideMenuSection = async (menuSectionID: number) => {
  await apiClient.put(HIDE_SHOW_MENU_SECTIONS, { menuSectionID, hide: true });
};

export const reorderMenuSections = async (menuID: number, menuSectionsOrder: number[]) => {
  await apiClient.patch(REORDER_MENU_SECTIONS, { menuID, menuSectionsOrder });
};

export const showMenuSection = async (menuSectionID: number) => {
  await apiClient.put(HIDE_SHOW_MENU_SECTIONS, { menuSectionID, hide: false });
};
