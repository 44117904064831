import { TagInterface } from '../../../types/TagInterface';

interface TagProps {
  tag: TagInterface;
}

const Tag = ({ tag: { tagColor, name } }: TagProps) => (
  <span className="special-tag" style={{ background: tagColor || '#8FA3AD' }}>
    {name}
  </span>
);

export default Tag;
