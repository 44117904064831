import { ItemSizeInterface } from '../types/ItemSizeInterface';

export const formatPriceExact = (price: number): string => `${(price / 100).toFixed(2)}`;

export const formatAddOnPriceExact = (price: number): string => {
  if (price) {
    return ` +${formatPriceExact(price)}`;
  }
  return null;
};

export const formatItemSizePriceExact = (baseItemSize: ItemSizeInterface): string => {
  const { price, priceOverride } = baseItemSize;
  if (priceOverride) {
    return priceOverride;
  }
  return `$${formatPriceExact(price)}`;
};

export const formatPriceString = (price: string) => {
  if (price) {
    if (price.length >= 3) {
      const decimal = price.slice(-2);
      return `${price.substring(0, price.length - 2)}.${decimal}`;
    }
    if (price.length === 2) {
      return `.${price}`;
    }
    return `.0${price}`;
  }

  return '';
};
