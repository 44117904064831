import { AxiosResponse } from 'axios';
import apiClient from './apiClient';
import { DELETE_MEDIA, GET_MEDIA, UPLOAD_MEDIA_IMAGES, UPLOAD_MEDIA_VIDEOS } from '../constants/UriConstants';
import { MediaLibraryInterface } from '../types/MediaInterface';

export const deleteMedia = async (mediaID: number) => {
  await apiClient.delete(`${DELETE_MEDIA}/${mediaID}`);
};

export const getMedia = (): Promise<MediaLibraryInterface[]> =>
  apiClient.get(GET_MEDIA).then((response: AxiosResponse<MediaLibraryInterface[]>) => response.data);

export const uploadMediaImages = async (images: Blob[]): Promise<MediaLibraryInterface[]> => {
  const formData = new FormData();
  images?.forEach((image) => {
    formData.append('images', image);
  });

  const response = await apiClient.post(UPLOAD_MEDIA_IMAGES, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return response?.data;
};

export const uploadMediaVideos = async (videos: Blob[]): Promise<MediaLibraryInterface[]> => {
  const formData = new FormData();
  videos?.forEach((video) => {
    formData.append('videos', video);
  });

  const response = await apiClient.post(UPLOAD_MEDIA_VIDEOS, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return response?.data;
};
