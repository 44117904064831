import React, { useState } from 'react';
import { ErrorText, Label } from '../../../components/common/Form';
import Button from '../../../components/common/Button';
import ImageUploader from '../../../components/common/Form/ImageUploader';
import MultipleMediaUploader from '../../../components/MultipleMediaUploader';
import { AlbumMediaInterface } from '../../../types/AlbumMediaInterface';
import { BlobWrapper } from '../../../types/MediaInterface';

interface RestaurantPhotoPageProps {
  thumbnailImage: Blob | string;
  profileImage: Blob | string;
  menuCoverImage: Blob | string;
  galleryImages: (BlobWrapper | AlbumMediaInterface)[];
  handleThumbnailImageUpload: Function;
  handleProfileImageUpload: Function;
  handleMenuCoverImageUpload: Function;
  handleGalleryImagesUpload: Function;
  onNext: Function;
  onBack: Function;
}

const RestaurantPhotoPage = ({
  thumbnailImage,
  profileImage,
  menuCoverImage,
  galleryImages,
  handleThumbnailImageUpload,
  handleProfileImageUpload,
  handleMenuCoverImageUpload,
  handleGalleryImagesUpload,
  onNext,
  onBack
}: RestaurantPhotoPageProps) => {
  const [errorsMap, setErrorsMap] = useState<Map<string, string>>(new Map());

  const handleNextClicked = () => {
    const newErrors: Map<string, string> = new Map();

    if (!profileImage) {
      newErrors.set('profileImage', 'Restaurant Profile photo is required.');
    }

    if (!thumbnailImage) {
      newErrors.set('thumbnailImage', 'Restaurant Thumbnail photo is required.');
    }

    if (!menuCoverImage) {
      newErrors.set('menuCoverImage', 'Restaurant Menu Cover photo is required.');
    }

    setErrorsMap(newErrors);

    if (newErrors.size === 0) {
      onNext?.();
    }
  };

  const handleThumbnailUpload = (file: Blob) => {
    handleThumbnailImageUpload(file);
    // Clear the error associated with thumbnail image
    if (errorsMap.has('thumbnailImage')) {
      setErrorsMap((errors) => {
        errors.delete('thumbnailImage');
        return new Map(errors);
      });
    }
  };

  const handleProfileUpload = (file: Blob) => {
    handleProfileImageUpload(file);
    // Clear the error associated with profile image
    if (errorsMap.has('profileImage')) {
      setErrorsMap((errors) => {
        errors.delete('profileImage');
        return new Map(errors);
      });
    }
  };

  const handleMenuCoverUpload = (file: Blob) => {
    handleMenuCoverImageUpload(file);
    // Clear the error associated with menu cover image
    if (errorsMap.has('menuCoverImage')) {
      setErrorsMap((errors) => {
        errors.delete('menuCoverImage');
        return new Map(errors);
      });
    }
  };

  const handleGalleryUpload = (files: (BlobWrapper | AlbumMediaInterface)[]) => {
    handleGalleryImagesUpload(files);
    // Clear the error associated with gallery images
    setErrorsMap((errors) => {
      errors.forEach((value, key) => {
        if (key.startsWith('galleryImage')) {
          errors.delete(key);
        }
      });
      return new Map(errors);
    });
  };

  const renderError = (photoKey: string) =>
    errorsMap.has(photoKey) && <ErrorText error={errorsMap.get(photoKey) ?? ''} />;
  return (
    <div className="restaurant-photo-page">
      <div className="restaurant-wizard-thumbnail-image-container">
        <Label label="THUMBNAIL PHOTO" />
        <p className="restaurant-wizard-image-copy">
          Choose your favorite restaurant photo for your thumbnail photo. This photo will displayed in TapTab’s
          restaurant list. This photo should be at least 100 px wide & 100 px tall and will be cropped to fit a 4:6
          aspect ratio.
        </p>
        <ImageUploader id="1" image={thumbnailImage} onUpdate={handleThumbnailUpload} />
        {renderError('thumbnailImage')}
      </div>
      <div className="restaurant-wizard-profile-image-container">
        <Label label="PROFILE PHOTO" />
        <p className="restaurant-wizard-image-copy">
          Choose your favorite restaurant photo for your profile photo. This photo will displayed on your profile
          screen. This photo should be at least 100 px wide & 100 px tall and will be cropped to fit a 4:6 aspect ratio.
        </p>
        <ImageUploader id="2" image={profileImage} onUpdate={handleProfileUpload} />
        {renderError('profileImage')}
      </div>
      <div className="restaurant-wizard-menuCover-image-container">
        <Label label="MENU COVER PHOTO" />
        <p className="restaurant-wizard-image-copy">
          Choose a photo that will appear at the top of your menu. The photo should be at least 100 px wide and 100 px
          tall.
        </p>
        <ImageUploader id="3" image={menuCoverImage} onUpdate={handleMenuCoverUpload} />
        {renderError('menuCoverImage')}
      </div>
      <div className="restaurant-wizard-gallery-image-container">
        <Label label="GALLERY PHOTOS" />
        <p className="restaurant-wizard-image-copy">
          Choose up to 15 photos for your TapTab profile. Each photo should be at least 100 px wide and 100 px tall.
        </p>
        <MultipleMediaUploader media={galleryImages} onUpdate={handleGalleryUpload} type="image" maxImages={15} />
      </div>
      <div className="restaurant-wizard-photo-buttons">
        <Button className="restaurant-wizard-back-button" onClick={onBack}>
          BACK
        </Button>
        <Button className="restaurant-wizard-next-button" onClick={handleNextClicked}>
          NEXT
        </Button>
      </div>
    </div>
  );
};

export default RestaurantPhotoPage;
