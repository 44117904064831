export const compareArrays = (original: number[], selected: number[], strict = false): boolean => {
  if (original.length !== selected.length) {
    return false;
  }

  if (strict) {
    for (let i = 0; i < original.length; i++) {
      if (original[i] !== selected[i]) {
        return false;
      }
    }
    return true;
  }

  const sortedOriginal = [...original].sort((a, b) => a - b);
  const sortedSelected = [...selected].sort((a, b) => a - b);

  for (let i = 0; i < sortedOriginal.length; i++) {
    if (sortedOriginal[i] !== sortedSelected[i]) {
      return false;
    }
  }
  return true;
};

export const isUniqueArray = (array: string[]): boolean => {
  const filteredArray = new Set();
  array.map((item: string) => filteredArray.add(item.toLowerCase()));

  return filteredArray.size === array.length;
};

export const sortArrayAlphabetically = (array: any[], field = ''): any[] => {
  const _array = array.slice();
  if (field) {
    _array.sort((a, b) => {
      if (a?.[field] < b?.[field]) {
        return -1;
      }
      if (a?.[field] > b?.[field]) {
        return 1;
      }
      return 0;
    });
  } else {
    _array.sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });
  }

  return _array;
};

export const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const handleHttpsInput = (input: string): string => {
  const trimmedValue = input.trim();
  const expectedChars = ['h', 't', 't', 'p', 's', ':', '/', '/'];
  const length = trimmedValue.length > expectedChars.length ? expectedChars.length : trimmedValue.length;
  for (let i = 0; i < length; i++) {
    if (trimmedValue[i] !== expectedChars[i]) {
      return `https://${trimmedValue}`;
    }
  }

  return input;
};
