import ModifiersTable from '../ModifiersTable';
import useResponsive from '../../hooks/useResponsive';

const ModifiersView = () => {
  const { isDesktop } = useResponsive();

  return isDesktop ? (
    <div className="modifiers-view-container">
      <ModifiersTable isDesktop={isDesktop} />
    </div>
  ) : (
    <div className="modifiers-view-mobile-container">
      <ModifiersTable isDesktop={isDesktop} />
    </div>
  );
};
export default ModifiersView;
