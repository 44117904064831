import TableCell from './TableCell';
import { ModifierGroupInterface } from '../../../types/ModifierGroupInterface';

const CustomizationCell = ({ value }: any) => {
  const customizationDisplay = (modifierGroup: ModifierGroupInterface) => (
    <span key={modifierGroup.modifierGroupID}>{modifierGroup.label}</span>
  );

  return (
    <TableCell
      className={value.isHidden ? 'hidden-cell-value' : ''}
      value={value.modifierGroups}
      mappedFunction={customizationDisplay}
    />
  );
};

export default CustomizationCell;
