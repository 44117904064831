import { createContext, ReactElement, useMemo, useContext, useState, useCallback } from 'react';
import { useRestaurantContext } from '../RestaurantContext';
import { TagInterface } from '../../types/TagInterface';
import { getTags } from '../../api/tags';

interface TagsContextInterface {
  hasError: boolean;
  isLoading: boolean;
  tags: TagInterface[];
  addTag: Function;
  loadTags: Function;
  getTag: Function;
}

interface TagsProviderInterface {
  children: ReactElement;
}

const TagsContext = createContext<TagsContextInterface>({
  hasError: false,
  isLoading: false,
  tags: null,
  addTag: () => {},
  loadTags: () => {},
  getTag: () => {}
});

const TagsProvider = ({ children }: TagsProviderInterface) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [tags, setTags] = useState<TagInterface[]>([]);
  const [, setCurrentRestaurantID] = useState<number>(-1);
  const { currentRestaurant } = useRestaurantContext();

  const addTag = useCallback(
    (tag: TagInterface): void => {
      const _tags = tags.slice();
      _tags.push(tag);
      setTags(_tags);
    },
    [tags]
  );

  const loadTags = useCallback(() => {
    setIsLoading(true);
    getTags()
      .then((response: TagInterface[]) => {
        setTags(response);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status !== 401) {
          // on non auth related failure indicate failure has occurred
          setHasError(true);
        }
        setIsLoading(false);
      });
    setCurrentRestaurantID(currentRestaurant?.restaurantID);
  }, [currentRestaurant?.restaurantID]);

  const getTag = useCallback(
    (tagID: number): TagInterface => {
      const tag = tags.find((_tag) => _tag.tagID === tagID);
      if (!tag) {
        throw new Error(`Tag with id: ${tagID} does not exist.`);
      }

      return tag;
    },
    [tags]
  );

  const providerValue = useMemo(
    () => ({ hasError, isLoading, tags, addTag, getTag, loadTags }),
    [hasError, isLoading, tags, addTag, getTag, loadTags]
  );
  return <TagsContext.Provider value={providerValue}>{children}</TagsContext.Provider>;
};

const useTagsContext = () => {
  const context = useContext(TagsContext);
  if (!context) {
    throw new Error(`TagsContext must be used within the TagsProvider component`);
  }
  return context;
};

export { TagsProvider as default, useTagsContext };
