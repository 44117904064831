import { DishCharacteristicInterface } from '../../../types/DishCharacteristicInterface';
import TableCell from './TableCell';

const CharacteristicCell = ({ value }: any) => {
  const characteristicDisplay = (dishCharacteristic: DishCharacteristicInterface) => (
    <span key={dishCharacteristic.name}>{dishCharacteristic.name}</span>
  );

  return (
    <TableCell
      className={value.isHidden ? 'hidden-cell-value' : ''}
      value={value.characteristics}
      mappedFunction={characteristicDisplay}
    />
  );
};

export default CharacteristicCell;
