import CurrencyInput from 'react-currency-input-field';
import ErrorText from '../ErrorText';
import Label from '../Label';

interface CurrencyInputProps {
  className?: string;
  decimalsLimit?: number;
  decimalScale?: number;
  decimalSeparator?: string;
  label?: string;
  name: string;
  placeholder?: string;
  prefix?: string;
  onChange: any;
  onBlur: any;
  value: any;
  touched?: boolean;
  error?: string;
  allowDecimals?: boolean;
}

const CurrencyInputWrapper = ({
  value,
  className,
  decimalsLimit,
  decimalScale,
  decimalSeparator,
  label,
  name,
  placeholder,
  onChange,
  onBlur,
  touched,
  error,
  prefix = '$',
  allowDecimals = true
}: CurrencyInputProps) => (
  <div className={className}>
    <Label htmlFor={name} label={label} />
    <div className="mt-2">
      <CurrencyInput
        name={name}
        placeholder={placeholder}
        className="w-full p-4 text-sm rounded-sm border border-neutral-400"
        defaultValue=""
        allowDecimals={allowDecimals}
        decimalsLimit={decimalsLimit ?? undefined}
        decimalScale={decimalScale ?? undefined}
        decimalSeparator={decimalSeparator ?? undefined}
        allowNegativeValue={false}
        value={value}
        onValueChange={(val: string) => {
          onChange(val);
        }}
        onBlur={onBlur}
        prefix={prefix}
      />
    </div>
    <ErrorText error={touched ? error : ''} />
  </div>
);

export default CurrencyInputWrapper;
