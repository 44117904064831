import ReactDOM from 'react-dom';
import React, { ReactNode } from 'react';

interface ModalProps {
  children: ReactNode;
  className?: string;
  onOverlayClicked?: () => void;
}
const Modal = ({ children, className = '', onOverlayClicked }: ModalProps) => {
  const handleOverlayClicked = () => {
    if (!onOverlayClicked) {
      return;
    }

    onOverlayClicked();
  };

  const handleModalContentClicked = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return ReactDOM.createPortal(
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className="modal" onClick={handleOverlayClicked}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleModalContentClicked} className={`modal-content ${className}`}>
        {children}
      </div>
    </div>,
    document.querySelector('#modal-root')
  );
};

export default Modal;
