import React, { MutableRefObject } from 'react';
import ReactDOM from 'react-dom';
import { TrashCanIcon, EditIcon, ShowIcon, HideIcon, DownloadIcon } from '../../assets/svgs/icons';
import Tooltip from '../common/Tooltip';

interface ContextMenuProps {
  iconRef: MutableRefObject<any>;
  isHidden: boolean;
  hidden: boolean;
  onEditClicked?: Function;
  onHideShowClicked?: Function;
  onDeleteClicked?: Function;
  onDownloadClicked?: Function;
  onRenameClicked?: Function;
  onBlur: Function;
}
const ContextMenu = ({
  iconRef,
  isHidden,
  hidden,
  onEditClicked,
  onHideShowClicked,
  onDeleteClicked,
  onDownloadClicked,
  onRenameClicked,
  onBlur
}: ContextMenuProps) => {
  const handleEditClicked = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    onEditClicked?.();
  };

  const handleEditKeyPressed = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      onEditClicked?.();
      event.stopPropagation();
    }
  };

  const handleHideShowClicked = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    onHideShowClicked?.();
  };

  const handleHideShowKeyPressed = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      onHideShowClicked?.();
      event.stopPropagation();
    }
  };

  const handleDeleteClicked = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    onDeleteClicked?.();
  };

  const handleDeleteKeyPressed = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      onDeleteClicked?.();
      event.stopPropagation();
    }
  };

  const handleDownloadClicked = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    onDownloadClicked?.();
  };

  const handleDownloadKeyPressed = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      onDownloadClicked?.();
      event.stopPropagation();
    }
  };

  const handleRenameClicked = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    onRenameClicked?.();
  };

  const handleRenameKeyPressed = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (event.code === 'Enter') {
      onRenameClicked?.();
      event.stopPropagation();
    }
  };

  return ReactDOM.createPortal(
    <Tooltip iconRef={iconRef} hidden={hidden} onBlur={onBlur} className="context-menu" width={88} offset={50}>
      {!!onEditClicked && (
        <li>
          <a role="button" tabIndex={0} onClick={handleEditClicked} onKeyPress={handleEditKeyPressed}>
            <EditIcon />
            <span>Edit</span>
          </a>
        </li>
      )}
      {!!onRenameClicked && (
        <li>
          <a role="button" tabIndex={0} onClick={handleRenameClicked} onKeyPress={handleRenameKeyPressed}>
            <EditIcon />
            <span>Rename</span>
          </a>
        </li>
      )}
      {!!onHideShowClicked && (
        <li>
          <a role="button" tabIndex={0} onClick={handleHideShowClicked} onKeyPress={handleHideShowKeyPressed}>
            {isHidden ? <ShowIcon /> : <HideIcon />}
            <span>{isHidden ? 'Show' : 'Hide'}</span>
          </a>
        </li>
      )}
      {!!onDeleteClicked && (
        <li>
          <a role="button" tabIndex={0} onClick={handleDeleteClicked} onKeyPress={handleDeleteKeyPressed}>
            <TrashCanIcon />
            <span>Delete</span>
          </a>
        </li>
      )}
      {!!onDownloadClicked && (
        <li>
          <a role="button" tabIndex={0} onClick={handleDownloadClicked} onKeyPress={handleDownloadKeyPressed}>
            <DownloadIcon />
            <span style={{ fontSize: '0.675rem' }}>Download</span>
          </a>
        </li>
      )}
    </Tooltip>,
    document.querySelector('#context-menu-root')
  );
};

export default ContextMenu;
