// eslint-disable-next-line import/prefer-default-export
export const RestaurantWizardPages = {
  BASIC: 'Basic Info',
  LOCATION: 'Location',
  HOURS: 'Hours',
  PHOTO: 'Photos',
  LAYOUT: 'Layout',
  CONFIRM: 'Confirm',
  SOCIAL: 'Social',
  LOGO_PHOTO: 'Logo'
};
