import { useNavigate } from 'react-router-dom';
import Button from '../common/Button';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleHomeRedirect = () => {
    navigate('/');
  };

  return (
    <div className="not-found-page">
      <div className="not-found-content">
        <h1>Oops! Page Not Found</h1>
        <p>We&apos;re sorry, but the page you are looking for does not exist.</p>
        <Button onClick={handleHomeRedirect}>Go to Home</Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
