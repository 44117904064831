import Button from '../../../components/common/Button';

interface SettingSelectorProps {
  view: 'restaurant' | 'account';
  onViewChange: Function;
}
const SettingSelector = ({ view, onViewChange }: SettingSelectorProps) => (
  <div className="settings-selector">
    <Button
      className={`settings-selector-button ${view === 'restaurant' ? 'active' : ''}`}
      onClick={() => onViewChange('restaurant')}
    >
      RESTAURANT INFORMATION
    </Button>
    <Button
      className={`settings-selector-button ${view === 'account' ? 'active' : ''}`}
      onClick={() => onViewChange('account')}
    >
      ACCOUNT INFORMATION
    </Button>
  </div>
);

export default SettingSelector;
