const InfoIcon = () => (
  <svg width="25" height="25" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="" />
    <circle cx="20" cy="20" r="11" stroke="black" strokeWidth="2" />
    <rect x="19" y="17" width="2" height="9" rx="1" fill="black" />
    <rect x="19" y="14" width="2" height="2" rx="1" fill="black" />
  </svg>
);

export default InfoIcon;
