import React, { useState } from 'react';
import NameCell from './NameCell';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { deleteAnnouncement, showOrHideAnnouncement } from '../../../api/announcements';
import { useAnnouncementsContext } from '../../../contexts/AnnouncementsContext';
import { AnnouncementStatusInterface } from '../../../types/AnnouncementInterface';
import DeleteItemModal from '../../DeleteItemModal';
import AnnouncementModal from '../../AnnouncementModal';
import { PROMOTION_TYPES } from '../../../constants/PromotionTypeConstants';

const PromotionNameCell = ({ value }: any) => {
  const { image, isHidden, promotionID, name, type, submitEmail } = value;
  const { dispatch } = useModalContext();
  const { removeAnnouncement, updateAnnouncement } = useAnnouncementsContext();
  const [hasError, setHasError] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [showAnnouncementModal, setShowAnnouncementModal] = useState<boolean>(false);

  const handleHideShowAnnouncement = async () => {
    try {
      const response: AnnouncementStatusInterface = await showOrHideAnnouncement(promotionID, !isHidden);

      updateAnnouncement(promotionID, { hidden: !isHidden, active: response.active });
    } catch (error) {
      if (error.response?.status === 409) {
        openModal({
          dispatch,
          message: `${error.response.data?.errors?.[0]?.message} Please hide the promotion with the overlapping timespan or update this promotion's timeframe to show it.`
        });
      } else {
        openModal({ dispatch });
      }
    }
  };

  const handleDeleteAnnouncement = async () => {
    try {
      await deleteAnnouncement(promotionID);

      removeAnnouncement(promotionID);

      // close modal
      setOpenDeleteModal(false);
      if (hasError) {
        setHasError(false);
      }
    } catch (error) {
      setHasError(true);
    }
  };

  return (
    <span className="promotion-name-cell">
      {showAnnouncementModal && (
        <AnnouncementModal
          announcementID={promotionID}
          image={image ? { mediaID: image?.imageID, mediaURL: image?.imageURL, type: 'image' } : undefined}
          modalType={type}
          promotionType={submitEmail ? PROMOTION_TYPES.EMAIL_SUBMISSION : PROMOTION_TYPES.ANNOUNCEMENT}
          isEdit
          onSubmit={() => setShowAnnouncementModal(false)}
          onCancel={() => setShowAnnouncementModal(false)}
        />
      )}
      <DeleteItemModal
        title="DELETE PROMOTION"
        message="Please confirm that you would like to delete this promotion."
        error={
          hasError &&
          `An unexpected error has occurred while deleting promotion. Unable to perform action at this time.`
        }
        isOpen={openDeleteModal}
        onCancel={() => {
          setOpenDeleteModal(false);
          setHasError(false);
        }}
        onConfirm={handleDeleteAnnouncement}
      />
      <NameCell
        name={name}
        imageURL={image?.imageURL}
        itemID={promotionID}
        draggable={false}
        isHidden={isHidden}
        onEdit={() => setShowAnnouncementModal(true)}
        onDelete={() => setOpenDeleteModal(true)}
        onHideShow={handleHideShowAnnouncement}
      />
    </span>
  );
};

export default PromotionNameCell;
