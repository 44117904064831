const IMAGE_SIZE_LIMIT = 750000;
const TOTAL_IMAGE_SIZE_LIMIT = 125000000;
const VIDEO_SIZE_LIMIT = 32000000;
const IMAGE_COUNT_LIMIT = 15;
const VIDEO_COUNT_LIMIT = 1;
// 9:16 aspect ratio
const VIDEO_ASPECT_RATIO = 0.5625;

/**
 * Validate the meta data for uploaded video files.
 * Performs check on duration limit of video and aspect ratio.
 * @param videos
 */
const validateVideoMetadata = (videos: Blob[]): Promise<boolean> =>
  new Promise((resolve, reject) => {
    for (let i = 0; i < videos.length; i++) {
      const video = document.createElement('video');
      video.hidden = true;
      video.preload = 'metadata';
      video.id = `video${i}`;

      video.onerror = reject;
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);

        if (Number.isNaN(video.duration)) {
          reject(new Error('Video upload failed due to corrupt media file.'));
        }

        if (Math.floor(video.duration) > 15) {
          reject(new Error('Video duration exceeds limit of 15 seconds.'));
        }

        if (Math.floor(video.duration) < 2) {
          reject(new Error('Video duration must be at least 2 seconds.'));
        }

        if (
          video.videoWidth / video.videoHeight <= VIDEO_ASPECT_RATIO - 0.1 &&
          video.videoWidth / video.videoHeight >= VIDEO_ASPECT_RATIO + 0.1
        ) {
          reject(new Error('Video must have an aspect ratio of 9:16, meaning the height should be twice their width.'));
        }

        if (i === videos.length - 1) {
          resolve(true);
        }
      };
      video.src = window.URL.createObjectURL(videos[i]);
    }
  });

// eslint-disable-next-line import/prefer-default-export
export const validateMediaUpload = async (fileList: FileList): Promise<boolean> => {
  const videos: Blob[] = [];
  const images: Blob[] = [];

  return new Promise((resolve, reject) => {
    for (let i = 0; i < fileList?.length; i++) {
      if (fileList?.item(i).type?.includes('image')) {
        images.push(fileList?.item(i));
      } else if (fileList?.item(i).type?.includes('video')) {
        videos.push(fileList?.item(i));
      }
    }

    for (let i = 0; i < images?.length; i++) {
      if (images[i]?.size > IMAGE_SIZE_LIMIT) {
        reject(new Error(`Image: ${(images[i] as File)?.name} size exceeds limit of 750 KB.`));
      }
    }

    const totalSize = images.reduce((sum, img) => sum + (img?.size || 0), 0);
    if (totalSize > TOTAL_IMAGE_SIZE_LIMIT) {
      reject(new Error(`Selected images total size exceeds limit of 125 MB.`));
    }

    if (videos.length > 0) {
      if (videos.length > VIDEO_COUNT_LIMIT) {
        reject(new Error(`Amount of videos uploaded exceeds limit of ${VIDEO_COUNT_LIMIT}.`));
      }

      for (let i = 0; i < videos?.length; i++) {
        if (videos[i]?.size > VIDEO_SIZE_LIMIT) {
          reject(new Error(`Video: ${(videos[i] as File)?.name} size exceeds limit of 32 MB.`));
        }
      }

      validateVideoMetadata(videos);
    }

    resolve(true);
  });
};
