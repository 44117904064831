const DateTimeCell = ({ value }: any) => (
  <span className={`custom-cell-container date-time-cell ${value.isHidden && 'hidden-cell-value'}`}>
    {value.timestamp ? (
      <>
        <span>{new Date(Date.parse(value.timestamp)).toLocaleDateString('en-US')}</span>
        <span>{new Date(Date.parse(value.timestamp)).toLocaleTimeString('en-US', { timeStyle: 'short' })}</span>
      </>
    ) : (
      <span>--</span>
    )}
  </span>
);

export default DateTimeCell;
