import { createContext, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getMenuDetails } from '../../api/menu';
import { MenuSection, DetailMenuSectionInterface, MenuSectionInterface } from '../../types/MenuSectionInterface';
import { MenuHourInterface } from '../../types/MenuHourInterface';
import { DisclaimerMessageInterface } from '../../types/DisclaimerMessageInterface';
import { DetailedMenuInterface } from '../../types/MenuInterface';
import { CreateMenuItemInterface, DetailedMenuItemInterface } from '../../types/MenuItemInterface';
import { openModal, useModalContext } from '../ModalContext';
import { useRestaurantContext } from '../RestaurantContext';
import { ModifierGroupInterface } from '../../types/ModifierGroupInterface';
import { ModifierInterface } from '../../types/ModifierInterface';
import { MenuItemMediaInterface } from '../../types/MediaInterface';

interface MenuContextInterface {
  hasError: boolean;
  isLoading: boolean;
  menu: DetailedMenuInterface;
  selectedMenuSectionID: number;
  setSelectedMenuSectionID: Function;
  addMenuItem: Function;
  addMenuSection: Function;
  getMenuItem: Function;
  reorderMenuSections: Function;
  setMenuID: Function;
  removeFromMenuItem: Function;
  removeMediaFromMenuItem: Function;
  removeModifierFromMenuItem: Function;
  removeItem: Function;
  removeMenuSection: Function;
  resetMenu: Function;
  updateMenuItem: Function;
  updateMenuInfo: Function;
  updateMenuItems: Function;
  updateMenuSection: Function;
}

interface MenuProviderInterface {
  children: ReactElement;
}

const MenuContext = createContext<MenuContextInterface>({
  hasError: false,
  isLoading: false,
  menu: null,
  selectedMenuSectionID: null,
  setSelectedMenuSectionID: () => {},
  addMenuItem: () => {},
  getMenuItem: () => {},
  removeItem: () => {},
  addMenuSection: () => {},
  setMenuID: () => {},
  removeFromMenuItem: () => {},
  removeMediaFromMenuItem: () => {},
  removeModifierFromMenuItem: () => {},
  removeMenuSection: () => {},
  reorderMenuSections: () => {},
  resetMenu: () => {},
  updateMenuItem: () => {},
  updateMenuInfo: () => {},
  updateMenuItems: () => {},
  updateMenuSection: () => {}
});

const MenuProvider = ({ children }: MenuProviderInterface) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [menu, setMenu] = useState<DetailedMenuInterface>(null);
  const [selectedMenuSectionID, setSelectedMenuSectionID] = useState<number>(null);

  const { menus } = useRestaurantContext();
  const { dispatch } = useModalContext();

  const fetchMenuDetails = useCallback(
    (menuID: number) => {
      setIsLoading(true);

      getMenuDetails(menuID)
        .then((data: DetailedMenuInterface) => {
          setMenu(data);
          setSelectedMenuSectionID(data?.menuSections[0]?.menuSectionID || null);
        })
        .catch((error) => {
          if (error.response.status !== 401) {
            // on non auth related failure indicate failure has occurred
            setHasError(true);
            openModal({ dispatch });
          }
        })
        .finally(() => setIsLoading(false));
    },
    [dispatch]
  );

  const setMenuID = useCallback(
    (menuID: number) => {
      if (menuID !== menu?.menuID) {
        fetchMenuDetails(menuID);
      }
    },
    [fetchMenuDetails, menu?.menuID]
  );

  const updateMenuInfo = useCallback(
    (
      name: string,
      menuHours: MenuHourInterface[],
      messages: DisclaimerMessageInterface[],
      isPrixFixe: boolean,
      isHidden: boolean
    ) => {
      const _menu: DetailedMenuInterface = { ...menu };
      _menu.menuName = name;
      _menu.menuHours = menuHours;
      _menu.messages = messages;
      _menu.isPrixFixe = isPrixFixe;
      _menu.isHidden = isHidden;
      setMenu(_menu);
    },
    [menu]
  );

  const resetMenu = useCallback(() => {
    setMenu(null);
  }, []);

  const addMenuSection = useCallback(
    (menuSection: MenuSection) => {
      const _menu: DetailedMenuInterface = { ...menu };
      _menu.menuSections.push({
        menuSectionID: menuSection.menuSectionID,
        sectionName: menuSection.name,
        items: [],
        message: menuSection.message
      });
      setMenu(_menu);

      if (_menu.menuSections.length === 1) {
        setSelectedMenuSectionID(menuSection.menuSectionID);
      }
    },
    [menu]
  );

  const updateMenuSection = useCallback(
    (menuSection: MenuSectionInterface) => {
      const { menuSectionID, sectionName: menuSectionName, isHidden, message } = menuSection;
      const _menu: DetailedMenuInterface = { ...menu };
      const menuSectionIndex: number = _menu.menuSections.findIndex(
        (section) => section.menuSectionID === menuSectionID
      );
      _menu.menuSections[menuSectionIndex].sectionName = menuSectionName;
      _menu.menuSections[menuSectionIndex].message = message;
      _menu.menuSections[menuSectionIndex].isHidden = isHidden;
      setMenu(_menu);
    },
    [menu]
  );

  const reorderMenuSections = useCallback(
    (menuSectionIDs: number[]) => {
      const menuSections: DetailMenuSectionInterface[] = [];
      const _menu: DetailedMenuInterface = { ...menu };

      menuSectionIDs.forEach((id) => {
        const menuSection = _menu.menuSections.filter((section) => section.menuSectionID === id);
        if (menuSection.length === 1) {
          menuSections.push(menuSection[0]);
        }
      });
      _menu.menuSections = menuSections;
      setMenu(_menu);
    },
    [menu]
  );

  const removeMenuSection = useCallback(
    (menuSectionID: number) => {
      const _menu: DetailedMenuInterface = { ...menu };
      const sectionIndex = _menu.menuSections.findIndex((menuSection) => menuSection.menuSectionID === menuSectionID);
      _menu.menuSections.splice(sectionIndex, 1);
      setMenu(_menu);
    },
    [menu]
  );

  const addMenuItem = useCallback(
    (menuItem: CreateMenuItemInterface) => {
      const menuSections: DetailMenuSectionInterface[] = menu.menuSections.slice();

      const menuSectionIndex = menuSections.findIndex((section) => section.menuSectionID === menuItem.menuSectionID);
      menuSections[menuSectionIndex].items.push(menuItem);

      setMenu({ ...menu, menuSections });
    },
    [menu]
  );

  const getMenuItem = useCallback(
    (menuItemID: number, menuSectionID?: number) => {
      const idOfMenuSection = menuSectionID ?? selectedMenuSectionID;
      const menuSection = menu?.menuSections?.find((section) => section?.menuSectionID === idOfMenuSection);
      return menuSection?.items?.find((item) => item.menuItemID === menuItemID);
    },
    [menu, selectedMenuSectionID]
  );

  /**
   * Update a Menu Item for any of it's values, also menu section they are in
   *
   * @param menuItemID
   * @param menuSectionID - id of menu section that menu item will exist in (will move item if menuSectionID is new)
   * @param menuItem
   */
  const updateMenuItem = useCallback(
    (menuItemID: number, menuSectionID: number, menuItem: DetailedMenuItemInterface) => {
      const _menu: DetailedMenuInterface = { ...menu };
      const menuSectionIndex = _menu.menuSections.findIndex(
        (menuSection) => menuSection.menuSectionID === selectedMenuSectionID
      );
      const menuItemIndex = _menu.menuSections[menuSectionIndex].items.findIndex(
        (item) => item.menuItemID === menuItemID
      );
      const selectedMenuSectionItems = _menu.menuSections[menuSectionIndex].items.slice();

      if (selectedMenuSectionID === menuSectionID) {
        const items = _menu.menuSections[menuSectionIndex].items.slice();
        const item: DetailedMenuItemInterface = items[menuItemIndex];

        selectedMenuSectionItems[menuItemIndex] = { ...item, ...menuItem };

        delete _menu.menuSections[menuSectionIndex].items;
        _menu.menuSections[menuSectionIndex].items = selectedMenuSectionItems;
      } else {
        const item: DetailedMenuItemInterface[] = selectedMenuSectionItems.splice(menuItemIndex, 1);

        const newMenuSectionIndex = _menu.menuSections.findIndex(
          (menuSection) => menuSection.menuSectionID === menuSectionID
        );

        delete _menu.menuSections[menuSectionIndex].items;
        _menu.menuSections[menuSectionIndex].items = selectedMenuSectionItems;
        _menu.menuSections[newMenuSectionIndex].items.push({ ...item?.[0], ...menuItem });
      }

      setMenu({ ..._menu });
    },
    [menu, selectedMenuSectionID]
  );

  const updateMenuItems = useCallback(
    (menuItems: DetailedMenuItemInterface[], menuSectionID: number) => {
      const index = menu.menuSections.findIndex((section) => section.menuSectionID === menuSectionID);
      const updatedMenu = { ...menu };
      updatedMenu.menuSections[index].items = menuItems;
      setMenu(updatedMenu);
    },
    [menu]
  );

  const removeFromMenuItem = useCallback(
    (itemID: number, target: string, getID: Function) => {
      const _menu = { ...menu };
      const menuSections: DetailMenuSectionInterface[] = _menu.menuSections.slice();

      menuSections.forEach((menuSection) => {
        menuSection?.items.forEach((item) => {
          // @ts-ignore
          const itemIndex = item?.[target]?.findIndex((_target) => getID(_target) === itemID);
          if (itemIndex > -1) {
            // @ts-ignore
            item?.[target]?.splice(itemIndex, 1);
          }
        });
      });
      delete _menu.menuSections;
      _menu.menuSections = menuSections;
      setMenu(_menu);
    },
    [menu]
  );

  const removeModifierFromMenuItem = useCallback(
    (modifierID: number) => {
      const _menu = { ...menu };
      const menuSections: DetailMenuSectionInterface[] = _menu.menuSections.slice();

      menuSections.forEach((menuSection) => {
        menuSection?.items.forEach((item) => {
          item?.modifierGroups.forEach((modifierGroup: ModifierGroupInterface) => {
            const itemIndex: number = modifierGroup.modifiers?.findIndex(
              (modifier: ModifierInterface) => modifier.modifierID === modifierID
            );
            if (itemIndex > -1) {
              // @ts-ignore
              modifierGroup.modifiers?.splice(itemIndex, 1);
            }
          });
        });
      });

      delete _menu.menuSections;
      _menu.menuSections = menuSections;
      setMenu(_menu);
    },
    [menu]
  );

  const removeMediaFromMenuItem = useCallback(
    (mediaID: number) => {
      const _menu = { ...menu };
      const menuSections: DetailMenuSectionInterface[] = _menu.menuSections.slice();

      menuSections.forEach((menuSection) => {
        menuSection?.items.forEach((item) => {
          const itemIndex: number = item?.media?.findIndex(
            (_media: MenuItemMediaInterface) => _media.mediaID === mediaID
          );
          if (itemIndex > -1) {
            // @ts-ignore
            item.media?.splice(itemIndex, 1);
          }
        });
      });

      delete _menu.menuSections;
      _menu.menuSections = menuSections;
      setMenu(_menu);
    },
    [menu]
  );

  const removeItem = useCallback(
    (itemID: number, menuSectionID: number, category: 'food' | 'drink') => {
      const _menu: DetailedMenuInterface = { ...menu };
      const menuSections: DetailMenuSectionInterface[] = _menu.menuSections.slice();

      const menuSectionIndex = menuSections.findIndex((section) => section.menuSectionID === menuSectionID);
      const menuSection = menuSections[menuSectionIndex];
      const items = menuSection.items.slice();
      const filteredItems = items.filter(
        (item) => item.category !== category || (item.menuItemID !== itemID && item.category === category)
      );
      delete menuSection.items;
      menuSection.items = filteredItems;

      menuSections[menuSectionIndex] = menuSection;
      delete _menu.menuSections;
      _menu.menuSections = menuSections;
      setMenu(_menu);
    },
    [menu]
  );

  useEffect(() => {
    // initial loading of menu first menu details
    if (menu == null && menus?.length > 0) {
      fetchMenuDetails(menus[0].menuID);
    }
  }, [fetchMenuDetails, menu, menus, menus?.length]);

  const providerValue = useMemo(
    () => ({
      hasError,
      isLoading,
      menu,
      selectedMenuSectionID,
      setSelectedMenuSectionID,
      setMenuID,
      addMenuItem,
      addMenuSection,
      getMenuItem,
      removeItem,
      removeFromMenuItem,
      removeMediaFromMenuItem,
      removeModifierFromMenuItem,
      reorderMenuSections,
      removeMenuSection,
      resetMenu,
      updateMenuInfo,
      updateMenuItems,
      updateMenuItem,
      updateMenuSection
    }),
    [
      hasError,
      isLoading,
      menu,
      selectedMenuSectionID,
      setSelectedMenuSectionID,
      setMenuID,
      addMenuItem,
      addMenuSection,
      getMenuItem,
      removeFromMenuItem,
      removeMediaFromMenuItem,
      removeModifierFromMenuItem,
      removeItem,
      reorderMenuSections,
      removeMenuSection,
      resetMenu,
      updateMenuInfo,
      updateMenuItems,
      updateMenuItem,
      updateMenuSection
    ]
  );
  return <MenuContext.Provider value={providerValue}>{children}</MenuContext.Provider>;
};

const useMenuContext = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error(`MenuContext must be used within the MenuProvider component`);
  }
  return context;
};

export { MenuProvider as default, useMenuContext };
