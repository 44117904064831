import { Field, useField } from 'formik';
import ErrorText from '../ErrorText';

interface CheckboxProps {
  className?: string;
  name: string;
  label: string;
}

const FormikCheckbox = ({ className, name, label }: CheckboxProps) => {
  const [, meta] = useField(name);
  const error = meta.touched ? meta.error : null;

  return (
    <div className={className}>
      <div className="flex flex-row justify-start items-center">
        <Field type="checkbox" name={name} className="mr-4" />
        <label htmlFor={name}>{label}</label>
      </div>
      <ErrorText error={error} />
    </div>
  );
};

export default FormikCheckbox;
