import { AxiosResponse } from 'axios';

import apiClient from './apiClient';
import { CREATE_MODIFIER, DELETE_MODIFIER, EDIT_MODIFIER, GET_MODIFIERS } from '../constants/UriConstants';
import { CreateModifierRequest, EditModifierRequest, ModifierInterface } from '../types/ModifierInterface';

export const createModifier = (request: CreateModifierRequest): Promise<ModifierInterface> =>
  apiClient.post(CREATE_MODIFIER, request).then((response: AxiosResponse<ModifierInterface>) => response.data);

export const editModifier = async (request: EditModifierRequest): Promise<void> => {
  await apiClient.put(EDIT_MODIFIER, request);
};

export const deleteModifier = async (modifierID: number) => {
  await apiClient.delete(`${DELETE_MODIFIER}/${modifierID}`);
};

export const getModifiers = (): Promise<ModifierInterface[]> =>
  apiClient.get(GET_MODIFIERS).then((response) => response.data);
