import { forwardRef, useImperativeHandle, useState } from 'react';
import { DishCharacteristicInterface } from '../../types/DishCharacteristicInterface';
import SelectCharacteristicsModal from './SelectCharacteristicsModal';
import { linkRestrictionsToMenuItem } from '../../api/menuItem';
import { useMenuContext } from '../../contexts/MenuContext';
import { useCharacteristicsContext } from '../../contexts/CharacteristicsContext';

export default forwardRef((props: any, ref) => {
  const [originalRestrictionIDs] = useState<number[]>(
    props.value.characteristics.map((selected: DishCharacteristicInterface) => selected.restrictionID)
  );

  const { characteristics } = useCharacteristicsContext();
  const { updateMenuItem } = useMenuContext();

  /* Component Editor Lifecycle methods
   * https://www.ag-grid.com/react-data-grid/component-cell-editor/#configure-popup
   */
  useImperativeHandle(ref, () => ({
    // the final value to send to the grid, on completion of editing
    getValue() {
      return props.value.characteristics;
    },

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    isCancelBeforeStart() {
      return false;
    },

    // Gets called once when editing is finished (eg if Enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    isCancelAfterEnd() {
      return true;
    }
  }));

  const handleSubmit = async (restrictionIDs: number[]) => {
    await linkRestrictionsToMenuItem(props.value.menuItemID, restrictionIDs);
    updateMenuItem(props.value.menuItemID, props.value.menuSectionID, {
      dietaryRestrictions: characteristics.filter((characteristic) =>
        restrictionIDs.some((id) => id === characteristic.restrictionID)
      )
    });
    props.api.stopEditing();
  };

  return (
    <SelectCharacteristicsModal
      itemName={props.value.name}
      originalRestrictionIDs={originalRestrictionIDs}
      closeModal={() => props.api.stopEditing()}
      handleSubmit={(restrictionIDs: number[]) => handleSubmit(restrictionIDs)}
    />
  );
});
