import React from 'react';
import imageDisplay from '../../assets/images/ImageDisplay.png';

const NoImageDisplay = () => (
  <div className="no-image-display">
    <img className="no-image-icon" src={imageDisplay} alt="Icon indicating no content" />
    <p>No Image Available</p>
  </div>
);

export default NoImageDisplay;
