import { useFormik } from 'formik';
import { InferType, object, string } from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import Button from '../../../components/common/Button';
import WizardHeader from '../../../components/WizardHeader';
import ErrorText from '../../../components/common/Form/ErrorText';
import { Input, TextArea, CurrencyInput } from '../../../components/common/Form';
import { PAGE_MODIFIERS } from '../../../constants/UriConstants';
import { displayLoading, hideLoading, useAsyncContext } from '../../../contexts/AsyncContext';
import { useModifiersContext } from '../../../contexts/ModifiersContext';
import { createModifier, editModifier } from '../../../api/modifiers';
import useResponsive from '../../../hooks/useResponsive';

const ModifierInfoSchema = object({
  name: string().default('').required('Name is required'),
  price: string().default(''),
  description: string().default('')
});

interface ModifierWizardNavState {
  modifierID: number;
  name: string;
  price: string;
  description: string;
}

const ModifierWizard = () => {
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();
  const { state } = useLocation();
  const { name, price, description, modifierID } = (state as ModifierWizardNavState) || {};

  const isEditModifier = !!name;

  const [onSubmitError, setOnSubmitError] = useState<string>('');
  const { addModifier, updateModifier } = useModifiersContext();
  const { dispatch: asyncDispatch } = useAsyncContext();

  const handleOnSubmit = async (value: InferType<typeof ModifierInfoSchema>) => {
    const parsePrice = (submittedPrice: string): number => {
      const parsed = parseInt(submittedPrice?.replace(/\./, ''), 10);
      return parsed === 0 ? null : parsed;
    };
    if (ModifierInfoSchema.isValidSync(value)) {
      displayLoading({ dispatch: asyncDispatch, message: `${isEditModifier ? 'Updating' : 'Creating'} Modifier...` });
      try {
        let modifier;
        if (isEditModifier) {
          const editedModifier = {
            modifierID,
            name: value.name,
            price: parsePrice(value.price),
            description: value.description || ''
          };
          await editModifier(editedModifier);
          updateModifier(editedModifier);
        } else {
          modifier = await createModifier({
            name: value.name,
            price: parsePrice(value.price) ?? 0,
            description: value.description || ''
          });
          addModifier([modifier]);
        }
        navigate(`/${PAGE_MODIFIERS}`);
      } catch (error) {
        hideLoading(asyncDispatch);
        console.log(error);
        setOnSubmitError('An unexpected error has occurred. Please check your input and try again.');
      } finally {
        hideLoading(asyncDispatch);
      }
    }
  };

  const formik = useFormik({
    initialValues: { name, price, description } || ModifierInfoSchema.cast({}),
    validationSchema: ModifierInfoSchema,
    onSubmit: handleOnSubmit
  });

  return isDesktop ? (
    <div className="modifier-wizard-container h-screen w-full flex flex-col items-center overflow-auto">
      <WizardHeader className="modifier-wizard-header">
        <div className="modifier-wizard-header-content">
          <h1>{isEditModifier ? 'EDIT' : 'CREATE'} MODIFIER</h1>
        </div>
      </WizardHeader>
      <form className="modifier-wizard-content bg-white" onSubmit={formik.handleSubmit}>
        <Input
          name="name"
          label="NAME"
          className="modifier-wizard-name-input"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          touched={formik.touched.name}
          error={formik.errors.name}
        />
        <TextArea
          name="description"
          label="DESCRIPTION"
          className="modifier-wizard-description-input"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.description}
          touched={formik.touched.description}
          error={formik.errors.description}
        />
        <CurrencyInput
          className="modifier-wizard-price-input"
          name="price"
          label="PRICE"
          placeholder="$"
          decimalScale={2}
          decimalsLimit={2}
          onChange={(value: string) => {
            formik.setFieldValue('price', value);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.price}
          touched={formik.touched.price}
          error={formik.errors.price}
        />
        {onSubmitError && <ErrorText className="mt-3" error={onSubmitError} />}
        <div className="modifier-wizard-button-container">
          <Button className="modifier-wizard-cancel-button" onClick={() => navigate(-1)}>
            CANCEL
          </Button>
          <Button className="modifier-wizard-submit-button" submit>
            SAVE
          </Button>
        </div>
      </form>
    </div>
  ) : (
    <div className="mobile-modifier-wizard-container h-screen w-full flex flex-col items-center overflow-auto">
      <WizardHeader className="modifier-wizard-header">
        <div className="modifier-wizard-header-content">
          <h1>{isEditModifier ? 'EDIT' : 'CREATE'} MODIFIER</h1>
        </div>
      </WizardHeader>
      <form className="modifier-wizard-content bg-white" onSubmit={formik.handleSubmit}>
        <Input
          name="name"
          label="NAME"
          className="modifier-wizard-name-input"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          touched={formik.touched.name}
          error={formik.errors.name}
        />

        <CurrencyInput
          className="modifier-wizard-price-input"
          name="price"
          label="PRICE"
          placeholder="$"
          decimalScale={2}
          decimalsLimit={2}
          onChange={(value: string) => {
            formik.setFieldValue('price', value);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.price}
          touched={formik.touched.price}
          error={formik.errors.price}
        />
        {onSubmitError && <ErrorText className="mt-3" error={onSubmitError} />}
        <TextArea
          name="description"
          label="DESCRIPTION"
          className="modifier-wizard-description-input"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.description}
          touched={formik.touched.description}
          error={formik.errors.description}
        />
        <div className="modifier-wizard-button-container">
          <Button className="modifier-wizard-cancel-button" onClick={() => navigate(-1)}>
            CANCEL
          </Button>
          <Button className="modifier-wizard-submit-button" submit>
            SAVE
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ModifierWizard;
