import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Dropdown, { DropdownValue } from '../common/Form/Dropdown/Dropdown';

import { PAGE_CREATE_MODIFIER, PAGE_MENUS, PAGE_MODIFIERS } from '../../constants/UriConstants';
import AddItemButtons from '../AddItemButtons';
import { useRestaurantContext } from '../../contexts/RestaurantContext';

const MobileMenuTopBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { menus } = useRestaurantContext();

  const [selectedDropdownOption, setSelectedDropdownOption] = useState<string | { value: string; label: string }>(
    pathname.includes(PAGE_MENUS) || pathname === '/' ? PAGE_MENUS : PAGE_MODIFIERS
  );

  useEffect(() => {
    if (pathname.includes(PAGE_MENUS) || pathname === '/') {
      setSelectedDropdownOption(PAGE_MENUS);
    } else if (pathname.includes(PAGE_MODIFIERS)) {
      setSelectedDropdownOption(PAGE_MODIFIERS);
    }
  }, [pathname]);

  const dropdownOptions: DropdownValue[] = [
    { value: PAGE_MENUS, label: 'Menu Items' },
    { value: PAGE_MODIFIERS, label: 'Modifiers' }
  ];

  const handleSelectChange = (value: string | { value: string; label: string } | undefined) => {
    setSelectedDropdownOption(value);
    if (typeof value === 'string') {
      navigate(value);
    } else if (value) {
      navigate(value.value);
    }
  };

  const hasMenuSections = () => {
    let hasValues = false;

    menus.forEach((menu) => {
      if (menu?.menuSections?.length > 0) {
        hasValues = true;
      }
    });

    return hasValues;
  };

  return (
    <div className="mobile-menu-top-bar-container h-[60px]">
      <Dropdown
        value={typeof selectedDropdownOption === 'string' ? selectedDropdownOption : ''}
        onChange={handleSelectChange}
        values={dropdownOptions}
        className="mobile-navbar-dropdown"
      />
      {hasMenuSections() && (pathname.includes(PAGE_MENUS) || pathname === '/') && <AddItemButtons />}
      {pathname.includes(PAGE_MODIFIERS) && (
        <Link to={`/${PAGE_CREATE_MODIFIER}`} className="mobile-add-modifier-button button">
          ADD MODIFIER
        </Link>
      )}
    </div>
  );
};

export default MobileMenuTopBar;
