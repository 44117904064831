import { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/TapTabLargeLogoWhite.png';
import { ClosePopUpIcon } from '../../assets/svgs/icons';

interface WizardHeaderProps {
  className: string;
  children: ReactNode;
}
const WizardHeader = ({ className, children }: WizardHeaderProps) => {
  const navigate = useNavigate();

  const handleCloseIconClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className={className}>
      <ClosePopUpIcon
        onIconClicked={handleCloseIconClicked}
        stroke="white"
        className={`self-start ${className}-close-icon`}
      />
      <img src={Logo} alt="TapTab logo" />
      {children}
    </div>
  );
};

export default WizardHeader;
