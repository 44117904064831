import { createContext, ReactElement, useMemo, useContext, useState, useCallback, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { COOKIE_STRIPE_SESSION_ID } from '../../constants/AppConstants';

interface CheckoutContextInterface {
  checkoutSessionID: string;
  managerPackageID: number;
  setManagerPackageID: Function;
  setSession: Function;
  removeSession: Function;
}

interface CheckoutProviderInterface {
  children: ReactElement;
}

const CheckoutContext = createContext<CheckoutContextInterface>({
  checkoutSessionID: '',
  managerPackageID: -1,
  setManagerPackageID: () => {},
  setSession: () => {},
  removeSession: () => {}
});

const CheckoutProvider = ({ children }: CheckoutProviderInterface) => {
  const [checkoutSessionID, setCheckoutSessionID] = useState<string>('');
  const [managerPackageID, setManagerPackageID] = useState<number>(-1);

  const [cookies, setCookies] = useState(new Cookies());

  useEffect(() => {
    if (!checkoutSessionID) {
      const session: string = cookies.get(COOKIE_STRIPE_SESSION_ID);
      if (session) {
        setCheckoutSessionID(session);
      }
    }
  }, [checkoutSessionID, cookies]);

  const setSession = useCallback(
    (sessionID: string) => {
      cookies.set(COOKIE_STRIPE_SESSION_ID, sessionID, { path: '/' });
      setCookies(cookies);
      setCheckoutSessionID(sessionID);
    },
    [cookies]
  );

  const removeSession = useCallback(() => {
    cookies.remove(COOKIE_STRIPE_SESSION_ID, { path: '/' });
    setCookies(cookies);
    setCheckoutSessionID('');
  }, [cookies]);

  const providerValue = useMemo(
    () => ({
      checkoutSessionID,
      managerPackageID,
      setManagerPackageID,
      setSession,
      removeSession
    }),
    [checkoutSessionID, managerPackageID, setManagerPackageID, setSession, removeSession]
  );

  return <CheckoutContext.Provider value={providerValue}>{children}</CheckoutContext.Provider>;
};

const useCheckoutContext = () => {
  const context = useContext(CheckoutContext);
  if (!context) {
    throw new Error(`CheckoutContext must be used within the CheckoutProvider component`);
  }
  return context;
};

export { CheckoutProvider as default, useCheckoutContext };
