import { InferType } from 'yup';
import React from 'react';
import Switch from 'react-switch';
import { CurrencyInput, Input } from '../index';
import { MultiPriceSchema } from '../../../../pages/MainContainer/MenuItemWizard/MenuItemWizard';
import { TrashCanIcon, PlusIcon } from '../../../../assets/svgs/icons';
import { isUniqueArray } from '../../../../utils/general';
import InfoTooltip from '../../InfoTooltip';

interface SizePriceInputProps {
  className?: string;
  name: string;
  formik: any;
  values: InferType<typeof MultiPriceSchema>[];
  multiple?: boolean;
  onChange: any;
  onBlur: any;
  onDelete: Function;
  touched: any;
  errors: any;
  isPrixFixe?: boolean;
  defaultIndex?: number;
  setDefaultIndex?: Function;
}

const SizePriceInput = ({
  name,
  formik,
  values,
  className,
  multiple = false,
  onChange,
  onBlur,
  onDelete,
  touched,
  errors,
  isPrixFixe = false,
  defaultIndex = 0,
  setDefaultIndex
}: SizePriceInputProps) => {
  const isCustomPrice = (price: string): boolean => /[^0-9$.]/.test(price);

  const validateLabel = (label: string): boolean => {
    let isValid = true;
    if (label.trim() === '') {
      isValid = false;
    }

    const labels = values?.map((price: InferType<typeof MultiPriceSchema>) => price.label);
    if (!isUniqueArray(labels)) {
      isValid = false;
    }

    return isValid;
  };

  const validatePrice = (price: string): boolean => {
    let isValid = true;
    if (price.trim() === '') {
      isValid = false;
    }

    if (!isCustomPrice(price.trim()) && parseInt(price.replace(/\./, '').replace(/\$/, ''), 10) <= 0) {
      isValid = false;
    }

    return isValid;
  };

  const handlePlusClicked = (index: number) => {
    const touch = [];
    for (let i = 0; i < index + 1; i++) {
      touch.push({ label: true, price: true });
    }
    formik.setTouched({ price: touch });
    if (validateLabel(formik.values.price[index].label) && validatePrice(formik.values.price[index].price)) {
      formik.setFieldValue(`price.${index + 1}`, MultiPriceSchema.cast({}, { assert: false }));
    }
  };

  if (!multiple) {
    const selectedValue = values[defaultIndex]?.price;
    const selectedCustomVal = values[defaultIndex]?.isCustom;

    return (
      <div className={className}>
        <div className="multiple-size-price-container">
          {values.map((value, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              {index === 0 && (
                <>
                  <span className="prix-prompt">{`Enter 'prix' if you do not want a price to appear.`}</span>
                  <div className={`size-price-container ${isPrixFixe ? 'is-prix-fixe' : ''}`}>
                    <div className="price-input-toggle-container">
                      {selectedCustomVal ? (
                        <Input
                          name={`${name}.${index}.price`}
                          label={!multiple || index === values.length - 1 ? 'Price' : null}
                          className="size-price-input"
                          onChange={(event: any) => {
                            onChange(`${name}.${index}.price`, event.target.value);
                          }}
                          onBlur={onBlur}
                          value={selectedValue}
                          placeholder="(e.g 'Market Price')"
                          disabled={isPrixFixe}
                          touched={touched?.[index]?.price}
                          error={errors?.[index]?.price}
                        />
                      ) : (
                        <CurrencyInput
                          className="size-price-input"
                          name={`${name}.${index}.price`}
                          label="Price"
                          decimalSeparator="."
                          decimalScale={2}
                          decimalsLimit={2}
                          placeholder="$"
                          onChange={(val: string) => {
                            onChange(`${name}.${index}.price`, val);
                          }}
                          onBlur={onBlur}
                          value={selectedValue}
                          touched={touched?.[index]?.price}
                          error={errors?.[index]?.price}
                        />
                      )}
                      <div className="price-switch-container">
                        <div className="custom-tooltip-container">
                          <span className="price-label">Custom?</span>
                          <InfoTooltip
                            description="If you select Custom, then you can enter numbers, letters and special symbols."
                            direction="right"
                          />
                        </div>
                        <Switch
                          height={20}
                          width={45}
                          handleDiameter={18}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          onColor="#000000"
                          offColor="#EEEEEE"
                          boxShadow="0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16)"
                          onChange={() => {
                            onChange(`${name}.${index}.isCustom`, !values?.[index]?.isCustom);
                            onChange(`${name}.${index}.price`, '');
                          }}
                          checked={selectedCustomVal}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="multiple-size-price-container">
        {values.map((value, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <fieldset className={defaultIndex === index ? 'default-container' : ''}>
              {defaultIndex === index && <legend>Default</legend>}
              <div className={`size-price-container ${isPrixFixe ? 'is-prix-fixe' : ''}`}>
                {multiple && (
                  <div className="radio-button-container">
                    <div className="radio-button">
                      <input
                        type="radio"
                        name={`${name}.selected`}
                        checked={defaultIndex === index}
                        onChange={() => setDefaultIndex(index)}
                      />
                    </div>
                    <Input
                      name={`${name}.${index}.label`}
                      label={index === values.length - 1 ? 'Size' : null}
                      className="size-price-input"
                      onChange={(event: any) => onChange(`${name}.${index}.label`, event.target.value)}
                      onBlur={onBlur}
                      value={values?.[index]?.label}
                      disabled={isPrixFixe}
                      touched={touched?.[index]?.label}
                      error={errors?.[index]?.label}
                    />
                  </div>
                )}
                <div className="price-input-toggle-container">
                  {values?.[index]?.isCustom ? (
                    <Input
                      name={`${name}.${index}.price`}
                      label={index === values.length - 1 ? 'Price' : null}
                      className="size-price-input"
                      onChange={(event: any) => {
                        onChange(`${name}.${index}.price`, event.target.value);
                      }}
                      onBlur={onBlur}
                      value={values?.[index]?.price}
                      placeholder="(e.g 'Market Price')"
                      disabled={isPrixFixe}
                      touched={touched?.[index]?.price}
                      error={errors?.[index]?.price}
                    />
                  ) : (
                    <CurrencyInput
                      className="size-price-input"
                      name={`${name}.${index}.price`}
                      label={index === values.length - 1 ? 'Price' : null}
                      placeholder="$"
                      decimalSeparator="."
                      decimalScale={2}
                      decimalsLimit={2}
                      onChange={(val: string) => {
                        onChange(`${name}.${index}.price`, val);
                      }}
                      onBlur={onBlur}
                      value={values?.[index]?.price}
                      touched={touched?.[index]?.price}
                      error={errors?.[index]?.price}
                    />
                  )}
                  <div className="multi-price-switch-container">
                    {multiple && index === values.length - 1 && (
                      <div className="multiprice-custom-tooltip-container">
                        <span className="price-label">Custom?</span>
                        <InfoTooltip
                          description="If you select Custom, then you can enter numbers, letters and special symbols."
                          direction="right"
                        />
                      </div>
                    )}
                    <Switch
                      className="toggle"
                      height={20}
                      width={45}
                      handleDiameter={18}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      onColor="#000000"
                      offColor="#EEEEEE"
                      boxShadow="0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16)"
                      onChange={() => {
                        onChange(`${name}.${index}.isCustom`, !values?.[index]?.isCustom);
                        onChange(`${name}.${index}.price`, '');
                      }}
                      checked={values?.[index]?.isCustom}
                    />
                  </div>
                  <div>
                    {multiple && index === values.length - 1 && (
                      <PlusIcon onIconClicked={() => handlePlusClicked(index)} />
                    )}
                    {multiple && index !== values.length - 1 && <TrashCanIcon onIconClicked={() => onDelete(index)} />}
                  </div>
                </div>
              </div>
            </fieldset>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default SizePriceInput;
