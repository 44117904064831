import React, { useRef, useState } from 'react';
import Fuse from 'fuse.js';
import { TagInterface } from '../../types/TagInterface';
import { Input } from '../common/Form';
import TagSelectMenu from './TagSelectMenu';
import Tag from '../common/Tag';
import CustomTagModal from '../CustomTagModal';
import { useTagsContext } from '../../contexts/TagsContext/TagsContext';

interface TagFilterProps {
  selectedTag?: TagInterface;
  onUpdate: Function;
}

const TagFilter = ({ selectedTag, onUpdate }: TagFilterProps) => {
  const { tags, addTag } = useTagsContext();

  const [isActive, setIsActive] = useState<boolean>(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [filterInput, setFilterInput] = useState<string>('');
  const [filteredTags, setFilteredTags] = useState<TagInterface[]>(tags);

  const ref = useRef(null);

  const fuse = new Fuse(tags, {
    includeScore: true,
    findAllMatches: true,
    keys: ['name']
  });

  const handleFilterChange = (event: any) => {
    const input = event.target.value;
    setFilterInput(input);
    if (input.trim() === '') {
      setFilteredTags(tags);
    } else {
      const results: Fuse.FuseResult<TagInterface>[] = fuse.search(input === '' ? null : input);
      setFilteredTags(results.map((result) => result.item));
    }
  };

  const handleInputFocus = () => {
    setIsActive(true);
  };

  const handleMenuBlur = () => {
    if (!isCreateModalOpen) {
      setIsActive(false);
    }
  };

  const handleTagSelection = (tag: TagInterface) => {
    if (tag.tagID === selectedTag?.tagID) {
      onUpdate(null);
    } else {
      onUpdate(tag);
    }
  };

  const handleSubmittedTag = (tag: TagInterface) => {
    addTag(tag);
    onUpdate(tag);
    fuse.add(tag);
    setIsCreateModalOpen(false);
  };

  const InputComponent = (
    <Input
      className="tag-input"
      name="tag-input"
      onChange={handleFilterChange}
      onFocus={handleInputFocus}
      value={filterInput}
      placeholder="Filter TapTags"
    />
  );

  const TapFilterComponent = isActive ? (
    <TagSelectMenu
      selectedTag={selectedTag}
      hidden={!isActive}
      inputRef={ref}
      filterInput={filterInput}
      tags={filteredTags}
      onSelectTag={handleTagSelection}
      onCreateTag={() => setIsCreateModalOpen(true)}
      onMenuBlur={handleMenuBlur}
    >
      {InputComponent}
    </TagSelectMenu>
  ) : (
    InputComponent
  );

  return (
    <>
      <div className="tag-filter-container" ref={ref}>
        {TapFilterComponent}
        {selectedTag ? <Tag tag={selectedTag} /> : <p>None Selected.</p>}
      </div>
      {isCreateModalOpen && (
        <CustomTagModal
          tagName={filterInput}
          onSubmit={handleSubmittedTag}
          onCancel={() => setIsCreateModalOpen(false)}
        />
      )}
    </>
  );
};

export default TagFilter;
