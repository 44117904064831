import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import AccountInformation from '../../../components/AccountInformation';
import RestaurantInformation from '../../../components/RestaurantInformation';
import { useMenuLayoutContext } from '../../../contexts/MenuLayoutContext';
import SettingSelector from './SettingSelector';
import { useManagerContext } from '../../../contexts/ManagerContext';
import Button from '../../../components/common/Button';
import { PAGE_EDIT_RESTAURANT } from '../../../constants/UriConstants';
import { useRestaurantContext } from '../../../contexts/RestaurantContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Settings = () => {
  const [view, setView] = useState<'restaurant' | 'account'>('restaurant');

  const navigate = useNavigate();
  const { loadManager } = useManagerContext();
  const { loadLayouts } = useMenuLayoutContext();
  const { currentRestaurant } = useRestaurantContext();

  useEffect(() => {
    // only want to load the lookup list of menu layouts/manager info if settings page is being utilized
    // call to load those layouts/manager info on initial load
    loadLayouts();
    loadManager();
  }, [loadManager, loadLayouts]);

  const getSettingsView = (value: string) => {
    if (value === 'restaurant') {
      return <RestaurantInformation />;
    }
    return (
      <Elements stripe={stripePromise}>
        <AccountInformation />
      </Elements>
    );
  };
  return (
    <div className="settings-page-container">
      <div className="settings-container">
        <div className="settings-buttons">
          <SettingSelector view={view} onViewChange={(value: 'restaurant' | 'account') => setView(value)} />
          {view === 'restaurant' && currentRestaurant !== null && (
            <Button onClick={() => navigate(`/${PAGE_EDIT_RESTAURANT}`)} className="edit-restaurant-button">
              EDIT
            </Button>
          )}
        </div>
        <div className="settings-content">{getSettingsView(view)}</div>
      </div>
    </div>
  );
};

export default Settings;
