import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NameCell from './NameCell';
import { PAGE_EDIT_MODIFIER } from '../../../constants/UriConstants';
import { formatPriceString } from '../../../utils/priceFormatter';
import DeleteItemModal from '../../DeleteItemModal';
import { useModifiersContext } from '../../../contexts/ModifiersContext';
import { deleteModifier } from '../../../api/modifiers';

const ModifierNameCell = ({ value }: any) => {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const navigate = useNavigate();
  const { removeModifier } = useModifiersContext();

  const handleEditModifier = useCallback(() => {
    navigate(`/${PAGE_EDIT_MODIFIER}`, {
      state: {
        modifierID: value.modifierID,
        name: value.name,
        price: formatPriceString(value.price),
        description: value.description
      }
    });
  }, [navigate, value.description, value.name, value.price, value.modifierID]);

  const handleDeleteClicked = () => {
    setOpenDeleteModal(true);
  };

  const handleDeleteModifier = async (modifierID: number) => {
    try {
      await deleteModifier(modifierID);

      // remove modifier
      removeModifier([modifierID]);

      // close modal
      setOpenDeleteModal(false);
      if (hasError) {
        setHasError(false);
      }
    } catch (error) {
      setHasError(true);
    }
  };

  return (
    <span>
      <DeleteItemModal
        title="DELETE MODIFIER"
        message="Please confirm that you would like to delete this modifier."
        error={
          hasError && 'An unexpected error has occurred while deleting modifier. Unable to perform action at this time.'
        }
        isOpen={openDeleteModal}
        onCancel={() => {
          setOpenDeleteModal(false);
          setHasError(false);
        }}
        onConfirm={() => handleDeleteModifier(value?.modifierID)}
      />
      <NameCell
        name={value?.name}
        imageURL={value?.imageURL}
        itemID={value?.modifierID}
        draggable={false}
        onEdit={handleEditModifier}
        onDelete={handleDeleteClicked}
      />
    </span>
  );
};

export default ModifierNameCell;
