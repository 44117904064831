import React from 'react';

export interface DropdownValue {
  label: string;
  value: string | number;
}

interface DropdownProps {
  className?: string;
  label?: string;
  emptyAllowed?: boolean;
  onChange: Function;
  value: string | number;
  values: DropdownValue[];
}

const Dropdown = ({ value, className, label, onChange, values, emptyAllowed = false }: DropdownProps) => {
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange?.(event.target.value);
  };

  return (
    <div className={`dropdown-container ${className || ''}`}>
      <label htmlFor={label}>
        {label}
        <div className="select-wrapper rounded-sm">
          <select name={label} value={value} onChange={handleSelectChange}>
            {emptyAllowed && <option value=""> </option>}
            {values?.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </label>
    </div>
  );
};

export default Dropdown;
