import { ItemSizeInterface } from '../../../types/ItemSizeInterface';
import { formatItemSizePriceExact } from '../../../utils/priceFormatter';
import TableCell from './TableCell';

const SizePriceCell = ({ value }: any) => {
  const singleSizePriceDisplay = (itemSize: ItemSizeInterface) => (
    <span key={itemSize.label} className="size-price-value">
      <span className="size-price">
        {itemSize.priceOverride === 'prix' ? '--' : formatItemSizePriceExact(itemSize)}
      </span>
    </span>
  );

  const sizePriceDisplay = (itemSize: ItemSizeInterface) => (
    <span key={itemSize.label} className="size-price-value">
      <span className="size-label">{itemSize.label}:</span>
      <span className="size-price">{formatItemSizePriceExact(itemSize)}</span>
    </span>
  );

  return (
    <TableCell
      className={`size-price-cell-container ${value.isHidden && 'hidden-cell-value'}`}
      value={value?.isPrixFixe ? [] : value.allItemSizes}
      mappedFunction={value?.allItemSizes?.length === 1 ? singleSizePriceDisplay : sizePriceDisplay}
    />
  );
};
export default SizePriceCell;
