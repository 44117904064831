import { AxiosResponse } from 'axios';
import apiClient from './apiClient';
import { CreateModifierGroupRequest, ModifierGroupInterface } from '../types/ModifierGroupInterface';
import {
  CREATE_MODIFIER_GROUP,
  DELETE_MODIFIER_GROUP,
  EDIT_MODIFIER_GROUP,
  LINK_MODIFIER_GROUPS_MODIFIER
} from '../constants/UriConstants';

export const createModifierGroup = (request: CreateModifierGroupRequest): Promise<ModifierGroupInterface> =>
  apiClient
    .post(CREATE_MODIFIER_GROUP, request)
    .then((response: AxiosResponse<ModifierGroupInterface>) => response.data);

export const editModifierGroup = async (modifierGroupID: number, name: string, label: string) => {
  await apiClient.put(EDIT_MODIFIER_GROUP, { modifierGroupID, name, label });
};

export const deleteModifierGroup = async (modifierGroupID: number) => {
  await apiClient.delete(`${DELETE_MODIFIER_GROUP}/${modifierGroupID}`);
};

export const linkModifiersToModifierGroup = async (modifierGroupID: number, modifierIDs: number[]) => {
  await apiClient.put(LINK_MODIFIER_GROUPS_MODIFIER, { modifierGroupID, modifierIDs });
};
