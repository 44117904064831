import React from 'react';

interface ClosePopUpIconProps {
  stroke?: 'white' | 'black';
  onIconClicked?: Function;
  className?: string;
  width?: string;
  height?: string;
  tabIndex?: number;
}

const ClosePopUpIcon = ({
  onIconClicked,
  className = '',
  stroke = 'black',
  width = '18',
  height = '19',
  tabIndex = 0
}: ClosePopUpIconProps) => {
  const handleIconClicked = () => {
    onIconClicked?.();
  };

  const handleIconKeyPress = (event: React.KeyboardEvent<SVGSVGElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      onIconClicked?.();
    }
  };
  return (
    <svg
      role="button"
      onKeyPress={handleIconKeyPress}
      onClick={handleIconClicked}
      tabIndex={tabIndex}
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.48009 17.5L16.873 1.46878" stroke={stroke} strokeLinecap="square" />
      <path d="M16.8431 17.5L1.45021 1.46878" stroke={stroke} strokeLinecap="square" />
    </svg>
  );
};

export default ClosePopUpIcon;
