import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteDrinkItem, hideDrinkItem, showDrinkItem } from '../../../api/drinkItem';
import { deleteMenuItem, hideMenuItem, showMenuItem } from '../../../api/menuItem';
import { PAGE_EDIT_DRINK_ITEM, PAGE_EDIT_MENU_ITEM } from '../../../constants/UriConstants';
import { useMenuContext } from '../../../contexts/MenuContext';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import DeleteItemModal from '../../DeleteItemModal';
import NameCell from './NameCell';
import { useDrinkItemsContext } from '../../../contexts/DrinkItemsContext';
import { PairingInterface } from '../../../types/PairingInterface';
import { MediaInterface } from '../../../types/MediaInterface';

const MenuItemNameCell = ({ value }: any) => {
  const { category, media, isHidden, menuItemID, menuSectionID, name, tags } = value;
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const { dispatch } = useModalContext();

  const navigate = useNavigate();
  const { menu, removeItem, removeFromMenuItem, updateMenuItem } = useMenuContext();
  const { removeDrink, updateDrink } = useDrinkItemsContext();

  const handleDeleteDrinkItem = async () => {
    try {
      await deleteDrinkItem(menuItemID);

      // remove delete item
      removeItem(menuItemID, menuSectionID, 'drink');
      removeDrink(menuItemID);
      removeFromMenuItem(menuItemID, 'pairings', (drinkItem: PairingInterface) => drinkItem.drinkItemID);

      // close modal
      setOpenDeleteModal(false);
      if (hasError) {
        setHasError(false);
      }
    } catch (error) {
      setHasError(true);
    }
  };

  const handleDeleteMenuItem = async () => {
    try {
      await deleteMenuItem(menuItemID);

      // remove menu item
      removeItem(menuItemID, menuSectionID, 'food');

      // close modal
      setOpenDeleteModal(false);
      if (hasError) {
        setHasError(false);
      }
    } catch (error) {
      setHasError(true);
    }
  };

  const handleHideShowMenuItem = async () => {
    try {
      const isMenuItem = category === 'food';
      if (isMenuItem) {
        const toggleFunction = isHidden ? showMenuItem : hideMenuItem;
        await toggleFunction(menuItemID);
      } else {
        const toggleFunction = isHidden ? showDrinkItem : hideDrinkItem;
        await toggleFunction(menuItemID);
      }

      if (!isMenuItem) {
        updateDrink({ drinkItemID: menuItemID, name, isHidden: !isHidden });
      }
      updateMenuItem(menuItemID, menuSectionID, { isHidden: !isHidden });
    } catch (error) {
      openModal({ dispatch });
    }
  };

  const handleEditMenuItem = useCallback(() => {
    const path = category === 'food' ? `/${PAGE_EDIT_MENU_ITEM}` : `/${PAGE_EDIT_DRINK_ITEM}`;
    navigate(path, {
      state: {
        menuItem: {
          menuItemID
        },
        menuSectionID,
        menuID: menu.menuID
      }
    });
  }, [category, navigate, menuItemID, menuSectionID, menu.menuID]);

  const handleDeleteClicked = () => {
    setModalMessage(category === 'food' ? 'menu item' : 'drink item');
    setOpenDeleteModal(true);
  };

  const handleDeleteConfirmed = async () => {
    if (category === 'food') {
      await handleDeleteMenuItem();
    } else {
      await handleDeleteDrinkItem();
    }
  };

  const getNameCellImage = (_media: MediaInterface): string => {
    if (_media?.type === 'video') {
      return _media?.thumbnail instanceof Blob ? _media?.mediaURL : _media?.thumbnail?.thumbnailURL;
    }

    return _media?.mediaURL;
  };

  return (
    <span>
      <DeleteItemModal
        title={`DELETE ${modalMessage.toUpperCase()}`}
        message={`Please confirm that you would like to delete this ${modalMessage}.`}
        error={
          hasError &&
          `An unexpected error has occurred while deleting ${modalMessage}. Unable to perform action at this time.`
        }
        isOpen={openDeleteModal}
        onCancel={() => {
          setOpenDeleteModal(false);
          setHasError(false);
        }}
        onConfirm={handleDeleteConfirmed}
      />
      <NameCell
        name={name}
        imageURL={getNameCellImage(media?.[0])}
        itemID={menuItemID}
        tags={tags}
        draggable
        isHidden={isHidden}
        hasVideo={media?.some((_media: MediaInterface) => _media?.type === 'video')}
        hasMulti={media?.length > 1}
        onEdit={handleEditMenuItem}
        onDelete={handleDeleteClicked}
        onHideShow={handleHideShowMenuItem}
      />
    </span>
  );
};

export default MenuItemNameCell;
