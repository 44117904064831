import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../common/Button';
import Logo from '../../assets/images/TapTabLargeLogoWhite.png';
import { PAGE_CREATE_RESTAURANT } from '../../constants/UriConstants';

const InitialRestaurantsView = () => {
  const navigate = useNavigate();

  const handleCreateRestaurant = useCallback(() => {
    navigate(`/${PAGE_CREATE_RESTAURANT}`);
  }, [navigate]);

  return (
    <div
      id="image"
      role="img"
      aria-label="View of restaurant bar and kitchen"
      className="initial-restaurants-view-container"
    >
      <div className="initial-restaurants-view-content">
        <img src={Logo} alt="TapTab logo" />
        <h1>Welcome to TapTab!</h1>
        <div className="call-to-action">Create Your First Restaurant Profile</div>
        <Button onClick={handleCreateRestaurant}>CREATE RESTAURANT</Button>
      </div>
    </div>
  );
};

export default InitialRestaurantsView;
