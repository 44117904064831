import { useFormik } from 'formik';
import { useMemo } from 'react';
import { InferType, object, string } from 'yup';
import { toast } from 'react-toastify';
import Button from '../../../components/common/Button';
import WizardHeader from '../../../components/WizardHeader';
import { Input } from '../../../components/common/Form';
import ReservationOrderInputLabel from '../../../components/ReservationOrderInputLabel';
import { useRestaurantContext } from '../../../contexts/RestaurantContext';
import { updateURL } from '../../../api/restaurant';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { handleHttpsInput } from '../../../utils/general';

const ReservationOrderInputSchema = object({
  reservation: string().default(''),
  onlineOrder: string().default('')
});

const ReservationOrderPage = () => {
  const { currentRestaurant, updateRestaurant } = useRestaurantContext();
  const { dispatch } = useModalContext();

  const handleOnSubmit = async (value: InferType<typeof ReservationOrderInputSchema>) => {
    const { reservationUrl, orderingUrl } = currentRestaurant;
    let tempReservationUrl = null;
    let tempOrderingUrl = null;

    if (value.reservation !== reservationUrl) {
      tempReservationUrl = value.reservation;
    }

    if (value.onlineOrder !== orderingUrl) {
      tempOrderingUrl = value.onlineOrder;
    }
    if (tempReservationUrl !== null || tempOrderingUrl !== null) {
      try {
        await updateURL(tempReservationUrl, tempOrderingUrl);
        updateRestaurant({
          ...currentRestaurant,
          reservationUrl: tempReservationUrl != null ? tempReservationUrl : reservationUrl,
          orderingUrl: tempOrderingUrl != null ? tempOrderingUrl : orderingUrl
        });
        toast.success('Successfully Updated!', {
          position: toast.POSITION.TOP_RIGHT
        });
      } catch (error) {
        openModal({
          dispatch,
          message:
            'An unexpected error has occurred. Please email our team at info@taptabapp.com so that we can address this issue promptly.'
        });
      }
    }
  };
  const initialValues = useMemo(
    () => ({
      reservation: currentRestaurant?.reservationUrl || '',
      onlineOrder: currentRestaurant?.orderingUrl || ''
    }),
    [currentRestaurant]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: ReservationOrderInputSchema,
    onSubmit: handleOnSubmit,
    enableReinitialize: true
  });

  const handleInputChange = (name: string, value: string) => {
    const modifiedValue = handleHttpsInput(value);
    formik.setFieldValue(name, modifiedValue);
  };

  return (
    <div className="reservation-container">
      <WizardHeader className="reservation-header">
        <div className="reservation-header-content">
          <h1>Reservations & Online Ordering</h1>
        </div>
      </WizardHeader>
      <form className="reservation-order-content" onSubmit={formik.handleSubmit}>
        <div className="reservation-order-page">
          <div className="reservation-order-inputs">
            <Input
              name="reservation"
              label={
                <ReservationOrderInputLabel
                  label="reservations"
                  text="Enter the link at which guests can make reservations."
                />
              }
              className="reservation-order-input"
              onChange={(event: any) => handleInputChange('reservation', event.target.value)}
              onBlur={formik.handleBlur}
              value={formik.values.reservation}
              touched={formik.touched.reservation}
              error={formik.errors.reservation}
            />
            <Input
              name="onlineOrder"
              label={
                <ReservationOrderInputLabel
                  label="online ordering"
                  text="Enter the link at which guests can place online orders."
                />
              }
              className="reservation-order-input"
              onChange={(event: any) => handleInputChange('onlineOrder', event.target.value)}
              onBlur={formik.handleBlur}
              value={formik.values.onlineOrder}
              touched={formik.touched.onlineOrder}
              error={formik.errors.onlineOrder}
            />
          </div>
          <Button className="reservation-order-submit-button" submit>
            SUBMIT
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ReservationOrderPage;
