const TwitterIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
      fill="#2DAAE1"
    />
    <path
      d="M17.3333 6.61547C16.8429 6.83309 16.3162 6.98021 15.7623 7.04633C16.3276 6.70786 16.7606 6.1715 16.965 5.53315C16.4281 5.85205 15.8405 6.07656 15.2277 6.19692C14.729 5.66537 14.0179 5.3335 13.2311 5.3335C11.721 5.3335 10.4963 6.55771 10.4963 8.0687C10.4963 8.2828 10.5204 8.49166 10.5668 8.6922C8.29351 8.57795 6.27811 7.48903 4.92909 5.83441C4.69354 6.23851 4.55871 6.70836 4.55871 7.20966C4.55871 8.15849 5.04165 8.99565 5.77547 9.48604C5.34106 9.47257 4.91618 9.35532 4.53636 9.14407V9.17863C4.53636 10.504 5.47905 11.6091 6.73041 11.8605C6.50053 11.9234 6.25929 11.9563 6.00972 11.9563C5.83326 11.9563 5.66207 11.9396 5.49483 11.9082C5.8429 12.9945 6.85306 13.7852 8.04963 13.8076C7.11351 14.5414 5.93395 14.9788 4.65283 14.9788C4.43217 14.9788 4.21409 14.9657 4 14.9399C5.21065 15.7162 6.64811 16.1693 8.19282 16.1693C13.2237 16.1693 15.9751 12.0015 15.9751 8.38705C15.9751 8.26839 15.9725 8.15061 15.9668 8.03326C16.5028 7.64552 16.9656 7.16543 17.3333 6.61547Z"
      fill="white"
    />
  </svg>
);

export default TwitterIcon;
