import { useEffect, useMemo, useState } from 'react';
import { object, string, number, InferType, array } from 'yup';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { v4 as uuid } from 'uuid';
import WizardHeader from '../../../components/WizardHeader';
import WizardProgressBar from '../../../components/WizardProgressBar/WizardProgressBar';
import { RestaurantWizardPages } from '../../../constants/RestaurantWizardConstants';
import RestaurantBasicInfoPage from './RestaurantBasicInfoPage';
import RestaurantLocationPage from './RestaurantLocationPage';
import RestaurantPhotoPage from './RestaurantPhotoPage';
import RestaurantLayoutPage from './RestaurantLayoutPage';
import RestaurantConfirmationPage from './RestaurantConfirmationPage';
import { useMenuLayoutContext } from '../../../contexts/MenuLayoutContext';
import { useCuisinesContext } from '../../../contexts/CuisinesContext';
import {
  CreateRestaurantRequestInterface,
  CreateRestaurantResponseInterface,
  EditRestaurantRequestInterface,
  GalleryImagesUploadInterface,
  RestaurantDetailsInterface,
  RestaurantImagesInterface
} from '../../../types/RestaurantInterface';
import {
  assignPackageToRestaurant,
  createRestaurant,
  editRestaurant,
  uploadRestaurantImages
} from '../../../api/restaurant';
import { updateMenuLayout } from '../../../api/menuLayouts';
import { CreateRestaurantAddressRequestInterface } from '../../../types/RestaurantAddressInterface';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { useRestaurantContext } from '../../../contexts/RestaurantContext';
import { PAGE_CREATE_RESTAURANT, PAGE_MENUS, PAGE_SETTINGS } from '../../../constants/UriConstants';
import { displayLoading, hideLoading, useAsyncContext } from '../../../contexts/AsyncContext';
import { useCheckoutContext } from '../../../contexts/CheckoutContext';
import getStripeSessionInfo from '../../../api/stripeSessionInfo';
import { COOKIE_STRIPE_SESSION_ID } from '../../../constants/AppConstants';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import RestaurantSocialsPage from './RestaurantSocialsPage';
import { RestaurantSocialsInterface } from '../../../types/RestaurantSocialsInterface';
import { DayAndHourPickerRowSchema } from '../../../components/common/Form';
import RestaurantHoursPage from './RestaurantHoursPage';
import { RestaurantHourInterface } from '../../../types/RestaurantHourInterface';
import { fullToShortDayName, shortToFullDayName } from '../../../constants/weekDayConstants';
import { buildRestaurantHours, compareMilitaryTime } from '../../../utils/time';
import { usePermissionsContext } from '../../../contexts/PermissionsContext';
import { BlobWrapper, MediaInterface } from '../../../types/MediaInterface';
import { AlbumMediaInterface } from '../../../types/AlbumMediaInterface';
import RestaurantLogoPhotoPage from './RestaurantLogoPhotoPage';
import { ImageInterface } from '../../../types/ImageInterface';
import { AlbumInterface } from '../../../types/AlbumInterface';

export const AddressSchema = object({
  address1: string().default('').required('Street address is required.'),
  address2: string().default(''),
  city: string().default('').required('City is required.'),
  governingDistrict: string().default('').required('State is required.'),
  postCode: string().default('').required('Zip code is required.')
});
export const RestaurantSocialsSchema = object({
  facebook: string().default(''), // edit socials needs to be null, empty string deletes (updates) socials
  instagram: string().default(''),
  snapchat: string().default(''),
  tiktok: string().default(''),
  twitter: string().default('')
});

const RestaurantWizardSchema = object({
  name: string().default('').required('Name is required.'),
  description: string().default(''),
  phone: string()
    .default('')
    .min(12, 'Phone number must be at least 10 digits long.')
    .required('Phone number is required.'),
  email: string().default('').email('Email is not valid.').required('Email is required.'),
  cuisine: number().default(1).required('Cuisine is required.'),
  layoutID: number().default(1),
  website: string().default(''),
  address: AddressSchema.default(AddressSchema.cast({})).required('Address is required.'),
  socials: RestaurantSocialsSchema.default(RestaurantSocialsSchema.cast({})),
  restaurantHours: array()
    .of(DayAndHourPickerRowSchema)
    .default([DayAndHourPickerRowSchema.cast({ id: uuid() })]),
  availabilityNotes: string().default('')
});

const cookies = new Cookies();

const RestaurantWizard = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { loadLayouts, getMenuLayout } = useMenuLayoutContext();
  const { loadCuisines, getCuisine } = useCuisinesContext();
  const { dispatch } = useModalContext();
  const { dispatch: asyncDispatch } = useAsyncContext();
  const { isLoading, currentRestaurant, addRestaurant, updateRestaurant } = useRestaurantContext();
  const { checkoutSessionID, managerPackageID, removeSession, setManagerPackageID, setSession } = useCheckoutContext();
  const {
    permissions: { hasPairings, hasImageUpload }
  } = usePermissionsContext();
  const isSuper = hasPairings && hasImageUpload;

  const [currentPage, setCurrentPage] = useState<string>(RestaurantWizardPages.BASIC);
  const [logoImage, setLogoImage] = useState<Blob | string>(undefined);
  const [thumbnailImage, setThumbnailImage] = useState<Blob | string>(undefined);
  const [profileImage, setProfileImage] = useState<Blob | string>(undefined);
  const [menuCoverImage, setMenuCoverImage] = useState<Blob | string>(undefined);
  const [galleryImages, setGalleryImages] = useState<(BlobWrapper | AlbumMediaInterface)[]>(null);
  const [stripeKey, setStripeKey] = useState<string>(null);
  const [isExpired, setIsExpired] = useState<boolean>(false);

  const isEdit = !pathname.includes(PAGE_CREATE_RESTAURANT);

  useEffect(() => {
    // only want to load the lookup list of menu layouts and cuisines if restaurant wizard is being utilized
    // call to load those layouts on initial load
    loadLayouts();
    loadCuisines();
  }, [loadLayouts, loadCuisines]);

  useEffect(() => {
    if (isEdit && currentRestaurant) {
      if (logoImage === undefined) {
        setLogoImage(currentRestaurant?.images?.logo?.imageURL || null);
      }
      if (thumbnailImage === undefined) {
        setThumbnailImage(currentRestaurant?.images?.thumbnail?.imageURL || null);
      }
      if (profileImage === undefined) {
        setProfileImage(currentRestaurant?.images?.profile[0]?.imageURL || null);
      }
      if (menuCoverImage === undefined) {
        setMenuCoverImage(currentRestaurant?.images?.menuCover?.imageURL || null);
      }

      if (galleryImages == null) {
        setGalleryImages(
          currentRestaurant?.images?.albums?.[0]?.media?.map((_media) => ({
            mediaURL: _media?.mediaURL,
            mediaID: _media?.mediaID,
            type: 'image'
          })) || []
        );
      }
    }
  }, [isEdit, currentRestaurant, logoImage, thumbnailImage, profileImage, menuCoverImage, galleryImages]);

  useEffect(() => {
    if (!isSuper) {
      if (!isEdit && managerPackageID === -1 && !stripeKey) {
        const session = new URLSearchParams(search).get('session');
        const sessionID: string = checkoutSessionID || cookies.get(COOKIE_STRIPE_SESSION_ID);
        if (session) {
          setStripeKey(session);
          setSession(session);
        } else if (sessionID) {
          setStripeKey(sessionID);
        } else {
          openModal({
            dispatch,
            title: 'Session Expired',
            message:
              'It appears the purchased subscription has already been applied to a restaurant. If this is not the case please email our team at info@taptabapp.com so that we can address this issue promptly.',
            onDismiss: () => navigate('/')
          });
        }
      }
    }
  }, [managerPackageID, checkoutSessionID, navigate, search, setSession, stripeKey, isEdit, dispatch, isSuper]);

  useEffect(() => {
    if (stripeKey && managerPackageID === -1 && !isExpired) {
      getStripeSessionInfo(stripeKey)
        .then((info) => {
          if (info.managerPackageIDs.length === 0) {
            setIsExpired(true);
            openModal({
              dispatch,
              title: 'Session Expired',
              message:
                'It appears the purchased subscription has already been applied to a restaurant. If this is not the case please email our team at info@taptabapp.com so that we can address this issue promptly, ' +
                `and make sure to include this session ID in your email. \n \n SESSION ID: ${stripeKey}`,
              onDismiss: () => navigate('/')
            });
          } else {
            setManagerPackageID(info.managerPackageIDs[0]);
            toast.success('Successfully Purchased!', {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        })
        .catch(() => {
          openModal({
            dispatch,
            message:
              'Please email our team at info@taptabapp.com so that we can address this issue promptly, ' +
              `and make sure to include this session ID in your email. \n \n SESSION ID: ${stripeKey}`
          });
        });
    }
  }, [dispatch, isExpired, managerPackageID, navigate, setManagerPackageID, stripeKey]);

  const getInitialValues = () => {
    const restaurantHoursToFormik = (hours: RestaurantHourInterface[]): InferType<typeof DayAndHourPickerRowSchema>[] =>
      hours
        .map((block) => {
          const daysOfWeek: any = {};
          Object.entries(fullToShortDayName).forEach(([day]) => {
            daysOfWeek[fullToShortDayName[day]] = block.day.includes(day);
          });
          return {
            id: uuid(),
            daysOfWeek,
            startTime: block.start,
            endTime: block.end
          } as InferType<typeof DayAndHourPickerRowSchema>;
        })
        .sort((a, b) => {
          const [aDay] = Object.entries(a.daysOfWeek).find(([key, value]) => value && shortToFullDayName[key]);
          const [bDay] = Object.entries(b.daysOfWeek).find(([key, value]) => value && shortToFullDayName[key]);
          if (aDay === bDay) {
            return compareMilitaryTime(a.startTime, b.startTime);
          }
          return Object.keys(shortToFullDayName).indexOf(aDay) < Object.keys(shortToFullDayName).indexOf(bDay) ? -1 : 1;
        });

    if (isEdit) {
      return {
        name: currentRestaurant?.name,
        description: currentRestaurant?.description || '',
        phone: `+1${currentRestaurant?.phone}`,
        email: currentRestaurant?.email,
        cuisine: currentRestaurant?.cuisine?.cuisineID,
        website: currentRestaurant?.website || '',
        layoutID: currentRestaurant?.menuLayout?.layoutID,
        address: {
          address1: currentRestaurant?.address?.address1,
          address2: currentRestaurant?.address?.address2 || '',
          city: currentRestaurant?.address?.city,
          governingDistrict: currentRestaurant?.address?.governingDistrict,
          postCode: currentRestaurant?.address?.postalCode
        },
        socials: {
          facebook: currentRestaurant?.socials?.facebook,
          instagram: currentRestaurant?.socials?.instagram,
          snapchat: currentRestaurant?.socials?.snapchat,
          tiktok: currentRestaurant?.socials?.tiktok,
          twitter: currentRestaurant?.socials?.twitter
        },
        restaurantHours:
          currentRestaurant?.restaurantHours?.length > 0
            ? restaurantHoursToFormik(currentRestaurant?.restaurantHours)
            : [DayAndHourPickerRowSchema.cast({ id: uuid() })],
        availabilityNotes: currentRestaurant?.availabilityNotes ?? ''
      };
    }
    return RestaurantWizardSchema.cast({});
  };

  const uploadGalleryMedia = async (
    existingMedia: AlbumMediaInterface[],
    uploadedMedia: (BlobWrapper | AlbumMediaInterface)[]
  ): Promise<GalleryImagesUploadInterface> => {
    const hasReorder = (
      array1: (BlobWrapper | AlbumMediaInterface)[],
      array2: (BlobWrapper | AlbumMediaInterface)[]
    ): boolean => {
      let reorder = false;
      array1?.forEach((val, index) => {
        if ((val as AlbumMediaInterface)?.mediaID !== (array2[index] as AlbumMediaInterface)?.mediaID) {
          reorder = true;
        }
      });

      return reorder;
    };
    const buildReordered = (uploaded: (BlobWrapper | AlbumMediaInterface)[]): (number | string)[] => {
      let fileCount = 0;
      return uploaded.map((val: BlobWrapper | AlbumMediaInterface) => {
        if ('file' in val) {
          let fileName;
          if ((val as BlobWrapper)?.file.type?.includes('image')) {
            fileName = `filename-${fileCount}`;
            fileCount++;
          }
          return fileName;
        }

        return val.mediaID;
      });
    };

    // check if any newly uploaded files
    const deletedIDs: number[] = [];
    const reorderValues: (number | string)[] = [];
    const hasUploads: boolean = uploadedMedia?.some((_media) => 'file' in _media);
    const imageUploads: Blob[] = [];

    if (hasUploads) {
      imageUploads.push(
        ...(uploadedMedia
          .filter((_media) => 'file' in _media && (_media as BlobWrapper)?.file?.type?.includes('image'))
          .map((_media) => (_media as BlobWrapper)?.file) as Blob[])
      );

      const existing: AlbumMediaInterface[] = uploadedMedia.filter(
        (_media) => !('file' in _media)
      ) as AlbumMediaInterface[];

      if (existingMedia?.length) {
        deletedIDs.push(
          ...existingMedia
            .filter((_media) => !existing.some((_existing) => _existing.mediaID === _media.mediaID))
            .map((_media) => (_media as AlbumMediaInterface)?.mediaID)
        );
      }

      reorderValues.push(...buildReordered(uploadedMedia));
    } else {
      const hasDelete: boolean = uploadedMedia?.length !== existingMedia?.length;
      if (hasDelete && existingMedia?.length) {
        deletedIDs?.push(
          ...existingMedia
            .filter((_media) => !uploadedMedia.some((_existing) => _existing.mediaID === _media.mediaID))
            .map((_media) => (_media as MediaInterface).mediaID)
        );
        reorderValues.push(...buildReordered(uploadedMedia));
      } else if (hasReorder(existingMedia, uploadedMedia)) {
        reorderValues.push(...buildReordered(uploadedMedia));
      }
    }

    return {
      newGalleryImages: imageUploads,
      newGalleryImagesToDelete: deletedIDs,
      newGalleryOrder: reorderValues as string[]
    };
  };

  // removes deleted and reordered GALLERY images for restaurant
  const handleDeletedGalleryImages = (galleryImagesToDelete: number[], media: AlbumMediaInterface[]) => {
    const clone = currentRestaurant?.images?.albums[0]?.media.slice();
    const response: AlbumMediaInterface[] = [];
    if (galleryImagesToDelete?.length) {
      galleryImagesToDelete.forEach((deletedImage) => {
        clone.forEach((galleryImage) => {
          if (deletedImage !== galleryImage?.mediaID) {
            response.push(galleryImage);
          }
        });
      });
    } else {
      return media;
    }
    return response;
  };

  // remove restaurant images profile images
  const handleDeletedRestaurantProfileImages = (imagesToDelete: number[], profile: ImageInterface[]) => {
    const clone = currentRestaurant?.images?.profile?.slice();
    const response: ImageInterface[] = [];
    if (imagesToDelete?.length) {
      imagesToDelete?.forEach((deletedImage) => {
        clone.forEach((_profileImage) => {
          if (deletedImage !== _profileImage?.imageID) {
            response.push(_profileImage);
          }
        });
      });
    } else {
      return profile;
    }
    return response;
  };

  // handle reordering gallery images
  const handleGalleryImageOrder = (images: RestaurantImagesInterface, gallerOrder: string[]) => {
    let galleryImagesToReorder: AlbumMediaInterface[] = [];
    if (images) {
      galleryImagesToReorder = images?.albums?.[0]?.media;
    }
    const temp: AlbumMediaInterface[] = [];
    gallerOrder.forEach((element) => {
      if (parseInt(element, 10)) {
        // regular ids "1", "2", "3", "4", ..."N" in string format
        temp.push(
          currentRestaurant?.images?.albums[0]?.media.find((_media) => _media.mediaID === parseInt(element, 10))
        );
      } else {
        // filename-N
        const index = parseInt(element.split('-')[1], 10);
        temp.push(galleryImagesToReorder[index]);
      }
    });
    // leftovers
    galleryImagesToReorder?.forEach((_media) => {
      if (!temp.find((tempMedia) => tempMedia.mediaID === _media.mediaID)) {
        temp.push(_media);
      }
    });

    if (temp?.length) {
      return [{ ...currentRestaurant?.images?.albums?.[0], media: temp }] as unknown as AlbumInterface[];
    }
    return [{ ...currentRestaurant?.images?.albums?.[0] }] as unknown as AlbumInterface[];
  };

  const handleOnSubmit = async (value: InferType<typeof RestaurantWizardSchema>) => {
    if (RestaurantWizardSchema.isValidSync(value)) {
      displayLoading({ dispatch: asyncDispatch, message: `${isEdit ? 'Updating' : 'Creating'} Restaurant...` });
      try {
        const phoneNumber = value.phone.substring(2, value.phone.length);
        if (isEdit) {
          if (!thumbnailImage || !profileImage || !menuCoverImage) {
            openModal({
              dispatch,
              title: `Required Image is missing.`,
              message: `A required image is not provided. Check your input and try again.`,
              onDismiss: () => setCurrentPage(RestaurantWizardPages.PHOTO)
            });
            return;
          }

          const buildEditRestaurantRequest = (
            restaurant: InferType<typeof RestaurantWizardSchema>
          ): EditRestaurantRequestInterface => {
            const editRequest: EditRestaurantRequestInterface = {};

            if (restaurant.name !== currentRestaurant?.name) {
              editRequest.name = restaurant.name;
            }
            if (restaurant.description?.trim() !== currentRestaurant?.description?.trim()) {
              editRequest.description = restaurant.description;
            }
            if (phoneNumber.trim() !== currentRestaurant?.phone?.trim()) {
              editRequest.phone = phoneNumber;
            }
            if (restaurant.email.trim() !== currentRestaurant?.email?.trim()) {
              editRequest.email = restaurant.email;
            }
            if (restaurant.cuisine !== currentRestaurant?.cuisine.cuisineID) {
              editRequest.cuisineID = restaurant.cuisine;
            }
            if (restaurant.website.trim() !== currentRestaurant?.website?.trim()) {
              editRequest.website = restaurant.website;
            }

            if (
              restaurant.address.address1.trim() !== currentRestaurant?.address?.address1?.trim() ||
              restaurant.address.address2.trim() !== currentRestaurant?.address?.address2?.trim() ||
              restaurant.address.city.trim() !== currentRestaurant?.address?.city?.trim() ||
              restaurant.address.governingDistrict.trim() !== currentRestaurant?.address?.governingDistrict?.trim() ||
              restaurant.address.postCode.trim() !== currentRestaurant?.address?.postalCode?.trim()
            ) {
              editRequest.address = {
                restaurantAddressID: currentRestaurant?.address?.restaurantAddressID,
                country: 'United States',
                postalCode: restaurant.address.postCode.trim(),
                address1: restaurant.address.address1.trim(),
                address2: restaurant.address.address2.trim() ? restaurant.address.address2.trim() : null,
                city: restaurant.address.city.trim(),
                governingDistrict: restaurant.address.governingDistrict.trim()
              };
            }

            // socials
            if (
              restaurant.socials?.facebook?.trim() !== currentRestaurant?.socials?.facebook?.trim() ||
              restaurant.socials?.instagram?.trim() !== currentRestaurant?.socials?.instagram?.trim() ||
              restaurant.socials?.snapchat?.trim() !== currentRestaurant?.socials?.snapchat?.trim() ||
              restaurant.socials?.tiktok?.trim() !== currentRestaurant?.socials?.tiktok?.trim() ||
              restaurant.socials?.twitter?.trim() !== currentRestaurant?.socials?.twitter?.trim()
            ) {
              // copy over
              editRequest.socials = { ...restaurant?.socials };
              // trim all existing values
              Object.values(editRequest?.socials)
                .filter((social) => social)
                .forEach((social) => social.trim());
            }

            if (restaurant.availabilityNotes.trim() !== currentRestaurant.availabilityNotes.trim()) {
              editRequest.availabilityNotes = restaurant.availabilityNotes.trim();
            }

            // TODO: Only set restaurant hours if they've been updated
            editRequest.restaurantHours = buildRestaurantHours(restaurant?.restaurantHours);

            return editRequest;
          };

          const request = buildEditRestaurantRequest(value);

          if (Object.keys(request).length > 0) {
            await editRestaurant(request);
          }

          if (value.layoutID !== currentRestaurant?.menuLayout?.layoutID) {
            await updateMenuLayout(value.layoutID);
          }

          let logo: Blob = null;
          let thumbnail: Blob = null;
          let profile: Blob = null;
          let menuCover: Blob = null;
          const imagesToDelete: number[] = [];

          if (typeof logoImage !== 'string') {
            logo = logoImage;
            if (currentRestaurant?.images?.logo) {
              imagesToDelete.push(currentRestaurant?.images?.logo?.imageID);
            }
          }
          if (typeof thumbnailImage !== 'string') {
            thumbnail = thumbnailImage;
            if (currentRestaurant?.images?.thumbnail) {
              imagesToDelete.push(currentRestaurant?.images?.thumbnail?.imageID);
            }
          }
          if (typeof profileImage !== 'string') {
            profile = profileImage;
            if (currentRestaurant?.images?.profile?.length > 0) {
              imagesToDelete.push(currentRestaurant?.images?.profile?.[0]?.imageID);
            }
          }

          if (typeof menuCoverImage !== 'string') {
            menuCover = menuCoverImage;
            if (currentRestaurant?.images?.menuCover) {
              imagesToDelete.push(currentRestaurant?.images?.menuCover?.imageID);
            }
          }

          const { newGalleryImagesToDelete, newGalleryOrder, newGalleryImages } = await uploadGalleryMedia(
            currentRestaurant?.images?.albums?.[0]?.media,
            galleryImages
          );

          const galleryImagesToDelete = newGalleryImagesToDelete;
          const galleryOrder = newGalleryOrder;
          const galleryImagesToUpload = newGalleryImages;

          let images: RestaurantImagesInterface = null;
          if (
            logo ||
            thumbnail ||
            profile ||
            menuCover ||
            galleryImagesToUpload?.length ||
            imagesToDelete?.length ||
            galleryImagesToDelete?.length ||
            galleryOrder?.length
          ) {
            images = await uploadRestaurantImages(
              logo,
              thumbnail,
              profile,
              menuCover,
              galleryImagesToUpload,
              galleryImagesToDelete,
              imagesToDelete,
              galleryOrder
            );
          }

          const restaurant: RestaurantDetailsInterface = {
            ...currentRestaurant,
            name: value.name,
            description: value.description,
            phone: phoneNumber,
            email: value.email,
            website: value.website,
            address: {
              ...currentRestaurant?.address,
              address1: value.address?.address1,
              address2: value.address?.address2,
              city: value.address?.city,
              governingDistrict: value.address?.governingDistrict,
              postalCode: value.address?.postCode
            },
            menuLayout: getMenuLayout(value.layoutID),
            cuisine: getCuisine(value.cuisine),
            socials: {
              facebook: value.socials?.facebook,
              instagram: value.socials?.instagram,
              snapchat: value.socials?.snapchat,
              tiktok: value.socials?.tiktok,
              twitter: value.socials?.twitter
            },
            restaurantHours: buildRestaurantHours(value?.restaurantHours),
            availabilityNotes: value?.availabilityNotes
          };

          if (galleryImagesToDelete?.length || imagesToDelete?.length) {
            restaurant.images = {
              albums: [
                {
                  ...currentRestaurant?.images?.albums[0],
                  media: handleDeletedGalleryImages(
                    galleryImagesToDelete,
                    currentRestaurant?.images?.albums?.[0]?.media
                  )
                }
              ],
              logo:
                imagesToDelete?.length &&
                imagesToDelete.find((deletedImage) => deletedImage === currentRestaurant?.images?.logo?.imageID)
                  ? null
                  : currentRestaurant?.images?.logo,
              menuCover:
                imagesToDelete?.length &&
                imagesToDelete.find((deletedImage) => deletedImage === images?.menuCover?.imageID)
                  ? null
                  : currentRestaurant?.images?.menuCover,
              profile: handleDeletedRestaurantProfileImages(imagesToDelete, currentRestaurant?.images?.profile),
              thumbnail:
                imagesToDelete?.length &&
                imagesToDelete.find((deletedImage) => deletedImage === images?.thumbnail?.imageID)
                  ? null
                  : currentRestaurant?.images?.thumbnail
            };
          }

          if (images || galleryOrder.length) {
            restaurant.images = {
              albums: galleryOrder?.length ? handleGalleryImageOrder(images, galleryOrder) : restaurant?.images?.albums,
              logo: images?.logo ? images?.logo : restaurant?.images?.logo,
              menuCover: images?.menuCover ? images?.menuCover : restaurant?.images?.menuCover,
              profile: images?.profile && images?.profile?.length > 0 ? images?.profile : restaurant?.images?.profile,
              thumbnail: images?.thumbnail ? images?.thumbnail : restaurant?.images?.thumbnail
            };
          }

          updateRestaurant(restaurant);
          toast.success('Successfully Updated Restaurant!', {
            position: toast.POSITION.TOP_RIGHT
          });
          navigate(`/${PAGE_SETTINGS}`);
        } else {
          const streetName = value.address.address1
            .split(' ')
            .map((val: string, index: number) => (index !== 0 ? `${val} ` : ''));
          const restaurantAddress: CreateRestaurantAddressRequestInterface = {
            address1: value.address.address1.trim(),
            address2: value.address.address2?.trim() || null,
            streetNumber: value.address.address1.split(' ')?.[0],
            streetName: streetName.toString().replace(/,/g, '').trim(),
            city: value.address.city.trim(),
            governingDistrict: value.address.governingDistrict.trim(),
            country: 'United States',
            postalCode: value.address.postCode.trim()
          };

          const restaurantSocials: RestaurantSocialsInterface = { ...value?.socials };
          Object.values(restaurantSocials)?.forEach((social) => social?.trim());

          const createRestaurantRequest: CreateRestaurantRequestInterface = {
            name: value.name.trim(),
            description: value.description?.trim() || null,
            phone: phoneNumber.trim(),
            email: value.email.trim(),
            cuisineID: value.cuisine,
            website: value.website?.trim() || null,
            address: restaurantAddress,
            socials: restaurantSocials,
            restaurantHours: buildRestaurantHours(value?.restaurantHours),
            availabilityNotes: value.availabilityNotes?.trim() || null
          };

          const createdRestaurant: CreateRestaurantResponseInterface = await createRestaurant(createRestaurantRequest);

          const images: RestaurantImagesInterface = await uploadRestaurantImages(
            typeof logoImage === 'string' ? null : logoImage,
            typeof thumbnailImage === 'string' ? null : thumbnailImage,
            typeof profileImage === 'string' ? null : profileImage,
            typeof menuCoverImage === 'string' ? null : menuCoverImage,
            !galleryImages?.filter((gallery: BlobWrapper) => gallery?.file as Blob)?.length
              ? null
              : (galleryImages?.map((gallery: BlobWrapper) => gallery?.file) as Blob[]),
            [],
            [],
            [],
            createdRestaurant.restaurantID
          );

          await updateMenuLayout(value.layoutID, createdRestaurant.restaurantID);

          addRestaurant({
            ...createdRestaurant,
            menuLayout: getMenuLayout(value.layoutID),
            images
          } as RestaurantDetailsInterface);

          if (!isSuper) {
            await assignPackageToRestaurant(managerPackageID, createdRestaurant.restaurantID);
            removeSession();
          }

          toast.success('Successfully Created Restaurant!', {
            position: toast.POSITION.TOP_RIGHT
          });
          navigate(`/${PAGE_MENUS}`);
        }
      } catch (error) {
        let errorMessage = `Unexpected error occurred while ${
          isEdit ? 'editing' : 'creating'
        } restaurant. Check your input and try again. If this issue keeps occurring please reach out to the TapTab team.`;

        if (error?.response?.status === 409) {
          errorMessage = `Restaurant with the same name already exists at this address.`;
        }

        if (error?.response?.status === 400) {
          errorMessage = error?.response?.data?.errors?.[0]?.message;
        }

        hideLoading(asyncDispatch);
        openModal({
          dispatch,
          title: `Error occurred while ${isEdit ? 'editing' : 'creating'} restaurant.`,
          message: errorMessage
        });
      } finally {
        hideLoading(asyncDispatch);
      }
    }
  };

  const handleLogoImageUpload = (file: Blob) => {
    setLogoImage(file);
  };

  const handleThumbnailImageUpload = (file: Blob) => {
    setThumbnailImage(file);
  };

  const handleProfileImageUpload = (file: Blob) => {
    setProfileImage(file);
  };

  const handleMenuCoverImageUpload = (file: Blob) => {
    setMenuCoverImage(file);
  };

  const handleGalleryImagesUpload = (files: (BlobWrapper | AlbumMediaInterface)[]) => {
    setGalleryImages(files);
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: RestaurantWizardSchema,
    enableReinitialize: false,
    onSubmit: handleOnSubmit,
    validateOnBlur: true
  });

  const wizardPage = useMemo(() => {
    if (isEdit && Object.keys(currentRestaurant).length === 0) {
      return null;
    }

    switch (currentPage) {
      case RestaurantWizardPages.LOCATION:
        return (
          <RestaurantLocationPage
            formik={formik}
            onNext={() => setCurrentPage(RestaurantWizardPages.HOURS)}
            onBack={() => setCurrentPage(RestaurantWizardPages.BASIC)}
          />
        );
      case RestaurantWizardPages.HOURS:
        return (
          <RestaurantHoursPage
            formik={formik}
            onNext={() => setCurrentPage(RestaurantWizardPages.LOGO_PHOTO)}
            onBack={() => setCurrentPage(RestaurantWizardPages.LOCATION)}
          />
        );
      case RestaurantWizardPages.LOGO_PHOTO:
        return (
          <RestaurantLogoPhotoPage
            logoImage={logoImage}
            onNext={() => setCurrentPage(RestaurantWizardPages.PHOTO)}
            onBack={() => setCurrentPage(RestaurantWizardPages.HOURS)}
            handleLogoImageUpload={handleLogoImageUpload}
          />
        );
      case RestaurantWizardPages.PHOTO:
        return (
          <RestaurantPhotoPage
            thumbnailImage={thumbnailImage}
            profileImage={profileImage}
            menuCoverImage={menuCoverImage}
            galleryImages={galleryImages == null ? [] : galleryImages}
            onNext={() => setCurrentPage(RestaurantWizardPages.LAYOUT)}
            onBack={() => setCurrentPage(RestaurantWizardPages.LOGO_PHOTO)}
            handleThumbnailImageUpload={handleThumbnailImageUpload}
            handleProfileImageUpload={handleProfileImageUpload}
            handleMenuCoverImageUpload={handleMenuCoverImageUpload}
            handleGalleryImagesUpload={handleGalleryImagesUpload}
          />
        );
      case RestaurantWizardPages.LAYOUT:
        return (
          <RestaurantLayoutPage
            formik={formik}
            onNext={() => setCurrentPage(RestaurantWizardPages.SOCIAL)}
            onBack={() => setCurrentPage(RestaurantWizardPages.PHOTO)}
          />
        );
      case RestaurantWizardPages.SOCIAL:
        return (
          <RestaurantSocialsPage
            formik={formik}
            onNext={() => setCurrentPage(RestaurantWizardPages.CONFIRM)}
            onBack={() => setCurrentPage(RestaurantWizardPages.LAYOUT)}
          />
        );
      case RestaurantWizardPages.CONFIRM:
        return (
          <RestaurantConfirmationPage
            formik={formik}
            onBack={() => setCurrentPage(RestaurantWizardPages.SOCIAL)}
            logoImage={logoImage}
            thumbnailImage={thumbnailImage}
            profileImage={profileImage}
            menuCoverImage={menuCoverImage}
            galleryImages={galleryImages}
          />
        );
      case RestaurantWizardPages.BASIC:
      default:
        return (
          <RestaurantBasicInfoPage formik={formik} onNext={() => setCurrentPage(RestaurantWizardPages.LOCATION)} />
        );
    }
  }, [
    currentPage,
    currentRestaurant,
    formik,
    isEdit,
    logoImage,
    thumbnailImage,
    profileImage,
    menuCoverImage,
    galleryImages
  ]);

  return (
    <div className="restaurant-wizard-container">
      <WizardHeader className="restaurant-wizard-header">
        <div className="restaurant-wizard-header-content">
          <h1 className="restaurant-wizard-header-title">{isEdit ? 'EDIT' : 'CREATE'} RESTAURANT</h1>
          <WizardProgressBar
            steps={[
              RestaurantWizardPages.BASIC,
              RestaurantWizardPages.LOCATION,
              RestaurantWizardPages.HOURS,
              RestaurantWizardPages.LOGO_PHOTO,
              RestaurantWizardPages.PHOTO,
              RestaurantWizardPages.LAYOUT,
              RestaurantWizardPages.SOCIAL,
              RestaurantWizardPages.CONFIRM
            ]}
            currentPage={currentPage}
            navigation={currentPage === RestaurantWizardPages.CONFIRM || isEdit}
            handleNavUpdate={(page: string) => setCurrentPage(page)}
          />
        </div>
      </WizardHeader>
      {isLoading ? (
        <div className="mt-6">
          <LoadingSpinner />
        </div>
      ) : (
        <form className="restaurant-wizard-content" onSubmit={formik.handleSubmit}>
          {wizardPage}
        </form>
      )}
    </div>
  );
};

export default RestaurantWizard;
