export const ANNOUNCEMENT_TYPES = {
  DRAWER: 'drawer',
  EMBEDDED: 'embed',
  MODAL: 'modal'
};

// Solution for Types found here:
// https://www.typescriptlang.org/docs/handbook/enums.html#objects-vs-enums
export type AnnouncementType = typeof ANNOUNCEMENT_TYPES[keyof typeof ANNOUNCEMENT_TYPES];

export const ANNOUNCEMENT_TYPE_VALUES = [
  {
    label: 'Pop Up (Center)',
    value: ANNOUNCEMENT_TYPES.MODAL
  },
  {
    label: 'Pop Up (Bottom)',
    value: ANNOUNCEMENT_TYPES.DRAWER
  },
  {
    label: 'In Menu',
    value: ANNOUNCEMENT_TYPES.EMBEDDED
  }
];
