import { array, InferType, object, string } from 'yup';
import { FieldArray, FormikProps, useField, Formik, Form } from 'formik';
import { ReactNode } from 'react';
import Button from '../common/Button';
import Modal from '../common/Modal';
import { ErrorText, FormikInput, FormikTextArea } from '../common/Form';
import { PlusIcon, TrashCanIcon, ClosePopUpIcon } from '../../assets/svgs/icons';

interface MenuSectionModalProps {
  name: string;
  isOpen: boolean;
  onSubmit: Function;
  onCancel: Function;
}

export const MenuSectionSchema = object({
  name: string()
    .default('')
    .when('message', {
      // if message has a value, then require a name value,
      // otherwise it's a blank entry so ignore it
      is: (val: string) => !!val,
      then: (schema) => schema.required('Name is required.')
    }),
  message: string().default('')
});

const MenuSectionInfoSchema = object({
  sections: array()
    .of(MenuSectionSchema)
    .default([MenuSectionSchema.cast({ name: '', message: '' })])
});

const MultiMenuSectionModal = ({ name, isOpen, onSubmit, onCancel }: MenuSectionModalProps) => {
  const [, meta] = useField(name);
  const { value } = meta;

  if (!isOpen) {
    return null;
  }
  const handleCancel = () => {
    onCancel?.();
  };

  const handleAddSection = (index: number, push: any, form: FormikProps<any>) => {
    const touch = [];
    for (let i = 0; i < index + 1; i++) {
      touch.push({ name: true });
    }
    form.setTouched({ sections: touch });
    const hasError = typeof form.errors.sections === 'string';
    if (MenuSectionSchema.isValidSync(form.values.sections?.[index]) && !hasError) {
      form.setFieldError(`sections.${index}`, null);
      push(MenuSectionSchema.cast({}, { assert: false }));
    }
  };

  const handleOnSubmit = (val: InferType<typeof MenuSectionInfoSchema>) => {
    if (MenuSectionInfoSchema.isValidSync(val)) {
      let filtered: InferType<typeof MenuSectionSchema>[] = val.sections;
      if (val.sections.length > 1) {
        filtered = val.sections.filter((section: InferType<typeof MenuSectionSchema>) => section.name.trim() !== '');
      }
      onSubmit(filtered);
    }
  };

  const displayDuplicateError = (index: number, form: FormikProps<any>): ReactNode => {
    let count = 0;
    const val = form.values.sections?.[index];

    form.values.sections.forEach((section: InferType<typeof MenuSectionSchema>) => {
      if (section.name !== '' && section.name.trim().toLowerCase() === val.name.trim().toLowerCase()) {
        count++;
      }
    });
    if (count > 1) {
      return <ErrorText error={form.errors?.sections as string} />;
    }
    return null;
  };

  const getInitialValues = (
    existingValues: InferType<typeof MenuSectionSchema>[]
  ): InferType<typeof MenuSectionInfoSchema> => {
    if (existingValues.length > 1) {
      return { sections: existingValues };
    }

    if (existingValues.length === 1 && existingValues[0].name !== '') {
      return { sections: existingValues };
    }

    return MenuSectionInfoSchema.cast({});
  };

  return (
    <Modal className="multi-menu-section-modal">
      <div className="modal-header">
        <ClosePopUpIcon onIconClicked={onCancel} className="modal-close-icon" />
        <h1 className="modal-title">
          {value.length > 1 || (value.length === 1 && value[0].name !== '') ? 'EDIT' : 'ADD'} MENU SECTIONS
        </h1>
      </div>
      <div className="modal-body">
        <Formik
          initialValues={getInitialValues(value)}
          enableReinitialize
          validationSchema={MenuSectionInfoSchema}
          onSubmit={handleOnSubmit}
        >
          <Form>
            <FieldArray name="sections">
              {({ remove, push, form }) => (
                <>
                  <div className="menu-section-inputs-container">
                    {form.values.sections.length > 0 &&
                      form.values.sections.map((entry: InferType<typeof MenuSectionSchema>, index: number) => (
                        <div className="menu-section-input-container" key={index}>
                          <div className="menu-section-formik-input">
                            <FormikInput
                              name={`sections.${index}.name`}
                              label="MENU SECTION"
                              className="menu-section-input"
                            />
                            {displayDuplicateError(index, form)}
                          </div>
                          <FormikTextArea
                            name={`sections.${index}.message`}
                            label={
                              <span>
                                DESCRIPTION <i>(Optional)</i>
                              </span>
                            }
                            className="menu-section-area"
                          />
                          {index === form.values.sections.length - 1 ? (
                            <div className={`menu-section-icon ${form?.errors?.sections ? 'has-error' : ''}`}>
                              <PlusIcon
                                width="12"
                                height="12"
                                onIconClicked={() => handleAddSection(index, push, form)}
                              />
                            </div>
                          ) : (
                            <div className="menu-section-icon">
                              <TrashCanIcon onIconClicked={() => remove(index)} />
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                  <div className="modal-footer">
                    <Button
                      onClick={() => {
                        handleCancel();
                      }}
                      className="modal-cancel-button"
                    >
                      CANCEL
                    </Button>
                    <Button submit className="modal-confirm-button">
                      CONFIRM
                    </Button>
                  </div>
                </>
              )}
            </FieldArray>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
};

export default MultiMenuSectionModal;
