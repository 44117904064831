import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface SidebarLinkProps {
  name: string;
  path: string;
  icon?: ReactNode;
  isActive?: boolean;
}

const SidebarLink = ({ name, path, icon, isActive }: SidebarLinkProps) => (
  <li className={`sidebar-link ${isActive ? 'selected' : ''}`}>
    <Link to={path}>
      {icon}
      {name}
    </Link>
  </li>
);

export default SidebarLink;
