import { useState } from 'react';
import Button from '../../../components/common/Button';
import SelectCharacteristicsModal from '../../../components/SelectCharacteristicsModal';
import ErrorText from '../../../components/common/Form/ErrorText';
import { CurrencyInput, Label } from '../../../components/common/Form';
import SelectPairingsModal from '../../../components/SelectPairingsModal';
import TagFilter from '../../../components/TagFilter';
import { TagInterface } from '../../../types/TagInterface';
import { ModifierGroupInterface } from '../../../types/ModifierGroupInterface';
import ModifierGroupsModal from '../../../components/ModifierGroupsModal';
import useResponsive from '../../../hooks/useResponsive';

interface OptionalWithAModalProps {
  title: string;
  subtitle: string;
  summary?: string;
  isEdit: boolean;
  buttonName: string;
  handleButtonClick: Function;
}

const OptionalWithAModal = ({
  title,
  subtitle,
  summary,
  isEdit,
  buttonName,
  handleButtonClick
}: OptionalWithAModalProps) => (
  <div className="my-8">
    <p className="optional-value-label mb-2">{title}</p>
    <div className="md:flex">
      <div className="w-full lg:w-[350px] md:w-[310px] pr-4 mr-10">
        <p className="font-normal text-xs text-[#6C6C6C] tracking-[0.4px] mb-1">{subtitle}</p>
        {summary && <p className="text-xs text-[#6C6C6C] font-light italic">{summary}</p>}
      </div>
      <Button
        className={`optional-value-button whitespace-nowrap ${isEdit ? '!bg-[rgb(33,55,122)]' : ''}`}
        onClick={() => handleButtonClick()}
      >
        {isEdit ? `Edit ${buttonName}` : `Add ${buttonName}`}
      </Button>
    </div>
  </div>
);

interface ItemAdditionalInfoPageProps {
  formik: any;
  itemName: string;
  modifierGroups: ModifierGroupInterface[];
  restrictionIDs: number[];
  pairingIDs: number[];
  selectedTag: TagInterface;
  errorMessage: string;
  isMenuItem: boolean;
  handleBackClicked: Function;
  onPairingsChange: Function;
  onCharacteristicsChange: Function;
  // eslint-disable-next-line no-unused-vars
  onModifierGroupAdd: (modifierGroup: Partial<ModifierGroupInterface>) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  onModifierGroupEdit: (modifierGroup: ModifierGroupInterface) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  onModifierGroupDelete: (id: number) => void;
  // eslint-disable-next-line no-unused-vars
  onModifierGroupsReorder: (modifierGroups: ModifierGroupInterface[]) => Promise<void>;
  onSelectedTagChange: Function;
}

const ItemAdditionalInfoPage = ({
  formik,
  itemName,
  modifierGroups,
  restrictionIDs,
  pairingIDs,
  selectedTag,
  errorMessage,
  isMenuItem,
  handleBackClicked,
  onPairingsChange,
  onCharacteristicsChange,
  onModifierGroupAdd,
  onModifierGroupEdit,
  onModifierGroupDelete,
  onModifierGroupsReorder,
  onSelectedTagChange
}: ItemAdditionalInfoPageProps) => {
  const [selectCharacteristics, setSelectCharacteristics] = useState<boolean>(false);
  const [selectModifierGroups, setSelectModifierGroups] = useState<boolean>(false);
  const [selectPairings, setSelectPairings] = useState<boolean>(false);
  const { isDesktop } = useResponsive();

  return (
    <div className="w-[100%]">
      <div className="special-tags-container">
        <Label label={<span>SPECIAL TAG</span>} />
        <TagFilter selectedTag={selectedTag} onUpdate={onSelectedTagChange} />
      </div>
      <CurrencyInput
        className="menu-item-wizard-calories-input"
        name="calories"
        label="CALORIES"
        placeholder=""
        onChange={(value: string) => {
          formik.setFieldValue('calories', value);
        }}
        decimalSeparator=""
        onBlur={formik.handleBlur}
        value={formik.values.calories}
        touched={formik.touched.calories}
        error={formik.errors.calories}
        prefix=""
        allowDecimals={false}
      />
      <OptionalWithAModal
        title="KEY DISH CHARACTERISTICS"
        subtitle={`Does this ${isMenuItem ? 'menu item' : 'drink'} contain any of the following?`}
        summary="Examples of Dish Characteristics are Contains Nuts, Contains Shellfish, Contains Beef, Vegan,
                  Vegetarian, and Gluten Free."
        isEdit={restrictionIDs?.length > 0}
        buttonName="Dish Characteristics"
        handleButtonClick={() => setSelectCharacteristics(true)}
      />
      <OptionalWithAModal
        title="MODIFIER GROUPS"
        subtitle={`Can this ${isMenuItem ? 'menu item' : 'drink'} be customized?`}
        summary="Examples of modifier groups are Spiciness and Temperature"
        isEdit={modifierGroups?.length > 0}
        buttonName="Modifier Groups"
        handleButtonClick={() => setSelectModifierGroups(true)}
      />
      {isMenuItem && (
        <OptionalWithAModal
          title="PAIRINGS"
          subtitle="Do you recommend pairing any drinks with this menu item?"
          isEdit={pairingIDs?.length > 0}
          buttonName="Pairings"
          handleButtonClick={() => setSelectPairings(true)}
        />
      )}
      <div className="mt-16">
        <Button className="menu-item-wizard-back-button" onClick={handleBackClicked}>
          BACK
        </Button>
        <Button className="menu-item-wizard-submit-button" submit>
          SAVE
        </Button>
      </div>
      {errorMessage && <ErrorText className="mt-3" error={errorMessage} />}
      {selectCharacteristics && (
        <SelectCharacteristicsModal
          itemName={itemName}
          originalRestrictionIDs={restrictionIDs || []}
          closeModal={() => setSelectCharacteristics(false)}
          handleSubmit={(ids: number[]) => {
            onCharacteristicsChange(ids);
            setSelectCharacteristics(false);
          }}
          isDesktop={isDesktop}
        />
      )}
      {selectModifierGroups && (
        <ModifierGroupsModal
          modifierGroups={modifierGroups}
          onClose={() => setSelectModifierGroups(false)}
          addModifierGroup={onModifierGroupAdd}
          updateModifierGroup={onModifierGroupEdit}
          deleteModifierGroup={onModifierGroupDelete}
          reorderModifierGroups={onModifierGroupsReorder}
        />
      )}
      {selectPairings && (
        <SelectPairingsModal
          itemName={itemName}
          originalPairingIDs={pairingIDs || []}
          closeModal={() => setSelectPairings(false)}
          handleSubmit={(ids: number[]) => {
            onPairingsChange(ids);
            setSelectPairings(false);
          }}
          isDesktop={isDesktop}
        />
      )}
    </div>
  );
};

export default ItemAdditionalInfoPage;
