import { useEffect, useRef, useState } from 'react';
import { ROW_HEIGHT } from '../../ItemTable';

const DescriptionCell = ({ value }: any) => {
  const [isOverFlow, setIsOverflow] = useState<boolean>(false);
  const cellRef = useRef(null);

  useEffect(() => {
    if (cellRef?.current && value?.description) {
      setIsOverflow(cellRef?.current.clientHeight > ROW_HEIGHT - 4);
    }
  }, [cellRef, value?.description]);

  const getValue = () => (isOverFlow ? value?.description.substring(0, 100) : value?.description);
  return (
    <span
      ref={cellRef}
      className={`custom-cell-container break-normal overflow-hidden pr-[10px] ${
        value?.isHidden && 'hidden-cell-value'
      }`}
      style={{ minWidth: 200 }}
    >
      {getValue() || '--'}
      {isOverFlow && <span>...</span>}
    </span>
  );
};

export default DescriptionCell;
