import React, { useState } from 'react';
import Modal from '../common/Modal';
import Button from '../common/Button';
import { downloadMenu } from '../../api/menu';
import { ClosePopUpIcon } from '../../assets/svgs/icons';
import { ErrorText } from '../common/Form';
import useResponsive from '../../hooks/useResponsive';

interface DeleteItemModalProps {
  menuID: number;
  onConfirm: Function;
}
const DownloadMenuModal = ({ menuID, onConfirm }: DeleteItemModalProps) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { isDesktop } = useResponsive();

  if (!menuID) {
    return null;
  }

  const handleDownloadPDF = async () => {
    try {
      const { fileURL } = await downloadMenu(menuID, 'pdf');
      window.open(fileURL, '_blank');
      onConfirm();
    } catch (err) {
      let message = 'An unexpected error has occurred. If it keeps occurring reach out to the TapTab team.';
      if (err.response.status === 422) {
        message = 'The menu needs to have at least one menu section and one menu item to download.';
      }
      setErrorMessage(message);
    }
  };

  const handleDownloadWordDoc = async () => {
    try {
      const { fileURL } = await downloadMenu(menuID, 'docx');
      window.open(fileURL, '_blank');
      onConfirm();
    } catch (err) {
      setErrorMessage('An unexpected error has occurred. If it keeps occurring reach out to the TapTab team.');
    }
  };

  return (
    <Modal className="download-menu-modal">
      <div className="modal-header">
        <h1 className="modal-title">DOWNLOAD MENU</h1>
        <ClosePopUpIcon onIconClicked={onConfirm} width="16" height="16" />
      </div>
      <div className="modal-body">
        <div className="modal-message" style={{ wordBreak: 'keep-all' }}>
          This menu is a text menu that allows you to offer a simple printed version to those guests that prefer printed
          menus. You can design or edit the print menu however you’d like.
          <br />
          <br />
          The menu includes names, sizes, prices, descriptions, priced add ons, and disclaimers, but does not include
          pairing recommendations, dish characteristics, or non-priced add ons or customizations.
        </div>
        <ErrorText error={errorMessage} />
      </div>
      {isDesktop ? (
        <div className="modal-footer">
          <Button onClick={handleDownloadPDF} className="modal-pdf-button">
            DOWNLOAD PDF
          </Button>
          <Button onClick={handleDownloadWordDoc} className="modal-word-doc-button">
            DOWNLOAD WORD DOC
          </Button>
        </div>
      ) : (
        <div className="modal-footer">
          <Button onClick={handleDownloadPDF} className="modal-pdf-button">
            PDF
          </Button>
          <Button onClick={handleDownloadWordDoc} className="modal-word-doc-button">
            WORD
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default DownloadMenuModal;
