import React from 'react';
import { Input, TextArea, Label } from '../common/Form';
import Button from '../common/Button';
import { PlusIcon, TrashCanIcon } from '../../assets/svgs/icons';
import imageDisplay from '../../assets/images/ImageDisplay.png';
import MediaPreview from '../MediaPreview';
import MediaLibraryModal from '../MediaLibraryModal';
import { MediaLibraryInterface, MenuItemMediaInterface } from '../../types/MediaInterface';

interface RestaurantAboutTeamMemberProps {
  formik: any;
  teamMemberImage: MenuItemMediaInterface;
  handleTeamMemberImageUpload: Function;
  handleDeleteTeamMember: Function;
  isLastTeamMember: boolean;
  index: number;
  handleSkipCreateTeam: Function;
  handleAddTeamMember: Function;
}

const RestaurantAboutTeamMember: React.FC<RestaurantAboutTeamMemberProps> = ({
  formik,
  teamMemberImage,
  handleTeamMemberImageUpload,
  handleDeleteTeamMember,
  isLastTeamMember,
  handleSkipCreateTeam,
  handleAddTeamMember,
  index
}) => {
  const [openMediaLibraryModal, setOpenMediaLibraryModal] = React.useState(false);

  const handleImageUpload = (_media: MediaLibraryInterface[]) => {
    handleTeamMemberImageUpload(
      _media?.[0] ? { mediaID: _media?.[0].mediaID, mediaURL: _media?.[0].mediaUrl, type: 'image' } : undefined
    );
  };
  const handleMediaLibraryRemoval = () => {
    handleTeamMemberImageUpload(undefined);
  };

  const handleCloseMediaLibraryModal = () => {
    setOpenMediaLibraryModal(false);
  };

  return (
    <div className="restaurant-about-team-container">
      <Input
        name={`teamMembers.${index}.teamName`}
        label="Name"
        className="restaurant-wizard-about-name-input"
        onBlur={formik.handleBlur}
        touched={formik.touched?.teamMembers?.[index]?.teamName}
        onChange={formik.handleChange}
        value={formik.values?.teamMembers?.[index]?.teamName}
        error={formik.errors?.teamMembers?.[index]?.teamName}
      />
      <Input
        name={`teamMembers.${index}.teamTitle`}
        label="Title"
        className="restaurant-wizard-title-input"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.teamMembers?.[index]?.teamTitle}
        touched={formik.touched?.teamMembers?.[index]?.teamTitle}
        error={formik.errors?.teamMembers?.[index]?.teamTitle}
      />

      <TextArea
        name={`teamMembers.${index}.teamDescription`}
        label="Description"
        className="restaurant-wizard-about-team-description-input"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.teamMembers?.[index]?.teamDescription}
        touched={formik.touched?.teamMembers?.[index]?.teamDescription}
        error={formik.errors?.teamMembers?.[index]?.teamDescription}
      />
      <div className="restaurant-wizard-about-team-photo">
        <Label label="Photo" />
        <div className="about-page-profile-photo-upload">
          <div className="media-container">
            {!teamMemberImage && (
              <Button className="select-media-button" onClick={() => setOpenMediaLibraryModal(true)}>
                <img className="media-upload-icon" src={imageDisplay} alt="Icon indicating upload" />
                <p>Upload Media</p>
              </Button>
            )}
            <MediaPreview media={teamMemberImage ? [teamMemberImage] : []} onUpdate={handleImageUpload} multiple />
            {openMediaLibraryModal && (
              <MediaLibraryModal
                selectedMediaIDs={teamMemberImage ? [teamMemberImage.mediaID] : []}
                onSelect={handleImageUpload}
                onRemove={handleMediaLibraryRemoval}
                onClose={handleCloseMediaLibraryModal}
                mediaTypeOverride="image"
              />
            )}
          </div>
        </div>
      </div>
      {formik.values.teamMembers.length - 1 === index && isLastTeamMember && (
        <Button className="about-page-add-button" onClick={handleAddTeamMember}>
          <PlusIcon /> ADD TEAM MEMBER
        </Button>
      )}
      {formik.values.teamMembers.length > 1 && (
        <Button className="delete-team-member-button" onClick={handleDeleteTeamMember}>
          <TrashCanIcon /> Delete Team Member
        </Button>
      )}
      {formik.values.teamMembers.length === 1 &&
        !formik.values.teamMembers[0].teamName &&
        !formik.values.teamMembers[0].teamTitle &&
        !formik.values.teamMembers[0].teamDescription && (
          <Button className="hide-team-member-button" onClick={handleSkipCreateTeam}>
            <TrashCanIcon /> Skip For Now
          </Button>
        )}
    </div>
  );
};
export default RestaurantAboutTeamMember;
