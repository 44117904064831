import { InferType } from 'yup';
import { FieldArray, useField } from 'formik';
import { MenuSectionSchema } from '../MenuSectionModal/MultiMenuSectionModal';

interface MenuAddSectionProps {
  name: string;
}

const MenuAddSection = ({ name }: MenuAddSectionProps) => {
  const [, meta] = useField(name);
  const { value } = meta;

  if (value[0].name.length === 0) {
    return null;
  }
  return (
    <div className="multi-menu-section-form">
      <div className="modal-header">
        <h1 className="modal-title">ADDED MENU SECTIONS</h1>
      </div>
      <div className="modal-body">
        <div className="added-sections-header">
          <h2>MENU SECTION</h2>
          <h2 className="menu-section-title-description">DESCRIPTION</h2>
        </div>
        <FieldArray name={name}>
          {() => (
            <div>
              <div className="menu-section-inputs-container">
                {value.map((entry: InferType<typeof MenuSectionSchema>, index: number) => (
                  <div className="menu-section-input-container" key={index}>
                    <div className="menu-section-formik-input">
                      <span className="menu-section-input">{entry.name}</span>
                    </div>
                    <span className="menu-section-area">{entry.message}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </FieldArray>
      </div>
    </div>
  );
};

export default MenuAddSection;
