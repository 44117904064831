import React from 'react';
import { TagInterface } from '../../types/TagInterface';
import { ClosePopUpIcon } from '../../assets/svgs/icons';

interface TagMenuItemProps {
  tag: TagInterface;
  isSelected: boolean;
  onSelectTag: Function;
}

const TagMenuItem = ({ isSelected, tag, onSelectTag }: TagMenuItemProps) => {
  const handleTagSelection = () => {
    onSelectTag(tag);
  };

  const handleTagSelectionKeyPress = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      onSelectTag(tag);
    }
  };

  return (
    <li role="none" className={`${isSelected ? 'selected-tag' : ''} tag-menu-item`}>
      <a
        tabIndex={0}
        role="menuitem"
        onClick={handleTagSelection}
        onKeyPress={(event) => handleTagSelectionKeyPress(event)}
      >
        <div className="tag-info-container">
          <div className="tag-color" style={{ backgroundColor: tag.tagColor }} />
          <span className="tag-name">{tag.name}</span>
        </div>
        <ClosePopUpIcon className="remove-tag-icon" width="9px" height="9px" tabIndex={null} />
      </a>
    </li>
  );
};

export default TagMenuItem;
