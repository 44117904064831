import { createContext, ReactElement, useMemo, useContext, useState, useEffect, useCallback } from 'react';
import { MenuLayoutInterface } from '../../types/MenuLayoutInterface';
import { getMenuLayouts } from '../../api/menuLayouts';

interface MenuLayoutContextInterface {
  hasError: boolean;
  isLoading: boolean;
  menuLayouts: MenuLayoutInterface[];
  loadLayouts: Function;
  getMenuLayout: Function;
}

interface MenuLayoutProviderInterface {
  children: ReactElement;
}

const MenuLayoutContext = createContext<MenuLayoutContextInterface>({
  hasError: false,
  isLoading: false,
  menuLayouts: [],
  loadLayouts: () => {},
  getMenuLayout: () => {}
});

const MenuLayoutProvider = ({ children }: MenuLayoutProviderInterface) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [menuLayouts, setMenuLayouts] = useState<MenuLayoutInterface[]>([]);

  useEffect(() => {
    let canceled = false;
    if (menuLayouts.length === 0 && initialLoad) {
      setIsLoading(true);

      getMenuLayouts()
        .then((layouts: MenuLayoutInterface[]) => {
          if (canceled) return;

          setMenuLayouts(layouts);
          setIsLoading(false);
        })
        .catch((error) => {
          if (canceled) return;
          if (error.response.status !== 401) {
            // on non auth related failure indicate failure has occurred
            setHasError(true);
          }
          setIsLoading(false);
        });
    }

    return () => {
      canceled = true;
    };
  }, [initialLoad, menuLayouts.length]);

  const getMenuLayout = useCallback(
    (layoutID: number): MenuLayoutInterface => {
      const layoutIndex = menuLayouts.findIndex((layout) => layout.layoutID === layoutID);

      if (layoutID === -1) {
        throw new Error(`Provided menu layout id does not exist`);
      }

      return menuLayouts[layoutIndex];
    },
    [menuLayouts]
  );

  const loadLayouts = useCallback(() => {
    if (!initialLoad && menuLayouts?.length === 0) {
      setInitialLoad(true);
    }
  }, [initialLoad, menuLayouts?.length]);

  const providerValue = useMemo(
    () => ({ hasError, isLoading, menuLayouts, getMenuLayout, loadLayouts }),
    [hasError, isLoading, menuLayouts, getMenuLayout, loadLayouts]
  );
  return <MenuLayoutContext.Provider value={providerValue}>{children}</MenuLayoutContext.Provider>;
};

const useMenuLayoutContext = () => {
  const context = useContext(MenuLayoutContext);
  if (!context) {
    throw new Error(`MenuLayoutContext must be used within the MenuLayoutProvider component`);
  }
  return context;
};

export { MenuLayoutProvider as default, useMenuLayoutContext };
