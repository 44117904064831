import { useEffect, useState } from 'react';
import MediaLibraryTopBar from '../../../components/MediaLibraryTopBar';
import { useMediaLibraryContext } from '../../../contexts/MediaLibraryContext';
import MediaLibraryContent from '../../../components/MediaLibraryContent';

const MediaLibrary = () => {
  const [mediaTypeFilter, setMediaTypeFilter] = useState<'all' | 'image' | 'video'>('all');
  const { loadMedia } = useMediaLibraryContext();

  useEffect(() => {
    // only want to load the restaurants user media if manager page is being utilized
    // call to load media on initial load
    loadMedia();
  }, [loadMedia]);

  return (
    <div className="media-library-container">
      <MediaLibraryTopBar mediaTypeFilter={mediaTypeFilter} onTypeChange={(type) => setMediaTypeFilter(type)} />
      <MediaLibraryContent mediaTypeFilter={mediaTypeFilter} />
    </div>
  );
};

export default MediaLibrary;
