import Skeleton from 'react-loading-skeleton';
import { formatPhoneNumber } from 'react-phone-number-input';
import React, { useState } from 'react';
import { useRestaurantContext } from '../../contexts/RestaurantContext';
import { MENU_LAYOUTS } from '../../constants/MenuLayoutConstants';
import LoadingSpinner from '../common/LoadingSpinner';
import { sleep } from '../../utils/general';
import NoImageDisplay from '../NoImageDisplay';
import DayHourDisplay from '../DayHourDisplay';

interface RestaurantInfoDisplayProps {
  value: string;
  label: string;
  isLoading: boolean;
}
interface RestaurantImageDisplayProps {
  value: string;
  label: string;
}

const RestaurantImageDisplay = ({ value, label }: RestaurantImageDisplayProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const imageOnErrorHandler = () => {
    setHasError(true);
    setLoading(false);
  };

  if (!value) {
    return (
      <div>
        <h2 className="restaurant-info-header">{label}</h2>
        <NoImageDisplay />
      </div>
    );
  }

  return (
    <div>
      <h2 className="restaurant-info-header">{label}</h2>
      {loading && !hasError && <LoadingSpinner />}
      {hasError && <NoImageDisplay />}
      {!hasError && (
        <img
          alt={label}
          width="200px"
          height="100px"
          className="restaurant-image-display"
          style={{ display: !loading ? 'initial' : 'none' }}
          src={value}
          onLoad={async () => {
            // sleep to make animations look smoother
            await sleep(500);
            setLoading(false);
          }}
          onError={imageOnErrorHandler}
        />
      )}
    </div>
  );
};

const RestaurantInfoDisplay = ({ value, label, isLoading }: RestaurantInfoDisplayProps) => {
  if (isLoading) {
    return <Skeleton width="100px" height="22px" />;
  }

  return (
    <div>
      <h2 className="restaurant-info-header">{label}</h2>
      <p className="restaurant-info-value-display">{value || '--'}</p>
    </div>
  );
};

const RestaurantInformation = () => {
  const { currentRestaurant, isLoading } = useRestaurantContext();

  return (
    <div className="restaurant-info-container">
      <div className="restaurant-info-box">
        <RestaurantInfoDisplay value={currentRestaurant?.name} label="RESTAURANT NAME" isLoading={isLoading} />
        <RestaurantInfoDisplay
          value={formatPhoneNumber(`+1${currentRestaurant?.phone}`)}
          label="PHONE"
          isLoading={isLoading}
        />
        <RestaurantInfoDisplay value={currentRestaurant?.email} label="EMAIL" isLoading={isLoading} />
        <RestaurantInfoDisplay value={currentRestaurant?.website} label="WEBSITE" isLoading={isLoading} />
        <RestaurantInfoDisplay value={currentRestaurant?.cuisine?.name} label="CUISINE" isLoading={isLoading} />
      </div>
      <hr />
      <div className="restaurant-info-box">
        <RestaurantInfoDisplay
          value={`${currentRestaurant?.address?.address1 || '--'} ${currentRestaurant?.address?.address2 || ''}`}
          label="STREET ADDRESS"
          isLoading={isLoading}
        />
        <RestaurantInfoDisplay value={currentRestaurant?.address?.city} label="CITY" isLoading={isLoading} />
        <RestaurantInfoDisplay
          value={currentRestaurant?.address?.governingDistrict}
          label="STATE"
          isLoading={isLoading}
        />
        <RestaurantInfoDisplay value={currentRestaurant?.address?.postalCode} label="ZIP CODE" isLoading={isLoading} />
      </div>
      <hr />
      <div className="restaurant-description-hours-box">
        <RestaurantInfoDisplay value={currentRestaurant?.description} label="DESCRIPTION" isLoading={isLoading} />
        <DayHourDisplay
          label="HOURS"
          restaurantHours={currentRestaurant?.restaurantHours}
          className="restaurant-info-hours"
        />
        <RestaurantInfoDisplay value={currentRestaurant?.availabilityNotes} label="NOTES" isLoading={isLoading} />
      </div>
      <hr />
      <div className="restaurant-info-box">
        <RestaurantImageDisplay value={currentRestaurant?.images?.profile?.[0]?.imageURL} label="PROFILE PHOTO" />
        <RestaurantInfoDisplay
          // eslint-disable-next-line no-unsafe-optional-chaining
          value={currentRestaurant?.menuLayout ? MENU_LAYOUTS[currentRestaurant?.menuLayout?.layoutID - 1] : '--'}
          label="MENU LAYOUT"
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default RestaurantInformation;
