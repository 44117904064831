import ReactDOM from 'react-dom';
import { useAsyncContext } from '../../../contexts/AsyncContext';
import LoadingSpinner from '../LoadingSpinner';

interface LoadingOverlayProps {
  primary?: 'black' | 'white';
  secondary?: 'black' | 'white';
  size?: number;
}

const LoadingOverlay = ({ primary = 'black', secondary = 'white', size = 100 }: LoadingOverlayProps) => {
  const { state } = useAsyncContext();
  const { isLoading, message } = state;

  if (!isLoading) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="spinner-overlay">
      <LoadingSpinner primary={primary} size={size} secondary={secondary} />
      {message && <span>{message}</span>}
    </div>,
    document.querySelector('#loading-root')
  );
};

export default LoadingOverlay;
