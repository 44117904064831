import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import MenuWizard from './MenuWizard';
import MenuPage from './MenuPage';
import Sidebar from '../../components/Sidebar';
import { interceptor } from '../../api/apiClient';
import { useAuthContext } from '../../contexts/AuthContext';
import NotFoundPage from '../../components/NotFoundPage';
import useResponsive from '../../hooks/useResponsive';
import {
  PAGE_CREATE_DRINK_ITEM,
  PAGE_EDIT_DRINK_ITEM,
  PAGE_CREATE_MENU,
  PAGE_EDIT_MENU,
  PAGE_MENUS,
  PAGE_SETTINGS,
  PAGE_CREATE_MENU_ITEM,
  PAGE_EDIT_MENU_ITEM,
  PAGE_CREATE_RESTAURANT,
  PAGE_EDIT_RESTAURANT,
  PAGE_PROMOTIONS,
  PAGE_RESERVATION_ORDERING_LINKS,
  PAGE_EMAIL_MANAGER,
  PAGE_CREATE_MODIFIER,
  PAGE_EDIT_MODIFIER,
  PAGE_MODIFIERS,
  PAGE_PRESS,
  PAGE_ABOUT,
  PAGE_MEDIA_LIBRARY
} from '../../constants/UriConstants';
import MenuItemWizard from './MenuItemWizard';
import ModifierWizard from './ModifierWizard';
import Settings from './Settings';
import MenuListView from '../../components/MenuListView';
import CharacteristicsProvider from '../../contexts/CharacteristicsContext';
import 'react-toastify/dist/ReactToastify.css';
import MenuLayoutProvider from '../../contexts/MenuLayoutContext';
import TagsProvider from '../../contexts/TagsContext';
import DrinkItemsProvider from '../../contexts/DrinkItemsContext';
import ManagerProvider from '../../contexts/ManagerContext';
import RestaurantWizard from './RestaurantWizard';
import CuisinesProvider from '../../contexts/CuisinesContext';
import LoadingOverlay from '../../components/common/LoadingOverlay';
import AnnouncementsProvider from '../../contexts/AnnouncementsContext';
import PromotionsPage from './PromotionsPage';
import AlertModal from '../../components/common/AlertModal';
import ReservationOrderPage from './ReservationOrderPage';
import EmailManagerPage from './EmailManagerPage';
import SubmittedEmailsProvider from '../../contexts/SubmittedEmailsContext';
import ErrorModal from '../../components/ErrorModal';
import ModifiersView from '../../components/ModifiersView';
import ModifiersProvider from '../../contexts/ModifiersContext';
import MediaLibrary from './MediaLibrary';
import MobileNavBar from '../../components/MobileNavBar';
import PressPage from './PressPage/PressPage';
import AboutPage from './AboutPage';

const MainContainer = () => {
  const { isDesktop } = useResponsive();
  const { removeToken } = useAuthContext();
  const [showError, setShowError] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const dismissErrorModal = () => {
    setShowError(false);
  };
  const showErrorModal = (message: string) => {
    setErrorModalMessage(message);
    setShowError(true);
  };
  interceptor(removeToken, showErrorModal);

  return (
    <div className={`${isDesktop ? 'flex' : 'flex-col'}`}>
      {showError && (
        <ErrorModal modalTitle="Network Error" modalMessage={errorModalMessage} onDismiss={dismissErrorModal} />
      )}
      <LoadingOverlay />
      <AlertModal />
      {isDesktop ? <Sidebar /> : <MobileNavBar />}
      <Routes>
        <Route path={`/${PAGE_MEDIA_LIBRARY}`} element={<MediaLibrary />} />
        <Route path={`/${PAGE_ABOUT}`} element={<AboutPage />} />
        <Route path={`/${PAGE_PRESS}`} element={<PressPage />} />
        <Route
          path={`/${PAGE_PROMOTIONS}`}
          element={
            <AnnouncementsProvider>
              <PromotionsPage />
            </AnnouncementsProvider>
          }
        />
        <Route
          path={`/${PAGE_EMAIL_MANAGER}`}
          element={
            <SubmittedEmailsProvider>
              <EmailManagerPage />
            </SubmittedEmailsProvider>
          }
        />
        <Route
          path={`/${PAGE_SETTINGS}`}
          element={
            <MenuLayoutProvider>
              <ManagerProvider>
                <Settings />
              </ManagerProvider>
            </MenuLayoutProvider>
          }
        />
        <Route path="/" element={<MenuPage />}>
          <Route
            index
            element={
              <DrinkItemsProvider>
                <CharacteristicsProvider>
                  <ModifiersProvider>
                    <MenuListView />
                  </ModifiersProvider>
                </CharacteristicsProvider>
              </DrinkItemsProvider>
            }
          />
          <Route
            path={PAGE_MENUS}
            element={
              <DrinkItemsProvider>
                <CharacteristicsProvider>
                  <ModifiersProvider>
                    <MenuListView />
                  </ModifiersProvider>
                </CharacteristicsProvider>
              </DrinkItemsProvider>
            }
          />
          <Route
            path={PAGE_MODIFIERS}
            element={
              <ModifiersProvider>
                <ModifiersView />
              </ModifiersProvider>
            }
          />
        </Route>
        <Route
          path={PAGE_CREATE_DRINK_ITEM}
          element={
            <DrinkItemsProvider>
              <CharacteristicsProvider>
                <TagsProvider>
                  <ModifiersProvider>
                    <MenuItemWizard key="create-drink-item" />
                  </ModifiersProvider>
                </TagsProvider>
              </CharacteristicsProvider>
            </DrinkItemsProvider>
          }
        />
        <Route
          path={PAGE_EDIT_DRINK_ITEM}
          element={
            <DrinkItemsProvider>
              <CharacteristicsProvider>
                <TagsProvider>
                  <ModifiersProvider>
                    <MenuItemWizard key="edit-drink-item" />
                  </ModifiersProvider>
                </TagsProvider>
              </CharacteristicsProvider>
            </DrinkItemsProvider>
          }
        />
        <Route path={PAGE_CREATE_MENU} element={<MenuWizard />} />
        <Route path={PAGE_EDIT_MENU} element={<MenuWizard />} />
        <Route
          path={PAGE_CREATE_MENU_ITEM}
          element={
            <DrinkItemsProvider>
              <CharacteristicsProvider>
                <TagsProvider>
                  <ModifiersProvider>
                    <MenuItemWizard key="create-menu-item" />
                  </ModifiersProvider>
                </TagsProvider>
              </CharacteristicsProvider>
            </DrinkItemsProvider>
          }
        />
        <Route
          path={PAGE_EDIT_MENU_ITEM}
          element={
            <DrinkItemsProvider>
              <CharacteristicsProvider>
                <TagsProvider>
                  <ModifiersProvider>
                    <MenuItemWizard key="edit-menu-item" />
                  </ModifiersProvider>
                </TagsProvider>
              </CharacteristicsProvider>
            </DrinkItemsProvider>
          }
        />
        <Route
          path={PAGE_CREATE_MODIFIER}
          element={
            <ModifiersProvider>
              <ModifierWizard />
            </ModifiersProvider>
          }
        />
        <Route
          path={PAGE_EDIT_MODIFIER}
          element={
            <ModifiersProvider>
              <ModifierWizard />
            </ModifiersProvider>
          }
        />
        <Route
          path={PAGE_CREATE_RESTAURANT}
          element={
            <MenuLayoutProvider>
              <CuisinesProvider>
                <RestaurantWizard />
              </CuisinesProvider>
            </MenuLayoutProvider>
          }
        />
        <Route
          path={PAGE_EDIT_RESTAURANT}
          element={
            <MenuLayoutProvider>
              <CuisinesProvider>
                <RestaurantWizard />
              </CuisinesProvider>
            </MenuLayoutProvider>
          }
        />
        <Route path={PAGE_RESERVATION_ORDERING_LINKS} element={<ReservationOrderPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default MainContainer;
