import React, { useEffect, useState } from 'react';
import { AnimateLayoutChanges, defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSS } from '@dnd-kit/utilities';
import { EditIcon, PlayIcon, TrashCanIcon } from '../../assets/svgs/icons';
import { MediaInterface, ThumbnailInterface } from '../../types/MediaInterface';

interface MultipleMediaPreviewProps {
  id: number | string;
  media: Blob | MediaInterface;
  thumbnail?: Blob | ThumbnailInterface;
  multiple: boolean;
  onDelete: Function;
  onEdit: Function;
}

const MultipleMediaPreview = ({ id, media, thumbnail, multiple, onDelete, onEdit }: MultipleMediaPreviewProps) => {
  const [uploadedMedia, setUploadedMedia] = useState<string>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isVideo, setIsVideo] = useState<boolean>(null);

  const animateLayoutChanges: AnimateLayoutChanges = (args) =>
    defaultAnimateLayoutChanges({ ...args, wasDragging: true });

  const { setNodeRef, listeners, attributes, transform, transition, isDragging } = useSortable({
    id: id?.toString(),
    animateLayoutChanges
  });

  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  useEffect(() => {
    const readMedia = (file: Blob) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedMedia(reader.result.toString());
      };

      if (file.type?.includes('image')) {
        reader.readAsDataURL(file);
      } else if (file.type?.includes('video')) {
        const videoPreview: HTMLImageElement = document.querySelector(`#videoPreview${id}`);
        videoPreview.src = window.URL.createObjectURL(file);
      }
    };

    const openMedia = (_media: Blob | MediaInterface | ThumbnailInterface) => {
      if ('mediaURL' in _media) {
        setUploadedMedia(_media.mediaURL);
      } else if ('thumbnailURL' in _media) {
        setUploadedMedia(_media.thumbnailURL);
      } else {
        readMedia(_media as Blob);
      }
    };

    if (media) {
      if (media?.type === 'video' || media?.type?.includes('video')) {
        setIsVideo(true);
        if (thumbnail || 'thumbnail' in media) {
          openMedia(thumbnail ?? (media as MediaInterface)?.thumbnail);
          return;
        }
      } else {
        setIsVideo(false);
      }

      openMedia(media);
    }
  }, [id, media, thumbnail]);

  const handleDeleteClicked = (idOfMedia: number | string) => {
    onDelete?.(idOfMedia);
  };

  const handleEditClicked = (idOfMedia: number | string) => {
    onEdit?.(idOfMedia);
  };

  return (
    <div
      className="multiple-media-preview-container"
      style={itemStyle}
      ref={setNodeRef}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...attributes}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...listeners}
    >
      <div className={`multiple-media-preview ${isDragging ? 'is-dragging' : ''} ${multiple ? 'multiple' : ''}`}>
        <div
          className="image-container"
          style={{
            visibility: loaded ? 'visible' : 'hidden',
            display: isVideo === false || thumbnail || 'thumbnail' in media ? 'block' : 'none'
          }}
        >
          <img
            className="media-preview"
            style={{
              visibility: loaded ? 'visible' : 'hidden',
              display: isVideo === false || thumbnail || 'thumbnail' in media ? 'unset' : 'none'
            }}
            src={uploadedMedia}
            alt="preview of media upload"
            onLoad={async () => {
              setLoaded(true);
            }}
          />
          {isVideo && (thumbnail || 'thumbnail' in media) && <PlayIcon />}
        </div>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          id={`videoPreview${id}`}
          className="media-preview"
          style={{ display: isVideo === true && !thumbnail && !('thumbnail' in media) ? 'unset' : 'none' }}
          src={uploadedMedia}
        >
          Your browser does not support the video tag.
        </video>
      </div>
      {(!multiple || isVideo === false) && (
        <TrashCanIcon width="12" height="12" onIconClicked={() => handleDeleteClicked(id)} />
      )}
      {multiple && (isVideo === true || thumbnail) && (
        <EditIcon width="12" height="12" onIconClicked={() => handleEditClicked(id)} tabIndex={0} />
      )}
    </div>
  );
};

export default MultipleMediaPreview;
