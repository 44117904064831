const SingleItemCell = ({ value }: any) => {
  const { isHidden, item } = value;
  return (
    <div className={`custom-cell-container ${isHidden ? 'hidden-cell-value' : ''}`}>
      <span>{item || '--'}</span>
    </div>
  );
};

export default SingleItemCell;
