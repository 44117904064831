import { ReactNode } from 'react';

interface SocialInputLabelProps {
  label: string;
  icon: ReactNode;
  className?: string;
}

const SocialInputLabel = ({ label, icon, className }: SocialInputLabelProps) => (
  <div className={`socials-label ${className && className}`}>
    <span className={`${label}-icon`}>{icon}</span>
    <span>{label.toUpperCase()}</span>
  </div>
);

export default SocialInputLabel;
