import { createContext, useState, useEffect, ReactElement, useMemo, useContext, useCallback } from 'react';
import Cookies from 'universal-cookie';
import { useLocation, useNavigate } from 'react-router-dom';

import { COOKIE_NAME_AUTH, COOKIE_RESTAURANT_ID } from '../../constants/AppConstants';
import {
  BASE_PATH,
  LOGIN,
  PAGE_FORGOT_PASSWORD,
  PAGE_LOGIN,
  PAGE_RESET_PASSWORD,
  PAGE_SIGN_UP
} from '../../constants/UriConstants';

interface AuthContextInterface {
  authenticated: boolean;
  setToken: Function;
  removeToken: Function;
}

interface AuthProviderInterface {
  children: ReactElement;
}

const AuthContext = createContext<AuthContextInterface>({
  authenticated: false,
  setToken: () => {},
  removeToken: () => {}
});

const AuthProvider = ({ children }: AuthProviderInterface) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [cookies, setCookies] = useState(new Cookies());

  const getToken = useCallback(() => cookies.get(COOKIE_NAME_AUTH), [cookies]);

  const setToken = useCallback(
    (token: string) => {
      cookies.set(COOKIE_NAME_AUTH, token, { path: '/' });
      setCookies(cookies);
    },
    [cookies]
  );

  const removeToken = useCallback(() => {
    cookies.remove(COOKIE_NAME_AUTH, { path: '/' });
    cookies.remove(COOKIE_RESTAURANT_ID, { path: '/' });
    setCookies(cookies);
  }, [cookies]);

  const isAuthenticated = useCallback(() => !!getToken(), [getToken]);

  const [authenticated, setAuthenticated] = useState(isAuthenticated());

  useEffect(() => {
    const cookieChangeListener = () => {
      setAuthenticated(isAuthenticated());
    };

    cookies.addChangeListener(cookieChangeListener);
    setCookies(cookies);
    return () => {
      cookies.removeChangeListener(cookieChangeListener);
      setCookies(cookies);
    };
  }, [cookies, isAuthenticated]);

  useEffect(() => {
    if (authenticated) {
      if (pathname === LOGIN) {
        navigate(BASE_PATH);
      }
    } else if (
      !(
        pathname.includes(PAGE_LOGIN) ||
        pathname.includes(PAGE_FORGOT_PASSWORD) ||
        pathname.includes(PAGE_RESET_PASSWORD) ||
        pathname.includes(PAGE_SIGN_UP)
      )
    ) {
      navigate(LOGIN);
    }
  }, [authenticated, navigate, pathname]);

  const providerValue = useMemo(
    () => ({ authenticated, setToken, removeToken }),
    [authenticated, setToken, removeToken]
  );
  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuthContext must be used within the AuthProvider component`);
  }
  return context;
};

export { AuthProvider as default, useAuthContext };
