import { Field, useField } from 'formik';
import React, { ReactNode } from 'react';
import ErrorText from '../ErrorText';
import Label from '../Label';

interface FormikTextAreaProps {
  className?: string;
  label?: string | ReactNode;
  message?: ReactNode;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  onBlur?: Function;
  onChange?: Function;
}

const FormikTextArea = ({
  className,
  label,
  message,
  name,
  placeholder,
  disabled,
  onBlur,
  onChange
}: FormikTextAreaProps) => {
  const [field, meta] = useField(name);
  const error = meta.touched ? meta.error : null;

  const handleOnBlur = (e: React.FocusEvent<any>) => {
    onBlur?.();
    field.onBlur(e);
  };

  const handleOnChange = (e: React.ChangeEvent<any>) => {
    onChange?.(e.target.value);
    field.onChange(e);
  };

  return (
    <div className={className}>
      <Label htmlFor={name} label={label} />
      <div className="mt-2 text-neutral-500">{message}</div>
      <div className="mt-4 h-3/5">
        <Field
          type="text"
          as="textarea"
          name={name}
          disabled={disabled}
          className="w-full h-full p-4 whitespace-pre-wrap resize-none text-sm rounded-sm border border-neutral-400"
          placeholder={placeholder}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
        />
      </div>
      <ErrorText error={error} />
    </div>
  );
};

export default FormikTextArea;
