import { Form, Formik, FormikErrors } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { array, boolean, InferType, object, string } from 'yup';
import { createMenu, editMenu, getMenuDetails } from '../../../api/menu';
import Button from '../../../components/common/Button';
import {
  DayAndHourPickerRowSchema,
  FormikDaysAndHoursPicker,
  FormikCheckbox,
  FormikInput,
  Label
} from '../../../components/common/Form';
import ErrorText from '../../../components/common/Form/ErrorText';
import WizardHeader from '../../../components/WizardHeader';
import WizardProgressBar from '../../../components/WizardProgressBar/WizardProgressBar';
import { useMenuContext } from '../../../contexts/MenuContext';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { useRestaurantContext } from '../../../contexts/RestaurantContext';
import { MenuHourInterface } from '../../../types/MenuHourInterface';
import { DetailedMenuInterface } from '../../../types/MenuInterface';
import DisclaimerMessageInput from '../../../components/DisclaimerMessageInput';
import {
  CreateDisclaimerMessageRequestInterface,
  DisclaimerMessageInterface,
  EditDisclaimerMessageRequestInterface,
  UpdateDisclaimerMessageRequestInterface
} from '../../../types/DisclaimerMessageInterface';
import MENU_DISCLAIMER_POSITIONS from '../../../constants/MenuDisclaimerConstants';
import { displayLoading, hideLoading, useAsyncContext } from '../../../contexts/AsyncContext';
import { sleep } from '../../../utils/general';
import { MultiMenuSectionModal, MenuSectionSchema } from '../../../components/MenuSectionModal';
import { PlusIcon } from '../../../assets/svgs/icons';
import MenuAddSection from '../../../components/MenuAddSection/MenuAddSection';
import { fullToShortDayName, shortToFullDayName } from '../../../constants/weekDayConstants';
import useResponsive from '../../../hooks/useResponsive';

const MenuInfoSchema = object({
  name: string().default('').required('Menu Name is required.'),
  isHidden: boolean().default(false),
  menuSections: array()
    .of(MenuSectionSchema)
    .default([MenuSectionSchema.cast({ name: '', message: '' })]),
  topMenuMessage: string().default(''),
  bottomMenuMessage: string().default(''),
  isPrixFixe: boolean().default(false),
  menuAvailabilityHours: array()
    .of(DayAndHourPickerRowSchema)
    .default([DayAndHourPickerRowSchema.cast({ id: uuid() })])
});
interface MenuWizardNavState {
  menuID: number;
}

const MenuWizard = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { menuID } = (state as MenuWizardNavState) || {};
  const [isPage2, setIsPage2] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [onSubmitError, setOnSubmitError] = useState<string>('');
  const [menuToEdit, setMenuToEdit] = useState<InferType<typeof MenuInfoSchema>>(null);
  const [topMessage, setTopMessage] = useState<DisclaimerMessageInterface>(null);
  const [bottomMessage, setBottomMessage] = useState<DisclaimerMessageInterface>(null);
  const { isDesktop } = useResponsive();

  const isEditMenu = !!menuID;

  const { menu: selectedMenu, updateMenuInfo } = useMenuContext();
  const { addMenu, updateMenu } = useRestaurantContext();
  const { dispatch } = useModalContext();
  const { dispatch: asyncDispatch } = useAsyncContext();

  const handleOnSubmit = async (value: InferType<typeof MenuInfoSchema>) => {
    const buildCreateDisclaimerMessage = (
      message: string,
      position: string
    ): CreateDisclaimerMessageRequestInterface => {
      const trimmed = message?.trim();
      if (trimmed) {
        return {
          message: trimmed,
          position
        };
      }
      return null;
    };

    const buildDisclaimersCreateRequest = (
      topMenuMessage: string,
      bottomMenuMessage: string
    ): CreateDisclaimerMessageRequestInterface[] => {
      const createRequestDisclaimers: CreateDisclaimerMessageRequestInterface[] = [];

      const top: CreateDisclaimerMessageRequestInterface = buildCreateDisclaimerMessage(
        topMenuMessage,
        MENU_DISCLAIMER_POSITIONS.TOP
      );

      if (top) {
        createRequestDisclaimers.push(top);
      }

      const bottom: CreateDisclaimerMessageRequestInterface = buildCreateDisclaimerMessage(
        bottomMenuMessage,
        MENU_DISCLAIMER_POSITIONS.BOTTOM
      );

      if (bottom) {
        createRequestDisclaimers.push(bottom);
      }

      return createRequestDisclaimers;
    };

    const buildDisclaimersEditRequest = (
      topMenuMessage: string,
      bottomMenuMessage: string
    ): EditDisclaimerMessageRequestInterface => {
      const deleteArray: number[] = [];
      const insertArray: CreateDisclaimerMessageRequestInterface[] = [];
      const updateArray: UpdateDisclaimerMessageRequestInterface[] = [];

      const top: CreateDisclaimerMessageRequestInterface = buildCreateDisclaimerMessage(
        topMenuMessage,
        MENU_DISCLAIMER_POSITIONS.TOP
      );
      if (top) {
        if (topMessage === null) {
          insertArray.push(top);
        } else if (top.message !== topMessage.message.trim()) {
          updateArray.push({
            messageID: topMessage.messageID,
            message: top.message
          });
        }
      } else if (!top && topMessage != null) {
        deleteArray.push(topMessage?.messageID);
      }

      const bottom: CreateDisclaimerMessageRequestInterface = buildCreateDisclaimerMessage(
        bottomMenuMessage,
        MENU_DISCLAIMER_POSITIONS.BOTTOM
      );
      if (bottom) {
        if (bottomMessage === null) {
          insertArray.push(bottom);
        } else if (bottom.message !== bottomMessage.message.trim()) {
          updateArray.push({
            messageID: bottomMessage.messageID,
            message: bottom.message
          });
        }
      } else if (!bottom && bottomMessage != null) {
        deleteArray.push(bottomMessage?.messageID);
      }

      return {
        DELETE: deleteArray,
        INSERT: insertArray,
        UPDATE: updateArray
      } as EditDisclaimerMessageRequestInterface;
    };

    const buildDisclaimersForUpdate = (
      insertedDisclaimers: DisclaimerMessageInterface[],
      editDisclaimers: EditDisclaimerMessageRequestInterface
    ): DisclaimerMessageInterface[] => {
      const updatedDisclaimers: DisclaimerMessageInterface[] = [];
      const topID: number = topMessage?.messageID;
      const bottomID: number = bottomMessage?.messageID;

      const updatedTop = editDisclaimers.UPDATE.find((val) => val.messageID === topID);
      if (updatedTop) {
        updatedDisclaimers.push({
          messageID: updatedTop.messageID,
          message: updatedTop.message,
          position: MENU_DISCLAIMER_POSITIONS.TOP
        });
      } else if (topID && !editDisclaimers.DELETE.some((id) => id === topID)) {
        updatedDisclaimers.push(topMessage);
      }

      const updatedBottom = editDisclaimers.UPDATE.find((val) => val.messageID === bottomID);
      if (updatedBottom) {
        updatedDisclaimers.push({
          messageID: updatedBottom.messageID,
          message: updatedBottom.message,
          position: MENU_DISCLAIMER_POSITIONS.BOTTOM
        });
      } else if (bottomID && !editDisclaimers.DELETE.some((id) => id === bottomID)) {
        updatedDisclaimers.push(bottomMessage);
      }

      if (insertedDisclaimers.length > 0) {
        updatedDisclaimers.push(...insertedDisclaimers);
      }

      return updatedDisclaimers;
    };
    setOnSubmitError('');
    if (MenuInfoSchema.isValidSync(value)) {
      const restructuredMenuHours = value.menuAvailabilityHours.reduce(
        (hoursArray: MenuHourInterface[], setOfDays: any) => {
          const { daysOfWeek, startTime, endTime } = setOfDays;
          // iterate through all days of the week and see if they're set to true,
          // meaning that these hours apply to them
          Object.entries(daysOfWeek).forEach(([day, appliesToThisWeekday]) => {
            if (appliesToThisWeekday) {
              hoursArray.push({ day: shortToFullDayName[day], start: startTime, end: endTime });
            }
          });
          return hoursArray;
        },
        []
      );

      displayLoading({ dispatch: asyncDispatch, message: `${isEditMenu ? 'Updating' : 'Creating'} Menu...` });
      await sleep(500);
      try {
        if (isEditMenu) {
          const disclaimers: EditDisclaimerMessageRequestInterface = buildDisclaimersEditRequest(
            value.topMenuMessage,
            value.bottomMenuMessage
          );
          const result = await editMenu({
            menuID,
            name: value.name,
            menuHours: restructuredMenuHours,
            disclaimers,
            isPrixFixe: value.isPrixFixe
          });
          updateMenu({ menuID, menuName: value.name, isPrixFixe: value.isPrixFixe });
          if (menuID === selectedMenu.menuID) {
            updateMenuInfo(
              value.name,
              restructuredMenuHours,
              buildDisclaimersForUpdate(result.insertedDisclaimers, disclaimers),
              value.isPrixFixe,
              value.isHidden
            );
          }
        } else {
          const disclaimers: CreateDisclaimerMessageRequestInterface[] = buildDisclaimersCreateRequest(
            value.topMenuMessage,
            value.bottomMenuMessage
          );

          const menuSections = value.menuSections.filter(({ name }: { name: string }) => !!name);
          const { menus } = await createMenu({
            name: value.name,
            menuHours: restructuredMenuHours,
            menuSections,
            disclaimers,
            isPrixFixe: value.isPrixFixe,
            isHidden: value.isHidden
          });
          addMenu({
            menuID: menus[0].menuID,
            menuName: menus[0].name,
            menuSections: menus[0].menuSections,
            isPrixFixe: menus[0].isPrixFixe,
            isHidden: menus[0].isHidden
          });
        }
        navigate('/');
      } catch (error) {
        hideLoading(asyncDispatch);
        if (error?.response?.status === 409) {
          setOnSubmitError(`A menu already exists with the name "${value.name}" for this restaurant.`);
        } else if (error?.response) {
          setOnSubmitError('An unexpected error has occurred. Please check your input and try again.');
        } else {
          setOnSubmitError(error?.message);
        }
      } finally {
        hideLoading(asyncDispatch);
      }
    }
  };

  const handleNextClicked = (values: InferType<typeof MenuInfoSchema>, validateForm: Function) => {
    validateForm().then((errors: FormikErrors<any>) => {
      let isValid = true;
      if (errors?.name) {
        setErrorText('Menu name is required.');
        isValid = false;
      }

      if (isValid) {
        setIsPage2(true);
        setErrorText('');
      }
    });
  };

  useEffect(() => {
    const buildAvailabilityHours = (menuHours: MenuHourInterface[]): InferType<typeof DayAndHourPickerRowSchema>[] => {
      const menuAvailabilityHours: InferType<typeof DayAndHourPickerRowSchema>[] = [];

      menuHours.forEach((menuHour) => {
        const timeSpanIndex = menuAvailabilityHours.findIndex(
          (hours) => hours.startTime === menuHour.start && hours.endTime === menuHour.end
        );
        if (menuAvailabilityHours.length === 0 || timeSpanIndex === -1) {
          const availabilityHour: InferType<typeof DayAndHourPickerRowSchema> = {
            id: uuid(),
            startTime: menuHour.start,
            endTime: menuHour.end,
            daysOfWeek: { M: false, T: false, W: false, Th: false, F: false, Sa: false, Su: false }
          };
          availabilityHour.daysOfWeek[fullToShortDayName[menuHour.day]] = true;
          menuAvailabilityHours.push(availabilityHour);
        } else {
          const existingAvailabilityHour = menuAvailabilityHours[timeSpanIndex];
          existingAvailabilityHour.daysOfWeek[fullToShortDayName[menuHour.day]] = true;
          menuAvailabilityHours.splice(timeSpanIndex, 1, existingAvailabilityHour);
        }
      });

      return menuAvailabilityHours;
    };

    const buildInitialValues = (menu: DetailedMenuInterface): InferType<typeof MenuInfoSchema> => {
      const top: DisclaimerMessageInterface = menu.messages?.find(
        (message) => message.position === MENU_DISCLAIMER_POSITIONS.TOP
      );
      const bottom: DisclaimerMessageInterface = menu.messages?.find(
        (message) => message.position === MENU_DISCLAIMER_POSITIONS.BOTTOM
      );

      if (top) {
        setTopMessage(top);
      }

      if (bottom) {
        setBottomMessage(bottom);
      }

      return {
        name: menu.menuName,
        topMenuMessage: top?.message || '',
        bottomMenuMessage: bottom?.message || '',
        menuAvailabilityHours: buildAvailabilityHours(menu.menuHours),
        menuSections: [MenuSectionSchema.cast({ name: 'default', message: '' })],
        isPrixFixe: menu.isPrixFixe || false,
        isHidden: menu.isHidden || false
      };
    };
    if (isEditMenu && selectedMenu) {
      if (menuID === selectedMenu.menuID) {
        setMenuToEdit(buildInitialValues(selectedMenu));
      } else {
        getMenuDetails(menuID)
          .then((data: DetailedMenuInterface) => {
            setMenuToEdit(buildInitialValues(data));
          })

          .catch((error) => {
            if (error && error.response && error.response.status !== 401) {
              openModal({ dispatch });
            }
          });
      }
    }
  }, [dispatch, isEditMenu, menuID, selectedMenu]);

  const handleMenuSectionModalSubmit = (sections: InferType<typeof MenuSectionSchema>, setFieldValue: any) => {
    setFieldValue('menuSections', sections);
    setIsOpen(false);
  };
  const isNextButtonDisabled = (name: string) => name.trim() === '';

  const handleCancelClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className="menu-wizard-container h-screen w-full flex flex-col items-center overflow-auto">
      <WizardHeader className="menu-wizard-header">
        <div className="menu-wizard-header-content">
          <h1 className="menu-wizard-header-title">{isEditMenu ? 'EDIT' : 'CREATE'} MENU</h1>
          <WizardProgressBar currentPage={isPage2 ? 'Menu Hours' : 'Menu Info'} steps={['Menu Info', 'Menu Hours']} />
        </div>
      </WizardHeader>
      <Formik
        initialValues={isEditMenu ? menuToEdit || MenuInfoSchema.cast({}) : MenuInfoSchema.cast({})}
        enableReinitialize={isEditMenu}
        validationSchema={MenuInfoSchema}
        onSubmit={handleOnSubmit}
      >
        {({ validateForm, values, isValid, setFieldValue }) => (
          <Form className="menu-wizard-content-container">
            {!isPage2 && (
              <>
                <div className="menu-wizard-inputs-container">
                  <FormikInput
                    name="name"
                    label="MENU NAME"
                    className="menu-wizard-name-input"
                    placeholder="(Breakfast, Lunch, Restaurant, etc.)"
                  />
                  <FormikCheckbox
                    name="isPrixFixe"
                    className="mt-4 mb-2 menu-wizard-checkbox"
                    label="Check here if this menu is a prix fixe menu."
                  />
                  {!isEditMenu && (
                    <>
                      <Label label="MENU SECTIONS" className="menu-section-label" />
                      <div className="menu-section-container">
                        <span>
                          {values?.menuSections?.length > 1 ||
                          (values?.menuSections?.length === 1 && values?.menuSections?.[0].name !== '')
                            ? 'Edit menu sections.'
                            : 'Add sections to the menu.'}
                        </span>
                        <PlusIcon width="12" height="12" onIconClicked={() => setIsOpen(true)} />
                      </div>
                      <MultiMenuSectionModal
                        isOpen={isOpen}
                        name="menuSections"
                        onSubmit={(_val: InferType<typeof MenuSectionSchema>) => {
                          handleMenuSectionModalSubmit(_val, setFieldValue);
                        }}
                        onCancel={() => {
                          setIsOpen(false);
                        }}
                      />
                      <MenuAddSection name="menuSections" />
                    </>
                  )}
                  <div className="menu-wizard-inputs-container flex flex-wrap">
                    <DisclaimerMessageInput name="topMenuMessage" label="TOP" />
                    <DisclaimerMessageInput name="bottomMenuMessage" label="BOTTOM" />
                  </div>
                  {!isEditMenu && (
                    <FormikCheckbox
                      name="isHidden"
                      className="mt-4 mb-2 menu-wizard-checkbox"
                      label="Check here if this menu will be hidden on creation."
                    />
                  )}
                  {errorText && <ErrorText error={errorText} />}
                </div>
                {isDesktop ? (
                  <Button
                    className="menu-wizard-next-button"
                    onClick={() => handleNextClicked(values, validateForm)}
                    isDisabled={isNextButtonDisabled(values.name)}
                  >
                    NEXT
                  </Button>
                ) : (
                  <div className="w-[100%] mt-[34px] flex flex-row justify-start">
                    <Button className="menu-wizard-cancel-button float-left mr-5" onClick={handleCancelClicked}>
                      CANCEL
                    </Button>
                    <Button
                      className="menu-wizard-submit-button float-right"
                      onClick={() => handleNextClicked(values, validateForm)}
                      isDisabled={isNextButtonDisabled(values.name)}
                    >
                      NEXT
                    </Button>
                  </div>
                )}
              </>
            )}
            {isPage2 && (
              <div className="day-hour-picker bg-white overflow-auto">
                <FormikDaysAndHoursPicker name="menuAvailabilityHours" isDesktop={isDesktop} />
                {onSubmitError && <ErrorText className="mt-3" error={onSubmitError} />}
                <div className={`w-[93%] ${isDesktop ? 'mt-[34px]' : 'mt-[20px] flex flex-start'}`}>
                  <Button
                    className="menu-wizard-back-button float-left"
                    onClick={() => {
                      setIsPage2(false);
                      setOnSubmitError('');
                    }}
                  >
                    BACK
                  </Button>
                  <Button className="menu-wizard-submit-button float-right " isDisabled={!isValid} submit>
                    FINISH
                  </Button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { MenuWizard as default, MenuInfoSchema };
