import { Form, Formik } from 'formik';
import { InferType, object, string } from 'yup';
import { useState } from 'react';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { formatPhoneNumber } from 'react-phone-number-input';
import { ErrorText, FormikInput, PhoneInput } from '../common/Form';
import Button from '../common/Button';
import { editManager } from '../../api/manager';
import { useManagerContext } from '../../contexts/ManagerContext';

const UpdateUserInfoSchema = object({
  firstName: string().default('').required('First name is required.'),
  lastName: string().default('').required('Last name is required.'),
  email: string().default('').email('Email is not valid.').required('Email is required.'),
  phone: string()
    .default('')
    .min(12, 'Phone number must be at least 10 digits long.')
    .required('Phone number is required.')
});

interface UserInfoDisplayProps {
  value: string;
  label: string;
}

const UserInfoDisplay = ({ value, label }: UserInfoDisplayProps) => (
  <div>
    <h2 className="account-info-input-header">{label}</h2>
    {value ? <p className="user-info-value-display">{value}</p> : <Skeleton width="100px" height="22px" />}
  </div>
);

const UserInfoForm = () => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { manager, updateManager } = useManagerContext();

  const handleSubmitInfo = async ({ firstName, lastName, email, phone }: InferType<typeof UpdateUserInfoSchema>) => {
    if (UpdateUserInfoSchema.isValidSync({ firstName, lastName, email, phone })) {
      editManager({ firstName, lastName, email, phone: phone.substring(2, phone.length) })
        .then(() => {
          toast.success('Successfully Updated!', {
            position: toast.POSITION.TOP_RIGHT
          });
          updateManager({ firstName, lastName, email, phone: phone.substring(2, phone.length) });
          setErrorMessage(null);
          setIsEditMode(false);
        })
        .catch((error: any) => {
          if (error?.response?.status === 401) {
            setErrorMessage('Invalid credentials');
          } else if (error.response) {
            setErrorMessage('An unexpected error has occurred.');
          } else if (error.request) {
            // The request was made but no response was received
            setErrorMessage('Unable to process your request at this time');
          }
        });
    }
  };

  return (
    <div className="user-info-settings-container">
      {isEditMode ? (
        <Formik
          initialValues={{
            firstName: manager?.firstName,
            lastName: manager?.lastName,
            email: manager?.email,
            phone: `+1${manager.phone}`
          }}
          validationSchema={UpdateUserInfoSchema}
          onSubmit={handleSubmitInfo}
        >
          {({ errors, touched, setFieldValue, values, handleBlur }) => (
            <Form className="user-info-form">
              <FormikInput name="firstName" label="FIRST NAME" />
              <FormikInput name="lastName" label="LAST NAME" />
              <FormikInput name="email" label="EMAIL" />
              <PhoneInput
                name="phone"
                label="PHONE"
                placeholder="Phone"
                onBlur={handleBlur}
                touched={touched?.phone}
                onChange={(phoneNumber: string) => {
                  setFieldValue('phone', phoneNumber);
                }}
                value={values.phone}
                error={errors?.phone}
                maxLength={14}
              />
              <Button className="user-info-button" submit>
                SUBMIT
              </Button>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <div className="user-info-display-container">
            <UserInfoDisplay label="FIRST NAME" value={manager?.firstName} />
            <UserInfoDisplay label="LAST NAME" value={manager?.lastName} />
            <UserInfoDisplay label="EMAIL" value={manager?.email} />
            <UserInfoDisplay label="PHONE" value={formatPhoneNumber(`+1${manager?.phone}`)} />
            <Button className="user-info-button" onClick={() => setIsEditMode(true)}>
              EDIT
            </Button>
          </div>
          {errorMessage && <ErrorText error={errorMessage} />}
        </>
      )}
    </div>
  );
};

export default UserInfoForm;
