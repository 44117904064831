import InfoIcon from '../../../assets/svgs/icons/InfoIcon';

interface InfoTooltipProps {
  description: string;
  direction?: string;
  className?: string;
}

const InfoTooltip = ({ description, direction, className }: InfoTooltipProps) => {
  const getDirection = () => {
    const appendedClass = className || '';
    switch (direction) {
      case 'top':
      case 'right':
      case 'left':
        return `${direction} ${appendedClass}`;
      default:
        return `right ${appendedClass}`;
    }
  };
  return (
    <div className={`tooltip-wrapper ${getDirection()}`}>
      <InfoIcon />
      <div className="description">{description}</div>
    </div>
  );
};

export default InfoTooltip;
