import { useModalContext } from '../../contexts/ModalContext';
import { ModalActions } from '../../constants/ContextActions';

import ErrorModal from '../ErrorModal/ErrorModal';

const GenericErrorModal = () => {
  const { state, dispatch } = useModalContext();
  const { isOpen, modalTitle, modalMessage, onDismiss } = state;

  const handleDismiss = () => {
    onDismiss?.();
    dispatch({ type: ModalActions.DISMISS_MODAL });
  };

  if (!isOpen) {
    return null;
  }

  return <ErrorModal modalTitle={modalTitle} modalMessage={modalMessage} onDismiss={handleDismiss} />;
};

export default GenericErrorModal;
