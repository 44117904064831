import React from 'react';

const PlayIcon = () => (
  <svg className="play-icon" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M47.5667 22.3112C49.7926 23.4127 49.7926 26.5873 47.5667 27.6888L4.8306 48.8377C2.83665 49.8244 0.500008 48.3737 0.500008 46.1489L0.500008 3.8511C0.500008 1.62634 2.83665 0.175574 4.8306 1.16233L47.5667 22.3112Z"
      fill="white"
    />
  </svg>
);

export default PlayIcon;
