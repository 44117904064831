const FacebookIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 10.0611C20 4.50451 15.5229 0 10 0C4.47715 0 0 4.50451 0 10.0611C0 15.0829 3.65685 19.2452 8.4375 20V12.9694H5.89844V10.0611H8.4375V7.84453C8.4375 5.32295 9.93043 3.93013 12.2146 3.93013C13.3087 3.93013 14.4531 4.12663 14.4531 4.12663V6.6026H13.1922C11.9499 6.6026 11.5625 7.37816 11.5625 8.17382V10.0611H14.3359L13.8926 12.9694H11.5625V20C16.3431 19.2452 20 15.0829 20 10.0611Z"
      fill="#1877F2"
    />
  </svg>
);

export default FacebookIcon;
