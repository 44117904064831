import React from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  ScreenReaderInstructions,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { toast } from 'react-toastify';
import { Label } from '../common/Form';
import { ModifierInterface } from '../../types/ModifierInterface';
import SelectedModifier from './SelectedModifier';

interface SelectedModifiersProps {
  modifiers: ModifierInterface[];
  // eslint-disable-next-line no-unused-vars
  onRemoveModifier: (modifier: ModifierInterface) => void;
  // eslint-disable-next-line no-unused-vars
  onReorderModifiers: (modifiers: ModifierInterface[]) => void;
}

const SelectedModifiers = ({ modifiers, onRemoveModifier, onReorderModifiers }: SelectedModifiersProps) => {
  const screenReaderInstructions: ScreenReaderInstructions = {
    draggable: `
    To pick up a sortable item, press the space bar.
    While sorting, use the arrow keys to move the item.
    Press space again to drop the item in its new position, or press escape to cancel.
  `
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 2
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    try {
      if (!over) {
        return;
      }

      const oldIndex = modifiers.findIndex((modifier) => modifier.modifierID.toString() === active.id);
      const newIndex = modifiers.findIndex((modifier) => modifier.modifierID.toString() === over.id);

      // // if position is the same, do not send request
      if (oldIndex === newIndex) {
        return;
      }
      const reorderedModifierGroups = arrayMove(modifiers, oldIndex, newIndex);
      onReorderModifiers(reorderedModifierGroups);
    } catch (err) {
      toast.error('Reorder Failed', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  return (
    <div className="selected-modifiers-container">
      <Label label="Selected Modifiers" />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        screenReaderInstructions={screenReaderInstructions}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      >
        <ul className="selected-modifiers-list">
          <SortableContext
            strategy={verticalListSortingStrategy}
            items={modifiers?.map((modifier) => modifier.modifierID.toString())}
          >
            {modifiers.map((modifier: ModifierInterface) => (
              <SelectedModifier
                key={modifier.modifierID}
                allowSorting={modifiers.length > 1}
                modifier={modifier}
                onRemoveModifier={onRemoveModifier}
              />
            ))}
            {modifiers.length === 0 && <p className="no-modifiers-copy">No modifiers selected yet.</p>}
          </SortableContext>
        </ul>
      </DndContext>
    </div>
  );
};

export default SelectedModifiers;
