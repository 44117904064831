import React from 'react';

interface TrashIconInterface {
  onIconClicked?: Function;
  className?: string;
  width?: string;
  height?: string;
  color?: string;
}

const TrashCanIcon = ({
  onIconClicked,
  className = '',
  width = '10',
  height = '12',
  color = 'black'
}: TrashIconInterface) => {
  const handleIconClicked = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    onIconClicked?.();
  };

  const handleIconKeyPress = (event: React.KeyboardEvent<SVGSVGElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      onIconClicked?.();
    }
  };

  return (
    <svg
      style={{ cursor: 'pointer' }}
      className={`delete-icon ${className}`}
      role="button"
      tabIndex={0}
      onClick={handleIconClicked}
      onKeyPress={handleIconKeyPress}
      width={width}
      height={height}
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.952393 3C0.952393 1.34315 2.29554 0 3.95239 0H4.84615H6.04763C7.70448 0 9.04763 1.34315 9.04763 3V8.42857C9.04763 10.0854 7.70448 11.4286 6.04763 11.4286H3.95239C2.29554 11.4286 0.952393 10.0854 0.952393 8.42857V3Z"
        fill={color}
      />
      <rect y="0.476074" width="10" height="0.952381" fill={color} />
      <rect width="10" height="0.47619" transform="matrix(1 0 0 -1 0 1.90479)" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.90467 0H8.09515C8.09515 0 9.64276 0.714286 8.09515 0.952381C6.54753 1.19048 3.45229 1.19048 1.90467 0.952381C0.35705 0.714286 1.90467 0 1.90467 0Z"
        fill={color}
      />
    </svg>
  );
};

export default TrashCanIcon;
