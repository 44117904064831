import { FieldArray, useField } from 'formik';
// TODO: Write our own internal TimePicker component so we can remove these dependencies
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { v4 as uuid } from 'uuid';
import { InferType, object, string } from 'yup';
import { ErrorText, Label } from '..';
import { PlusIcon, TrashCanIcon } from '../../../../assets/svgs/icons';
import { DAYS_ABBREVIATIONS } from '../../../../constants/weekDayConstants';
import MobileFormikDaysAndHoursPicker from './MobileFormikDaysAndHoursPicker';

interface DaysAndHoursPickerProps {
  label?: string;
  name: string;
  isDesktop?: boolean;
}

export const DayAndHourPickerRowSchema = object({
  id: string().uuid().required(),
  startTime: string().default('07:00').required(),
  endTime: string().default('17:00').required(),
  daysOfWeek: object()
    .default(DAYS_ABBREVIATIONS)
    .required()
    .test('day-value', 'At least one day must be selected', (value: object) => Object.values(value).includes(true))
});

const FormikDaysAndHoursPicker = ({ label, name, isDesktop }: DaysAndHoursPickerProps) => {
  const [, meta, { setValue }] = useField(name);
  const { value } = meta;
  const error = meta.touched ? meta.error : '';
  const timeFormat = 'HH:mm';
  // TODO: handle pm times and 12 am
  const getMomentFromString = (time: string) => {
    const [hour, minute] = time.split(':');
    return moment().hour(Number(hour)).minute(Number(minute));
  };

  const handleStartTimeChange =
    (entry: InferType<typeof DayAndHourPickerRowSchema>, index: number) => (time: moment.Moment) => {
      const timeString = time.format(timeFormat);
      value.splice(index, 1, { ...entry, startTime: timeString });
      setValue(value);
    };

  const handleEndTimeChange =
    (entry: InferType<typeof DayAndHourPickerRowSchema>, index: number) => (time: moment.Moment) => {
      value.splice(index, 1, { ...entry, endTime: time.format(timeFormat) });
      setValue(value);
    };

  const handleDayOfWeekClicked = (entry: InferType<typeof DayAndHourPickerRowSchema>, index: number, day: string) => {
    value.splice(index, 1, { ...entry, daysOfWeek: { ...entry.daysOfWeek, [day]: !entry.daysOfWeek[day] } });
    setValue(value);
  };

  const gridClasses = 'grid gap-2 grid-cols-[315px_100px_100px_31px]';

  return isDesktop ? (
    <>
      <Label htmlFor={name} label={label} />
      <FieldArray name={name}>
        {({ remove, push }) => (
          <div>
            <div className={`${gridClasses} mb-[10px]`}>
              <div className="day-hour-picker-label ">DAYS OF THE WEEK</div>
              <div className="day-hour-picker-label ml-[4px]">START TIME</div>
              <div className="day-hour-picker-label ml-[4px]">END TIME</div>
            </div>

            {value?.length > 0 &&
              value?.map((entry: InferType<typeof DayAndHourPickerRowSchema>, index: number) => (
                <div key={entry.id} className={`${gridClasses} mb-[19px]`}>
                  <div>
                    {Object.keys(DAYS_ABBREVIATIONS).map((day) => (
                      <button
                        type="button"
                        key={day}
                        className={`day-picker text-sm w-[40px] h-[40px] mr-1 ${
                          entry.daysOfWeek[day] ? 'bg-green-700 text-white' : 'bg-neutral-200 text-neutral-500'
                        }`}
                        onClick={() => handleDayOfWeekClicked(entry, index, day)}
                      >
                        {day}
                      </button>
                    ))}
                  </div>
                  <TimePicker
                    className="time-picker !flex items-center w-[4.5rem] !text-black"
                    showSecond={false}
                    use12Hours
                    allowEmpty={false}
                    value={getMomentFromString(entry.startTime)}
                    onChange={handleStartTimeChange(entry, index)}
                  />
                  <TimePicker
                    className="time-picker !flex items-center w-[4.5rem]"
                    showSecond={false}
                    use12Hours
                    allowEmpty={false}
                    defaultValue={getMomentFromString(entry.endTime)}
                    onChange={handleEndTimeChange(entry, index)}
                  />
                  {index === 0 ? (
                    <button
                      type="button"
                      className="h-fit self-center justify-self-center"
                      onClick={() => push(DayAndHourPickerRowSchema.cast({ id: uuid() }))}
                    >
                      <PlusIcon width="10" height="10" />
                    </button>
                  ) : (
                    <TrashCanIcon
                      className="h-fit self-center justify-self-center"
                      onIconClicked={() => remove(index)}
                    />
                  )}
                </div>
              ))}
          </div>
        )}
      </FieldArray>
      {error && <ErrorText error="Value is required." />}
    </>
  ) : (
    <MobileFormikDaysAndHoursPicker label={label} name={name} />
  );
};

export default FormikDaysAndHoursPicker;
