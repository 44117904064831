import React from 'react';

interface RightArrowIconProps {
  onClick?: Function;
  visible?: boolean;
}
const RightArrowIcon = ({ onClick, visible = true }: RightArrowIconProps) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    onClick?.();
    event.stopPropagation();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      onClick?.();
    }
    event.stopPropagation();
  };

  return (
    <div
      style={{ visibility: visible ? 'visible' : 'hidden' }}
      role="button"
      className="arrow-icon p-1"
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      tabIndex={0}
    >
      <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.15569 7.99999L1.28303 1.27674" stroke="black" strokeOpacity="0.67" strokeLinecap="square" />
        <path d="M1.31111 14.6956L8.18377 7.97235" stroke="black" strokeOpacity="0.67" strokeLinecap="square" />
      </svg>
    </div>
  );
};

export default RightArrowIcon;
