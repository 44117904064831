import { Label } from '../../../components/common/Form';
import Button from '../../../components/common/Button';
import ImageUploader from '../../../components/common/Form/ImageUploader';

interface RestaurantLogoImagePageProps {
  logoImage: Blob | string;
  handleLogoImageUpload: Function;
  onNext: Function;
  onBack: Function;
}

const RestaurantLogoPhotoPage = ({
  logoImage,
  handleLogoImageUpload,
  onNext,
  onBack
}: RestaurantLogoImagePageProps) => {
  const handleNextClicked = () => {
    onNext?.();
  };

  const handleLogoUpload = (file: Blob) => {
    handleLogoImageUpload(file);
  };

  return (
    <div className="restaurant-photo-page">
      <div className="restaurant-wizard-logo-image-container">
        <Label label="LOGO PHOTO" />
        <p className="restaurant-wizard-image-copy">
          Upload your restaurant&apos;s logo to be used on your QR code. Please note that higher resolution files are
          more likely to display more clearly.
        </p>
        <ImageUploader id="0" image={logoImage} onUpdate={handleLogoUpload} />
      </div>
      <div className="restaurant-wizard-photo-buttons">
        <Button className="restaurant-wizard-back-button" onClick={onBack}>
          BACK
        </Button>
        <Button className="restaurant-wizard-next-button" onClick={handleNextClicked}>
          NEXT
        </Button>
      </div>
    </div>
  );
};

export default RestaurantLogoPhotoPage;
