import React from 'react';
import { Dropdown } from '../common/Form';
import useResponsive from '../../hooks/useResponsive';

interface MediaLibraryTopBarProps {
  mediaTypeFilter: 'all' | 'image' | 'video';
  // eslint-disable-next-line no-unused-vars
  onTypeChange: (type: 'all' | 'image' | 'video') => void;
}

const MediaLibraryTopBar = ({ mediaTypeFilter, onTypeChange }: MediaLibraryTopBarProps) => {
  const { isDesktop } = useResponsive();
  const handleTypeFilterChange = (type: 'all' | 'image' | 'video') => {
    onTypeChange(type);
  };

  return isDesktop ? (
    <div className="media-library-top-bar-container shadow-[0_0_20px_6px_rgb(0,0,0,0.1)]">
      <Dropdown
        className="media-type-dropdown"
        onChange={handleTypeFilterChange}
        value={mediaTypeFilter}
        values={[
          { value: 'all', label: 'All Media' },
          { value: 'image', label: 'Images' },
          { value: 'video', label: 'Video' }
        ].map((item) => ({ label: item.label, value: item.value }))}
      />
    </div>
  ) : (
    <div className="mobile-media-library-top-bar-container h-[60px]">
      <div className="mobile-media-library-top-bar-title">
        <h1>MEDIA</h1>
      </div>
      <Dropdown
        className="media-type-dropdown"
        onChange={handleTypeFilterChange}
        value={mediaTypeFilter}
        values={[
          { value: 'all', label: 'All' },
          { value: 'image', label: 'Images' },
          { value: 'video', label: 'Video' }
        ].map((item) => ({ label: item.label, value: item.value }))}
      />
    </div>
  );
};

export default MediaLibraryTopBar;
