import ReactDOM from 'react-dom';
import { useAlertContext, dismissAlert } from '../../../contexts/AlertContext';
import Button from '../Button';

const AlertModal = () => {
  const { state, dispatch } = useAlertContext();
  const { isOpen, title, message, onConfirm, onCancel } = state;

  if (!isOpen) {
    return null;
  }

  const handleAlertCanceled = () => {
    onCancel?.();
    dismissAlert({ dispatch });
  };

  const handleAlertConfirmed = () => {
    onConfirm();
    dismissAlert({ dispatch });
  };

  return ReactDOM.createPortal(
    <div className="alert-modal">
      <div className="alert-modal-container">
        <div className="alert-modal-content">
          {title && <h3>{title}</h3>}
          {message && <p>{message}</p>}
        </div>
        <div className="alert-modal-buttons">
          <Button className="alert-modal-cancel-button" onClick={handleAlertCanceled}>
            CANCEL
          </Button>
          <Button className="alert-modal-confirm-button" onClick={handleAlertConfirmed}>
            CONFIRM
          </Button>
        </div>
      </div>
    </div>,
    document.querySelector('#alert-root')
  );
};

export default AlertModal;
