import { Form, Formik } from 'formik';
import { InferType, object, ref, string } from 'yup';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { changePassword } from '../../api/authentication';
import { ErrorText, PasswordInput } from '../common/Form';
import Button from '../common/Button';

const ChangePasswordSchema = object({
  currentPassword: string().default('').required('Value is required'),
  newPassword: string()
    .default('')
    .required('Value is required.')
    .min(9, 'Password must be at least 9 characters long.')
    .matches(/(?=.*[A-Z])/, 'Password must have one upper case character.')
    .matches(/(?=.*[a-z])/, 'Password must have one lower case character.')
    .matches(/(?=.*\d)/, 'Password must have one number.')
    .matches(/(?=.*\W)/, 'Password must have one special character.'),
  confirmPassword: string()
    .default('')
    .required('Value is required')
    .oneOf([ref('newPassword'), null], 'Passwords must match')
});

const ChangePasswordForm = () => {
  const [changePasswordError, setChangePasswordError] = useState(null);

  const handleChangePassword = async (
    { currentPassword, newPassword, confirmPassword }: InferType<typeof ChangePasswordSchema>,
    // @ts-ignore
    { resetForm }
  ) => {
    if (ChangePasswordSchema.isValidSync({ currentPassword, newPassword, confirmPassword })) {
      changePassword(currentPassword, newPassword)
        .then(() => {
          toast.success('Successfully changed password.', {
            position: toast.POSITION.TOP_RIGHT
          });
          resetForm();
          setChangePasswordError(null);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            setChangePasswordError('Invalid credentials');
          } else if (error.response) {
            setChangePasswordError('An unexpected error has occurred.');
          } else if (error.request) {
            // The request was made but no response was received
            setChangePasswordError('Unable to process your reset request at this time');
          }
        });
    }
  };

  return (
    <>
      <Formik
        initialValues={ChangePasswordSchema.cast({})}
        validationSchema={ChangePasswordSchema}
        onSubmit={handleChangePassword}
      >
        {({ errors, touched }) => (
          <Form className="change-password-form">
            <PasswordInput
              name="currentPassword"
              className="password-input"
              placeholder="Current Password"
              errors={errors}
              touched={touched}
            />{' '}
            <PasswordInput
              name="newPassword"
              className="password-input"
              placeholder="New Password"
              errors={errors}
              touched={touched}
            />
            <PasswordInput
              name="confirmPassword"
              className="password-input"
              placeholder="Confirm New Password"
              errors={errors}
              touched={touched}
            />
            <Button className="change-password-button" submit>
              CHANGE
            </Button>
          </Form>
        )}
      </Formik>
      {changePasswordError && <ErrorText error={changePasswordError} />}
    </>
  );
};

export default ChangePasswordForm;
