import React from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { PAGE_CREATE_MODIFIER, PAGE_MENUS, PAGE_MODIFIERS } from '../../constants/UriConstants';
import AddItemButtons from '../AddItemButtons';
import { useRestaurantContext } from '../../contexts/RestaurantContext';

const MenuTopBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { menus } = useRestaurantContext();

  const handleMenuItemClick = () => {
    navigate(`/${PAGE_MENUS}`);
  };

  const handleMenuItemKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      navigate(`/${PAGE_MENUS}`);
    }
  };

  const hasMenuSections = () => {
    let hasValues = false;

    menus.forEach((menu) => {
      if (menu?.menuSections?.length > 0) {
        hasValues = true;
      }
    });

    return hasValues;
  };

  return (
    <div className="menu-top-bar-container h-[90px] w-full shadow-[0_0_20px_6px_rgb(0,0,0,0.1)]">
      <span
        role="button"
        onClick={handleMenuItemClick}
        onKeyPress={handleMenuItemKeyPress}
        tabIndex={0}
        className={`menu-top-selection ${pathname.includes(PAGE_MENUS) || pathname === '/' ? 'active' : ''}`}
      >
        MENU ITEMS
      </span>
      <NavLink
        to={`/${PAGE_MODIFIERS}`}
        className={({ isActive }) => (isActive ? 'menu-top-selection active' : 'menu-top-selection')}
      >
        MODIFIERS
      </NavLink>
      {hasMenuSections() && (pathname.includes(PAGE_MENUS) || pathname === '/') && <AddItemButtons />}
      {pathname.includes(PAGE_MODIFIERS) && (
        <Link to={`/${PAGE_CREATE_MODIFIER}`} className="add-modifier-button button">
          ADD MODIFIER
        </Link>
      )}
    </div>
  );
};

export default MenuTopBar;
