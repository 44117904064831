import React from 'react';
import { useLocation } from 'react-router-dom';
import SidebarLink from '../Sidebar/SidebarLink';
import {
  PAGE_EMAIL_MANAGER,
  PAGE_MEDIA_LIBRARY,
  PAGE_MENUS,
  PAGE_PROMOTIONS,
  PAGE_SETTINGS
} from '../../constants/UriConstants';

interface MobileBurgerSideBarProps {
  isVisible: boolean;
}

const MobileBurgerSideBar = ({ isVisible }: MobileBurgerSideBarProps) => {
  const { pathname } = useLocation();

  const handleActive = (path: string) =>
    pathname === path ||
    pathname.startsWith(`${path}/`) ||
    (pathname === '/' && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('create-menu') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('create-menu-item') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('create-drink-item') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('edit-menu') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('edit-menu-item') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('edit-drink-item') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('edit-restaurant') && path === `/${PAGE_SETTINGS}`);

  const isMenuActive = handleActive(`/${PAGE_MENUS}`);
  const isPromotionsActive = handleActive(`/${PAGE_PROMOTIONS}`);
  const isEmailActive = handleActive(`/${PAGE_EMAIL_MANAGER}`);
  const isSettingsActive = handleActive(`/${PAGE_SETTINGS}`);
  const isMediaActive = handleActive(`/${PAGE_MEDIA_LIBRARY}`);
  return (
    <div className={`mobile-burger-sidebar ${isVisible ? 'visible' : ''}`}>
      <ul className="sidebar-link-container">
        <SidebarLink name="Menu" path={`/${PAGE_MENUS}`} isActive={isMenuActive} />
        <SidebarLink name="Promotions" path={`/${PAGE_PROMOTIONS}`} isActive={isPromotionsActive} />
        <SidebarLink name="Emails" path={`/${PAGE_EMAIL_MANAGER}`} isActive={isEmailActive} />
        <SidebarLink name="Media" path={`/${PAGE_MEDIA_LIBRARY}`} isActive={isMediaActive} />
        <SidebarLink name="Profile & Settings" path={`/${PAGE_SETTINGS}`} isActive={isSettingsActive} />
      </ul>
    </div>
  );
};

export default MobileBurgerSideBar;
