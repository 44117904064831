import React from 'react';

interface EditIconInterface {
  onIconClicked?: Function;
  tabIndex?: number;
  width?: string;
  height?: string;
}

const EditIcon = ({ onIconClicked, width = '9', height = '9', tabIndex = null }: EditIconInterface) => {
  const handleIconClicked = () => {
    onIconClicked?.();
  };

  const handleIconKeyPress = (event: React.KeyboardEvent<SVGSVGElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      onIconClicked?.();
    }
  };

  return (
    <svg
      className="edit-icon"
      style={{ cursor: 'pointer' }}
      role="button"
      tabIndex={tabIndex}
      onClick={handleIconClicked}
      onKeyPress={handleIconKeyPress}
      width={width}
      height={height}
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 7.75L7.25 3.25L9 1.5L7.5 0L5.75 1.75L1.24304 6.25692L1.24566 6.25954L0 9L2.74046 7.75434L2.74306 7.75694L2.75 7.75Z"
        fill="black"
      />
    </svg>
  );
};

export default EditIcon;
