import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import { formatPriceExact } from '../../utils/priceFormatter';
import { Input } from '../common/Form';
import { ModifierInterface } from '../../types/ModifierInterface';
import Button from '../common/Button';

interface AvailableModifiersProps {
  modifiers: ModifierInterface[];
  // eslint-disable-next-line no-unused-vars
  onAddModifier: (modifier: ModifierInterface) => void;
}

const AvailableModifiers = ({ modifiers, onAddModifier }: AvailableModifiersProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredModifiers, setFilteredModifiers] = useState<ModifierInterface[]>(modifiers);
  const [fuse, setFuse] = useState(
    new Fuse(modifiers, {
      includeScore: true,
      findAllMatches: true,
      keys: ['name'],
      isCaseSensitive: false,
      distance: 0,
      location: 0,
      threshold: 0
    })
  );

  useEffect(() => {
    setFuse(
      new Fuse(modifiers, {
        includeScore: true,
        findAllMatches: true,
        keys: ['name'],
        isCaseSensitive: false,
        distance: 0,
        location: 0,
        threshold: 0
      })
    );
  }, [modifiers]);

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredModifiers(modifiers);
    } else {
      const results: Fuse.FuseResult<ModifierInterface>[] = fuse.search(searchTerm.toLowerCase());
      const matchingModifiers = results.map((result) => result.item);
      setFilteredModifiers(matchingModifiers);
    }
  }, [searchTerm, modifiers, fuse]);

  const handleFilterChange = (value: string) => {
    setSearchTerm(value);
  };

  return (
    <div className="available-modifiers-container">
      <Input
        className="search-modifiers-input"
        name="modifier-input"
        placeholder="Search for..."
        value={searchTerm}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFilterChange(event.target.value)}
      />
      <ul className="available-modifiers-list">
        {filteredModifiers.map((modifier: ModifierInterface) => (
          <li className="modifier-item" key={modifier.modifierID}>
            <Button className="add-modifier-button" onClick={() => onAddModifier(modifier)}>
              Add
            </Button>
            <span className="entity-name">{modifier.name}</span>
            {modifier.price > 0 && <span className="modifier-price">+{formatPriceExact(modifier.price)}</span>}
          </li>
        ))}
        {modifiers.length === 0 && (
          <p className="no-modifiers-copy">All modifiers are in use or none have been created yet.</p>
        )}
        {modifiers.length > 0 && filteredModifiers.length === 0 && (
          <p className="no-modifiers-copy">No matches found.</p>
        )}
      </ul>
    </div>
  );
};

export default AvailableModifiers;
