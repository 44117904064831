import React, { useEffect, useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  ScreenReaderInstructions,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates
} from '@dnd-kit/sortable';
import { restrictToHorizontalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { MenuSectionInterface } from '../../types/MenuSectionInterface';
import { LeftArrowIcon, PlusIcon, RightArrowIcon } from '../../assets/svgs/icons';
import { useMenuContext } from '../../contexts/MenuContext';
import DeleteItemModal from '../DeleteItemModal';
import { deleteMenuSection, hideMenuSection, reorderMenuSections, showMenuSection } from '../../api/menuSections';
import MenuSectionSelector from '../MenuSectionSelector';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import HorizontalScrollBar from '../HorizontalScrollBar';
import { useRestaurantContext } from '../../contexts/RestaurantContext';
import { MenuSectionModal } from '../MenuSectionModal';

const MenuSectionSelectionBar = () => {
  const [menuSections, setMenuSections] = useState<MenuSectionInterface[]>([]);
  const [showMenuSectionModal, setShowMenuSectionModal] = useState<boolean>(false);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState<boolean>(false);
  const [showRightScrollArrow, setShowRightScrollArrow] = useState<boolean>(false);

  /**
   * ID of menu section to delete, can possibly be different then the currently selected menu section id
   */
  const [menuSectionToDelete, setMenuSectionToDelete] = useState<number>(null);
  const [hasError, setHasError] = useState<boolean>(false);

  const {
    menu,
    removeMenuSection,
    selectedMenuSectionID,
    setSelectedMenuSectionID,
    reorderMenuSections: reorderSections,
    updateMenuSection
  } = useMenuContext();
  const { removeMenuSection: removeMenuSectionForRestaurant } = useRestaurantContext();
  const { dispatch } = useModalContext();

  const screenReaderInstructions: ScreenReaderInstructions = {
    draggable: `
    To pick up a sortable item, press the space bar.
    While sorting, use the arrow keys to move the item.
    Press space again to drop the item in its new position, or press escape to cancel.
  `
  };

  useEffect(() => {
    if (menu) {
      const sections = menu.menuSections.slice();
      const parsedSections: MenuSectionInterface[] = sections.map((section) => ({
        menuSectionID: section.menuSectionID,
        sectionName: section.sectionName,
        message: section.message,
        isHidden: section.isHidden
      }));
      setMenuSections(parsedSections);
    }
  }, [menu]);

  const handleDeleteMenuSection = async () => {
    try {
      await deleteMenuSection(menuSectionToDelete);

      // remove menu section
      removeMenuSection(menuSectionToDelete);
      removeMenuSectionForRestaurant(menuSectionToDelete, menu.menuID);
      if (menuSectionToDelete === selectedMenuSectionID) {
        // if deleting selected menu section will need to reset the view
        setSelectedMenuSectionID(menu.menuSections?.[0]?.menuSectionID || null);
      }

      // set menu section back to null to close modal
      setMenuSectionToDelete(null);
      if (hasError) {
        setHasError(false);
      }
    } catch (error) {
      setHasError(true);
    }
  };

  const handleHideShowMenuSection = async (menuSection: MenuSectionInterface) => {
    try {
      const toggleFunction = menuSection.isHidden ? showMenuSection : hideMenuSection;
      await toggleFunction(menuSection.menuSectionID);

      updateMenuSection({ ...menuSection, isHidden: !menuSection.isHidden });

      if (hasError) {
        setHasError(false);
      }
    } catch (error) {
      setHasError(true);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 2
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = async ({ active, over }: DragEndEvent) => {
    if (!over) {
      return;
    }

    const _menuSections = menuSections.slice();
    const oldIndex = menuSections.findIndex((section) => section.menuSectionID.toString() === active.id);
    const newIndex = menuSections.findIndex((section) => section.menuSectionID.toString() === over.id);

    // if position is the same, do not send request
    if (oldIndex === newIndex) {
      return;
    }
    const reorderedMenuSections = arrayMove(menuSections, oldIndex, newIndex);
    setMenuSections(reorderedMenuSections);
    const menuSectionIDs: number[] = reorderedMenuSections.map((section) => section.menuSectionID);
    try {
      await reorderMenuSections(menu.menuID, menuSectionIDs);
      reorderSections(menuSectionIDs);
    } catch (error) {
      setMenuSections(_menuSections);
      openModal({ dispatch });
    }
  };

  const handleScrollRight = () => {
    const container = document.querySelector('.menu-section-selection-bar-scroll-container');
    container.scrollLeft += 75;
  };

  const handleScrollLeft = () => {
    const container = document.querySelector('.menu-section-selection-bar-scroll-container');
    container.scrollLeft -= 75;
  };

  return (
    <div className="menu-section-selection-bar-container">
      <DeleteItemModal
        title="DELETE MENU SECTION"
        message="Please confirm that you would like to delete this menu section."
        warning="NOTE: By deleting this menu section, you will be deleting every menu item in this section."
        error={
          hasError &&
          'An unexpected error has occurred while deleting menu section. Unable to perform action at this time.'
        }
        isOpen={!!menuSectionToDelete}
        onCancel={() => {
          setMenuSectionToDelete(null);
          setHasError(false);
        }}
        onConfirm={handleDeleteMenuSection}
      />
      {showMenuSectionModal && (
        <MenuSectionModal
          isEdit={false}
          onSubmit={() => setShowMenuSectionModal(false)}
          onCancel={() => setShowMenuSectionModal(false)}
        />
      )}
      <PlusIcon onIconClicked={() => setShowMenuSectionModal(true)} width=".875rem" height=".875rem" />
      <LeftArrowIcon visible={showLeftScrollArrow} onClick={handleScrollLeft} />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        screenReaderInstructions={screenReaderInstructions}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToHorizontalAxis, restrictToWindowEdges]}
      >
        <HorizontalScrollBar
          className="menu-section-selection-bar-scroll-container"
          items={menuSections}
          showLeftArrow={setShowLeftScrollArrow}
          showRightArrow={setShowRightScrollArrow}
        >
          <SortableContext
            strategy={horizontalListSortingStrategy}
            items={menuSections.map((menuSection) => menuSection.menuSectionID.toString())}
          >
            {menuSections.map((menuSection) => (
              <MenuSectionSelector
                key={menuSection.menuSectionID}
                menuSection={{ name: menuSection.sectionName, ...menuSection }}
                onMenuSectionDeletion={(itemID: number) => setMenuSectionToDelete(itemID)}
                onMenuSectionHideShow={() => handleHideShowMenuSection(menuSection)}
                isHidden={menuSection.isHidden}
              />
            ))}
          </SortableContext>
        </HorizontalScrollBar>
      </DndContext>
      <RightArrowIcon visible={showRightScrollArrow} onClick={handleScrollRight} />
    </div>
  );
};

export default MenuSectionSelectionBar;
