import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { InferType, object, string } from 'yup';

import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorText, PasswordInput } from '../../components/common/Form';
import logoWithName from '../../images/TapTabLogoWhiteSmallWithName.png';
import { PAGE_FORGOT_PASSWORD } from '../../constants/UriConstants';
import { resetPassword } from '../../api/authentication';
import { useAuthContext } from '../../contexts/AuthContext/AuthContext';

const ResetPasswordSchema = object({
  temporaryPassword: string().default('').required('Temporary Password is required.'),
  password: string()
    .default('')
    .min(9, 'Password must be at least 9 characters long.')
    .required('New password is required.')
    .matches(/(?=.*[A-Z])/, 'Password must have one upper case character.')
    .matches(/(?=.*[a-z])/, 'Password must have one lower case character.')
    .matches(/(?=.*\d)/, 'Password must have one number.')
    .matches(/(?=.*\W)/, 'Password must have one special character.')
});

interface ResetPasswordNavState {
  email: string;
}

const ResetPassword = () => {
  const { setToken } = useAuthContext();
  const [resetPasswordError, setResetPasswordError] = useState(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { email } = (state as ResetPasswordNavState) || {};

  useEffect(() => {
    if (!(email && email.length > 0)) {
      navigate(`/${PAGE_FORGOT_PASSWORD}`);
    }
  }, [email, navigate]);

  const handleResetPassword = async ({ password, temporaryPassword }: InferType<typeof ResetPasswordSchema>) => {
    if (ResetPasswordSchema.isValidSync({ password, temporaryPassword })) {
      resetPassword(email, temporaryPassword, password)
        .then((data) => {
          if (data?.token) {
            setResetPasswordError(null);
            setToken(data.token);
            navigate('/');
          }
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            setResetPasswordError('Invalid credentials');
          } else if (error.request) {
            // The request was made but no response was received
            setResetPasswordError('Unable to process your reset request at this time');
          } else {
            // Something happened in setting up the request that triggered an Error
            setResetPasswordError('Invalid credentials');
          }
        });
    }
  };

  const handleRetrySendEmailClicked = useCallback(() => {
    navigate(`/${PAGE_FORGOT_PASSWORD}`);
  }, [navigate]);

  const handleRetrySendEmailKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      if (event.code === 'Enter' || event.code === 'Space') {
        navigate(`/${PAGE_FORGOT_PASSWORD}`);
      }
    },
    [navigate]
  );

  return (
    // url value is relative to index.css where tailwind is imported
    <div
      className={`h-screen w-screen bg-[#000]/[0.8] bg-[url('../images/LoginBackground.png')] bg-blend-darken backdrop-blur-sm bg-cover`}
    >
      <div className="h-screen w-screen backdrop-blur-md flex justify-center items-center">
        <div className="inline-block">
          <div className="flex items-center mb-4">
            <img src={logoWithName} className="h-[50px] pr-2" alt="logo" />
            <p className="text-[21px] text-white border-l-2 border-white pl-3">TapManager</p>
          </div>
          <h1 className="text-[20px] text-white font-semibold text-center mb-2">RESET PASSWORD</h1>
          <ErrorText error={resetPasswordError} />
          <Formik
            initialValues={ResetPasswordSchema.cast({})}
            validationSchema={ResetPasswordSchema}
            onSubmit={handleResetPassword}
          >
            {({ errors, touched }) => (
              <Form className="grid mt-2">
                <PasswordInput
                  name="temporaryPassword"
                  placeholder="Temporary Password"
                  className="w-full mb-2"
                  errors={errors}
                  touched={touched}
                />
                <PasswordInput
                  name="password"
                  placeholder="New Password"
                  className="w-full"
                  errors={errors}
                  touched={touched}
                />
                <button type="submit" className="w-full h-[50px] mt-2 bg-[#979797] text-white text-[18px]">
                  Reset Password
                </button>
                <a
                  role="link"
                  className="w-full mt-4 text-white text-[12px] text-center cursor-pointer"
                  tabIndex={0}
                  onClick={handleRetrySendEmailClicked}
                  onKeyPress={handleRetrySendEmailKeyPress}
                >
                  Retry send email
                </a>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
