/* eslint-disable react/no-unused-prop-types */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { InferType, object, string, ref, boolean } from 'yup';
import { toast } from 'react-toastify';
import { Checkbox, ErrorText, FormikInput, PasswordInput, PhoneInput } from '../../components/common/Form';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import Logo from '../../assets/images/TapTabLargeLogoWhite.png';
import signUp from '../../api/signup';
import getStripeSessionInfo from '../../api/stripeSessionInfo';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import { StripeSessionInfoInterface } from '../../types/StripeSessionInfoInterface';
import { useAuthContext } from '../../contexts/AuthContext';
import { useCheckoutContext } from '../../contexts/CheckoutContext';
import Button from '../../components/common/Button';
import { PAGE_CREATE_RESTAURANT } from '../../constants/UriConstants';

const SignUpSchema = object({
  stripeCustomerID: string().required('Stripe customer ID is required.'),
  firstName: string().default('').required('First name is required.'),
  lastName: string().default('').required('Last name is required.'),
  phone: string().default('').required('Phone is required.'),
  email: string().default('').email('Must be a valid email').required('Email is required.'),
  password: string()
    .default('')
    .required('Password is required.')
    .min(9, 'Password must be at least 9 characters long.')
    .matches(/(?=.*[A-Z])/, 'Password must have one upper case character.')
    .matches(/(?=.*[a-z])/, 'Password must have one lower case character.')
    .matches(/(?=.*\d)/, 'Password must have one number.')
    .matches(/(?=.*\W)/, 'Password must have one special character.'),
  passwordConfirmation: string()
    .default('')
    .required('Password confirmation is required.')
    .oneOf([ref('password'), null], "Passwords don't match."),
  agreement: boolean().oneOf([true], 'Must agree to terms.')
});

const SignUp = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { authenticated, setToken } = useAuthContext();
  const { checkoutSessionID, setManagerPackageID, setSession } = useCheckoutContext();
  const { dispatch } = useModalContext();
  const [stripeKey, setStripeKey] = useState(null);
  const [defaultInfo, setDefaultInfo] = useState<StripeSessionInfoInterface>(null);
  const [sessionError, setSessionError] = useState<boolean>(false);

  useEffect(() => {
    if (authenticated) {
      navigate('/');
    }
  }, [authenticated, navigate]);

  useEffect(() => {
    if (!stripeKey && !authenticated) {
      const session = new URLSearchParams(search).get('session');
      if (session) {
        setStripeKey(session);
        setSession(session);
      } else if (checkoutSessionID) {
        setStripeKey(checkoutSessionID);
      } else {
        navigate(-1);
      }
    }
  }, [authenticated, checkoutSessionID, navigate, search, setSession, stripeKey]);

  useEffect(() => {
    if (stripeKey && defaultInfo == null && !sessionError && !authenticated) {
      getStripeSessionInfo(stripeKey)
        .then((info) => {
          setDefaultInfo(info);
          setManagerPackageID(info.managerPackageIDs[0]);
          if (sessionError) {
            setSessionError(false);
          }
          toast.success('Successfully Purchased!', {
            position: toast.POSITION.TOP_RIGHT
          });
        })
        .catch(() => {
          setSessionError(true);
          openModal({
            dispatch,
            message:
              'Please email our team at info@taptabapp.com so that we can address this issue promptly, ' +
              `and make sure to include this session ID in your email. \n \n SESSION ID: ${stripeKey}`
          });
        });
    }
  }, [authenticated, defaultInfo, dispatch, sessionError, setManagerPackageID, stripeKey]);

  const getInitialValues = (stripeInfo: StripeSessionInfoInterface) =>
    ({
      stripeCustomerID: stripeInfo.stripeCustomerID || '',
      firstName: '',
      lastName: '',
      phone: '',
      email: stripeInfo.email || '',
      password: '',
      passwordConfirmation: '',
      agreement: false
    } as InferType<typeof SignUpSchema>);

  const handleSignUp = (data: InferType<typeof SignUpSchema>) => {
    SignUpSchema.isValid(data).then(() => {
      signUp({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone.substring(2, data.phone.length),
        pwd: data.password,
        stripeCustomerID: data.stripeCustomerID,
        titleName: 'manager'
      })
        .then((token) => {
          if (token) {
            setToken(token);
            navigate(`/${PAGE_CREATE_RESTAURANT}`);
          } else {
            openModal({
              dispatch,
              message:
                'Please email our team at info@taptabapp.com so that we can address this issue promptly, ' +
                `and make sure to include this session ID in your email. \n \n SESSION ID: ${stripeKey}`
            });
          }
        })
        .catch(() => {
          openModal({
            dispatch,
            message:
              'Please email our team at info@taptabapp.com so that we can address this issue promptly, ' +
              `and make sure to include this session ID in your email. \n \n SESSION ID: ${stripeKey}`
          });
        });
    });
  };

  return (
    // url value is relative to index.css where tailwind is imported
    <div className={`h-screen w-screen py-4 bg-[url('../images/SignUpBackground.png')] bg-cover overflow-auto`}>
      <div className="h-full w-full flex justify-center tall:items-center">
        <div className="flex-col items-center">
          <div className="flex justify-center mb-10">
            <img src={Logo} alt="TapTab logo" />
          </div>
          <p className="text-[28px] text-white text-center">Create Your TapTab Account!</p>
          {defaultInfo ? (
            <Formik
              initialValues={getInitialValues(defaultInfo)}
              validationSchema={SignUpSchema}
              onSubmit={handleSignUp}
            >
              {({ errors, touched, setFieldValue, values, handleBlur }) => (
                <Form className="grid gap-2">
                  <div className="flex gap-8">
                    <FormikInput
                      name="firstName"
                      autoComplete="firstName"
                      placeholder="First Name"
                      className="w-[190px]"
                    />
                    <FormikInput
                      name="lastName"
                      autoComplete="lastName"
                      placeholder="Last Name"
                      className="w-[190px]"
                    />
                  </div>
                  <PhoneInput
                    name="phone"
                    placeholder="Phone"
                    className="w-full"
                    onBlur={handleBlur}
                    touched={touched?.phone}
                    onChange={(phoneNumber: string) => setFieldValue('phone', phoneNumber)}
                    value={values.phone}
                    error={errors?.phone}
                  />
                  <FormikInput name="email" disabled placeholder="Email" className="w-full" type="email" />
                  <PasswordInput
                    name="password"
                    placeholder="Password"
                    className="w-full mt-2"
                    errors={errors}
                    touched={touched}
                  />
                  <PasswordInput
                    name="passwordConfirmation"
                    placeholder="Confirm Password"
                    className="w-full mt-2"
                    errors={errors}
                    touched={touched}
                  />
                  <div className="flex items-center mt-4 text-[12px] text-white">
                    <Field name="agreement">
                      {({
                        field,
                        form
                      }: {
                        field: { name: string; value: boolean };
                        form: { setFieldValue: Function };
                      }) => (
                        <Checkbox
                          checked={field?.value}
                          onChange={() => form?.setFieldValue(field?.name, !field?.value)}
                        />
                      )}
                    </Field>
                    By checking this box, you agree to TapTab&apos;s
                    <a
                      className="text-white text-[12px] font-extrabold px-1 cursor-pointer"
                      tabIndex={0}
                      target="_blank"
                      href="https://taptabapp.com/terms-of-use/"
                      rel="noreferrer"
                    >
                      Terms of Use
                    </a>
                    &
                    <a
                      className="text-white text-[12px] font-extrabold pl-1 cursor-pointer"
                      tabIndex={0}
                      target="_blank"
                      href="https://taptabapp.com/privacy-policy-2/"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </div>
                  {errors.agreement && touched.agreement && <ErrorText error={errors.agreement} />}
                  <Button overrideCSS submit className="w-full h-[50px] my-4 bg-[#05944F] text-white text-[18px]">
                    SIGN UP
                  </Button>
                </Form>
              )}
            </Formik>
          ) : (
            <div className="flex justify-center mt-6">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
