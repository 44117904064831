import React, { useCallback, useEffect, useRef } from 'react';
import { useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSS } from '@dnd-kit/utilities';
import { useNavigate } from 'react-router-dom';
import ContextMenuIconButton from '../ContextMenuIconButton';
import { useMenuContext } from '../../contexts/MenuContext';
import { PAGE_EDIT_MENU } from '../../constants/UriConstants';

interface MenuSelectorProps {
  menuID: number;
  menuName: string;
  onDelete: Function;
  onDownload: Function;
  onHideShow: Function;
  isHidden: boolean;
}

const MenuSelector = ({ menuID, menuName, onDelete, onDownload, onHideShow, isHidden }: MenuSelectorProps) => {
  const { menu: selectedMenu, setMenuID } = useMenuContext();
  const navigate = useNavigate();

  const ref = useRef(null);

  useEffect(() => {
    if (selectedMenu?.menuID === menuID) {
      if (ref?.current) {
        ref.current.scrollIntoView();
      }
    }
  }, [menuID, selectedMenu?.menuID]);

  const handleMenuSelectionKeyPress = async (event: React.KeyboardEvent<HTMLDivElement>, id: number) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      setMenuID(id);
    }
  };

  const { setNodeRef, listeners, attributes, transform, transition, isDragging } = useSortable({
    id: menuID.toString(),
    transition: {
      duration: 150, // milliseconds
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
    }
  });

  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const handleEditMenu = useCallback(() => {
    navigate(`/${PAGE_EDIT_MENU}`, { state: { menuID } });
  }, [menuID, navigate]);

  return (
    <div
      ref={setNodeRef}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...attributes}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...listeners}
    >
      <div
        role="button"
        onClick={() => setMenuID(menuID)}
        onKeyPress={(event) => handleMenuSelectionKeyPress(event, menuID)}
        className={`menu-selection-name ${selectedMenu?.menuID === menuID ? 'active' : ''} ${
          !isDragging ? 'non-dragging' : ''
        } ${isHidden ? 'is-hidden' : ''}
        `}
        tabIndex={0}
        style={{ ...itemStyle, cursor: isDragging ? 'grab' : 'pointer' }}
        ref={ref}
      >
        <ContextMenuIconButton
          onEditClicked={handleEditMenu}
          onDeleteClicked={(itemID: number) => onDelete(itemID)}
          onDownloadClicked={(itemID: number) => onDownload(itemID)}
          onHideShowClicked={(itemID: number) => onHideShow(itemID)}
          itemID={menuID}
          isHidden={isHidden}
        />
        <span>{menuName}</span>
      </div>
    </div>
  );
};

export default MenuSelector;
