import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import Button from '../common/Button';
import { getStripeCustomerPortal, stripeCheckout } from '../../api/stripe';
import ChangePasswordForm from './ChangePasswordForm';
import UserInfoForm from './UserInfoForm';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { usePermissionsContext } from '../../contexts/PermissionsContext';
import { PAGE_CREATE_RESTAURANT } from '../../constants/UriConstants';

const AccountInformation = () => {
  const stripe = useStripe();
  const { dispatch } = useModalContext();
  const {
    permissions: { hasPairings, hasImageUpload }
  } = usePermissionsContext();
  const isSuper = hasPairings && hasImageUpload;
  const navigate = useNavigate();

  const getCustomerPortal = async () => {
    try {
      const url = await getStripeCustomerPortal();
      window.location.replace(url);
    } catch (err) {
      let message =
        'An unexpected error has occurred. Please email our team at info@taptabapp.com so that we can address this issue promptly.';
      if (err.response.status === 404) {
        message =
          'An active subscription has not been found. If this is not the case, please email our team at info@taptabapp.com so that we can address this issue promptly.';
      }
      openModal({
        dispatch,
        message
      });
    }
  };

  const getStripeCheckout = async () => {
    if (isSuper) {
      navigate(`/${PAGE_CREATE_RESTAURANT}`);
      return;
    }

    const id = await stripeCheckout();
    const result = await stripe.redirectToCheckout({ sessionId: id });
    if (result.error) {
      openModal({
        dispatch,
        message:
          'An unexpected error has occurred. Please email our team at info@taptabapp.com so that we can address this issue promptly.'
      });
    }
  };

  return (
    <div className="account-info-container">
      <UserInfoForm />
      <hr />
      <h2 className="account-info-input-header">CHANGE PASSWORD</h2>
      <ChangePasswordForm />
      <hr />
      <div className="account-info-stripe-container">
        <div className="account-info-current-services">
          <h2 className="account-info-input-header">CURRENT SERVICES</h2>
          <Button className="manage-services-button" onClick={getCustomerPortal}>
            MANAGE SERVICES
          </Button>
        </div>
        <div className="account-info-new-service">
          <h2 className="account-info-input-header">ADD SERVICES</h2>
          <Button className="add-service-button" onClick={getStripeCheckout}>
            ADD RESTAURANT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccountInformation;
