import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AuthProvider from './contexts/AuthContext';
import Pages from './pages';
import ModalProvider from './contexts/ModalContext';
import ErrorBoundary from './components/ErrorBoundary';
import PermissionsProvider from './contexts/PermissionsContext';
import AsyncProvider from './contexts/AsyncContext';
import CheckoutProvider from './contexts/CheckoutContext';
import AlertProvider from './contexts/AlertContext';

const App = () => (
  <BrowserRouter>
    <ModalProvider>
      <AsyncProvider>
        <AlertProvider>
          <AuthProvider>
            <PermissionsProvider>
              <CheckoutProvider>
                <ErrorBoundary>
                  <>
                    <ToastContainer />
                    <Pages />
                  </>
                </ErrorBoundary>
              </CheckoutProvider>
            </PermissionsProvider>
          </AuthProvider>
        </AlertProvider>
      </AsyncProvider>
    </ModalProvider>
  </BrowserRouter>
);

export default App;
