import React, { ReactNode } from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

interface ButtonProps {
  children: ReactNode;
  className?: string;
  onClick?: Function;
  submit?: boolean;
  isDisabled?: boolean;
  overrideCSS?: boolean;
  isLoading?: boolean;
}

const Button = ({
  children,
  className,
  onClick,
  submit,
  isDisabled = false,
  overrideCSS = false,
  isLoading = false
}: ButtonProps) => {
  const handleClick = () => {
    onClick?.();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      onClick?.();
    }
  };

  return (
    <button
      type={submit ? `submit` : `button`}
      disabled={isDisabled}
      className={`${overrideCSS ? '' : 'button'} ${className}`}
      onClick={handleClick}
      onKeyDown={handleKeyPress}
      style={{ position: 'relative', overflow: 'hidden' }}
    >
      {isLoading ? (
        <div className="button-loading-spinner">
          <LoadingSpinner size={30} />
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
