import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { InferType, object, string } from 'yup';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  ScreenReaderInstructions,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { toast } from 'react-toastify';
import Modal from '../common/Modal';
import { ModifierGroupInterface } from '../../types/ModifierGroupInterface';
import { ClosePopUpIcon } from '../../assets/svgs/icons';
import Button from '../common/Button';
import { FormikInput } from '../common/Form';
import { ModifierInterface } from '../../types/ModifierInterface';
import { useModifiersContext } from '../../contexts/ModifiersContext';
import ErrorText from '../common/Form/ErrorText';
import ModifierGroup from '../ModifierGroup';
import AvailableModifiers from '../AvailableModifiers';
import SelectedModifiers from '../SelectedModifiers';
import { sortArrayAlphabetically } from '../../utils/general';

interface ModifierGroupsModalProps {
  modifierGroups: ModifierGroupInterface[];
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  addModifierGroup: (modifierGroup: Partial<ModifierGroupInterface>) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  updateModifierGroup: (modifierGroup: ModifierGroupInterface) => Promise<void>;
  // eslint-disable-next-line no-unused-vars
  deleteModifierGroup: (id: number) => void;
  // eslint-disable-next-line no-unused-vars
  reorderModifierGroups: (modifierGroups: ModifierGroupInterface[]) => Promise<void>;
}

const ModifierGroupsModal: React.FC<ModifierGroupsModalProps> = ({
  modifierGroups,
  onClose,
  addModifierGroup,
  updateModifierGroup,
  deleteModifierGroup,
  reorderModifierGroups
}) => {
  const [currentModifierGroup, setCurrentModifierGroup] = useState<ModifierGroupInterface | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { modifiers } = useModifiersContext();

  const [availableModifiers, setAvailableModifiers] = useState<ModifierInterface[]>([]);

  const screenReaderInstructions: ScreenReaderInstructions = {
    draggable: `
    To pick up a sortable item, press the space bar.
    While sorting, use the arrow keys to move the item.
    Press space again to drop the item in its new position, or press escape to cancel.
  `
  };

  const isModifierGroupNameUnique = (name: string) => {
    if (currentModifierGroup != null) {
      return !modifierGroups
        .filter((group) => group.modifierGroupID !== currentModifierGroup.modifierGroupID)
        .some((group) => group.name.toLowerCase() === name.toLowerCase());
    }

    return !modifierGroups.some((group) => group.name.toLowerCase() === name.toLowerCase());
  };

  const ModifierGroupSchema = object({
    name: string()
      .default('')
      .required('Name is required.')
      .test(
        'is-unique',
        'Modifier group name must be unique.',
        // eslint-disable-next-line func-names
        function (value) {
          if (!isModifierGroupNameUnique(value)) {
            // eslint-disable-next-line react/no-this-in-sfc
            return this.createError({
              message: 'Modifier group name must be unique.',
              path: 'name'
            });
          }
          return true;
        }
      ),
    label: string().default('').required('Label is required.')
  });

  const handleSubmitModifierGroup = async ({ name, label }: InferType<typeof ModifierGroupSchema>) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    if (currentModifierGroup.modifiers.length === 0) {
      setErrorMessage('At least one modifier is required for a modifier group.');
      return;
    }

    try {
      if (currentModifierGroup?.modifierGroupID > -1) {
        await updateModifierGroup({
          modifierGroupID: currentModifierGroup?.modifierGroupID,
          name,
          label,
          modifiers: currentModifierGroup.modifiers
        } as ModifierGroupInterface);
      } else {
        await addModifierGroup({
          name,
          label,
          modifiers: currentModifierGroup.modifiers
        } as ModifierGroupInterface);
      }
    } catch (err) {
      if (err.response?.status === 409) {
        setErrorMessage('Modifier group name must be unique.');
      } else {
        setErrorMessage('An unexpected error has occurred. Check your input and try again.');
      }
      return;
    }
    setCurrentModifierGroup(null);
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 2
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = async ({ active, over }: DragEndEvent) => {
    try {
      if (!over) {
        return;
      }

      const oldIndex = modifierGroups.findIndex((group) => group.modifierGroupID.toString() === active.id);
      const newIndex = modifierGroups.findIndex((group) => group.modifierGroupID.toString() === over.id);

      // // if position is the same, do not send request
      if (oldIndex === newIndex) {
        return;
      }
      const reorderedModifierGroups = arrayMove(modifierGroups, oldIndex, newIndex);
      await reorderModifierGroups(reorderedModifierGroups);
    } catch (err) {
      toast.error('Reorder Failed', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const handleAddModifierGroup = () => {
    setAvailableModifiers(modifiers);
    setCurrentModifierGroup({ modifierGroupID: -1, name: '', label: '', modifiers: [] });
  };

  const handleEditModifierGroup = (modifierGroup: ModifierGroupInterface) => {
    const idsInGroup = modifierGroup.modifiers.map((mod) => mod.modifierID);
    const available = modifiers.filter((modifier) => !idsInGroup.includes(modifier.modifierID));

    setAvailableModifiers(available);
    setCurrentModifierGroup(modifierGroup);
  };

  const handleAddModifier = (modifier: ModifierInterface) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    const selectedModifiers = currentModifierGroup?.modifiers.slice();
    selectedModifiers.push(modifier);

    const available = availableModifiers.slice();
    const idx = availableModifiers?.findIndex((mod) => mod.modifierID === modifier.modifierID);
    available.splice(idx, 1);

    setAvailableModifiers(available);
    setCurrentModifierGroup({
      ...currentModifierGroup,
      modifiers: selectedModifiers
    });
  };

  const handleRemoveModifier = (modifier: ModifierInterface) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    const selectedModifiers = currentModifierGroup?.modifiers.slice();
    const idx = selectedModifiers?.findIndex((mod) => mod.modifierID === modifier.modifierID);
    selectedModifiers.splice(idx, 1);

    const available = availableModifiers.slice();
    available.push(modifier);
    available.sort();

    setAvailableModifiers(sortArrayAlphabetically(available, 'name'));
    setCurrentModifierGroup({
      ...currentModifierGroup,
      modifiers: selectedModifiers
    });
  };

  const handleReorderModifier = (mods: ModifierInterface[]) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    setCurrentModifierGroup({
      ...currentModifierGroup,
      modifiers: mods
    });
  };

  return (
    <Modal className="modifier-groups-modal">
      <div className="modal-header">
        <ClosePopUpIcon onIconClicked={onClose} />
        <h1 className="modal-title">MODIFIER GROUPS</h1>
      </div>
      <div className="modal-body">
        {currentModifierGroup == null ? (
          <div className="modifier-group-display-container">
            {modifierGroups.length > 0 ? (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                screenReaderInstructions={screenReaderInstructions}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
              >
                <ul className="modifier-group-display-list">
                  <SortableContext
                    strategy={verticalListSortingStrategy}
                    items={modifierGroups?.map((modifierGroup) => modifierGroup.modifierGroupID.toString())}
                  >
                    {modifierGroups.map((modifierGroup: ModifierGroupInterface) => (
                      <ModifierGroup
                        key={modifierGroup.modifierGroupID}
                        modifierGroup={modifierGroup}
                        onDeleteClick={(modifierGroupID) => deleteModifierGroup(modifierGroupID)}
                        onEditClick={handleEditModifierGroup}
                        allowSorting={modifierGroups.length > 1} // <-- New prop added
                      />
                    ))}
                  </SortableContext>
                </ul>
              </DndContext>
            ) : (
              <p className="no-modifier-group-copy">
                Get started by creating your first modifier group. Click &apos;Add New Modifier Group&apos; to begin.
              </p>
            )}
            <Button className="create-modifier-group-button" onClick={handleAddModifierGroup}>
              Add New Modifier Group
            </Button>
          </div>
        ) : (
          <div className="modifier-group-builder-container">
            <div className="modal-message">
              Please name this modifier group - i.e., Spiciness Level, Temperature, etc.
            </div>
            <Formik
              initialValues={{
                name: currentModifierGroup?.name,
                label: currentModifierGroup?.label
              }}
              validate={(values) => {
                try {
                  ModifierGroupSchema.validateSync(values, { context: { modifierGroups } });
                  return {}; // <-- Return an empty object when validation succeeds
                } catch (error) {
                  if (error.message.toLowerCase().includes('label')) {
                    return {
                      label: error.message
                    };
                  }
                  if (error.message.toLowerCase().includes('name')) {
                    return {
                      name: error.message
                    };
                  }
                  return {
                    name: error.message,
                    label: error.message
                  };
                }
              }}
              validationSchema={ModifierGroupSchema}
              onSubmit={handleSubmitModifierGroup}
            >
              <Form className="modifier-group-form">
                <div className="modifier-group-inputs">
                  <FormikInput className="modifier-group-input" name="name" label="INTERNAL NAME" />
                  <FormikInput className="modifier-group-input" name="label" label="LABEL" />
                </div>
                {errorMessage && <ErrorText error={errorMessage} />}
                <AvailableModifiers modifiers={availableModifiers} onAddModifier={handleAddModifier} />
                <SelectedModifiers
                  modifiers={currentModifierGroup?.modifiers}
                  onRemoveModifier={handleRemoveModifier}
                  onReorderModifiers={handleReorderModifier}
                />
                <div className="modifier-group-builder-buttons">
                  <Button
                    className="cancel-modifier-group-button"
                    onClick={() => {
                      setCurrentModifierGroup(null);
                      setAvailableModifiers([]);
                    }}
                  >
                    CANCEL
                  </Button>
                  <Button className="submit-modifier-group-button" submit>
                    SUBMIT
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModifierGroupsModal;
