import Modal from '../common/Modal';
import Button from '../common/Button';

interface DeleteItemModalProps {
  title: string;
  message: string;
  warning?: string;
  error?: string;
  isOpen: boolean;
  onCancel: Function;
  onConfirm: Function;
}
const DeleteItemModal = ({ title, message, warning, error, isOpen, onConfirm, onCancel }: DeleteItemModalProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal>
      <div className="modal-header">
        <h1 className="modal-title">{title}</h1>
      </div>
      <div className="modal-body">
        <div className="modal-error">{error}</div>
        <div className="modal-message">{message}</div>
        {warning && <div className="modal-warning">{warning}</div>}
      </div>
      <div className="modal-footer">
        <Button onClick={onCancel} className="modal-cancel-button">
          CANCEL
        </Button>
        <Button onClick={onConfirm} className="modal-confirm-button">
          DELETE
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteItemModal;
