import { useEffect } from 'react';
import { useAnnouncementsContext } from '../../../contexts/AnnouncementsContext';
import PromotionsTopBar from '../../../components/PromotionsTopBar';
import PromotionsTable from '../../../components/PromotionsTable';
import useResponsive from '../../../hooks/useResponsive';

const PromotionsPage = () => {
  const { loadAnnouncements } = useAnnouncementsContext();
  const { isDesktop } = useResponsive();

  useEffect(() => {
    // only want to load the restaurants announcements if promotions page is being utilized
    // call to load those announcements on initial load
    loadAnnouncements();
  }, [loadAnnouncements]);

  return (
    <div className="promotions-page-container">
      <PromotionsTopBar isDesktop={isDesktop} />
      <PromotionsTable isDesktop={isDesktop} />
    </div>
  );
};

export default PromotionsPage;
