import { Oval } from 'react-loader-spinner';

interface LoadingSpinnerProps {
  primary?: 'black' | 'white';
  secondary?: 'black' | 'white';
  size?: number;
}
const LoadingSpinner = ({ primary = 'black', secondary = 'white', size = 100 }: LoadingSpinnerProps) => (
  <Oval
    ariaLabel="loading-indicator"
    height={size}
    width={size}
    strokeWidth={5}
    strokeWidthSecondary={1}
    color={primary}
    secondaryColor={secondary}
  />
);

export default LoadingSpinner;
