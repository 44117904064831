import { MutableRefObject, useEffect, useState } from 'react';

/**
 * Hook that returns a flag indicating whether a DOM Element has clicks outside of the passed ref
 * Currently being utilized by the Context Menu Tooltip
 */
const useOutsideEventSensor = (ref: MutableRefObject<any>): boolean => {
  const [hasClickedOutside, setHasClickedOutside] = useState<boolean>(false);
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setHasClickedOutside(true);
      } else if (hasClickedOutside) {
        setHasClickedOutside(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, hasClickedOutside, setHasClickedOutside]);

  return hasClickedOutside;
};

export default useOutsideEventSensor;
