import { ReactElement } from 'react';
import { ClosePopUpIcon } from '../../assets/svgs/icons';
import Button from '../common/Button';
import Modal from '../common/Modal';

interface SelectorModalProps {
  title: string;
  submitText: string;
  createText: string;
  children: ReactElement | ReactElement[];
  hasValues: boolean;
  onSubmit: Function;
  onCreate: Function;
  onCancel: Function;
}

const SelectorModal = ({
  title,
  submitText,
  createText,
  children,
  hasValues,
  onCancel,
  onSubmit,
  onCreate
}: SelectorModalProps) => (
  <Modal className="selector-modal">
    <div className="modal-header">
      <ClosePopUpIcon onIconClicked={onCancel} />
      <h1 className="modal-title">{title}</h1>
    </div>
    <div className="modal-body">
      {children}
      <div className="modal-footer">
        <Button onClick={onCancel} className="modal-cancel-button">
          CANCEL
        </Button>
        {hasValues ? (
          <Button onClick={onSubmit} className="modal-confirm-button">
            {submitText}
          </Button>
        ) : (
          <Button onClick={onCreate} className="modal-create-button">
            {createText}
          </Button>
        )}
      </div>
    </div>
  </Modal>
);

export default SelectorModal;
