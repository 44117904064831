import ErrorModal from '../ErrorModal/ErrorModal';
import { useAuthContext } from '../../contexts/AuthContext/AuthContext';

interface ErrorBoundaryModalProps {
  onDismiss: Function;
}
const ErrorBoundaryModal = ({ onDismiss }: ErrorBoundaryModalProps) => {
  const { removeToken } = useAuthContext();

  const handleDismiss = () => {
    onDismiss();
    removeToken();
  };

  return <ErrorModal onDismiss={handleDismiss} />;
};

export default ErrorBoundaryModal;
