import { createContext, ReactElement, useMemo, useContext, useState, useEffect } from 'react';
import { useRestaurantContext } from '../RestaurantContext';
import { DishCharacteristicInterface } from '../../types/DishCharacteristicInterface';
import getRestrictions from '../../api/restrictions';

interface CharacteristicsContextInterface {
  hasError: boolean;
  isLoading: boolean;
  characteristics: DishCharacteristicInterface[];
}

interface CharacteristicsProviderInterface {
  children: ReactElement;
}

const CharacteristicsContext = createContext<CharacteristicsContextInterface>({
  hasError: false,
  isLoading: false,
  characteristics: null
});

const CharacteristicsProvider = ({ children }: CharacteristicsProviderInterface) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [characteristics, setCharacteristics] = useState<DishCharacteristicInterface[]>([]);
  const [currentRestaurantID, setCurrentRestaurantID] = useState<number>(-1);

  const { currentRestaurant } = useRestaurantContext();

  useEffect(() => {
    let canceled = false;
    if (currentRestaurant && currentRestaurant.restaurantID !== currentRestaurantID) {
      setIsLoading(true);

      getRestrictions()
        .then((dishCharacteristics: DishCharacteristicInterface[]) => {
          if (canceled) return;

          setCharacteristics(dishCharacteristics);
          setCurrentRestaurantID(currentRestaurant.restaurantID);
          setIsLoading(false);
        })
        .catch((error) => {
          if (canceled) return;
          if (error.response.status !== 401) {
            // on non auth related failure indicate failure has occurred
            setHasError(true);
          }
          setIsLoading(false);
        });
    }

    return () => {
      canceled = true;
    };
  }, [currentRestaurant, currentRestaurantID]);

  const providerValue = useMemo(
    () => ({ hasError, isLoading, characteristics }),
    [hasError, isLoading, characteristics]
  );
  return <CharacteristicsContext.Provider value={providerValue}>{children}</CharacteristicsContext.Provider>;
};

const useCharacteristicsContext = () => {
  const context = useContext(CharacteristicsContext);
  if (!context) {
    throw new Error(`CharacteristicsContext must be used within the CharacteristicsProvider component`);
  }
  return context;
};

export { CharacteristicsProvider as default, useCharacteristicsContext };
