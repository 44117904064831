import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Dropdown } from '../../../components/common/Form';
import Button from '../../../components/common/Button';
import { useMenuLayoutContext } from '../../../contexts/MenuLayoutContext';
import { MENU_LAYOUT_IMAGES, MENU_LAYOUTS } from '../../../constants/MenuLayoutConstants';
import { sleep } from '../../../utils/general';

interface RestaurantLayoutPageProps {
  formik: any;
  onNext: Function;
  onBack: Function;
}

const RestaurantLayoutPage = ({ formik, onNext, onBack }: RestaurantLayoutPageProps) => {
  const { menuLayouts } = useMenuLayoutContext();
  const [loading, setLoading] = useState<boolean>(true);

  const handleLayoutChanged = (value: string) => {
    formik.setFieldValue('layoutID', parseInt(value, 10));
  };

  return (
    <div className="restaurant-layout-page">
      <p className="restaurant-layout-copy">Select a menu layout from TapTab’s menu layout options.</p>
      {menuLayouts && (
        <>
          <Dropdown
            label="MENU LAYOUT"
            className="restaurant-wizard-layout-select"
            onChange={handleLayoutChanged}
            value={formik.values.layoutID}
            values={menuLayouts?.map((menuLayout) => ({
              label: MENU_LAYOUTS[menuLayout.layoutID - 1],
              value: menuLayout.layoutID
            }))}
          />
          {loading && <Skeleton className="restaurant-layout-preview" height="570px" width="260px" />}
          <img
            src={MENU_LAYOUT_IMAGES[formik.values.layoutID - 1]}
            className="restaurant-layout-preview"
            style={{ display: !loading ? 'initial' : 'none' }}
            alt={MENU_LAYOUTS[formik.values.layoutID - 1]}
            onLoad={async () => {
              // sleep to make animations look smoother
              await sleep(500);
              setLoading(false);
            }}
          />
        </>
      )}
      <Button className="restaurant-wizard-back-button" onClick={onBack}>
        BACK
      </Button>
      <Button className="restaurant-wizard-next-button" onClick={onNext}>
        NEXT
      </Button>
    </div>
  );
};

export default RestaurantLayoutPage;
