import React, { useEffect, useState } from 'react';

interface WizardProgressBarProps {
  currentPage: string;
  steps: string[];
  navigation?: boolean;
  handleNavUpdate?: Function;
}

const WizardProgressBar = ({ currentPage, steps, handleNavUpdate, navigation = false }: WizardProgressBarProps) => {
  const [activeSteps, setActiveSteps] = useState<string[]>([]);
  const [inactiveSteps, setInactiveSteps] = useState<string[]>([]);

  useEffect(() => {
    setActiveSteps(() => {
      const index = steps.indexOf(currentPage);
      return steps.slice(0, index + 1);
    });
    setInactiveSteps(() => {
      const index = steps.indexOf(currentPage);
      return steps.slice(index + 1, steps.length + 1);
    });
  }, [steps, currentPage]);

  const handleStepClicked = (index: number) => {
    if (navigation) {
      handleNavUpdate?.(steps[index]);
    }
  };

  const handleStepKeyPress = async (event: React.KeyboardEvent<HTMLSpanElement>, index: number) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      if (navigation) {
        handleNavUpdate?.(steps[index]);
      }
    }
  };

  return (
    <div className="wizard-progress-bar-container">
      <div className="wizard-progress-bar-copy" style={{ columnGap: steps.length > 3 ? '60px' : '80px' }}>
        {steps.map((step, index) => (
          <span
            role="link"
            style={{ cursor: navigation ? 'pointer' : 'initial' }}
            tabIndex={0}
            onClick={() => handleStepClicked(index)}
            onKeyDown={(event) => handleStepKeyPress(event, index)}
            key={step}
            className={step === currentPage ? 'current' : ''}
          >
            {step}
          </span>
        ))}
      </div>
      <div className="wizard-progress-bar" style={{ width: steps.length > 4 ? `${steps.length * 110}px` : '500px' }}>
        {activeSteps.map((step, index) => (
          <span style={{ width: `${100 / steps.length}%` }} key={step + index} aria-label={step} className="active" />
        ))}
        {inactiveSteps.map((step, index) => (
          <span style={{ width: `${100 / steps.length}%` }} key={step + index} aria-label={step} />
        ))}
      </div>
    </div>
  );
};

export default WizardProgressBar;
