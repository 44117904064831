export const AlertActions = {
  DISPLAY_ALERT: 'DISPLAY_ALERT',
  HIDE_ALERT: 'HIDE_ALERT'
};

export const AsyncActions = {
  DISPLAY_LOADING: 'DISPLAY_LOADING',
  HIDE_LOADING: 'HIDE_LOADING'
};

export const ModalActions = {
  DISMISS_MODAL: 'DISMISS_MODAL',
  OPEN_MODAL: 'OPEN_MODAL'
};
