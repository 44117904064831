import { useState } from 'react';
import LoadingSpinner from '../common/LoadingSpinner';
import { useMediaLibraryContext } from '../../contexts/MediaLibraryContext';
import MediaLibraryItem from '../MediaLibraryItem';
import { deleteMedia } from '../../api/media';
import MediaLibraryUploader from '../MediaLibraryUploader';
import { MediaLibraryInterface } from '../../types/MediaInterface';
import DeleteItemModal from '../DeleteItemModal';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { useMenuContext } from '../../contexts/MenuContext';

interface MediaLibraryContentProps {
  mediaTypeFilter: 'all' | 'image' | 'video';
}

const MediaLibraryContent = ({ mediaTypeFilter }: MediaLibraryContentProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [mediaToDelete, setMediaToDelete] = useState<number | null>(null);

  const { media, isLoading, addMedia, removeMedia } = useMediaLibraryContext();
  const { removeMediaFromMenuItem } = useMenuContext();
  const { dispatch: modalDispatch } = useModalContext();

  if (isLoading) {
    return (
      <div className="media-library-loading">
        <LoadingSpinner />
      </div>
    );
  }

  const handleMediaDelete = async () => {
    try {
      await deleteMedia(mediaToDelete);
      removeMedia([mediaToDelete]);
      removeMediaFromMenuItem(mediaToDelete);
      setShowDeleteModal(false);
      setMediaToDelete(null);
    } catch (err) {
      let message = 'An unexpected error has occurred. Please check your selected files and try again.';

      if (err.response.status === 409) {
        message =
          "The image you're trying to delete is currently linked to an item that requires an image. Please detach the image from that item first, then you'll be able to delete it with no trouble.";
      }
      openModal({
        dispatch: modalDispatch,
        message
      });
    }
  };

  const handleMediaUpload = (uploaded: MediaLibraryInterface[]) => {
    addMedia(uploaded);
  };

  const filteredMedia = media.filter(
    (item) =>
      mediaTypeFilter === 'all' ||
      (mediaTypeFilter === 'image' && item.type === 'image') ||
      (mediaTypeFilter === 'video' && item.type === 'video')
  );

  const handleDeleteClicked = (mediaID: number) => {
    setMediaToDelete(mediaID);
    setShowDeleteModal(true);
  };

  const handleDeleteCanceled = () => {
    setShowDeleteModal(false);
    setMediaToDelete(null);
  };

  return (
    <>
      <div className="media-library-content-container">
        <MediaLibraryUploader mediaType={mediaTypeFilter} onUpload={handleMediaUpload} />
        {filteredMedia.map((_media) => (
          <MediaLibraryItem key={_media.mediaID} media={_media} onDelete={handleDeleteClicked} />
        ))}
      </div>
      <DeleteItemModal
        title="DELETE ITEM"
        message="Please confirm that you would like to delete this item."
        isOpen={showDeleteModal}
        onCancel={handleDeleteCanceled}
        onConfirm={handleMediaDelete}
      />
    </>
  );
};

export default MediaLibraryContent;
