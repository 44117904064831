import { createContext, ReactElement, useMemo, useContext, useReducer } from 'react';
import { AsyncActions } from '../../constants/ContextActions';

interface AsyncStateInterface {
  message: string;
  isLoading: boolean;
}

interface AsyncActionInterface {
  message: string;
  type: string;
}

interface AsyncContextInterface {
  state: AsyncStateInterface;
  dispatch: any;
}

interface AsyncProviderInterface {
  children: ReactElement;
}

const initialState = {
  message: '',
  isLoading: false
};

const AsyncContext = createContext<AsyncContextInterface>({
  dispatch: () => {},
  state: initialState
});

const asyncReducer = (state: AsyncStateInterface, action: AsyncActionInterface) => {
  switch (action.type) {
    case AsyncActions.DISPLAY_LOADING:
      return { isLoading: true, message: action.message };
    case AsyncActions.HIDE_LOADING:
      return initialState;
    default: {
      return state;
    }
  }
};

const AsyncProvider = ({ children }: AsyncProviderInterface) => {
  const [state, dispatch] = useReducer(asyncReducer, initialState);

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return <AsyncContext.Provider value={providerValue}>{children}</AsyncContext.Provider>;
};

interface DisplayLoadingProps {
  dispatch: any;
  message?: string;
}

const displayLoading = ({ dispatch, message }: DisplayLoadingProps) => {
  dispatch({ type: AsyncActions.DISPLAY_LOADING, message });
};

const hideLoading = (dispatch: any) => {
  dispatch({ type: AsyncActions.HIDE_LOADING });
};

const useAsyncContext = () => {
  const context = useContext(AsyncContext);
  if (!context) {
    throw new Error(`AsyncContext must be used within the AsyncProvider component`);
  }
  return context;
};

export { AsyncProvider as default, useAsyncContext, displayLoading, hideLoading };
