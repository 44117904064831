import React, { MutableRefObject } from 'react';
import ReactDOM from 'react-dom';
import { TagInterface } from '../../types/TagInterface';
import Tooltip from '../common/Tooltip';
import TagMenuItem from './TagMenuItem';

interface TagSelectMenuProps {
  filterInput: string;
  hidden: boolean;
  selectedTag?: TagInterface;
  tags: TagInterface[];
  onSelectTag: Function;
  onCreateTag: Function;
  onMenuBlur: Function;
  inputRef: MutableRefObject<any>;
  children: any;
}

const TagSelectMenu = ({
  filterInput,
  hidden,
  selectedTag,
  inputRef,
  tags,
  onSelectTag,
  onCreateTag,
  onMenuBlur,
  children
}: TagSelectMenuProps) => {
  const handleTagSelection = (tag: TagInterface) => {
    onSelectTag(tag);
  };

  const handleCreateTag = () => {
    onCreateTag();
  };

  const handleCreateTagKeyPress = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      handleCreateTag();
    }
  };

  return ReactDOM.createPortal(
    <Tooltip
      className="tag-select-menu-container"
      iconRef={inputRef}
      hidden={hidden}
      onBlur={onMenuBlur}
      width={250}
      offset={0}
      topOffset={-65}
    >
      <div className="tag-select-menu">
        {children}
        <menu className="tag-select-menu-items">
          {tags?.map((tag) => (
            <TagMenuItem
              key={tag.tagID}
              tag={tag}
              isSelected={tag.tagID === selectedTag?.tagID}
              onSelectTag={handleTagSelection}
            />
          ))}
          {filterInput?.length > 1 && (
            <li role="none">
              <a tabIndex={0} role="menuitem" onClick={handleCreateTag} onKeyPress={handleCreateTagKeyPress}>
                <span>Create new TapTag &quot;{filterInput}&quot;</span>
              </a>
            </li>
          )}
        </menu>
      </div>
    </Tooltip>,
    document.querySelector('#tooltip-root')
  );
};

export default TagSelectMenu;
