import { createContext, ReactElement, useMemo, useContext, useState, useEffect, useCallback } from 'react';
import { useRestaurantContext } from '../RestaurantContext';
import { PairingInterface } from '../../types/PairingInterface';
import { getDrinkItems } from '../../api/drinkItem';
import { sortArrayAlphabetically } from '../../utils/general';

interface DrinkItemsContextInterface {
  hasError: boolean;
  isLoading: boolean;
  drinks: PairingInterface[];
  addDrink: Function;
  removeDrink: Function;
  updateDrink: Function;
}

interface DrinkItemsProviderInterface {
  children: ReactElement;
}

const DrinkItemsContext = createContext<DrinkItemsContextInterface>({
  hasError: false,
  isLoading: false,
  drinks: [],
  addDrink: () => {},
  removeDrink: () => {},
  updateDrink: () => {}
});

const DrinkItemsProvider = ({ children }: DrinkItemsProviderInterface) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [drinks, setDrinks] = useState<PairingInterface[]>([]);
  const [currentRestaurantID, setCurrentRestaurantID] = useState<number>(-1);

  const { currentRestaurant } = useRestaurantContext();

  useEffect(() => {
    let canceled = false;
    if (currentRestaurant && currentRestaurant?.restaurantID !== currentRestaurantID) {
      setIsLoading(true);

      getDrinkItems()
        .then((data: PairingInterface[]) => {
          if (canceled) return;
          setDrinks(data);
          setCurrentRestaurantID(currentRestaurant.restaurantID);
          setIsLoading(false);
        })
        .catch((error) => {
          if (canceled) return;
          if (error.response.status !== 401) {
            // on non auth related failure indicate failure has occurred
            setHasError(true);
          }
          setIsLoading(false);
        });
    }

    return () => {
      canceled = true;
    };
  }, [currentRestaurant, currentRestaurantID]);

  const addDrink = useCallback(
    (drink: PairingInterface) => {
      const _drinks = drinks.slice();
      _drinks.push(drink);
      setDrinks(sortArrayAlphabetically(_drinks, 'name'));
    },
    [drinks]
  );

  const updateDrink = useCallback(
    (drink: PairingInterface) => {
      const compareDrinks = (drink1: PairingInterface, drink2: PairingInterface): boolean => {
        let isSame = true;

        if (drink1.name !== drink2.name || drink1.isHidden !== drink2.isHidden) {
          isSame = false;
        }

        return isSame;
      };
      const _drinks = drinks.slice();
      const drinkIdx = _drinks.findIndex((_drink) => _drink.drinkItemID === drink.drinkItemID);
      if (drinkIdx === -1) {
        throw new Error(`Drink with id: ${drink.drinkItemID} does not exist.`);
      }

      if (!compareDrinks(drink, _drinks[drinkIdx])) {
        _drinks.splice(drinkIdx, 1, drink);
        setDrinks(sortArrayAlphabetically(_drinks, 'name'));
      }
    },
    [drinks]
  );

  const removeDrink = useCallback(
    (drinkItemID: number) => {
      const _drinks = drinks.slice();
      const drinkIdx = _drinks.findIndex((drink) => drink.drinkItemID === drinkItemID);

      if (drinkIdx !== -1) {
        _drinks.splice(drinkIdx, 1);
        setDrinks(_drinks);
      }
    },
    [drinks]
  );

  const providerValue = useMemo(
    () => ({ hasError, isLoading, drinks, addDrink, removeDrink, updateDrink }),
    [hasError, isLoading, drinks, addDrink, removeDrink, updateDrink]
  );
  return <DrinkItemsContext.Provider value={providerValue}>{children}</DrinkItemsContext.Provider>;
};

const useDrinkItemsContext = () => {
  const context = useContext(DrinkItemsContext);
  if (!context) {
    throw new Error(`DrinkItemsContext must be used within the DrinkItemsProvider component`);
  }
  return context;
};

export { DrinkItemsProvider as default, useDrinkItemsContext };
