import React, { useState } from 'react';
import Button from '../common/Button';
import AnnouncementModal from '../AnnouncementModal';

interface PromotionsTopBarProps {
  isDesktop?: boolean;
}

const PromotionsTopBar = ({ isDesktop }: PromotionsTopBarProps) => {
  const [showAnnouncementModal, setShowAnnouncementModal] = useState<boolean>(false);
  return isDesktop ? (
    <div className="promotions-top-bar-container shadow-[0_0_20px_6px_rgb(0,0,0,0.1)]">
      {showAnnouncementModal && (
        <AnnouncementModal
          isEdit={false}
          onSubmit={() => setShowAnnouncementModal(false)}
          onCancel={() => setShowAnnouncementModal(false)}
        />
      )}
      <Button className="add-promotion-button" onClick={() => setShowAnnouncementModal(true)}>
        ADD PROMOTION
      </Button>
    </div>
  ) : (
    <div className="promotions-top-bar-container ">
      {showAnnouncementModal && (
        <AnnouncementModal
          isEdit={false}
          onSubmit={() => setShowAnnouncementModal(false)}
          onCancel={() => setShowAnnouncementModal(false)}
        />
      )}
      <h1 className="promotions-top-bar-title">PROMOTIONS</h1>
      <Button className="add-promotion-button" onClick={() => setShowAnnouncementModal(true)}>
        ADD PROMOTION
      </Button>
    </div>
  );
};

export default PromotionsTopBar;
