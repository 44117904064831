import { Dropdown, ErrorText, Input } from '../../../components/common/Form';
import Button from '../../../components/common/Button';
import { STATES } from '../../../constants/StatesConstants';

interface RestaurantLocationPageProps {
  formik: any;
  onNext: Function;
  onBack: Function;
}

const RestaurantLocationPage = ({ formik, onNext, onBack }: RestaurantLocationPageProps) => {
  const handleNextClicked = () => {
    formik.setTouched(
      { address: { address1: true, city: true, state: true, postCode: true, governingDistrict: true } },
      true
    );

    const hasRequiredValues =
      !!formik.values.address.address1 &&
      !!formik.values.address.city &&
      !!formik.values.address.postCode &&
      formik.values.address.governingDistrict !== '';
    const hasNoErrors =
      !formik.errors.address?.address1 &&
      !formik.errors.address?.city &&
      !formik.errors.address?.postCode &&
      formik.values.address.governingDistrict !== '';
    if (hasRequiredValues && hasNoErrors) {
      onNext?.();
    }
  };

  const handleStateChanged = (value: string) => {
    formik.setFieldValue('address.governingDistrict', value);
  };

  return (
    <div className="restaurant-location-page">
      <div className="restaurant-wizard-address-inputs">
        <Input
          name="address.address1"
          label="STREET ADDRESS 1"
          className="restaurant-wizard-street-address1-input"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.address.address1}
          touched={formik.touched?.address?.address1}
          error={formik.errors?.address?.address1}
        />
        <Input
          name="address.address2"
          label="STREET ADDRESS 2"
          className="restaurant-wizard-street-address2-input"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.address.address2}
          touched={formik.touched?.address?.address2}
          error={formik.errors?.address?.address2}
        />
      </div>
      <Input
        name="address.city"
        label="CITY"
        className="restaurant-wizard-city-input"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.address.city}
        touched={formik.touched?.address?.city}
        error={formik.errors?.address?.city}
      />
      <div className="restaurant-wizard-state-select">
        <Dropdown
          label="STATE"
          onChange={handleStateChanged}
          value={formik.values.address?.governingDistrict}
          values={[{ label: '', value: '' }, ...STATES]}
        />
        {formik.touched.address?.governingDistrict && <ErrorText error={formik.errors.address?.governingDistrict} />}
      </div>
      <Input
        name="address.postCode"
        label="ZIP"
        className="restaurant-wizard-zip-code-input"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.address.postCode}
        touched={formik.touched?.address?.postCode}
        error={formik.errors?.address?.postCode}
      />
      <Button className="restaurant-wizard-back-button" onClick={onBack}>
        BACK
      </Button>
      <Button className="restaurant-wizard-next-button" onClick={handleNextClicked}>
        NEXT
      </Button>
    </div>
  );
};

export default RestaurantLocationPage;
