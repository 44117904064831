import { ReactNode } from 'react';
import ErrorText from '../ErrorText';
import Label from '../Label';

interface TextAreaProps {
  className?: string;
  label?: string | ReactNode;
  name: string;
  message?: ReactNode;
  placeholder?: string;
  onChange: any;
  onBlur: any;
  value: any;
  touched: boolean;
  error: string;
}

const TextArea = ({
  value,
  className,
  label,
  message,
  name,
  placeholder,
  onChange,
  onBlur,
  touched,
  error
}: TextAreaProps) => (
  <div className={className}>
    <Label className="tracking-[1.1375px]" htmlFor={name} label={label} />
    <div className="mt-2 text-neutral-500">{message}</div>
    <div className="h-3/5">
      <textarea
        value={value}
        name={name}
        className="w-full h-full p-4 resize-none text-sm rounded-sm border border-neutral-400"
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
    {touched && error && <ErrorText error={error} />}
  </div>
);

export default TextArea;
