import { ColDef } from 'ag-grid-community';
import ItemTable from '../ItemTable';
import PriceCell from '../MenuItemTable/cells/PriceCell';
import { useModifiersContext } from '../../contexts/ModifiersContext';
import DescriptionCell from '../MenuItemTable/cells/DescriptionCell';
import ModifierNameCell from '../MenuItemTable/cells/ModifierNameCell';
import MobileItemTable from '../MobileItemTable';

interface ModifiersTableProps {
  isDesktop?: boolean;
}

const ModifiersTable = ({ isDesktop }: ModifiersTableProps) => {
  const { modifiers } = useModifiersContext();

  const nameCellGetter = (params: any) => ({
    imageURL: params.data.imageURL,
    name: params.data.name,
    modifierID: params.data.modifierID,
    price: params.data.price?.toString() || '',
    description: params.data.description || ''
  });

  const nameComparator = (values1: any, values2: any) => {
    if (values1 === values2) return 0;
    return values1 > values2 ? 1 : -1;
  };

  const descriptionCellGetter = (params: any) => ({
    description: params.data.description || ''
  });

  const minWidth = isDesktop ? 80 : 270;

  const COLUMNS: ColDef[] = isDesktop
    ? [
        {
          headerName: 'NAME',
          valueGetter: nameCellGetter,
          headerClass: 'name-header',
          cellRenderer: ModifierNameCell,
          minWidth,
          sortable: true,
          comparator: nameComparator,
          wrapText: true,
          autoHeight: true,
          suppressMovable: true
        },

        {
          headerName: 'DESCRIPTION',
          valueGetter: descriptionCellGetter,
          headerClass: 'custom-header',
          cellRenderer: DescriptionCell,
          minWidth: 200,
          suppressMovable: true,
          wrapText: true,
          autoHeight: true
        },
        {
          headerName: 'PRICE',
          field: 'price',
          headerClass: 'custom-header',
          cellRenderer: PriceCell,
          minWidth: 80,
          suppressMovable: true
        }
      ]
    : [
        {
          headerName: 'NAME',
          valueGetter: nameCellGetter,
          headerClass: 'name-header',
          cellRenderer: ModifierNameCell,
          minWidth,
          sortable: true,
          comparator: nameComparator,
          wrapText: true,
          autoHeight: true,
          suppressMovable: true
        },
        {
          headerName: 'PRICE',
          field: 'price',
          headerClass: 'custom-header',
          cellRenderer: PriceCell,
          minWidth: 80,
          suppressMovable: true
        }
      ];

  return isDesktop ? (
    <ItemTable className="modifiers-table-container" items={modifiers} columns={COLUMNS} draggable={false} />
  ) : (
    <MobileItemTable
      className="mobile-modifiers-table-container"
      items={modifiers}
      columns={COLUMNS}
      draggable={false}
      rowHeight={75}
    />
  );
};

export default ModifiersTable;
