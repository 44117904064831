import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addMethod, array, boolean, InferType, object, string } from 'yup';
import { createDrinkItem, deleteDrinkItem, editDrinkItem } from '../../../api/drinkItem';
import {
  createMenuItem,
  deleteMenuItem,
  editMenuItem,
  linkMediaToMenuItem,
  linkModifierGroupsToMenuItem,
  linkRestrictionsToMenuItem,
  linkTagsToMenuItem,
  pairMenuItem
} from '../../../api/menuItem';
import WizardHeader from '../../../components/WizardHeader';
import { PAGE_MENUS } from '../../../constants/UriConstants';
import { useMenuContext } from '../../../contexts/MenuContext';
import { openModal, useModalContext } from '../../../contexts/ModalContext';
import { ItemSizeInterface } from '../../../types/ItemSizeInterface';
import { CreateMenuItemInterface, DetailedMenuItemInterface } from '../../../types/MenuItemInterface';
import WizardProgressBar from '../../../components/WizardProgressBar/WizardProgressBar';
import ItemBasicInfoPage from './ItemBasicInfoPage';
import ItemAdditionalInfoPage from './ItemAdditionalInfoPage';
import { useCharacteristicsContext } from '../../../contexts/CharacteristicsContext';
import { compareArrays } from '../../../utils/general';
import { useRestaurantContext } from '../../../contexts/RestaurantContext';
import { useTagsContext } from '../../../contexts/TagsContext';
import { useDrinkItemsContext } from '../../../contexts/DrinkItemsContext';
import { displayLoading, hideLoading, useAsyncContext } from '../../../contexts/AsyncContext';
import { MenuItemMediaInterface } from '../../../types/MediaInterface';
import { TagInterface } from '../../../types/TagInterface';
import { ModifierGroupInterface } from '../../../types/ModifierGroupInterface';
import {
  createModifierGroup,
  deleteModifierGroup,
  editModifierGroup,
  linkModifiersToModifierGroup
} from '../../../api/modifierGroups';
import { PairingInterface } from '../../../types/PairingInterface';
import { DishCharacteristicInterface } from '../../../types/DishCharacteristicInterface';
import { useMediaLibraryContext } from '../../../contexts/MediaLibraryContext';

export const MultiPriceSchema = object({
  label: string().default('').required('Size is required.'),
  price: string()
    .default('')
    .required('Price is required.')
    .test('price-is-not-valid', 'Price must be greater than 0.', (value) => {
      const parsed = parseInt(value.replace(/\./, '').replace(/\$/, ''), 10);
      if (Number.isNaN(parsed)) {
        return true;
      }
      return parsed > 0;
    }),
  isCustom: boolean().default(false)
});

addMethod(array, 'unique', function isUnique(message, propertyName, mapper = (a: any) => a) {
  return this.test('unique', message, (list, { createError, path, parent }) => {
    if (!parent?.displayPrices) {
      return true;
    }

    const set = new Set(list.map(mapper));
    if (list.length === set.size) {
      return true;
    }
    const idx = list.findIndex((l, i) => mapper(l) !== Array.from(set)[i]);
    return createError({ path: `${path}.${idx}.${propertyName}`, message });
  });
});

const MenuItemInfoSchema = object({
  displayPrices: boolean().default(false),
  name: string().default('').required('Name is required.'),
  price: array()
    .min(1, 'At least one price is required.')
    .of(MultiPriceSchema)
    .default([MultiPriceSchema.cast({})])
    .unique('Size must be a unique value.', 'label', (a: any) => a.label.toLowerCase()),
  description: string().default(''),
  calories: string().default('')
});

interface MenuItemWizardNavState {
  menuItem: CreateMenuItemInterface;
  menuSectionID: number;
  menuID: number;
}

const MenuItemWizard = () => {
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const {
    menuItem: { menuItemID: id },
    menuSectionID: selectedMenuSectionID,
    menuID: selectedMenuID
  } = (state as MenuItemWizardNavState) || { menuItem: { menuItemID: null }, menuSectionID: -1, menuID: -1 };

  const { dispatch } = useModalContext();
  const { menu, getMenuItem, addMenuItem, updateMenuItem, removeItem } = useMenuContext();
  const { characteristics } = useCharacteristicsContext();
  const { drinks, addDrink, updateDrink } = useDrinkItemsContext();
  const { menus } = useRestaurantContext();
  const { loadTags } = useTagsContext();
  const { dispatch: asyncDispatch } = useAsyncContext();
  const { loadMedia } = useMediaLibraryContext();

  const [menuID, setMenuID] = useState<number>(-1);
  const [menuSectionID, setMenuSectionID] = useState<number>(null);
  const [originalMenuSectionID, setOriginalMenuSectionID] = useState<number>(null);
  const [isPrixFixe, setIsPrixFixe] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>(null);

  useEffect(() => {
    // only want to load the lookup list of tags if item wizard is being utilized
    // call to load those tags on initial load
    loadTags();
  }, [loadTags]);

  useEffect(() => {
    // only want to load the restaurants user media if manager page is being utilized
    // call to load media on initial load
    loadMedia();
  }, [loadMedia]);

  useEffect(() => {
    const getInitialMenu = () => {
      const menusWithSections = menus.filter((_menu) => _menu?.menuSections?.length > 0);
      return menusWithSections?.[0];
    };

    if (menu) {
      if (selectedMenuSectionID) {
        setMenuID(selectedMenuID);
        setIsPrixFixe(menu.isPrixFixe);
      } else {
        const initialMenu = getInitialMenu();
        setMenuID(initialMenu.menuID);
        setIsPrixFixe(initialMenu.isPrixFixe);
      }
    }
  }, [menu, menus, selectedMenuID, selectedMenuSectionID]);

  useEffect(() => {
    const getInitialMenuSectionID = () => {
      const menusWithSections = menus.filter((_menu) => _menu?.menuSections?.length > 0);
      return menusWithSections?.[0]?.menuSections?.[0]?.menuSectionID;
    };

    const newMenuSectionID = selectedMenuSectionID || getInitialMenuSectionID();
    if (menuSectionID !== newMenuSectionID && menus && menuSectionID == null) {
      setMenuSectionID(newMenuSectionID);
      setOriginalMenuSectionID(newMenuSectionID);
    }
  }, [menuSectionID, menus, selectedMenuSectionID]);

  const [menuItemID] = useState<number>(id);
  const [menuItem, setMenuItem] = useState<CreateMenuItemInterface>(null);
  const [media, setMedia] = useState<MenuItemMediaInterface[]>([]);

  const [selectedTag, setSelectedTag] = useState<TagInterface>(null);

  const [modifierGroups, setModifierGroups] = useState<ModifierGroupInterface[]>([]);

  const [restrictionIDs, setRestrictionIDs] = useState<number[]>([]);
  const [pairingIDs, setPairingIDs] = useState<number[]>([]);

  const [displayPrices, setDisplayPrices] = useState<boolean>(false);

  const [showRequiredInfoScreen, setShowRequiredInfoScreen] = useState<boolean>(true);

  const [onSubmitError, setOnSubmitError] = useState<string>('');
  const [defaultPriceIndex, setDefaultPriceIndex] = useState<number>(0);

  const isMenuItem = pathname.includes('menu-item');
  const isEditItem = !!menuItemID;

  useEffect(() => {
    if (menuItem?.allItemSizes) {
      setDisplayPrices(
        isEditItem &&
          (menuItem.allItemSizes.length > 1 ||
            (menuItem.allItemSizes.length === 1 && menuItem.allItemSizes[0].label !== 'default'))
      );
    }
  }, [menuItem?.allItemSizes, menuItem?.baseItemSize.label, isEditItem]);

  const handleMenuChange = (newMenuID: number, newIsPrixFixe: boolean) => {
    setMenuID(newMenuID);
    setIsPrixFixe(newIsPrixFixe);
  };

  const handleMediaUpdate = (mediaInvolved: MenuItemMediaInterface[]) => {
    setMedia(mediaInvolved);
  };

  const handleOnSubmit = async (value: InferType<typeof MenuItemInfoSchema>) => {
    const parsePrice = (submittedPrice: string, custom: boolean): number => {
      if (custom) {
        return 100;
      }

      return parseInt(submittedPrice.replace(/\D/g, ''), 10);
    };

    const parsePriceOverride = (submittedPrice: string, custom: boolean): string => {
      if (custom) {
        return submittedPrice.trim();
      }

      return '';
    };

    const buildItemSize = (item: InferType<typeof MultiPriceSchema>): ItemSizeInterface => ({
      label: displayPrices ? item.label : 'default',
      price: parsePrice(item.price, item.isCustom),
      priceOverride: parsePriceOverride(item.price, item.isCustom)
    });

    const buildAllItemSizes = (prices: InferType<typeof MultiPriceSchema>[]): ItemSizeInterface[] =>
      prices.map((item) => buildItemSize(item));

    let tag = menuItem?.tags?.[0] || null;
    if (MenuItemInfoSchema.isValidSync(value)) {
      displayLoading({
        dispatch: asyncDispatch,
        message: `${isEditItem ? 'Updating' : 'Creating'} ${isMenuItem ? 'Menu Item' : 'Drink'}...`
      });
      try {
        if (isEditItem) {
          const { name, price } = value;
          const description = value.description || '';
          const calories: number | null =
            value.calories && value.calories !== '' ? parseInt(value.calories.replace(/,/g, ''), 10) : null;
          const prices = price;
          const baseItemSize = buildItemSize(prices[defaultPriceIndex]);
          const allItemSizes = buildAllItemSizes(displayPrices ? prices : [prices[defaultPriceIndex]]);
          const itemToEdit: DetailedMenuItemInterface = {
            name: name?.trim(),
            menuItemID: menuItem.menuItemID,
            description,
            calories,
            category: isMenuItem ? 'food' : 'drink',
            baseItemSize,
            allItemSizes
          };
          if (isMenuItem) {
            await editMenuItem(itemToEdit, menuSectionID);
          } else {
            await editDrinkItem(itemToEdit, menuSectionID);
          }
          try {
            if (
              !compareArrays(
                menuItem.dietaryRestrictions.map((val) => val.restrictionID),
                restrictionIDs
              )
            ) {
              await linkRestrictionsToMenuItem(menuItem.menuItemID, restrictionIDs);
            }

            if (
              !compareArrays(
                menuItem.modifierGroups.map((val) => val.modifierGroupID),
                modifierGroups.map((val) => val.modifierGroupID),
                true
              )
            ) {
              await linkModifierGroupsToMenuItem(
                menuItem.menuItemID,
                modifierGroups.map((group) => group.modifierGroupID)
              );
            }

            if (
              !compareArrays(
                menuItem.pairings.map((val) => val.drinkItemID),
                pairingIDs
              )
            ) {
              await pairMenuItem(menuItem.menuItemID, pairingIDs);
            }

            if (
              !compareArrays(
                menuItem?.tags?.map((_tag) => _tag?.tagID),
                [selectedTag?.tagID]
              )
            ) {
              await linkTagsToMenuItem(menuItem.menuItemID, selectedTag == null ? [] : [selectedTag?.tagID]);
              tag = selectedTag;
            }
          } catch (error) {
            console.log(error);
            openModal({
              dispatch,
              title: `Error occurred while updating ${isMenuItem ? 'menu item' : 'drink'}.`,
              message: `Unexpected error occurred while updating ${
                isMenuItem ? 'menu item' : 'drink'
              }. Check your input and try again. If this issue keeps occurring please reach out to the TapTab team.`
            });
            return;
          }

          try {
            const mediaIDs: number[] = [];
            const thumbnails: { videoID: number; thumbnailID: number }[] = [];
            for (let i = 0; i < media.length; i++) {
              mediaIDs.push(media[i].mediaID);

              if (media[i].type === 'video') {
                thumbnails.push({
                  videoID: media[i].mediaID,
                  thumbnailID: media[i].thumbnail.thumbnailID
                });
              }
            }
            await linkMediaToMenuItem(menuItem.menuItemID, mediaIDs, thumbnails);
          } catch (error) {
            let errorMessage = `The ${
              isMenuItem ? 'menu item' : 'drink item'
            } was successfully updated but an issue occurred while updating the item's image. If this issue keeps occurring please reach out to the TapTab team.`;

            if (error?.response?.status === 413) {
              errorMessage =
                'File size of image exceeds limit of 750 KB. Please check the size of the image and try again.';
            }

            openModal({
              dispatch,
              title: 'Error occurred while updating image.',
              message: errorMessage,
              onDismiss: () => navigate(`/${PAGE_MENUS}`)
            });
            return;
          } finally {
            if (menuID === menu.menuID) {
              // TODO: only update if changes
              updateMenuItem(menuItem.menuItemID, menuSectionID, {
                name: name?.trim(),
                calories,
                description,
                baseItemSize,
                allItemSizes,
                media,
                modifierGroups,
                dietaryRestrictions: characteristics.filter((characteristic) =>
                  restrictionIDs.some((restrictionID) => restrictionID === characteristic.restrictionID)
                ),
                pairings: drinks?.filter(
                  (drink) => pairingIDs.some((drinkItemID) => drinkItemID === drink.drinkItemID) ?? []
                ),
                tags: tag ? [tag] : []
              });
            } else if (isEditItem) {
              // remove item from currently selected menu section
              removeItem(menuItem.menuItemID, originalMenuSectionID, isMenuItem ? 'food' : 'drink');
            }
          }

          if (!isMenuItem) {
            updateDrink({ drinkItemID: menuItem.menuItemID, name, isHidden: menuItem.isHidden });
          }
        } else {
          const prices: InferType<typeof MultiPriceSchema>[] = value.price;
          const itemToCreate: CreateMenuItemInterface = {
            name: value.name?.trim(),
            menuSectionID,
            calories: value.calories !== '' ? parseInt(value.calories.replace(/,/g, ''), 10) : null,
            description: value.description || '',
            category: isMenuItem ? 'food' : 'drink',
            baseItemSize: buildItemSize(prices[defaultPriceIndex]),
            allItemSizes: buildAllItemSizes(displayPrices ? prices : [value.price[defaultPriceIndex]])
          };
          let createdItem: CreateMenuItemInterface;
          if (isMenuItem) {
            createdItem = await createMenuItem(itemToCreate);
          } else {
            createdItem = await createDrinkItem(itemToCreate);
          }

          try {
            if (restrictionIDs.length > 0) {
              await linkRestrictionsToMenuItem(createdItem.menuItemID, restrictionIDs);
            }

            if (pairingIDs.length > 0) {
              await pairMenuItem(createdItem.menuItemID, pairingIDs);
            }

            if (modifierGroups?.length > 0) {
              await linkModifierGroupsToMenuItem(
                createdItem.menuItemID,
                modifierGroups.map((group) => group.modifierGroupID)
              );
            }

            if (selectedTag != null) {
              await linkTagsToMenuItem(createdItem.menuItemID, [selectedTag.tagID]);
              tag = selectedTag;
            }
          } catch (error) {
            openModal({
              dispatch,
              title: `Error occurred while creating ${isMenuItem ? 'menu item' : 'drink'}.`,
              message: `Unexpected error occurred while creating ${
                isMenuItem ? 'menu item' : 'drink'
              }. Check your input and try again. If this issue keeps occurring please reach out to the TapTab team.`
            });
            const cleanUpItem = isMenuItem ? deleteMenuItem : deleteDrinkItem;
            await cleanUpItem(createdItem.menuItemID);
            return;
          }

          try {
            const mediaIDs: number[] = [];
            const thumbnails: { videoID: number; thumbnailID: number }[] = [];
            for (let i = 0; i < media.length; i++) {
              mediaIDs.push(media[i].mediaID);

              if (media[i].type === 'video') {
                thumbnails.push({
                  videoID: media[i].mediaID,
                  thumbnailID: media[i].thumbnail.thumbnailID
                });
              }
            }
            await linkMediaToMenuItem(createdItem.menuItemID, mediaIDs, thumbnails);
          } catch (error) {
            let errorMessage = `The ${
              isMenuItem ? 'menu item' : 'drink item'
            } was successfully created but an issue occurred while uploading the item's image(s). If this issue keeps occurring please reach out to the TapTab team.`;

            if (error?.response?.status === 413) {
              errorMessage =
                'File size of image exceeds limit of 750 KB. Please check the size of the image and try again.';
            }

            openModal({
              dispatch,
              title: 'Error occurred while uploading image.',
              message: errorMessage,
              onDismiss: () => navigate(`/${PAGE_MENUS}`)
            });
            return;
          } finally {
            if (menuID === menu.menuID) {
              addMenuItem({
                ...createdItem,
                media,
                modifierGroups,
                dietaryRestrictions: characteristics.filter((characteristic) =>
                  restrictionIDs.some((restrictionID) => restrictionID === characteristic.restrictionID)
                ),
                pairings: drinks.filter((drink) => pairingIDs.some((drinkItemID) => drinkItemID === drink.drinkItemID)),
                tags: tag ? [tag] : []
              });
            }
            if (!isMenuItem) {
              addDrink({ drinkItemID: createdItem.menuItemID, name: createdItem.name, isHidden: false });
            }
          }
        }
        navigate(`/${PAGE_MENUS}`);
      } catch (error) {
        hideLoading(asyncDispatch);
        if (error?.response?.status === 409) {
          setOnSubmitError(
            `A ${isMenuItem ? 'menu item' : 'drink'} already exists with the name "${
              value.name
            }" in the selected menu section.`
          );
        } else if (error?.response) {
          setOnSubmitError('An unexpected error has occurred. Please check your input and try again.');
        } else {
          setOnSubmitError(error?.message);
        }
      } finally {
        hideLoading(asyncDispatch);
      }
    }
  };

  const getPrice = (item: ItemSizeInterface): string => {
    const { price, priceOverride } = item;
    if (priceOverride) {
      return priceOverride;
    }
    return `${(price / 100).toFixed(2)}`;
  };

  const getInitialValues = useCallback(
    (isEdit: boolean) => {
      if (isEdit) {
        const parseItemSizes = (itemSizes: ItemSizeInterface[]): InferType<typeof MultiPriceSchema>[] =>
          itemSizes.map((itemSize) => ({
            label: itemSize.label,
            price: getPrice(itemSize),
            isCustom: !!itemSize.priceOverride
          }));
        return {
          name: menuItem?.name,
          calories: menuItem?.calories != null ? `${menuItem?.calories}` : '',
          description: menuItem?.description || '',
          price: parseItemSizes(menuItem?.allItemSizes ?? []),
          displayPrices: false
        };
      }
      return MenuItemInfoSchema.cast({ price: [{ label: 'default' }] });
    },
    [menuItem]
  );

  useEffect(() => {
    if (menuItem && initialValues === null) {
      setInitialValues(getInitialValues(isEditItem));
    }
  }, [menuItem, getInitialValues, isEditItem, initialValues]);

  useEffect(() => {
    const loadMenuItem = () => {
      if (menus?.length > 0 && isEditItem && menuItemID && !menuItem) {
        const menuItemValues: CreateMenuItemInterface = getMenuItem(menuItemID, selectedMenuSectionID);
        setMenuItem(menuItemValues);
        setPairingIDs(menuItemValues?.pairings.map((val: PairingInterface) => val.drinkItemID));
        setRestrictionIDs(
          menuItemValues?.dietaryRestrictions.map((val: DishCharacteristicInterface) => val.restrictionID)
        );
        setModifierGroups(menuItemValues?.modifierGroups ?? []);
        setMedia(menuItemValues?.media);
        setSelectedTag(menuItemValues?.tags?.[0] || null);
        setDefaultPriceIndex(
          menuItemValues?.allItemSizes?.findIndex(
            (itemSize: ItemSizeInterface) => itemSize.label === menuItemValues?.baseItemSize.label || 0
          )
        );
      }
    };
    if (initialValues === null) {
      loadMenuItem();
    }
  }, [
    getInitialValues,
    getMenuItem,
    initialValues,
    isEditItem,
    menuItem,
    menuItemID,
    menus?.length,
    selectedMenuSectionID
  ]);

  const formik = useFormik({
    initialValues: initialValues || getInitialValues(false),
    validationSchema: MenuItemInfoSchema,
    enableReinitialize: true,
    onSubmit: handleOnSubmit
  });

  const handleNextClicked = () => {
    // Remove whitespace from description field
    const trimmedDescription = formik.values?.description?.trim() || '';
    formik.setFieldValue('description', trimmedDescription);

    const filteredPrices = formik.values?.price?.filter(
      (_price: InferType<typeof MultiPriceSchema>) => _price.label.trim() !== '' || _price.price.trim() !== ''
    );
    if (filteredPrices.length !== 0 && filteredPrices.length !== formik.values?.price?.length) {
      formik.setFieldValue('price', filteredPrices);
    }
    if (isPrixFixe) {
      if (formik.values?.name.trim() !== '') {
        formik.setFieldValue('price', [{ label: 'default', price: 'prix', isCustom: true }]);
        setShowRequiredInfoScreen(false);
      } else if (formik.values?.name.trim() === '') {
        formik.setTouched({ ...formik.touched, name: true });
      }
    } else if (formik.values?.name.trim() !== '' && filteredPrices.length > 0) {
      if (formik.values?.displayPrices) {
        if (defaultPriceIndex + 1 > filteredPrices.length) {
          setDefaultPriceIndex(0);
        }
      }
      if (
        filteredPrices.some(
          (_price: InferType<typeof MultiPriceSchema>) =>
            parseInt(_price.price.replace(/\./, '').replace(/\$/, ''), 10) <= 0
        )
      ) {
        return;
      }
      if (formik.errors.price instanceof string) {
        if (formik.errors.price) {
          return;
        }
      }

      if (formik.errors.price instanceof Array) {
        if (
          formik.errors.price?.some(
            // @ts-ignore
            (_price) => (_price?.price && !_price?.label) || (!_price?.price && _price?.label)
          )
        ) {
          formik.setTouched({
            ...formik.touched,
            name: true,
            price: [...filteredPrices.map(() => ({ price: true, label: true }))]
          });
          return;
        }
      }

      setShowRequiredInfoScreen(false);
    } else {
      formik.setTouched({
        ...formik.touched,
        name: true,
        price:
          filteredPrices?.length === 0
            ? [{ price: true, label: true }]
            : [...filteredPrices.map(() => ({ price: true, label: true }))]
      });
    }
  };

  const handleDisplayOfPrices = () => {
    if (!displayPrices) {
      if (formik.values.price.length === 1 && formik.values.price[0].label === 'default') {
        formik.setFieldValue('price', [
          { label: '', price: formik.values.price[0].price, isCustom: formik.values.price[0].isCustom }
        ]);
      }
    } else if (formik.values.price.length === 1 && formik.values.price[0].label === '') {
      // if no label entered for single price in multi view and switching back to single price
      // set the label to default to prevent error.
      formik.setFieldValue(
        'price',
        [{ label: 'default', price: formik.values.price[0].price, isCustom: formik.values.price[0].isCustom }],
        true
      );
      formik.setTouched({ ...formik.touched, price: [{ label: true, price: true }] });
    }

    formik.setFieldValue('displayPrices', !displayPrices);
    setDisplayPrices(!displayPrices);
  };

  const handleRemoveSizePrice = (index: number) => {
    if (defaultPriceIndex === index) {
      setDefaultPriceIndex(defaultPriceIndex + 1);
    }
    const prices: InferType<typeof MultiPriceSchema>[] = formik.values.price;
    prices.splice(index, 1);
    formik.setFieldValue('price', prices);
  };

  const handleAddModifierGroup = async (modifierGroup: Partial<ModifierGroupInterface>) => {
    const createdModifierGroup: ModifierGroupInterface = await createModifierGroup({
      name: modifierGroup.name,
      label: modifierGroup.label,
      modifierIDs: modifierGroup.modifiers.map((modifier) => modifier.modifierID)
    });
    const _modifierGroups = modifierGroups.slice();
    _modifierGroups.push(createdModifierGroup);

    setModifierGroups(_modifierGroups);
  };

  const handleEditModifierGroup = async (modifierGroup: ModifierGroupInterface) => {
    const _modifierGroups = modifierGroups.slice();
    const idx = _modifierGroups.findIndex((group) => group.modifierGroupID === modifierGroup.modifierGroupID);
    const { name, label, modifiers } = _modifierGroups[idx];

    if (name.trim() !== modifierGroup.name.trim() || label.trim() !== modifierGroup.label.trim()) {
      await editModifierGroup(modifierGroup.modifierGroupID, modifierGroup.name, modifierGroup.label);
    }

    const modifierIDs = modifierGroup.modifiers.map((modifier) => modifier.modifierID);
    if (
      !compareArrays(
        modifierIDs,
        modifiers.map((modifier) => modifier.modifierID),
        true
      )
    ) {
      // link modifiers to modifier group
      await linkModifiersToModifierGroup(modifierGroup.modifierGroupID, modifierIDs);
    }

    _modifierGroups.splice(idx, 1, {
      modifierGroupID: modifierGroup.modifierGroupID,
      name: modifierGroup.name,
      label: modifierGroup.label,
      modifiers: modifierGroup.modifiers
    } as ModifierGroupInterface);
    setModifierGroups(_modifierGroups);
  };

  const handleDeleteModifierGroup = async (modifierGroupID: number) => {
    const _modifierGroups = modifierGroups.slice();
    const idx = _modifierGroups.findIndex((group) => group.modifierGroupID === modifierGroupID);

    await deleteModifierGroup(modifierGroupID);

    _modifierGroups.splice(idx, 1);
    setModifierGroups(_modifierGroups);
  };

  return (
    <div className="menu-item-wizard-container h-screen w-full flex flex-col items-center overflow-auto">
      <WizardHeader className={`${isMenuItem ? 'menu-item' : 'drink-item'} menu-item-wizard-header`}>
        <div className="menu-item-wizard-header-content">
          <h1>
            {isEditItem ? 'EDIT' : 'CREATE'} {isMenuItem ? 'MENU ITEM' : 'DRINK'}
          </h1>
          <WizardProgressBar
            currentPage={!showRequiredInfoScreen ? 'Additional Info' : 'Basic Info'}
            steps={['Basic Info', 'Additional Info']}
          />
        </div>
      </WizardHeader>
      <form
        className={`menu-item-wizard-content${showRequiredInfoScreen ? '-required' : '-optional'} bg-white`}
        onSubmit={formik.handleSubmit}
      >
        {showRequiredInfoScreen ? (
          <ItemBasicInfoPage
            menuID={menuID}
            menuSectionID={menuSectionID}
            isPrixFixe={isPrixFixe}
            defaultIndex={defaultPriceIndex}
            setDefaultIndex={setDefaultPriceIndex}
            formik={formik}
            isMenuItem={isMenuItem}
            showMultiplePrices={displayPrices}
            media={media}
            handleMediaUpdate={handleMediaUpdate}
            handleMenuUpdate={handleMenuChange}
            handleMenuSectionUpdate={(value: number) => setMenuSectionID(value)}
            handleNextClicked={handleNextClicked}
            handleDisplayOfPrices={handleDisplayOfPrices}
            handleRemoveSizePrice={handleRemoveSizePrice}
          />
        ) : (
          <ItemAdditionalInfoPage
            formik={formik}
            itemName={formik.values.name}
            modifierGroups={modifierGroups}
            restrictionIDs={restrictionIDs}
            pairingIDs={pairingIDs}
            selectedTag={selectedTag}
            errorMessage={onSubmitError}
            isMenuItem={isMenuItem}
            handleBackClicked={() => {
              setShowRequiredInfoScreen(true);
              setOnSubmitError('');
            }}
            onCharacteristicsChange={(ids: number[]) => setRestrictionIDs(ids)}
            onPairingsChange={(ids: number[]) => setPairingIDs(ids)}
            onModifierGroupAdd={handleAddModifierGroup}
            onModifierGroupEdit={handleEditModifierGroup}
            onModifierGroupDelete={handleDeleteModifierGroup}
            onModifierGroupsReorder={async (reorderedGroups) => setModifierGroups(reorderedGroups)}
            onSelectedTagChange={(tag: TagInterface) => setSelectedTag(tag)}
          />
        )}
      </form>
    </div>
  );
};

export default MenuItemWizard;
