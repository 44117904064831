import Fuse from 'fuse.js';
import React, { ReactElement, useState } from 'react';
import { formatPriceExact } from '../../utils/priceFormatter';
import { Checkbox, Input } from '../common/Form';

interface EntityProps {
  id: number;
  name: string;
  price?: number;
  isHidden?: boolean;
}

interface EntitySelectorProps {
  entities: EntityProps[];
  noContextCopy: string | React.ReactNode;
  selected: number[];
  onSelectionToggle: Function;
}

const EntitySelector = ({ entities, noContextCopy, selected, onSelectionToggle }: EntitySelectorProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredEntities, setFilteredEntities] = useState<EntityProps[]>(entities);

  const fuse = new Fuse(entities, {
    includeScore: true,
    findAllMatches: true,
    keys: ['name']
  });

  const handleFilterChange = (value: string, toggledId?: number) => {
    const input = value;
    setSearchTerm(input);
    if (input.trim() === '') {
      setFilteredEntities(entities);
    } else {
      const results: Fuse.FuseResult<EntityProps>[] = fuse.search(input === '' ? null : input);
      const matchingEntities = results.map((result) => result.item);
      let selectedEntities = entities.filter((entity) => selected.includes(entity.id));
      if (toggledId) {
        selectedEntities = selectedEntities.filter((entity) => entity.id !== toggledId);
      }
      setFilteredEntities(Array.from(new Set([...selectedEntities, ...matchingEntities])));
    }
  };

  const handleSelectionToggle = (itemID: number) => {
    const isSelected = selected.includes(itemID);
    onSelectionToggle?.(itemID);
    if (isSelected) {
      handleFilterChange(searchTerm, itemID);
    }
  };

  const handleSelectionKeyPress = (event: React.KeyboardEvent<HTMLDivElement>, itemID: number) => {
    if (event.code === 'Enter' || event.code === 'Space') {
      onSelectionToggle?.(itemID);
      handleFilterChange(searchTerm, itemID);
    }
  };

  const getEntitySelector = (values: EntityProps[], selectedValues: number[]) => (
    <>
      {values.map((entity: EntityProps) => (
        <div className="entity-selection" key={entity.id}>
          <div
            className={`entity-selector ${entity.isHidden ? 'hidden-entity' : ''}`}
            role="button"
            onClick={() => handleSelectionToggle(entity.id)}
            onKeyPress={(event) => handleSelectionKeyPress(event, entity.id)}
            tabIndex={0}
          >
            <Checkbox checked={selectedValues.some((id) => id === entity.id)} />
            <span className="entity-name">{entity.name}</span>
            {entity.price > 0 && <span className="entity-price">+{formatPriceExact(entity.price)}</span>}
          </div>
        </div>
      ))}
    </>
  );

  const getEntities = (array: EntityProps[], selectedValues: number[]): ReactElement => {
    const half = Math.ceil(array.length / 2);
    const firstHalf = array.slice(0, half);
    const secondHalf = array.slice(half, array.length);

    if (array.length > 0) {
      return (
        <div className="entity-selector-container">
          <div>{getEntitySelector(firstHalf, selectedValues)}</div>
          <div>{getEntitySelector(secondHalf, selectedValues)}</div>
        </div>
      );
    }
    return <div className="self-center w-full text-slate-500">{noContextCopy}</div>;
  };

  return (
    <>
      <div>
        <Input
          className="tag-input"
          name="tag-input"
          placeholder="Search for..."
          value={searchTerm}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFilterChange(event.target.value)}
        />
      </div>
      <div className="selector-container">{getEntities(filteredEntities, selected)}</div>
    </>
  );
};

export default EntitySelector;
