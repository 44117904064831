import { InferType } from 'yup';
import { DayAndHourPickerRowSchema } from '../components/common/Form';
import { RestaurantHourInterface } from '../types/RestaurantHourInterface';
import { shortToFullDayName } from '../constants/weekDayConstants';

export const buildRestaurantHours = (hours: InferType<typeof DayAndHourPickerRowSchema>[]): RestaurantHourInterface[] =>
  hours?.map((block: InferType<typeof DayAndHourPickerRowSchema>) => {
    const days: string[] = [];

    Object.entries(block.daysOfWeek).forEach(([day, appliesToThisWeekday]) => {
      if (appliesToThisWeekday) {
        days.push(shortToFullDayName[day]);
      }
    });

    return {
      day: days,
      start: block.startTime,
      end: block.endTime
    } as RestaurantHourInterface;
  });

/**
 * Convert military time to standard time
 * @param militaryTime - time to be formatted - Ex: '08:00', '23:00'
 * @return standardTime - military formatted in standard time. - Ex: '8:00 AM', '11:00 PM'
 */
export const formatMilitaryToStandard = (militaryTime: string): string => {
  const time = militaryTime.split(':');

  const hours = Number(time[0]);
  const minutes = Number(time[1]);

  let timeValue;
  if (hours > 0 && hours <= 12) {
    timeValue = `${hours}`;
  } else if (hours > 12) {
    timeValue = `${hours - 12}`;
  } else if (hours === 0) {
    timeValue = '12';
  }

  timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`;
  timeValue += hours >= 12 ? ' PM' : ' AM';
  return timeValue;
};

/**
 * Compare two military times passed in
 * @param militaryTimeA - time comparison takes place for - Ex: '08:00', '23:00'
 * @param militaryTimeB- time to be compared against - Ex: '08:00', '23:00'
 * @return number - 1 if greater than, 0 if equal to, -1 if less than
 */
export const compareMilitaryTime = (militaryTimeA: string, militaryTimeB: string): number => {
  const timeA = militaryTimeA.split(':');
  const timeB = militaryTimeB.split(':');

  const hoursA = Number(timeA[0]);
  const minutesA = Number(timeA[1]);
  const hoursB = Number(timeB[0]);
  const minutesB = Number(timeB[1]);

  if (hoursA > hoursB) {
    return 1;
  }

  if (hoursA === hoursB) {
    if (minutesA > minutesB) {
      return 1;
    }
    if (minutesA === minutesB) {
      return 0;
    }
  }

  return -1;
};
