import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { toast } from 'react-toastify';
import { ModifierGroupInterface } from '../../types/ModifierGroupInterface';
import ModifierGroupsModal from './ModifierGroupsModal';
import { useMenuContext } from '../../contexts/MenuContext';
import { displayLoading, hideLoading, useAsyncContext } from '../../contexts/AsyncContext';
import {
  createModifierGroup,
  deleteModifierGroup,
  editModifierGroup,
  linkModifiersToModifierGroup
} from '../../api/modifierGroups';
import { linkModifierGroupsToMenuItem } from '../../api/menuItem';
import { compareArrays } from '../../utils/general';

export default forwardRef((props: ICellEditorParams, ref) => {
  const { dispatch: asyncDispatch } = useAsyncContext();
  const { updateMenuItem } = useMenuContext();

  const [modifierGroups, setModifierGroups] = useState<ModifierGroupInterface[]>(props.value.modifierGroups ?? []);

  /* Component Editor Lifecycle methods
   * https://www.ag-grid.com/react-data-grid/component-cell-editor/#configure-popup
   */
  useImperativeHandle(ref, () => ({
    // the final value to send to the grid, on completion of editing
    getValue() {
      return props.value.modifierGroups;
    },

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    isCancelBeforeStart() {
      return false;
    },

    // Gets called once when editing is finished (eg if Enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    isCancelAfterEnd() {
      return true;
    }
  }));

  const handleCloseModal = () => {
    updateMenuItem(props.value.menuItemID, props.value.menuSectionID, { modifierGroups });
    props.api.stopEditing();
  };

  const handleAddModifierGroup = async (modifierGroup: Partial<ModifierGroupInterface>) => {
    try {
      displayLoading({ dispatch: asyncDispatch, message: 'Creating Modifier Group...' });
      const createdModifierGroup: ModifierGroupInterface = await createModifierGroup({
        name: modifierGroup.name,
        label: modifierGroup.label,
        modifierIDs: modifierGroup.modifiers.map((modifier) => modifier.modifierID)
      });
      const _modifierGroups = modifierGroups.slice();
      _modifierGroups.push(createdModifierGroup);
      await linkModifierGroupsToMenuItem(
        props.value.menuItemID,
        _modifierGroups.map((group) => group.modifierGroupID)
      );

      setModifierGroups(_modifierGroups);

      toast.success('Menu Item Successfully Updated!', {
        position: toast.POSITION.TOP_RIGHT
      });
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      hideLoading(asyncDispatch);
    }
  };

  const handleEditModifierGroup = async (modifierGroup: ModifierGroupInterface) => {
    try {
      displayLoading({ dispatch: asyncDispatch, message: 'Updating Modifier Group...' });

      const _modifierGroups = modifierGroups.slice();
      const idx = _modifierGroups.findIndex((group) => group.modifierGroupID === modifierGroup.modifierGroupID);
      const { name, label, modifiers } = _modifierGroups[idx];

      if (name.trim() !== modifierGroup.name.trim() || label.trim() !== modifierGroup.label.trim()) {
        await editModifierGroup(modifierGroup.modifierGroupID, modifierGroup.name, modifierGroup.label);
      }

      const modifierIDs = modifierGroup.modifiers.map((modifier) => modifier.modifierID);
      if (
        !compareArrays(
          modifierIDs,
          modifiers.map((modifier) => modifier.modifierID),
          true
        )
      ) {
        // link modifiers to modifier group
        await linkModifiersToModifierGroup(modifierGroup.modifierGroupID, modifierIDs);
      }

      _modifierGroups.splice(idx, 1, {
        modifierGroupID: modifierGroup.modifierGroupID,
        name: modifierGroup.name,
        label: modifierGroup.label,
        modifiers: modifierGroup.modifiers
      } as ModifierGroupInterface);
      setModifierGroups(_modifierGroups);

      toast.success('Menu Item Successfully Updated!', {
        position: toast.POSITION.TOP_RIGHT
      });
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      hideLoading(asyncDispatch);
    }
  };

  const handleDeleteModifierGroup = async (modifierGroupID: number) => {
    try {
      displayLoading({ dispatch: asyncDispatch, message: 'Deleting Modifier Group...' });

      const _modifierGroups = modifierGroups.slice();
      const idx = _modifierGroups.findIndex((group) => group.modifierGroupID === modifierGroupID);

      await deleteModifierGroup(modifierGroupID);

      _modifierGroups.splice(idx, 1);
      setModifierGroups(_modifierGroups);

      toast.success(`Modifier Group Successfully Deleted!`, {
        position: toast.POSITION.TOP_RIGHT
      });
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      hideLoading(asyncDispatch);
    }
  };

  const handleReorderModifierGroups = async (reorderedGroups: ModifierGroupInterface[]) => {
    try {
      const modifierGroupIDs: number[] = reorderedGroups.map((group) => group.modifierGroupID);

      setModifierGroups(reorderedGroups);
      await linkModifierGroupsToMenuItem(props.value.menuItemID, modifierGroupIDs);
    } catch (err) {
      console.error(err);
      setModifierGroups(modifierGroups);
      throw err;
    }
  };

  return (
    <ModifierGroupsModal
      modifierGroups={modifierGroups}
      onClose={handleCloseModal}
      addModifierGroup={handleAddModifierGroup}
      updateModifierGroup={handleEditModifierGroup}
      deleteModifierGroup={handleDeleteModifierGroup}
      reorderModifierGroups={handleReorderModifierGroups}
    />
  );
});
