import { useState } from 'react';
import { useCharacteristicsContext } from '../../contexts/CharacteristicsContext';
import { compareArrays } from '../../utils/general';
import EntitySelector from '../EntitySelector';
import SelectorModal from '../SelectorModal';

interface SelectCharacteristicsModalProps {
  itemName: string;
  originalRestrictionIDs: number[];
  closeModal: Function;
  handleSubmit: Function;
  isDesktop?: boolean;
}

const SelectCharacteristicsModal = ({
  itemName,
  originalRestrictionIDs,
  closeModal,
  handleSubmit,
  isDesktop
}: SelectCharacteristicsModalProps) => {
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string>('');
  const [selectedCharacteristics, setSelectedCharacteristics] = useState<number[]>(originalRestrictionIDs);
  const { characteristics, hasError: fetchCharacteristicsError } = useCharacteristicsContext();

  const handleSelectionToggled = (restrictionID: number) => {
    const selected = selectedCharacteristics.slice();
    const idx = selectedCharacteristics.findIndex((item) => item === restrictionID);
    if (idx === -1) {
      selected.push(restrictionID);
    } else {
      selected.splice(idx, 1);
    }

    setSelectedCharacteristics(selected);
  };

  const onSubmitClicked = async (restrictionIDs: number[]) => {
    try {
      if (!compareArrays(originalRestrictionIDs, restrictionIDs)) {
        handleSubmit(restrictionIDs);
      } else {
        setSubmitErrorMessage(`Selected characteristics must be updated to submit.`);
      }
    } catch (error) {
      setSubmitErrorMessage(`An unexpected error has occurred while adding characteristics to ${itemName}.`);
    }
  };

  return (
    <SelectorModal
      title="SELECT A CHARACTERISTIC"
      submitText={isDesktop ? 'ADD CHARACTERISTICS' : 'ADD'}
      createText={isDesktop ? 'CREATE CHARACTERISTIC' : 'CREATE'}
      hasValues={characteristics.length > 0}
      onSubmit={() => onSubmitClicked(selectedCharacteristics)}
      onCancel={() => closeModal()}
      onCreate={() => {}}
    >
      <div className="modal-message">Please select any characteristics to include with the {itemName}.</div>
      {fetchCharacteristicsError && (
        <div className="selection-error">An error has occurred while fetching dish characteristics.</div>
      )}
      {!fetchCharacteristicsError && (
        <>
          {submitErrorMessage && <div className="selection-error">{submitErrorMessage}</div>}
          <EntitySelector
            entities={characteristics.map((characteristic) => ({
              id: characteristic.restrictionID,
              ...characteristic
            }))}
            noContextCopy="Sorry, no results were found for your search criteria."
            selected={selectedCharacteristics}
            onSelectionToggle={handleSelectionToggled}
          />
        </>
      )}
    </SelectorModal>
  );
};

export default SelectCharacteristicsModal;
