import apiClient from './apiClient';
import {
  CREATE_MENU,
  DELETE_MENU,
  DOWNLOAD_MENU,
  EDIT_MENU,
  GET_MENU_DETAIL,
  HIDE_SHOW_MENU,
  REORDER_MENUS
} from '../constants/UriConstants';
import {
  CreateMenuInterface,
  DetailedMenuInterface,
  EditMenuInterface,
  EditMenuResponse
} from '../types/MenuInterface';

export const createMenu = (menu: CreateMenuInterface) =>
  apiClient.post(CREATE_MENU, { menus: [menu] }).then((response) => response.data);

export const deleteMenu = async (menuID: number) => {
  await apiClient.delete(DELETE_MENU + menuID);
};

export const downloadMenu = (menuID: number, fileFormat: 'pdf' | 'docx'): Promise<{ fileURL: string }> =>
  apiClient.post(DOWNLOAD_MENU, { menuID, fileFormat }).then((response) => response.data);

export const editMenu = (menu: EditMenuInterface): Promise<EditMenuResponse> =>
  apiClient.put(EDIT_MENU, menu).then((response) => response.data);

export const getMenuDetails = (menuID: number): Promise<DetailedMenuInterface> =>
  apiClient.get(GET_MENU_DETAIL + menuID).then((response) => response.data);

export const hideMenu = async (menuID: number) => {
  await apiClient.put(HIDE_SHOW_MENU, { menuID, hide: true });
};

export const reorderMenus = async (menusOrder: number[]) => {
  await apiClient.patch(REORDER_MENUS, { menusOrder });
};

export const showMenu = async (menuID: number) => {
  await apiClient.put(HIDE_SHOW_MENU, { menuID, hide: false });
};
