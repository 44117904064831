import React from 'react';

interface DownloadIconInterface {
  onIconClicked?: Function;
  tabIndex?: number;
}

const DownloadIcon = ({ onIconClicked, tabIndex = null }: DownloadIconInterface) => {
  const handleIconClicked = () => {
    onIconClicked?.();
  };

  const handleIconKeyPress = (event: React.KeyboardEvent<SVGSVGElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      onIconClicked?.();
    }
  };

  return (
    <svg
      fill="#000000"
      width="18"
      height="18"
      viewBox="0 0 64 64"
      version="1.1"
      tabIndex={tabIndex}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      onClick={handleIconClicked}
      onKeyDown={handleIconKeyPress}
      xmlSpace="preserve"
      style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2' }}
    >
      <rect x="-576" y="-128" width="1280" height="800" style={{ fill: 'none' }} />
      <g>
        <path d="M48.089,52.095l0,4l-32.049,0l0,-4l32.049,0Zm-16.025,-4l-16.024,-16l8.098,0l-0.049,-24l15.975,0l0.048,24l7.977,0l-16.025,16Z" />
      </g>
    </svg>
  );
};

export default DownloadIcon;
