import React, { useState, useCallback } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  ScreenReaderInstructions,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  SortableContext,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates,
  arrayMove
} from '@dnd-kit/sortable';
import { useNavigate } from 'react-router-dom';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import MenuSelector from '../MenuSelector/MenuSelector';
import { reorderMenus, deleteMenu, hideMenu, showMenu } from '../../api/menu';
import { MenuInterface } from '../../types/MenuInterface';
import { LeftArrowIcon, PlusIcon, RightArrowIcon } from '../../assets/svgs/icons';
import { useRestaurantContext } from '../../contexts/RestaurantContext';
import { useMenuContext } from '../../contexts/MenuContext';
import DeleteItemModal from '../DeleteItemModal';
import HorizontalScrollBar from '../HorizontalScrollBar';
import DownloadMenuModal from '../DownloadMenuModal/DownloadMenuModal';

const MenuSelectionBar = () => {
  const { menu: selectedMenu, setMenuID, resetMenu } = useMenuContext();
  const { menus, removeMenu, reorderMenus: updateMenuOrder, setMenus, updateMenu } = useRestaurantContext();
  const { dispatch } = useModalContext();

  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState<boolean>(false);
  const [showRightScrollArrow, setShowRightScrollArrow] = useState<boolean>(false);

  const navigate = useNavigate();

  /**
   * ID of menu to delete, can possibly be different then the currently selected menu id
   */
  const [menuToDelete, setMenuToDelete] = useState<number>(null);
  const [menuToDownload, setMenuToDownload] = useState<number>(null);
  const [hasError, setHasError] = useState<boolean>(false);

  const screenReaderInstructions: ScreenReaderInstructions = {
    draggable: `
    To pick up a sortable item, press the space bar.
    While sorting, use the arrow keys to move the item.
    Press space again to drop the item in its new position, or press escape to cancel.
  `
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 2
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragEnd = async ({ active, over }: DragEndEvent) => {
    if (!over) {
      return;
    }

    const _menus = menus.slice();
    const oldIndex = menus.findIndex((menu) => menu.menuID.toString() === active.id);
    const newIndex = menus.findIndex((menu) => menu.menuID.toString() === over.id);

    if (oldIndex === newIndex) {
      return;
    }
    const reorderedMenus = arrayMove(menus, oldIndex, newIndex);
    setMenus(reorderedMenus);

    try {
      const ids = reorderedMenus.map((menu) => menu.menuID);
      await reorderMenus(ids);
      updateMenuOrder(ids);
    } catch (error) {
      setMenus(_menus);
      openModal({ dispatch });
    }
  };

  const handleDeleteMenu = async () => {
    try {
      await deleteMenu(menuToDelete);

      // remove menu
      removeMenu(menuToDelete);

      if (selectedMenu?.menuID === menuToDelete) {
        if (menus.length > 1) {
          const filteredMenus = menus?.filter((menu) => menu.menuID !== menuToDelete);
          setMenuID(filteredMenus?.[0]?.menuID);
        } else {
          resetMenu();
        }
      }

      // set menu back to null to close modal
      setMenuToDelete(null);
      if (hasError) {
        setHasError(false);
      }
    } catch (error) {
      setHasError(true);
    }
  };

  const handleHideShowMenu = async (menu: MenuInterface) => {
    try {
      const toggleFunction = menu.isHidden ? showMenu : hideMenu;
      await toggleFunction(menu.menuID);

      updateMenu({ ...menu, isHidden: !menu.isHidden });
    } catch (error) {
      openModal({ dispatch });
    }
  };

  const handleAddMenu = useCallback(() => {
    navigate('/create-menu');
  }, [navigate]);

  const handleScrollRight = () => {
    const container = document.querySelector('.menu-selection-bar-scroll-container');
    container.scrollLeft += 75;
  };

  const handleScrollLeft = () => {
    const container = document.querySelector('.menu-selection-bar-scroll-container');
    container.scrollLeft -= 75;
  };

  return (
    <div className="menu-selection-bar-container">
      <DeleteItemModal
        title="DELETE MENU"
        message="Please confirm that you would like to delete this menu."
        warning="NOTE: By deleting this menu, you will be deleting every menu section and menu item in this menu."
        error={
          hasError && 'An unexpected error has occurred while deleting menu. Unable to perform action at this time.'
        }
        isOpen={!!menuToDelete}
        onCancel={() => {
          setMenuToDelete(null);
          setHasError(false);
        }}
        onConfirm={handleDeleteMenu}
      />
      <DownloadMenuModal
        menuID={menuToDownload}
        onConfirm={() => {
          setMenuToDownload(null);
        }}
      />
      <PlusIcon onIconClicked={handleAddMenu} />
      <LeftArrowIcon visible={showLeftScrollArrow} onClick={handleScrollLeft} />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        screenReaderInstructions={screenReaderInstructions}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToHorizontalAxis, restrictToWindowEdges]}
      >
        <HorizontalScrollBar
          className="menu-selection-bar-scroll-container"
          items={menus}
          showLeftArrow={setShowLeftScrollArrow}
          showRightArrow={setShowRightScrollArrow}
        >
          <SortableContext strategy={horizontalListSortingStrategy} items={menus.map((menu) => menu.menuID.toString())}>
            {menus.length > 0 &&
              menus.map((menu: MenuInterface) => (
                <MenuSelector
                  key={menu.menuID}
                  menuID={menu.menuID}
                  menuName={menu.menuName}
                  onDelete={(itemID: number) => setMenuToDelete(itemID)}
                  onDownload={(itemID: number) => setMenuToDownload(itemID)}
                  onHideShow={() => handleHideShowMenu(menu)}
                  isHidden={menu.isHidden}
                />
              ))}
          </SortableContext>
        </HorizontalScrollBar>
      </DndContext>
      <RightArrowIcon visible={showRightScrollArrow} onClick={handleScrollRight} />
    </div>
  );
};

export default MenuSelectionBar;
