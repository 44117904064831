export const BASE_PATH = '/';

// Pages
export const PAGE_ABOUT = `about`;
export const PAGE_CREATE_DRINK_ITEM = `create-drink-item`;
export const PAGE_EDIT_DRINK_ITEM = `edit-drink-item`;
export const PAGE_CREATE_MENU = `create-menu`;
export const PAGE_EDIT_MENU = `edit-menu`;
export const PAGE_CREATE_MENU_ITEM = `create-menu-item`;
export const PAGE_EDIT_MENU_ITEM = `edit-menu-item`;
export const PAGE_CREATE_RESTAURANT = `create-restaurant`;
export const PAGE_EDIT_RESTAURANT = `edit-restaurant`;
export const PAGE_CREATE_MODIFIER = `create-modifier`;
export const PAGE_EDIT_MODIFIER = `edit-modifier`;
export const PAGE_FORGOT_PASSWORD = `forgot-password`;
export const PAGE_LOGIN = `login`;
export const PAGE_MEDIA_LIBRARY = `media-library`;
export const PAGE_MENUS = `menus`;
export const PAGE_MODIFIERS = `modifiers`;
export const PAGE_PRESS = `press`;
export const PAGE_PROMOTIONS = `promotions`;
export const PAGE_RESET_PASSWORD = `reset-password`;
export const PAGE_SETTINGS = `settings`;
export const PAGE_SIGN_UP = `signup`;
export const PAGE_RESERVATION_ORDERING_LINKS = `reservation-ordering-links`;
export const PAGE_EMAIL_MANAGER = `email-manager`;

/** *************************** */
/** ******* ENDPOINTS ********* */
/** *************************** */

// Announcements
export const ANNOUNCEMENT = `${BASE_PATH}announcement`;
export const CREATE_ANNOUNCEMENT = `${ANNOUNCEMENT}`;
export const DELETE_ANNOUNCEMENT = `${ANNOUNCEMENT}/`;
export const EDIT_ANNOUNCEMENT = `${ANNOUNCEMENT}`;
export const GET_ANNOUNCEMENTS = `${BASE_PATH}announcements`;
export const LINK_ANNOUNCEMENTS_MEDIA = '/announcements/media';
export const HIDE_SHOW_ANNOUNCEMENT = `${ANNOUNCEMENT}/hide`;
export const UPLOAD_ANNOUNCEMENT_IMAGE = `${ANNOUNCEMENT}/image`;

// Authentication
export const CHANGE_PASSWORD = `${BASE_PATH}managers/password`;
export const FORGOT_PASSWORD = `${BASE_PATH}forgotPassword`;
export const LOGIN = `${BASE_PATH}login`;
export const RESET_PASSWORD = `${BASE_PATH}resetPassword`;
export const SIGN_UP = `${BASE_PATH}signup`;

// Cuisines
export const GET_CUISINES = `${BASE_PATH}cuisines`;

// Drink Item
export const DRINK_ITEM = `${BASE_PATH}drinkItem`;
export const CREATE_DRINK_ITEM = `${DRINK_ITEM}`;
export const DELETE_DRINK_ITEM = `${DRINK_ITEM}/`;
export const EDIT_DRINK_ITEM = `${DRINK_ITEM}`;
export const GET_DRINK_ITEMS = `${DRINK_ITEM}s`;
export const HIDE_SHOW_DRINK_ITEM = `${DRINK_ITEM}/hide`;

// Manager
const MANAGER = `${BASE_PATH}manager`;
export const GET_MANAGER = `${MANAGER}`;
export const UPDATE_MANAGER = `${MANAGER}`;

// Media
const MEDIA = `${BASE_PATH}media`;
export const DELETE_MEDIA = `${MEDIA}`;
export const GET_MEDIA = `${MEDIA}`;
export const UPLOAD_MEDIA_IMAGES = `${MEDIA}/images`;
export const UPLOAD_MEDIA_VIDEOS = `${MEDIA}/videos`;

// Menu
export const MENUS = `${BASE_PATH}menus`;
export const CREATE_MENU = `${MENUS}`;
export const DELETE_MENU = `${MENUS}/`;
export const DOWNLOAD_MENU = `${MENUS}/generateFile`;
export const EDIT_MENU = `${MENUS}`;
export const GET_MENU_DETAIL = `${MENUS}/`;
export const HIDE_SHOW_MENU = `${MENUS}/hide`;
export const REORDER_MENUS = `${MENUS}/reorder`;

// Menu Item
export const MENU_ITEM = `${BASE_PATH}menuItem`;
export const CREATE_MENU_ITEM = `${MENU_ITEM}`;
export const DELETE_MENU_ITEM = `${MENU_ITEM}/`;
export const EDIT_MENU_ITEM = `${MENU_ITEM}`;
export const HIDE_SHOW_MENU_ITEM = `${MENU_ITEM}/hide`;
export const LINK_MENU_ITEM_MODIFIER_GROUPS = `${MENU_ITEM}/modifierGroups`;
export const LINK_MENU_ITEM_RESTRICTIONS = `${MENU_ITEM}/restrictions`;
export const LINK_MENU_ITEM_TAGS = `${MENU_ITEM}/tag`;
export const LINK_MENU_ITEM_MEDIA = '/menuItems/media';
export const PAIR_MENU_ITEM = `${MENU_ITEM}/pair`;
export const REORDER_MENU_ITEMS = `${MENU_ITEM}/reorder`;
export const UPLOAD_MENU_ITEM_MEDIA = `${MENU_ITEM}/media`;

// Menu Layouts
export const MENU_LAYOUTS = `${BASE_PATH}menuLayouts`;
export const GET_MENU_LAYOUTS = `${MENU_LAYOUTS}`;
export const UPDATE_MENU_LAYOUT = `${MENU_LAYOUTS}/restaurant`;

// Menu Sections
export const MENU_SECTIONS = `${BASE_PATH}menuSections`;
export const CREATE_MENU_SECTIONS = `${MENU_SECTIONS}`;
export const DELETE_MENU_SECTIONS = `${MENU_SECTIONS}/`;
export const EDIT_MENU_SECTIONS = `${MENU_SECTIONS}`;
export const REORDER_MENU_SECTIONS = `${MENU_SECTIONS}/reorder`;
export const HIDE_SHOW_MENU_SECTIONS = `${MENU_SECTIONS}/hide`;

// Modifiers
export const CREATE_MODIFIER = '/modifier';
export const DELETE_MODIFIER = '/modifiers';
export const EDIT_MODIFIER = '/modifier';
export const GET_MODIFIERS = '/modifiers';

// Modifier Groups
export const CREATE_MODIFIER_GROUP = '/modifierGroup';
export const DELETE_MODIFIER_GROUP = '/modifierGroup';
export const EDIT_MODIFIER_GROUP = '/modifierGroup';
export const LINK_MODIFIER_GROUPS_MODIFIER = '/modifierGroup/modifiers';

// Profile Pages
export const PROFILE_PAGES = `${BASE_PATH}profilePages`;
export const CREATE_PROFILE_PAGES = `${PROFILE_PAGES}`;
export const EDIT_PROFILE_PAGES = `${PROFILE_PAGES}`;
export const GET_PROFILE_PAGES = `${PROFILE_PAGES}/`;
export const GET_PROFILE_PAGES_OVERVIEW = `${PROFILE_PAGES}`;

// Profile Sections
export const PROFILE_SECTIONS = `${BASE_PATH}profileSections`;
export const CREATE_PROFILE_SECTIONS = `${PROFILE_SECTIONS}`;
export const EDIT_PROFILE_SECTIONS = `${PROFILE_SECTIONS}`;
export const DELETE_PROFILE_SECTIONS = `${PROFILE_SECTIONS}/`;
export const LINK_PROFILE_SECTIONS_MEDIA = `${PROFILE_SECTIONS}/media`;

// Profile Cards
export const PROFILE_CARDS = `${PROFILE_SECTIONS}/cards`;
export const CREATE_PROFILE_CARDS = `${PROFILE_CARDS}`;
export const EDIT_PROFILE_CARDS = `${PROFILE_CARDS}`;
export const DELETE_PROFILE_CARDS = `${PROFILE_CARDS}/`;
export const LINK_PROFILE_CARDS_MEDIA = `${PROFILE_CARDS}/media`;

// Restaurant
export const RESTAURANT = `${BASE_PATH}restaurant`;
export const ASSIGN_PACKAGE_TO_RESTAURANT = `${RESTAURANT}/package`;
export const CREATE_RESTAURANT = `${RESTAURANT}`;
export const EDIT_RESTAURANT = `${RESTAURANT}`;
export const GET_RESTAURANTS = `${RESTAURANT}s`;
export const GET_RESTAURANT_DETAILS = `${RESTAURANT}`;
export const GET_SUBMITTED_EMAILS = `${RESTAURANT}/emails`;
export const UPLOAD_RESTAURANT_IMAGES = `${RESTAURANT}/image`;
export const UPDATE_URLS = `${RESTAURANT}/urls`;

// Restrictions
export const GET_RESTRICTIONS = `${BASE_PATH}restrictions`;

// Stripe
export const STRIPE_CHECKOUT = `${BASE_PATH}stripe/checkout`;
export const STRIPE_CUSTOMER_PORTAL = `${BASE_PATH}manager/portal`;
export const STRIPE_SESSION_INFO = `${BASE_PATH}packages/checkout`;

// Tags
export const TAGS = `${BASE_PATH}tags`;
export const CREATE_TAG = `${TAGS}/restaurant`;
export const GET_TAGS = `${TAGS}`;
