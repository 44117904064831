import React, { MutableRefObject } from 'react';
import ReactDOM from 'react-dom';
import Tooltip from '../common/Tooltip';
import { ANNOUNCEMENT_TYPES, AnnouncementType } from '../../constants/AnnouncementTypeConstants';
import { ClosePopUpIcon } from '../../assets/svgs/icons';
import ImageUploader from '../common/Form/ImageUploader';

interface PromotionPreviewProps {
  title?: string;
  description?: string;
  type: AnnouncementType;
  image: string;
  iconRef: MutableRefObject<any>;
  hidden: boolean;
  onBlur: Function;
}

interface PreviewProps {
  previewType: AnnouncementType;
}

const PromotionPreview = ({ title, description, type, image, iconRef, hidden, onBlur }: PromotionPreviewProps) => {
  const Preview = ({ previewType }: PreviewProps) => {
    let PopUp = null;
    let Embed = null;
    switch (previewType) {
      case ANNOUNCEMENT_TYPES.EMBEDDED:
        Embed = (
          <div className="embed-preview-container">
            {image ? (
              <ImageUploader
                height="96px"
                width="100%"
                id="3"
                image={image}
                onUpdate={() => {}}
                readOnly
                animation={false}
              />
            ) : (
              <div className="embed-preview-placeholder">Upload Image</div>
            )}
            {image && (
              <div className="embed-close-button">
                <ClosePopUpIcon stroke="white" width="5" height="6" />
              </div>
            )}
          </div>
        );
        break;
      case ANNOUNCEMENT_TYPES.DRAWER:
        PopUp = (
          <div className="drawer-preview-container">
            <div className={`drawer-preview-content ${image ? 'has-image' : ''}`}>
              <div className={`drawer-preview-header ${image ? 'has-image' : ''}`}>
                {image && (
                  <ImageUploader
                    height="50px"
                    width="100%"
                    id="2"
                    image={image}
                    onUpdate={() => {}}
                    readOnly
                    animation={false}
                  />
                )}
                {image ? (
                  <div className="embed-close-button">
                    <ClosePopUpIcon stroke="white" width="5" height="6" />
                  </div>
                ) : (
                  <ClosePopUpIcon stroke="black" width="4" height="5" />
                )}
              </div>
              <div className={`drawer-preview-body ${image ? 'has-image' : ''}`}>
                <p className="drawer-preview-title">{title ? title.trim() : 'Lorem ipsum dolor sit amet'}</p>
                <p className="drawer-preview-message">
                  {description
                    ? description.trim()
                    : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. \n'}
                </p>
              </div>
            </div>
          </div>
        );
        break;
      case ANNOUNCEMENT_TYPES.MODAL:
      default:
        PopUp = (
          <div className="modal-preview-container">
            <div className={`modal-preview-content ${image ? 'has-image' : ''}`}>
              <div className={`modal-preview-header ${image ? 'has-image' : ''}`}>
                {image && (
                  <ImageUploader
                    height="50px"
                    width="100%"
                    id="2"
                    image={image}
                    onUpdate={() => {}}
                    readOnly
                    animation={false}
                  />
                )}
                {image ? (
                  <div className="embed-close-button">
                    <ClosePopUpIcon stroke="white" width="5" height="6" />
                  </div>
                ) : (
                  <ClosePopUpIcon stroke="black" width="4" height="5" />
                )}
              </div>
              <div className={`modal-preview-body ${image ? 'has-image' : ''}`}>
                <p className="modal-preview-title">{title ? title.trim() : 'Lorem ipsum dolor sit amet'}</p>
                <p className="modal-preview-message">
                  {description
                    ? description.trim()
                    : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. \n' +
                      '\n' +
                      'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. \n'}
                </p>
              </div>
            </div>
          </div>
        );
    }

    return (
      <div className="phone-preview-container">
        {PopUp}
        <div className="phone-preview-content">
          <div className="phone-preview-header">
            <p className="phone-preview-restaurant-name">Restaurant Name</p>
            <p className="phone-preview-menu-name">Menu</p>
            <p className="phone-preview-menu-section-name">MENU SECTION</p>
          </div>
          <div className="phone-preview-body">
            {Embed}
            <p>Menu Section</p>
            <div className="phone-preview-items">
              <div className="phone-preview-item" />
              <div className="phone-preview-item" />
              <div className="phone-preview-item" />
              <div className="phone-preview-item" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return ReactDOM.createPortal(
    <Tooltip className="promotion-preview" iconRef={iconRef} hidden={hidden} onBlur={onBlur} width={135} offset={0}>
      <Preview previewType={type} />
    </Tooltip>,
    document.querySelector('#tooltip-root')
  );
};
export default PromotionPreview;
