import React, { useEffect, useState } from 'react';
import Button from '../common/Button';
import Modal from '../common/Modal';
import { ClosePopUpIcon } from '../../assets/svgs/icons';
import { MenuItemMediaInterface, ThumbnailInterface } from '../../types/MediaInterface';
import { ErrorText, Label } from '../common/Form';
import MediaPreview from '../MediaPreview';
import MediaLibraryModal from '../MediaLibraryModal';
import imageDisplay from '../../assets/images/ImageDisplay.png';

interface VideoModalProps {
  video: MenuItemMediaInterface;
  index: number;
  isEdit: boolean;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (video: MenuItemMediaInterface, index: number, isEdit: boolean) => void;
  onCancel: Function;
  onRemove: Function;
}

const VideoSelectorModal = ({ video, index, isEdit, onSubmit, onCancel, onRemove }: VideoModalProps) => {
  const [localVideo, setLocalVideo] = useState<MenuItemMediaInterface>(null);
  const [localThumbnail, setLocalThumbnail] = useState<ThumbnailInterface>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openVideoLibraryModal, setOpenVideoLibraryModal] = useState<boolean>(false);
  const [openThumbnailLibraryModal, setOpenThumbnailLibraryModal] = useState<boolean>(false);

  useEffect(() => {
    if (video != null) {
      const _video = { ...video };

      if (_video.thumbnail) {
        setLocalThumbnail(_video.thumbnail);
      }

      delete _video.thumbnail;
      setLocalVideo(_video);
    }
  }, [video]);

  const handleVideoUpdate = (media: MenuItemMediaInterface[]) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    setLocalVideo(media?.[0] || null);
  };

  const handleThumbnailUpdate = (media: MenuItemMediaInterface[]) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    setLocalThumbnail(
      media?.length > 0
        ? {
            thumbnailID: media?.[0].mediaID,
            thumbnailURL: media?.[0].mediaURL
          }
        : null
    );
  };

  const handleSubmit = () => {
    if (errorMessage) {
      setErrorMessage('');
    }

    if (!localVideo && localThumbnail) {
      setErrorMessage('Video is required for thumbnail.');
      return;
    }

    if (localVideo && !localThumbnail) {
      setErrorMessage('Thumbnail is required.');
      return;
    }

    if (isEdit && !localVideo && !localThumbnail) {
      onRemove?.(video?.mediaID);
    } else {
      const videoToUpload: MenuItemMediaInterface = {
        ...localVideo,
        thumbnail: localThumbnail
      };
      onSubmit?.(videoToUpload, index, isEdit);
    }
  };

  return (
    <Modal className="video-upload-modal">
      <div className="modal-header">
        <ClosePopUpIcon onIconClicked={onCancel} className="modal-close-icon" />
      </div>
      <div className="modal-body">
        <Label className="video-upload-label" label="UPLOADED VIDEO" />
        <p className="video-upload-prompt">
          Videos can be no larger than 32 MB and no longer than 15 seconds, with an aspect ratio of 9:16.
        </p>
        <div className="video-upload-input">
          {localVideo == null ? (
            <Button className="select-media-button" onClick={() => setOpenVideoLibraryModal(true)}>
              <img className="media-upload-icon" src={imageDisplay} alt="Icon indicating upload" />
              <p>Upload Video</p>
            </Button>
          ) : (
            <MediaPreview media={[localVideo]} onUpdate={handleVideoUpdate} multiple={false} />
          )}
          {openVideoLibraryModal && (
            <MediaLibraryModal
              selectedMediaIDs={localVideo ? [localVideo.mediaID] : []}
              onSelect={(selectedVideo) =>
                setLocalVideo({
                  mediaID: selectedVideo[0].mediaID,
                  mediaURL: selectedVideo[0].mediaUrl,
                  type: selectedVideo[0].type
                })
              }
              onRemove={() => setLocalVideo(null)}
              onClose={() => setOpenVideoLibraryModal(false)}
              mediaTypeOverride="video"
            />
          )}
        </div>
        <Label
          className={`thumbnail-upload-label ${!localVideo && !localThumbnail ? 'is-disabled' : ''}`}
          label="SELECT A THUMBNAIL"
        />
        <p className={`thumbnail-upload-prompt ${!localVideo && !localThumbnail ? 'is-disabled' : ''}`}>
          Select the thumbnail photo that will appear on your menu screen and when your guest clicks on your menu item
          before the video plays. Can be no larger than 750 MB.
        </p>
        <div className={`thumbnail-upload-input ${!localVideo && !localThumbnail ? 'is-disabled' : ''}`}>
          {localThumbnail == null ? (
            <Button className="select-media-button" onClick={() => setOpenThumbnailLibraryModal(true)}>
              <img className="media-upload-icon" src={imageDisplay} alt="Icon indicating upload" />
              <p>Upload Image</p>
            </Button>
          ) : (
            <MediaPreview
              media={[{ mediaID: localThumbnail.thumbnailID, mediaURL: localThumbnail.thumbnailURL, type: 'image' }]}
              onUpdate={handleThumbnailUpdate}
              multiple={false}
            />
          )}
          {openThumbnailLibraryModal && (
            <MediaLibraryModal
              selectedMediaIDs={localThumbnail ? [localThumbnail.thumbnailID] : []}
              onSelect={(selectedThumbnail) =>
                setLocalThumbnail({
                  thumbnailID: selectedThumbnail[0].mediaID,
                  thumbnailURL: selectedThumbnail[0].mediaUrl
                })
              }
              onRemove={() => setLocalThumbnail(null)}
              onClose={() => setOpenThumbnailLibraryModal(false)}
              mediaTypeOverride="image"
            />
          )}
        </div>
      </div>
      {errorMessage && <ErrorText error={errorMessage} />}
      <div className="modal-footer">
        <Button onClick={onCancel} className="modal-cancel-button">
          CANCEL
        </Button>
        <Button onClick={handleSubmit} className="modal-confirm-button">
          CONFIRM
        </Button>
      </div>
    </Modal>
  );
};

export default VideoSelectorModal;
