import { createContext, useState, ReactElement, useMemo, useContext, useCallback } from 'react';

import { PermissionsInterface } from '../../types/AuthenticationInterface';

interface PermissionsContextInterface {
  permissions: PermissionsInterface;
  setUserPermissions: Function;
}

interface PermissionsProviderInterface {
  children: ReactElement;
}

const INITIAL_PERMISSIONS = {
  // TODO: Clean Up pairings permissions. Leaving for now in case we are refactoring how permissions are handled overall
  hasPairings: false,
  hasImageUpload: false
};

const PermissionsContext = createContext<PermissionsContextInterface>({
  permissions: INITIAL_PERMISSIONS,
  setUserPermissions: () => {}
});

const PermissionsProvider = ({ children }: PermissionsProviderInterface) => {
  const [permissions, setPermissions] = useState<PermissionsInterface>(INITIAL_PERMISSIONS);

  const setUserPermissions = useCallback(
    (userPermissions: PermissionsInterface) => {
      setPermissions({ ...permissions, ...userPermissions });
    },
    [permissions]
  );

  const providerValue = useMemo(() => ({ permissions, setUserPermissions }), [permissions, setUserPermissions]);
  return <PermissionsContext.Provider value={providerValue}>{children}</PermissionsContext.Provider>;
};

const usePermissionsContext = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error(`usePermissionsContext must be used within the PermissionsProvider component`);
  }
  return context;
};

export { PermissionsProvider as default, usePermissionsContext };
