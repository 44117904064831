import { Route, Routes } from 'react-router-dom';
import Login from './Login';
import MainContainer from './MainContainer';
import { useAuthContext } from '../contexts/AuthContext';
import ForgotPassword from './ForgotPassword';
import { PAGE_FORGOT_PASSWORD, PAGE_LOGIN, PAGE_RESET_PASSWORD, PAGE_SIGN_UP } from '../constants/UriConstants';
import ResetPassword from './ResetPassword';
import SignUp from './SignUp';
import RestaurantProvider from '../contexts/RestaurantContext';
import MenuProvider from '../contexts/MenuContext';
import MediaLibraryProvider from '../contexts/MediaLibraryContext';

const Pages = () => {
  const { authenticated } = useAuthContext();

  return (
    <Routes>
      <Route path={`/${PAGE_FORGOT_PASSWORD}`} element={<ForgotPassword />} />
      <Route path={`/${PAGE_LOGIN}`} element={<Login />} />
      <Route path={`/${PAGE_RESET_PASSWORD}`} element={<ResetPassword />} />
      <Route path={`/${PAGE_SIGN_UP}`} element={<SignUp />} />
      {authenticated && (
        <Route
          path="/*"
          element={
            <RestaurantProvider>
              <MenuProvider>
                <MediaLibraryProvider>
                  <MainContainer />
                </MediaLibraryProvider>
              </MenuProvider>
            </RestaurantProvider>
          }
        />
      )}
    </Routes>
  );
};

export default Pages;
