import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../common/Button';
import Logo from '../../assets/images/TapTabLargeLogoWhite.png';

function InitialMenusView() {
  const navigate = useNavigate();

  const handleAddMenu = useCallback(() => {
    navigate('/create-menu');
  }, [navigate]);

  return (
    <div
      id="image"
      role="img"
      aria-label="Six asian dishes displayed on a table"
      className="initial-menus-view-container"
    >
      <div className="initial-menus-view-content">
        <img src={Logo} alt="TapTab logo" />
        <h1>Welcome to TapTab!</h1>
        <div className="call-to-action">Add your first menu by selecting the Add Menu button below.</div>
        <Button onClick={handleAddMenu}>ADD MENU</Button>
      </div>
    </div>
  );
}

export default InitialMenusView;
