import { CHANGE_PASSWORD, FORGOT_PASSWORD, LOGIN, RESET_PASSWORD } from '../constants/UriConstants';
import apiClient from './apiClient';
import { LoginResponse } from '../types/AuthenticationInterface';

export const changePassword = async (currentPassword: string, newPassword: string) => {
  await apiClient.put(CHANGE_PASSWORD, { currentPassword, newPassword });
};

export const forgotPassword = async (email: string) => {
  await apiClient.post(FORGOT_PASSWORD, { email });
};

export const login = (email: string, password: string): Promise<LoginResponse> =>
  apiClient.post(LOGIN, { email, password }).then((response) => response.data);

export const resetPassword = async (email: string, tempPassword: string, newPassword: string): Promise<any> =>
  apiClient.post(RESET_PASSWORD, { email, tempPassword, newPassword }).then((response) => response.data);
