const MegaphoneIcon = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 488.5 488.5"
    style={{ background: 'new 0 0 488.5 488.5' }}
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M405.1,210.95v0.4c0,7.5,6.1,13.7,13.7,13.7h56c7.5,0,13.7-6.1,13.7-13.7v-0.4c0-7.5-6.1-13.7-13.7-13.7h-56
			C411.2,197.25,405.1,203.35,405.1,210.95z"
        />
        <path
          d="M406.2,270.75l53,21.1c7.4,2.9,15.6-1,18.1-8.5c2.2-6.8-1.3-14.2-8-16.9l-53-21.1c-7.4-2.9-15.6,1-18.1,8.5
			C396.1,260.75,399.5,268.15,406.2,270.75z"
        />
        <path
          d="M151.8,132.15H56.3c-19,0-34.4,15.4-34.4,34.4v15.3H11.5c-6.4,0-11.5,5.2-11.5,11.5v43.1c0,6.4,5.2,11.5,11.5,11.5h10.4
			v13.2c0,19,15.4,34.4,34.4,34.4h8.6l39.3,130.8l5.9-1.4l19.9-4.7l5.9-1.4l-15.6-123.3h31.5v-14.3c41.8,6.8,110.8,24.5,159,70.2
			c0.5,8.5,8,15.1,16.9,14.1c7.7-0.9,13.3-8,13.3-15.8v-88.5c19.4-7.2,33.2-25.9,33.2-47.7c0-21.9-13.8-40.5-33.2-47.7v-87.8
			c0-7.8-5.5-14.9-13.3-15.8c-8.9-1-16.3,5.6-16.9,14.1c-48.2,45.7-117.2,63.4-159,70.2L151.8,132.15L151.8,132.15z"
        />
        <path
          d="M459.3,130.45l-53,21.1c-6.7,2.7-10.2,10-8,16.9c2.4,7.5,10.7,11.5,18.1,8.5l53-21.1c6.7-2.7,10.2-10,8-16.9
			C474.9,131.45,466.6,127.55,459.3,130.45z"
        />
      </g>
    </g>
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
    <g />
  </svg>
);

export default MegaphoneIcon;
