import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSS } from '@dnd-kit/utilities';
import { DraggableIcon, EditIcon, TrashCanIcon } from '../../assets/svgs/icons';
import { ModifierGroupInterface } from '../../types/ModifierGroupInterface';

interface ModifierGroupProps {
  allowSorting: boolean;
  modifierGroup: ModifierGroupInterface;
  // eslint-disable-next-line no-unused-vars
  onDeleteClick: (id: number) => void;
  // eslint-disable-next-line no-unused-vars
  onEditClick: (modifierGroup: ModifierGroupInterface) => void;
}

const ModifierGroup = ({ allowSorting, modifierGroup, onDeleteClick, onEditClick }: ModifierGroupProps) => {
  const { setNodeRef, listeners, attributes, transform, transition, isDragging } = useSortable({
    id: modifierGroup.modifierGroupID.toString(),
    transition: {
      duration: 150, // milliseconds
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)'
    }
  });

  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const handleEditKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>, modGroup: ModifierGroupInterface) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onEditClick(modGroup);
    }
  };

  return (
    <li
      style={allowSorting ? { ...itemStyle, cursor: isDragging ? 'grab' : 'pointer' } : {}}
      className={`modifier-group-container ${isDragging ? 'is-dragging' : ''}`}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...(allowSorting ? attributes : {})}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...(allowSorting ? listeners : {})}
      ref={allowSorting ? setNodeRef : null}
    >
      <div className="modifier-group-name">
        {allowSorting && <DraggableIcon width="12px" height="6px" />}
        {modifierGroup.label}
      </div>
      <div className="modifier-group-icons">
        <button
          type="button"
          aria-label="Edit Modifier Group"
          onClick={() => onEditClick(modifierGroup)}
          onKeyDown={(event) => handleEditKeyDown(event, modifierGroup)}
        >
          <EditIcon height="18" width="15" />
        </button>
        <TrashCanIcon onIconClicked={() => onDeleteClick(modifierGroup.modifierGroupID)} height="17" width="12" />
      </div>
    </li>
  );
};

export default ModifierGroup;
