import { createContext, ReactElement, useMemo, useContext, useReducer } from 'react';
import GenericErrorModal from '../../components/GenericErrorModal';
import { ModalActions } from '../../constants/ContextActions';

interface ModalStateInterface {
  modalTitle: string;
  modalMessage: string;
  isOpen: boolean;
  onDismiss: Function;
}

interface ModalActionInterface {
  title: string;
  message: string;
  type: string;
  onDismiss: Function;
}

interface ModalContextInterface {
  state: ModalStateInterface;
  dispatch: any;
}

interface ModalProviderInterface {
  children: ReactElement;
}

const initialState = {
  modalTitle: '',
  modalMessage: '',
  isOpen: false,
  onDismiss: () => {}
};

const ModalContext = createContext<ModalContextInterface>({
  dispatch: () => {},
  state: initialState
});

const modalReducer = (state: ModalStateInterface, action: ModalActionInterface) => {
  switch (action.type) {
    case ModalActions.OPEN_MODAL:
      return { isOpen: true, onDismiss: action.onDismiss, modalTitle: action.title, modalMessage: action.message };
    case ModalActions.DISMISS_MODAL:
      return initialState;
    default: {
      return state;
    }
  }
};

const ModalProvider = ({ children }: ModalProviderInterface) => {
  const [state, dispatch] = useReducer(modalReducer, initialState);

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return (
    <ModalContext.Provider value={providerValue}>
      <GenericErrorModal />
      {children}
    </ModalContext.Provider>
  );
};

interface OpenModalProps {
  dispatch: any;
  title?: string;
  message?: string;
  onDismiss?: Function;
}
const openModal = ({ dispatch, title, message, onDismiss }: OpenModalProps) => {
  dispatch({ type: ModalActions.OPEN_MODAL, title, message, onDismiss });
};

const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(`ModalContext must be used within the ModalProvider component`);
  }
  return context;
};

export { ModalProvider as default, useModalContext, openModal };
