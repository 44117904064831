import apiClient from './apiClient';
import {
  CREATE_DRINK_ITEM,
  DELETE_DRINK_ITEM,
  EDIT_DRINK_ITEM,
  GET_DRINK_ITEMS,
  HIDE_SHOW_DRINK_ITEM
} from '../constants/UriConstants';
import { CreateMenuItemInterface, DetailedMenuItemInterface } from '../types/MenuItemInterface';
import { PairingInterface } from '../types/PairingInterface';

export const createDrinkItem = (drinkItem: CreateMenuItemInterface) =>
  apiClient.post(CREATE_DRINK_ITEM, drinkItem).then((response) => response.data);

export const deleteDrinkItem = async (drinkItemID: number) => {
  await apiClient.delete(DELETE_DRINK_ITEM + drinkItemID);
};

export const editDrinkItem = async (drinkItem: DetailedMenuItemInterface, menuSectionID: number) => {
  await apiClient.put(EDIT_DRINK_ITEM, { ...drinkItem, menuSectionID });
};

export const getDrinkItems = (): Promise<PairingInterface[]> =>
  apiClient.get(GET_DRINK_ITEMS).then((response) => response.data);

export const showDrinkItem = async (drinkItemID: number) => {
  await apiClient.put(HIDE_SHOW_DRINK_ITEM, { menuItemID: drinkItemID, hide: false });
};

export const hideDrinkItem = async (drinkItemID: number) => {
  await apiClient.put(HIDE_SHOW_DRINK_ITEM, { menuItemID: drinkItemID, hide: true });
};
