import { ReactNode, useEffect, useRef } from 'react';

interface HorizontalScrollBarProps {
  items: any[];
  className: string;
  children: ReactNode;
  showLeftArrow: Function;
  showRightArrow: Function;
}

const HorizontalScrollBar = ({
  children,
  className,
  items,
  showLeftArrow,
  showRightArrow
}: HorizontalScrollBarProps) => {
  const scrollBarRef = useRef(null);

  useEffect(() => {
    const handleHorizontalScroll = () => {
      if (scrollBarRef?.current.scrollLeft !== 0) {
        showLeftArrow(true);
      } else {
        showLeftArrow(false);
      }
    };
    const sensorDiv = scrollBarRef.current;
    if (sensorDiv) {
      sensorDiv.addEventListener('scroll', handleHorizontalScroll);
    }

    return () => {
      sensorDiv?.removeEventListener('scroll', handleHorizontalScroll);
    };
  }, [showLeftArrow]);

  useEffect(() => {
    if (scrollBarRef && items.length > 0) {
      if (scrollBarRef.current.scrollWidth > scrollBarRef.current.clientWidth) {
        showRightArrow(true);
      } else {
        showRightArrow(false);
      }
    }
  }, [scrollBarRef, items.length, showRightArrow]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (scrollBarRef.current.scrollWidth > scrollBarRef.current.clientWidth) {
        showRightArrow(true);
      } else {
        showRightArrow(false);
      }
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [showRightArrow]);

  return (
    <div className={className} ref={scrollBarRef}>
      {children}
    </div>
  );
};

export default HorizontalScrollBar;
