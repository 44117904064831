import { Input } from '../../../components/common/Form';
import Button from '../../../components/common/Button';
import InstagramIcon from '../../../assets/svgs/icons/Socials/InstagramIcon';
import FacebookIcon from '../../../assets/svgs/icons/Socials/FacebookIcon';
import SnapchatIcon from '../../../assets/svgs/icons/Socials/SnapchatIcon';
import TiktokIcon from '../../../assets/svgs/icons/Socials/TiktokIcon';
import TwitterIcon from '../../../assets/svgs/icons/Socials/TwitterIcon';
import SocialInputLabel from '../../../components/SocialsInputLabel';
import { handleHttpsInput } from '../../../utils/general';

interface RestaurantSocialsPageProps {
  formik: any;
  onNext: Function;
  onBack: Function;
}

const RestaurantSocialsPage = ({ formik, onNext, onBack }: RestaurantSocialsPageProps) => {
  const handleNextClicked = () => {
    formik.setTouched(
      { socials: { facebook: true, instagram: true, snapchat: true, tiktok: true, twitter: true } },
      true
    );

    const hasNoErrors =
      !formik.errors.socials?.facebook &&
      !formik.errors.socials?.instagram &&
      !formik.errors.socials?.snapchat &&
      !formik.errors.socials?.tiktok &&
      !formik.errors.socials?.twitter;
    if (hasNoErrors) {
      onNext?.();
    }
  };

  const handleInputChange = (name: string, value: string) => {
    const modifiedValue = handleHttpsInput(value);
    formik.setFieldValue(name, modifiedValue);
  };

  return (
    <div className="restaurant-socials-page">
      <div className="restaurant-wizard-socials-inputs">
        <Input
          name="socials.instagram"
          label={<SocialInputLabel label="instagram" icon={<InstagramIcon />} />}
          className="restaurant-wizard-socials-input"
          onChange={(event: any) => handleInputChange('socials.instagram', event.target.value)}
          onBlur={formik.handleBlur}
          value={formik.values.socials.instagram}
          touched={formik.touched?.socials?.instagram}
          error={formik.errors?.socials?.instagram}
          placeholder="https://instagram.com/username"
        />
        <Input
          name="socials.tiktok"
          label={<SocialInputLabel label="tiktok" icon={<TiktokIcon />} />}
          className="restaurant-wizard-socials-input"
          onChange={(event: any) => handleInputChange('socials.tiktok', event.target.value)}
          onBlur={formik.handleBlur}
          value={formik.values.socials.tiktok}
          touched={formik.touched?.socials?.tiktok}
          error={formik.errors?.socials?.tiktok}
          placeholder="https://tiktok.com/@username"
        />
        <Input
          name="socials.facebook"
          label={<SocialInputLabel label="facebook" icon={<FacebookIcon />} />}
          className="restaurant-wizard-socials-input"
          onChange={(event: any) => handleInputChange('socials.facebook', event.target.value)}
          onBlur={formik.handleBlur}
          value={formik.values.socials.facebook}
          touched={formik.touched?.socials?.facebook}
          error={formik.errors?.socials?.facebook}
          placeholder="https://facebook.com/username"
        />
        <Input
          name="socials.twitter"
          label={<SocialInputLabel label="twitter" icon={<TwitterIcon />} />}
          className="restaurant-wizard-socials-input"
          onChange={(event: any) => handleInputChange('socials.twitter', event.target.value)}
          onBlur={formik.handleBlur}
          value={formik.values.socials.twitter}
          touched={formik.touched?.socials?.twitter}
          error={formik.errors?.socials?.twitter}
          placeholder="https://twitter.com/username"
        />
        <Input
          name="socials.snapchat"
          label={<SocialInputLabel label="snapchat" icon={<SnapchatIcon />} />}
          className="restaurant-wizard-socials-input"
          onChange={(event: any) => handleInputChange('socials.snapchat', event.target.value)}
          onBlur={formik.handleBlur}
          value={formik.values.socials.snapchat}
          touched={formik.touched?.socials?.snapchat}
          error={formik.errors?.socials?.snapchat}
          placeholder="https://snapchat.com/add/username"
        />
      </div>
      <Button className="restaurant-wizard-back-button" onClick={onBack}>
        BACK
      </Button>
      <Button className="restaurant-wizard-next-button" onClick={handleNextClicked}>
        NEXT
      </Button>
    </div>
  );
};

export default RestaurantSocialsPage;
