import { useRef, useState } from 'react';
import { ThreeDotIcon } from '../../assets/svgs/icons';
import ContextMenu from '../ContextMenu';

interface ContextMenuIconButtonProps {
  itemID: number;
  isHidden?: boolean;
  onEditClicked?: Function;
  onHideShowClicked?: Function;
  onDeleteClicked?: Function;
  onDownloadClicked?: Function;
  onRenameClicked?: Function;
}
const ContextMenuIconButton = ({
  itemID,
  onDeleteClicked,
  onDownloadClicked,
  onEditClicked,
  onHideShowClicked,
  onRenameClicked,
  isHidden = false
}: ContextMenuIconButtonProps) => {
  const [showContextMenu, setShowContextMenu] = useState<number>(-1);
  const ref = useRef(null);

  const handleContextMenuClicked = (func: Function) => {
    setShowContextMenu(-1);
    func?.();
  };

  return (
    <div ref={ref} className="context-menu-action-container">
      <ThreeDotIcon onIconClicked={() => setShowContextMenu(itemID)} />
      <ContextMenu
        iconRef={ref}
        isHidden={isHidden}
        hidden={!(showContextMenu === itemID)}
        onBlur={() => {
          setShowContextMenu(-1);
        }}
        onEditClicked={onEditClicked ? () => handleContextMenuClicked(onEditClicked(itemID)) : null}
        onHideShowClicked={onHideShowClicked ? () => handleContextMenuClicked(onHideShowClicked) : null}
        onDeleteClicked={onDeleteClicked ? () => handleContextMenuClicked(onDeleteClicked(itemID)) : null}
        onDownloadClicked={onDownloadClicked ? () => handleContextMenuClicked(onDownloadClicked(itemID)) : null}
        onRenameClicked={onRenameClicked ? () => handleContextMenuClicked(onRenameClicked) : null}
      />
    </div>
  );
};

export default ContextMenuIconButton;
