import { useField } from 'formik';
import { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTime } from 'luxon';
import { ErrorText, Label } from '..';

// eslint-disable-next-line no-unused-vars
const CustomInput = forwardRef((props: any, ref) => {
  const { onChange, value: val, id, onClick } = props;
  return (
    <div className="mt-2">
      <input
        className="date-time-picker-input p-4 w-full text-sm rounded-sm border border-neutral-400"
        onChange={onChange}
        value={val}
        id={id}
        onClick={onClick}
      />
    </div>
  );
});

interface DateTimePickerProps {
  label?: string;
  name: string;
  minDate?: string;
  className?: string;
}

const DateTimePicker = ({ label, name, minDate, className = '' }: DateTimePickerProps) => {
  const [, meta, { setValue }] = useField(name);
  const { value } = meta;
  const error = meta.touched ? meta.error : '';

  const [date, setDate] = useState<Date>(null);

  useEffect(() => {
    if (date == null) {
      setDate(value ? new Date(value) : new Date());
    }
  }, [value, date]);

  const handleDateTimeChange = (val: Date) => {
    setDate(val);
    if (val == null) {
      setValue('');
    } else {
      setValue(DateTime.fromJSDate(val).toISO({ includeOffset: false }));
    }
  };

  return (
    <div className={`date-time-picker-container ${className}`}>
      <Label className="date-time-picker-label" htmlFor={name} label={label} />
      <DatePicker
        selected={date}
        onChange={(_date) => handleDateTimeChange(_date)}
        showTimeInput
        dateFormat="MM/dd/yyyy h:mm aa"
        customInput={<CustomInput />}
        minDate={minDate ? new Date(minDate) : new Date()}
      />
      {error && <ErrorText error={error} />}
    </div>
  );
};

export default DateTimePicker;
