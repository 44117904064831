import React from 'react';

interface PlusIconInterface {
  width?: string;
  height?: string;
  onIconClicked?: Function;
}

const PlusIcon = ({ width = '1rem', height = '1rem', onIconClicked }: PlusIconInterface) => {
  const handleIconClicked = () => {
    onIconClicked?.();
  };

  const handleIconKeyPress = (event: React.KeyboardEvent<SVGSVGElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      onIconClicked?.();
    }
  };

  return (
    <svg
      className="plus-icon"
      role="button"
      tabIndex={0}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleIconClicked}
      onKeyPress={handleIconKeyPress}
    >
      <path d="M1.4707 9.00001H16.5295" stroke="black" strokeWidth="2" strokeLinecap="square" />
      <path d="M9.00026 16.5294V1.47058" stroke="black" strokeWidth="2" strokeLinecap="square" />
    </svg>
  );
};

export default PlusIcon;
