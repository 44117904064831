import apiClient from './apiClient';
import {
  CREATE_PROFILE_SECTIONS,
  EDIT_PROFILE_SECTIONS,
  DELETE_PROFILE_SECTIONS,
  LINK_PROFILE_SECTIONS_MEDIA,
  CREATE_PROFILE_CARDS,
  EDIT_PROFILE_CARDS,
  LINK_PROFILE_CARDS_MEDIA,
  DELETE_PROFILE_CARDS
} from '../constants/UriConstants';
import {
  CreateProfileSectionCardRequestInterface,
  CreateProfileSectionCardResponseInterface,
  CreateProfileSectionRequestInterface,
  CreateProfileSectionResponseInterface,
  DeleteProfileSectionCardRequestInterface,
  DeleteProfileSectionRequestInterface,
  EditProfileSectionCardRequestInterface,
  EditProfileSectionRequestInterface
} from '../types/ProfileSectionInterface';
import {
  UpdateProfileSectionCardMediaRequestInterface,
  LinkProfileSectionMediaRequestInterface
} from '../types/MediaInterface';

export const createProfileSection = async (
  request: CreateProfileSectionRequestInterface
): Promise<CreateProfileSectionResponseInterface> =>
  apiClient.post(CREATE_PROFILE_SECTIONS, request).then((response) => response.data);

export const editProfileSection = async (request: EditProfileSectionRequestInterface) =>
  apiClient.put(EDIT_PROFILE_SECTIONS, request);

export const linkMediasToProfileSection = async (request: LinkProfileSectionMediaRequestInterface) =>
  apiClient.put(LINK_PROFILE_SECTIONS_MEDIA, request);

export const createProfileSectionCard = async (
  request: CreateProfileSectionCardRequestInterface
): Promise<CreateProfileSectionCardResponseInterface> =>
  apiClient.post(CREATE_PROFILE_CARDS, request).then((response) => response.data);

export const editProfileSectionCard = async (request: EditProfileSectionCardRequestInterface) =>
  apiClient.put(EDIT_PROFILE_CARDS, request);

export const updateMediaForProfileSectionCard = async (request: UpdateProfileSectionCardMediaRequestInterface) =>
  apiClient.put(LINK_PROFILE_CARDS_MEDIA, request);

export const deleteProfileSection = async (request: DeleteProfileSectionRequestInterface) =>
  apiClient.delete(DELETE_PROFILE_SECTIONS + request.sectionID);

export const deleteProfileSectionCard = async (request: DeleteProfileSectionCardRequestInterface) =>
  apiClient.delete(DELETE_PROFILE_CARDS + request.cardID);
