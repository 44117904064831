import { FieldArray, useField } from 'formik';
import { v4 as uuid } from 'uuid';
import { InferType, object, string } from 'yup';

import { FormikInput, Label } from '..';
import { PlusIcon, TrashCanIcon } from '../../../../assets/svgs/icons';

interface MultiInputProps {
  className?: string;
  label?: string;
  name: string;
  placeholder?: string;
}

export const MultiInputRowSchema = object({
  id: string().uuid(),
  name: string().default('').required('Menu Section is required.')
});

const MultiInput = ({ className, label, name, placeholder }: MultiInputProps) => {
  const [, meta] = useField(name);
  const { value } = meta;

  return (
    <div>
      <Label htmlFor={name} label={label} />
      <FieldArray name={name}>
        {({ remove, push }) => (
          <div className={className}>
            {value.length > 0 &&
              value.map((entry: InferType<typeof MultiInputRowSchema>, index: number) => (
                <div className="flex" key={entry.id}>
                  <FormikInput name={`${name}.${index}.name`} className="w-full mr-4" placeholder={placeholder} />
                  {index === 0 ? (
                    <button
                      type="button"
                      className="h-fit mt-7"
                      onClick={() => push(MultiInputRowSchema.cast({ id: uuid() }))}
                    >
                      <PlusIcon width="12" height="12" />
                    </button>
                  ) : (
                    <button type="button" className="h-fit mt-7 pr-[1px] pl-[1px]" onClick={() => remove(index)}>
                      <TrashCanIcon />
                    </button>
                  )}
                </div>
              ))}
          </div>
        )}
      </FieldArray>
    </div>
  );
};
export default MultiInput;
