import { createContext, ReactElement, useMemo, useContext, useReducer } from 'react';
import { AlertActions } from '../../constants/ContextActions';

interface AlertStateInterface {
  title: string;
  message: string;
  isOpen: boolean;
  onConfirm: Function;
  onCancel?: Function;
}

interface AlertActionInterface {
  title: string;
  message: string;
  type: string;
  onConfirm: Function;
  onCancel?: Function;
}

interface AlertContextInterface {
  state: AlertStateInterface;
  dispatch: any;
}

interface AlertProviderInterface {
  children: ReactElement;
}

const initialState = {
  title: '',
  message: '',
  isOpen: false,
  onConfirm: () => {},
  onCancel: () => {}
};

const AlertContext = createContext<AlertContextInterface>({
  dispatch: () => {},
  state: initialState
});

const alertReducer = (state: AlertStateInterface, action: AlertActionInterface) => {
  switch (action.type) {
    case AlertActions.DISPLAY_ALERT:
      return {
        isOpen: true,
        message: action.message,
        title: action.title,
        onCancel: action.onCancel,
        onConfirm: action.onConfirm
      };
    case AlertActions.HIDE_ALERT:
      return initialState;
    default: {
      return state;
    }
  }
};

const AlertProvider = ({ children }: AlertProviderInterface) => {
  const [state, dispatch] = useReducer(alertReducer, initialState);

  const providerValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return <AlertContext.Provider value={providerValue}>{children}</AlertContext.Provider>;
};

interface DisplayAlertProps {
  dispatch: any;
  title: string;
  message: string;
  onConfirm: Function;
  onCancel?: Function;
}

interface DismissAlertProps {
  dispatch: any;
}

const displayAlert = ({ dispatch, title, message, onConfirm, onCancel }: DisplayAlertProps) => {
  dispatch({ type: AlertActions.DISPLAY_ALERT, title, message, onConfirm, onCancel });
};

const dismissAlert = ({ dispatch }: DismissAlertProps) => {
  dispatch({ type: AlertActions.HIDE_ALERT });
};

const useAlertContext = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error(`AlertContext must be used within the AlertProvider component`);
  }
  return context;
};

export { AlertProvider as default, useAlertContext, displayAlert, dismissAlert };
