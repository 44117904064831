import { FieldArray, useField } from 'formik';
// TODO: Write our own internal TimePicker component so we can remove these dependencies
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { v4 as uuid } from 'uuid';
import { InferType, object, string } from 'yup';

import { ErrorText, Label } from '..';
import { PlusIcon, TrashCanIcon } from '../../../../assets/svgs/icons';
import { DAYS_ABBREVIATIONS } from '../../../../constants/weekDayConstants';

interface DaysAndHoursPickerProps {
  label?: string;
  name: string;
}

export const DayAndHourPickerRowSchema = object({
  id: string().uuid().required(),
  startTime: string().default('07:00').required(),
  endTime: string().default('17:00').required(),
  daysOfWeek: object()
    .default(DAYS_ABBREVIATIONS)
    .required()
    .test('day-value', 'At least one day must be selected', (value: object) => Object.values(value).includes(true))
});

const MobileFormikDaysAndHoursPicker = ({ label, name }: DaysAndHoursPickerProps) => {
  const [, meta, { setValue }] = useField(name);
  const { value } = meta;
  const error = meta.touched ? meta.error : '';
  const timeFormat = 'HH:mm';
  // TODO: handle pm times and 12 am
  const getMomentFromString = (time: string) => {
    const [hour, minute] = time.split(':');
    return moment().hour(Number(hour)).minute(Number(minute));
  };

  const handleStartTimeChange =
    (entry: InferType<typeof DayAndHourPickerRowSchema>, index: number) => (time: moment.Moment) => {
      const timeString = time.format(timeFormat);
      value.splice(index, 1, { ...entry, startTime: timeString });
      setValue(value);
    };

  const handleEndTimeChange =
    (entry: InferType<typeof DayAndHourPickerRowSchema>, index: number) => (time: moment.Moment) => {
      value.splice(index, 1, { ...entry, endTime: time.format(timeFormat) });
      setValue(value);
    };

  const handleDayOfWeekClicked = (entry: InferType<typeof DayAndHourPickerRowSchema>, index: number, day: string) => {
    value.splice(index, 1, { ...entry, daysOfWeek: { ...entry.daysOfWeek, [day]: !entry.daysOfWeek[day] } });
    setValue(value);
  };

  const gridClasses = 'grid gap-2 grid-cols-[315px_100px_100px_31px]';

  return (
    <>
      <Label htmlFor={name} label={label} />
      <FieldArray name={name}>
        {({ remove, push }) => (
          <div>
            {value?.length > 0 &&
              value?.map((entry: InferType<typeof DayAndHourPickerRowSchema>, index: number) => (
                <div key={entry.id} className={`${gridClasses} mb-[19px]`}>
                  <div>
                    <div>
                      <div className="mb-[10px] text-sm font-semibold tracking-wider">DAYS OF THE WEEK</div>
                      {Object.keys(DAYS_ABBREVIATIONS).map((day) => (
                        <button
                          type="button"
                          key={day}
                          className={`day-picker w-[40px] h-[40px] mr-1 mb-[20px] ${
                            entry.daysOfWeek[day] ? 'bg-green-700 text-white' : 'bg-neutral-200 text-neutral-500'
                          }`}
                          onClick={() => handleDayOfWeekClicked(entry, index, day)}
                        >
                          {day}
                        </button>
                      ))}
                    </div>
                    <div className="flex flex-row gap-5">
                      <div>
                        <div
                          className={`${gridClasses}day-hour-picker-label ml-[4px] text-sm font-semibold mb-[7px] tracking-wider`}
                        >
                          START TIME
                        </div>
                        <TimePicker
                          className="time-picker !flex items-center !w-[100px]"
                          showSecond={false}
                          use12Hours
                          allowEmpty={false}
                          value={getMomentFromString(entry.startTime)}
                          onChange={handleStartTimeChange(entry, index)}
                        />
                      </div>
                      <div>
                        <div
                          className={`${gridClasses}day-hour-picker-label ml-[14px] text-sm font-semibold mb-[7px] tracking-wider`}
                        >
                          END TIME
                        </div>
                        <TimePicker
                          className="time-picker !flex items-center !w-[100px] mb-[20px] ml-[14px]"
                          showSecond={false}
                          use12Hours
                          allowEmpty={false}
                          defaultValue={getMomentFromString(entry.endTime)}
                          onChange={handleEndTimeChange(entry, index)}
                        />
                      </div>
                      <div className="flex mt-[10px]">
                        {index === 0 ? (
                          <button
                            type="button"
                            className="h-fit self-center justify-self-center"
                            onClick={() => push(DayAndHourPickerRowSchema.cast({ id: uuid() }))}
                          >
                            <PlusIcon width="10" height="10" />
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="h-fit self-center justify-self-center"
                            onClick={() => remove(index)}
                          >
                            <TrashCanIcon />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </FieldArray>
      {error && <ErrorText error="Value is required." />}
    </>
  );
};

export default MobileFormikDaysAndHoursPicker;
