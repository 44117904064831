import React from 'react';

interface ConfirmationDisplayItemProps {
  label: string;
  value: string;
}

const ConfirmationDisplayItem = ({ label, value }: ConfirmationDisplayItemProps) => (
  <div className="restaurant-confirmation-display">
    <span className="restaurant-confirmation-label">{label}:</span>
    <span className="restaurant-confirmation-value">{value || '--'}</span>
  </div>
);

export default ConfirmationDisplayItem;
