import { Form, Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import { InferType, object, string } from 'yup';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/common/Button/Button';
import { login } from '../../api/authentication';
import { ErrorText, FormikInput, PasswordInput } from '../../components/common/Form';
import { useAuthContext } from '../../contexts/AuthContext';
import logoWithName from '../../images/TapTabLogoWhiteSmallWithName.png';
import { PAGE_FORGOT_PASSWORD } from '../../constants/UriConstants';
import { usePermissionsContext } from '../../contexts/PermissionsContext';

const LoginSchema = object({
  email: string().max(50).default('').email('Must be a valid email').required('Email is required'),
  password: string().default('').required('Password is required')
});

const Login = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setToken } = useAuthContext();
  const { setUserPermissions } = usePermissionsContext();
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(null);

  const handleLogin = ({ email, password }: InferType<typeof LoginSchema>) => {
    setIsLoading(true);
    login(email, password)
      .then((data) => {
        if (data?.token) {
          setIsLoading(false);
          setLoginError(null);
          setToken(data.token);
          setUserPermissions({ hasImageUpload: data.hasImageUpload, hasPairings: data.hasPairings });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setLoginError('Invalid login credentials');
        } else if (error.request) {
          // The request was made but no response was received
          setLoginError('Unable to process your login request at this time');
        } else {
          // Something happened in setting up the request that triggered an Error
          setLoginError('Invalid login credentials');
        }
      });
  };

  const handleForgotPasswordClicked = useCallback(() => {
    navigate(`/${PAGE_FORGOT_PASSWORD}`);
  }, [navigate]);

  const handleForgotPasswordKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      if (event.code === 'Enter' || event.code === 'Space') {
        navigate(`/${PAGE_FORGOT_PASSWORD}`);
      }
    },
    [navigate]
  );

  return (
    // url value is relative to index.css where tailwind is imported
    <div
      className={`h-screen w-screen bg-[#000]/[0.8] bg-[url('../images/LoginBackground.png')] bg-blend-darken backdrop-blur-sm bg-cover`}
    >
      <div className="h-screen w-screen backdrop-blur-md flex justify-center items-center">
        <div className="inline-block">
          <div className="flex items-center mb-10">
            <img src={logoWithName} className="h-[50px] pr-2" alt="logo" />
            <p className="text-[21px] text-white border-l-2 border-white pl-3">TapManager</p>
          </div>
          {loginError && <ErrorText error={loginError} />}
          <Formik initialValues={LoginSchema.cast({})} validationSchema={LoginSchema} onSubmit={handleLogin}>
            {({ errors, touched }) => (
              <Form className="grid">
                <FormikInput
                  name="email"
                  autoComplete="email"
                  placeholder="Email"
                  className="w-full mb-2"
                  onChange={() => setLoginError(null)}
                />
                <PasswordInput
                  name="password"
                  placeholder="Password"
                  className="w-full"
                  errors={errors}
                  touched={touched}
                  onChange={() => setLoginError(null)}
                />
                <Button
                  submit
                  overrideCSS
                  className="w-full h-[50px] mt-4 bg-[#979797] text-white text-[18px]"
                  isLoading={isLoading}
                >
                  Login
                </Button>
                <a
                  role="link"
                  className="w-full mt-4 text-white text-[12px] text-center cursor-pointer"
                  tabIndex={0}
                  onClick={handleForgotPasswordClicked}
                  onKeyPress={handleForgotPasswordKeyPress}
                >
                  Forgot Password?
                </a>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
