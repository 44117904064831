import React, { useEffect, useMemo, useState } from 'react';
import imageDisplay from '../../../../assets/images/ImageDisplay.png';
import { TrashCanIcon } from '../../../../assets/svgs/icons';
import { ErrorText } from '../index';
import { sleep } from '../../../../utils/general';
import LoadingSpinner from '../../LoadingSpinner';
import NoImageDisplay from '../../../NoImageDisplay';

interface ImageUploaderProps {
  id: string;
  image: Blob | string;
  width?: string;
  height?: string;
  onUpdate: Function;
  readOnly?: boolean;
  animation?: boolean;
}

const SIZE_LIMIT = 750000;
const ImageUploader = ({
  id,
  image,
  onUpdate,
  readOnly = false,
  width = '245px',
  height = '190px',
  animation = true
}: ImageUploaderProps) => {
  const [uploadedImage, setUploadedImage] = useState<string>(null);
  const [error, setError] = useState<string>('');
  const [loaded, setLoaded] = useState<boolean>(false);
  const reader = useMemo(() => new FileReader(), []);
  reader.onloadend = () => {
    setUploadedImage(reader.result.toString());
  };

  useEffect(() => {
    if (image) {
      if (typeof image === 'string') {
        setUploadedImage(image);
        return;
      }
      reader.readAsDataURL(image);
    }
  }, [image, reader]);

  const handleImageUpload = (event: any) => {
    if (event?.target?.files[0]?.size <= SIZE_LIMIT) {
      setError('');
      onUpdate(event?.target?.files[0]);
    } else {
      setError('File size exceeds limit of 750 KB.');
    }
  };

  const handleImageDelete = () => {
    onUpdate(null);
    setLoaded(false);
  };

  if (image) {
    return (
      <div className="image-preview-container">
        {!loaded && animation && <LoadingSpinner />}
        {uploadedImage && (
          <img
            className="image-preview-image"
            style={{ display: loaded ? 'initial' : 'none' }}
            src={uploadedImage}
            onLoad={async () => {
              if (animation) {
                // sleep to make animations look smoother
                await sleep(500);
              }
              setLoaded(true);
            }}
            alt="preview of upload"
          />
        )}
        {!readOnly && loaded && <TrashCanIcon width="1rem" height="1.25rem" onIconClicked={handleImageDelete} />}
      </div>
    );
  }

  if (!image && readOnly) {
    return <NoImageDisplay />;
  }

  return (
    <div style={{ width: 'fit-content' }}>
      <label style={{ width, height }} htmlFor={`imgUpload${id}`}>
        <div className="image-upload-container">
          <img className="image-upload-icon" src={imageDisplay} alt="Icon indicating upload" />
          <p>Select Photo</p>
          <input
            id={`imgUpload${id}`}
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            onClick={(event) => {
              // Allows for the same image to be uploaded multiple times (e.g. any time a failure occurs)
              // @ts-ignore
              // eslint-disable-next-line no-param-reassign
              event.target.value = null;
            }}
          />
        </div>
      </label>
      <ErrorText className="mt-2" error={error} />
    </div>
  );
};

export default ImageUploader;
