import React, { useState } from 'react';
import Modal from '../common/Modal';
import { ErrorText, Input, Label } from '../common/Form';
import Tag from '../common/Tag';
import { ClosePopUpIcon } from '../../assets/svgs/icons';
import Button from '../common/Button';
import { createTag } from '../../api/tags';
import { CreateTagResponseInterface } from '../../types/TagInterface';
import { useTagsContext } from '../../contexts/TagsContext';

interface CustomTagModalProps {
  tagName: string;
  onSubmit: Function;
  onCancel: Function;
}

const CustomTagModal = ({ tagName, onSubmit, onCancel }: CustomTagModalProps) => {
  const [tagNameInput, setTagNameInput] = useState<string>(tagName);
  const [nameErrorMessage, setNameErrorMessage] = useState<string>('');
  const [tagColorError, setTagColorError] = useState<boolean>(false);
  const [tagColorInput, setTagColorInput] = useState<string>('#FF6937');
  const [colorErrorMessage, setColorErrorMessage] = useState<string>('');
  const [tagColorPreview, setTagColorPreview] = useState<string>('#FF6937');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { tags } = useTagsContext();

  const HEX_REGEX: RegExp = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  const COLORS: string[] = ['#FF6937', '#3977EB', '#05944F', '#19B3D7', '#7356BF'];

  const handleTagNameChange = (event: any) => {
    if (errorMessage) {
      setErrorMessage('');
    }
    if (nameErrorMessage) {
      setNameErrorMessage('');
    }

    setTagNameInput(event.target.value);
  };

  const handleTagColorChange = (colorInput: string) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    setTagColorInput(colorInput);
    if (HEX_REGEX.test(colorInput.trim())) {
      setTagColorError(false);
      setTagColorPreview(colorInput.trim());
    } else {
      setTagColorError(true);
    }
  };

  const handleSaveTag = async () => {
    if (tagNameInput?.trim()?.length === 0) {
      setNameErrorMessage('Name is required.');
      return;
    }

    if (tagColorError) {
      setColorErrorMessage('Hex value is required.');
      return;
    }

    if (tags?.some((tag) => tag.name === tagNameInput?.trim() && tag.tagColor?.trim() === tagColorInput?.trim())) {
      setErrorMessage(`Tag already exists with name: ${tagNameInput.trim()} and color: ${tagColorInput.trim()}.`);
      return;
    }

    try {
      const createdTag: CreateTagResponseInterface = await createTag(tagNameInput.trim(), tagColorInput.trim());
      onSubmit({ ...createdTag, tagColor: createdTag.color });
    } catch (error) {
      if (error.response?.status === 409) {
        setErrorMessage(`Tag already exists with name: ${tagNameInput.trim()} and color: ${tagColorInput.trim()}.`);
      } else {
        setErrorMessage('Unexpected error has occurred. Please check your input and try again.');
      }
    }
  };

  return (
    <Modal className="custom-tag-modal-container">
      <div className="modal-header">
        <h1 className="modal-title">Create new Tag</h1>
        <ClosePopUpIcon onIconClicked={onCancel} width="16" height="16" />
      </div>
      <div className="modal-body">
        <div className="tag-preview-container">
          <Label label="Preview" />
          <Tag tag={{ tagID: 0, tagColor: tagColorPreview, name: tagNameInput.trim() || 'Preview' }} />
        </div>
        <Input
          className="tag-name-input"
          label="Tag Name"
          name="tag-name-input"
          onChange={handleTagNameChange}
          value={tagNameInput}
          error={nameErrorMessage}
          touched
          placeholder="New TapTag name..."
        />
        <div className="color-preview-container">
          <Label label="Color" htmlFor="tag-color-input" />
          <div className="color-preview" style={{ backgroundColor: tagColorPreview }} />
          <Input
            className={`tag-color-input ${tagColorError ? 'has-error' : ''}`}
            name="tag-color-input"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleTagColorChange(event.target.value)}
            value={tagColorInput}
            placeholder="# (Hex value starts with '#')"
            error={colorErrorMessage}
            maxLength={7}
            touched
          />
          <p>Hex colors should only contain numbers and letters from a-f.</p>
        </div>
        <div className="default-color-selector-container">
          <p>Choose from default colors:</p>
          <div className="default-color-selector">
            {COLORS.map((color, index) => (
              <button
                aria-label={`Color ${color}`}
                type="button"
                key={index}
                className="color-selector"
                style={{ backgroundColor: color }}
                onClick={() => handleTagColorChange(color)}
              >
                {color}
              </button>
            ))}
          </div>
        </div>
        {errorMessage && <ErrorText error={errorMessage} />}
      </div>
      <div className="modal-footer">
        <Button
          onClick={handleSaveTag}
          isDisabled={tagColorError || tagNameInput?.trim()?.length === 0}
          className="modal-confirm-button"
        >
          SAVE
        </Button>
      </div>
    </Modal>
  );
};

export default CustomTagModal;
