import { Form, Formik } from 'formik';
import { InferType, object, string } from 'yup';
import { useState } from 'react';
import Button from '../common/Button';
import Modal from '../common/Modal';
import { CreatedMenuSectionResponse, MenuSection } from '../../types/MenuSectionInterface';
import { ErrorText, FormikInput, FormikTextArea } from '../common/Form';
import { createMenuSection, editMenuSection } from '../../api/menuSections';
import { useMenuContext } from '../../contexts/MenuContext/MenuContext';
import { useRestaurantContext } from '../../contexts/RestaurantContext/RestaurantContext';

interface MenuSectionModalProps {
  menuSection?: MenuSection;
  isEdit: boolean;
  onSubmit: Function;
  onCancel: Function;
}

const MenuSectionSchema = object({
  name: string().default('').required('Name is required.'),
  message: string().default('')
});

const MenuSectionModal = ({ menuSection, isEdit, onSubmit, onCancel }: MenuSectionModalProps) => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { addMenuSection, menu, updateMenuSection } = useMenuContext();
  const { addMenuSection: addMenuSectionToRestaurant, updateMenuSection: updateMenuSectionToRestaurant } =
    useRestaurantContext();

  const handleMenuSectionSubmit = async (data: InferType<typeof MenuSectionSchema>) => {
    if (MenuSectionSchema.isValidSync(data)) {
      try {
        if (isEdit) {
          await editMenuSection(menu.menuID, menuSection.menuSectionID, data.name, data.message);
          updateMenuSection({ ...menuSection, sectionName: data.name, message: data.message });
          updateMenuSectionToRestaurant(menuSection.menuSectionID, data.name, menu.menuID, data.message);
        } else {
          const createdMenuSectionResponse: CreatedMenuSectionResponse = await createMenuSection(
            data.name,
            menu.menuID,
            data.message
          );
          const createdMenuSection: MenuSection = createdMenuSectionResponse.menuSections[0];
          addMenuSection(createdMenuSection);
          addMenuSectionToRestaurant(createdMenuSection, menu.menuID);
        }
        if (errorMessage) {
          setErrorMessage('');
        }
        onSubmit();
      } catch (error) {
        if (error.response?.status === 409) {
          setErrorMessage('Menu section already exists.');
        } else {
          setErrorMessage('Unexpected error has occurred.');
        }
      }
    }
  };

  const getInitialValues = () => {
    if (isEdit) {
      return {
        name: menuSection?.name,
        message: menuSection?.message
      };
    }
    return MenuSectionSchema.cast({});
  };

  return (
    <Modal className="menu-section-modal">
      <div className="modal-header">
        <h1 className="modal-title">{isEdit ? 'EDIT' : 'CREATE'} MENU SECTION</h1>
      </div>
      <div className="modal-body">
        <Formik
          initialValues={getInitialValues()}
          validationSchema={MenuSectionSchema}
          onSubmit={handleMenuSectionSubmit}
        >
          {() => (
            <Form>
              <div className="menu-section-input-container">
                <FormikInput name="name" label="MENU SECTION" className="menu-section-input" />
                <FormikTextArea
                  name="message"
                  label={
                    <span>
                      DESCRIPTION <i>(Optional)</i>
                    </span>
                  }
                  className="menu-section-area"
                />
              </div>
              {errorMessage && <ErrorText error={errorMessage} />}
              <div className="modal-footer">
                <Button onClick={onCancel} className="modal-cancel-button">
                  CANCEL
                </Button>
                <Button submit className="modal-confirm-button">
                  CONFIRM
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default MenuSectionModal;
