import React from 'react';

interface LeftArrowIconProps {
  onClick?: Function;
  visible?: boolean;
}
const LeftArrowIcon = ({ onClick, visible = true }: LeftArrowIconProps) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    onClick?.();
    event.stopPropagation();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event.code === 'Enter' || event.code === 'Space') {
      onClick?.();
    }
    event.stopPropagation();
  };

  return (
    <div
      style={{ visibility: visible ? 'visible' : 'hidden' }}
      role="button"
      className="arrow-icon p-1"
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      tabIndex={0}
    >
      <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.3116 7.99995L8.18425 14.7232" stroke="black" strokeOpacity="0.67" strokeLinecap="square" />
        <path d="M8.15617 1.30433L1.28352 8.02759" stroke="black" strokeOpacity="0.67" strokeLinecap="square" />
      </svg>
    </div>
  );
};

export default LeftArrowIcon;
