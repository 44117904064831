import { Form, Formik } from 'formik';
import { InferType } from 'yup';
import { DateTime } from 'luxon';
import React, { ReactNode, useState } from 'react';
import Button from '../common/Button';
import { ErrorText, FormikInput, DateTimePicker, Label } from '../common/Form';
import { useAnnouncementsContext } from '../../contexts/AnnouncementsContext';
import { EmbeddedAnnouncementSchema } from './AnnouncementModal';
import { MediaLibraryInterface, MenuItemMediaInterface } from '../../types/MediaInterface';
import MediaPreview from '../MediaPreview';
import MediaLibraryModal from '../MediaLibraryModal';
import imageDisplay from '../../assets/images/ImageDisplay.png';

interface EmbeddedAnnouncementFormProps {
  children: ReactNode | ReactNode[];
  announcementID?: number;
  image?: MenuItemMediaInterface;
  isEdit: boolean;
  errorMessage: string;
  onSubmit: Function;
  // eslint-disable-next-line no-unused-vars
  onImageUpload: (_image: MenuItemMediaInterface) => void;
  onCancel: Function;
  onEdited: Function;
  isDesktop?: boolean;
}

const EmbeddedAnnouncementForm = ({
  children,
  announcementID,
  image,
  isEdit,
  errorMessage,
  onSubmit,
  onImageUpload,
  onCancel,
  onEdited,
  isDesktop
}: EmbeddedAnnouncementFormProps) => {
  const { getAnnouncement } = useAnnouncementsContext();
  const [openMediaLibraryModal, setOpenMediaLibraryModal] = useState<boolean>(false);

  const handleImageUpload = (_media: MediaLibraryInterface[]) => {
    onImageUpload(
      _media?.[0] ? { mediaID: _media?.[0].mediaID, mediaURL: _media?.[0].mediaUrl, type: 'image' } : undefined
    );
  };

  const getInitialValues = () => {
    if (isEdit) {
      const announcement = getAnnouncement(announcementID);
      return {
        name: announcement.name,
        startDate: announcement.startDate,
        endDate: announcement.endDate
      };
    }
    return EmbeddedAnnouncementSchema.cast({
      startDate: DateTime.fromJSDate(new Date()).toISO({ includeOffset: false }),
      endDate: DateTime.fromJSDate(new Date()).plus({ days: 1 }).toISO({ includeOffset: false })
    });
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={EmbeddedAnnouncementSchema}
      onSubmit={(data: InferType<typeof EmbeddedAnnouncementSchema>) => onSubmit(data)}
    >
      {({ values }) => (
        <Form>
          <div className="announcement-form-container">
            <div className="announcement-inputs-container">
              <div className="announcement-name-container">
                <FormikInput name="name" label="PROMOTION NAME" className="announcement-input" onBlur={onEdited} />
                <p className="announcement-name-copy">
                  For your reference only. The name is not displayed on the promotion.
                </p>
              </div>
              {children}
            </div>
            <div className="announcement-timespan-input">
              <DateTimePicker name="startDate" label="START DATE" className="start-input" />
              <DateTimePicker name="endDate" label="END DATE" minDate={values?.startDate} />
            </div>
            {isDesktop ? (
              <div className="announcement-image-container">
                <Label
                  label={
                    <span>
                      PHOTO <i>(Optional)</i>
                    </span>
                  }
                  className="announcement-image-label"
                />
                <Button className="select-media-button" onClick={() => setOpenMediaLibraryModal(true)}>
                  Select Media
                </Button>
                <MediaPreview media={image ? [image] : []} onUpdate={handleImageUpload} multiple={false} />
                {openMediaLibraryModal && (
                  <MediaLibraryModal
                    selectedMediaIDs={image ? [image.mediaID] : []}
                    onSelect={handleImageUpload}
                    onRemove={() => onImageUpload(undefined)}
                    onClose={() => setOpenMediaLibraryModal(false)}
                    mediaTypeOverride="image"
                  />
                )}
                <p className="announcement-image-copy">
                  Photos should be no larger than 750 KB & have an aspect ratio of 3:2.
                </p>
              </div>
            ) : (
              <div className="announcement-image-container">
                <Label
                  label={
                    <span>
                      MEDIA <i>(OPTIONAL)</i>
                    </span>
                  }
                  className="announcement-image-label"
                />
                <p className="announcement-image-copy">
                  Photos should be no larger than 750 KB & have an aspect ratio of 3:2.
                </p>
                <div className="media-container">
                  <Button className="select-media-button" onClick={() => setOpenMediaLibraryModal(true)}>
                    <img className="media-upload-icon" src={imageDisplay} alt="Icon indicating upload" />
                    <p>Select Media</p>
                  </Button>
                  <MediaPreview media={image ? [image] : []} onUpdate={handleImageUpload} multiple={false} />
                </div>
                {openMediaLibraryModal && (
                  <MediaLibraryModal
                    selectedMediaIDs={image ? [image.mediaID] : []}
                    onSelect={handleImageUpload}
                    onRemove={() => onImageUpload(undefined)}
                    onClose={() => setOpenMediaLibraryModal(false)}
                    mediaTypeOverride="image"
                  />
                )}
              </div>
            )}
          </div>
          {errorMessage && <ErrorText error={errorMessage} />}
          <div className="modal-footer">
            <Button onClick={onCancel} className="modal-cancel-button">
              CANCEL
            </Button>
            <Button submit className="modal-confirm-button">
              CONFIRM
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EmbeddedAnnouncementForm;
