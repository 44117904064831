import apiClient from './apiClient';
import { STRIPE_CHECKOUT, STRIPE_CUSTOMER_PORTAL } from '../constants/UriConstants';

export const stripeCheckout = (): Promise<string> =>
  apiClient
    .post(STRIPE_CHECKOUT, { packages: [{ priceID: process.env.REACT_APP_STRIPE_PRICE_ID, quantity: 1 }] })
    .then((response) => response.data);

export const getStripeCustomerPortal = (): Promise<string> =>
  apiClient.get(STRIPE_CUSTOMER_PORTAL).then((response) => response.data);
