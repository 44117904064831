import { createContext, ReactElement, useMemo, useContext, useState, useEffect, useCallback } from 'react';
import { useRestaurantContext } from '../RestaurantContext';
import { MediaLibraryInterface } from '../../types/MediaInterface';
import { getMedia } from '../../api/media';

interface MediaLibraryContextInterface {
  hasError: boolean;
  isLoading: boolean;
  media: MediaLibraryInterface[];
  loadMedia: Function;
  addMedia: Function;
  removeMedia: Function;
}

interface MediaLibraryProviderInterface {
  children: ReactElement;
}

const MediaLibraryContext = createContext<MediaLibraryContextInterface>({
  hasError: false,
  isLoading: true,
  media: null,
  loadMedia: () => {},
  addMedia: () => {},
  removeMedia: () => {}
});

const MediaLibraryProvider = ({ children }: MediaLibraryProviderInterface) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [media, setMedia] = useState<MediaLibraryInterface[]>(null);
  const [currentRestaurantID, setCurrentRestaurantID] = useState<number>(-1);

  const { currentRestaurant } = useRestaurantContext();

  useEffect(() => {
    let canceled = false;

    if (initialLoad && currentRestaurant && currentRestaurant.restaurantID !== currentRestaurantID) {
      setIsLoading(true);

      getMedia()
        .then((restaurantMedia: MediaLibraryInterface[]) => {
          if (canceled) return;
          setMedia(restaurantMedia);
          setCurrentRestaurantID(currentRestaurant.restaurantID);
          setIsLoading(false);
        })
        .catch((error) => {
          if (canceled) return;
          if (error.response.status !== 401) {
            // on non auth related failure indicate failure has occurred
            setHasError(true);
          }
          setIsLoading(false);
        });
    }

    return () => {
      canceled = true;
    };
  }, [initialLoad, currentRestaurant, currentRestaurantID]);

  const loadMedia = useCallback(() => {
    if (!initialLoad && media == null) {
      setInitialLoad(true);
    }
  }, [initialLoad, media]);

  const addMedia = useCallback(
    (mediaArray: MediaLibraryInterface[]) => {
      const _media: MediaLibraryInterface[] = media.slice();
      setMedia(mediaArray.concat(_media));
    },
    [media]
  );

  const removeMedia = useCallback(
    (mediaIDs: number[]) => {
      const _media = media.slice();
      mediaIDs.forEach((mediaID) => {
        const mediaIndex = _media.findIndex((item) => item.mediaID === mediaID);
        if (mediaIndex !== -1) {
          _media.splice(mediaIndex, 1);
        }
      });

      setMedia(_media);
    },
    [media]
  );

  const providerValue = useMemo(
    () => ({
      hasError,
      isLoading,
      media,
      loadMedia,
      addMedia,
      removeMedia
    }),
    [hasError, isLoading, media, loadMedia, addMedia, removeMedia]
  );
  return <MediaLibraryContext.Provider value={providerValue}>{children}</MediaLibraryContext.Provider>;
};

const useMediaLibraryContext = () => {
  const context = useContext(MediaLibraryContext);
  if (!context) {
    throw new Error(`MediaLibraryContext must be used within the MediaLibraryProvider component`);
  }
  return context;
};

export { MediaLibraryProvider as default, useMediaLibraryContext };
