import React from 'react';
import { DraggableIcon, DuplicateIcon, PlayIcon } from '../../../assets/svgs/icons';
import Logo from '../../../assets/images/TapTabLogo.png';
import ContextMenuIconButton from '../../ContextMenuIconButton';
import { TagInterface } from '../../../types/TagInterface';
import Tag from '../../common/Tag';

interface NameCellProps {
  name: string;
  imageURL?: string;
  itemID: number;
  tags?: TagInterface[];
  draggable: boolean;
  isHidden?: boolean;
  hasVideo?: boolean;
  hasMulti?: boolean;
  onEdit?: Function;
  onHideShow?: Function;
  onDelete?: Function;
}

const NameCell = ({
  name,
  imageURL,
  itemID,
  draggable,
  onEdit,
  onHideShow,
  onDelete,
  isHidden = false,
  hasVideo = false,
  hasMulti = false,
  tags = []
}: NameCellProps) => {
  const getName = () => {
    if (draggable) {
      return (
        <div className="name-and-tag-container">
          <div className={`name-container ${isHidden ? 'hidden-cell-value' : ''} ${tags.length > 0 ? 'has-tag' : ''}`}>
            <span>{name}</span>
          </div>
          {tags.length > 0 && (
            <div className="name-cell-tag-container">
              {tags.map((tag) => (
                <Tag key={tag.tagID} tag={tag} />
              ))}
            </div>
          )}
        </div>
      );
    }

    return (
      <div className={`name-container ${isHidden ? 'hidden-cell-value' : ''}`}>
        <span>{name}</span>
      </div>
    );
  };

  return (
    <span className={draggable ? 'draggable-name-cell-container' : 'non-draggable-name-cell-container'}>
      <ContextMenuIconButton
        itemID={itemID}
        isHidden={isHidden}
        onEditClicked={onEdit || null}
        onDeleteClicked={onDelete || null}
        onHideShowClicked={onHideShow || null}
      />
      {draggable && <DraggableIcon />}
      <div className="image-container">
        <div className="icon-container">
          {hasVideo && <PlayIcon />}
          {hasMulti && <DuplicateIcon />}
        </div>
        {imageURL ? (
          <img className={`${isHidden && 'hidden-cell-value'}`} alt={`${name}`} src={imageURL} />
        ) : (
          <img id="logo" className={`${isHidden && 'hidden-cell-value'}`} alt="TapTab Placeholder" src={Logo} />
        )}
      </div>
      {getName()}
    </span>
  );
};

export default NameCell;
