import Button from '../../../components/common/Button';
import { Label } from '../../../components/common/Form';
import ImageUploader from '../../../components/common/Form/ImageUploader';
import { useCuisinesContext } from '../../../contexts/CuisinesContext';
import { MENU_LAYOUTS } from '../../../constants/MenuLayoutConstants';
import ConfirmationDisplayItem from '../../../components/ConfirmationDisplayItem';
import DayHourDisplay from '../../../components/DayHourDisplay';

import { buildRestaurantHours } from '../../../utils/time';
import { AlbumMediaInterface } from '../../../types/AlbumMediaInterface';
import { BlobWrapper } from '../../../types/MediaInterface';

interface RestaurantConfirmationPageProps {
  formik: any;
  onBack: Function;
  logoImage: Blob | string;
  thumbnailImage: Blob | string;
  profileImage: Blob | string;
  menuCoverImage: Blob | string;
  galleryImages: (BlobWrapper | AlbumMediaInterface)[];
}

const RestaurantConfirmationPage = ({
  formik,
  logoImage,
  thumbnailImage,
  profileImage,
  menuCoverImage,
  galleryImages,
  onBack
}: RestaurantConfirmationPageProps) => {
  const { getCuisine } = useCuisinesContext();

  const getImageForGallery = (image: BlobWrapper | AlbumMediaInterface): Blob | string => {
    if ((image as BlobWrapper)?.file) {
      return (image as BlobWrapper)?.file;
    }

    return (image as AlbumMediaInterface).mediaURL;
  };

  return (
    <div className="restaurant-confirmation-page">
      <ConfirmationDisplayItem label="NAME" value={formik.values?.name} />
      <div>
        <span className="restaurant-confirmation-label">DESCRIPTION: </span>
        <span className="restaurant-confirmation-value">{formik.values.description || '--'}</span>
      </div>
      <ConfirmationDisplayItem label="PHONE" value={formik.values?.phone} />
      <ConfirmationDisplayItem label="EMAIL" value={formik.values?.email} />
      <ConfirmationDisplayItem label="CUISINE" value={getCuisine(formik.values?.cuisine)?.name} />
      <ConfirmationDisplayItem label="WEBSITE" value={formik.values?.website} />
      <div className="restaurant-confirmation-address-display">
        <div className="restaurant-confirmation-display-col-1">
          <ConfirmationDisplayItem label="STREET ADDRESS 1" value={formik.values?.address?.address1} />
          <ConfirmationDisplayItem label="STREET ADDRESS 2" value={formik.values?.address?.address2} />
        </div>
        <div className="restaurant-confirmation-display-col-2">
          <ConfirmationDisplayItem label="CITY" value={formik.values?.address?.city} />
          <ConfirmationDisplayItem label="STATE" value={formik.values?.address?.governingDistrict} />
          <ConfirmationDisplayItem label="ZIP CODE" value={formik.values?.address?.postCode} />
        </div>
      </div>
      <div className="restaurant-confirmation-socials-display">
        <ConfirmationDisplayItem label="INSTAGRAM" value={formik.values?.socials?.instagram} />
        <ConfirmationDisplayItem label="TIKTOK" value={formik.values?.socials?.tiktok} />
        <ConfirmationDisplayItem label="FACEBOOK" value={formik.values?.socials?.facebook} />
        <ConfirmationDisplayItem label="TWITTER" value={formik.values?.socials?.twitter} />
        <ConfirmationDisplayItem label="SNAPCHAT" value={formik.values?.socials?.snapchat} />
      </div>
      <DayHourDisplay label="HOURS" restaurantHours={buildRestaurantHours(formik.values?.restaurantHours)} />
      <div>
        <span className="restaurant-confirmation-label">NOTES: </span>
        <span className="restaurant-confirmation-value">{formik.values.availabilityNotes || '--'}</span>
      </div>
      <div className="restaurant-confirmation-img-display">
        <Label label="LOGO PHOTO" />
        <ImageUploader id="0" image={logoImage} onUpdate={() => {}} readOnly />
      </div>
      <div className="restaurant-confirmation-img-display">
        <Label label="THUMBNAIL PHOTO" />
        <ImageUploader id="1" image={thumbnailImage} onUpdate={() => {}} readOnly />
      </div>
      <div className="restaurant-confirmation-img-display">
        <Label label="PROFILE PHOTO" />
        <ImageUploader id="2" image={profileImage} onUpdate={() => {}} readOnly />
      </div>
      <div className="restaurant-confirmation-img-display">
        <Label label="MENU COVER PHOTO" />
        <ImageUploader id="3" image={menuCoverImage} onUpdate={() => {}} readOnly />
      </div>
      <div className="restaurant-confirmation-img-display">
        <Label label="GALLERY PHOTOS" />
        <div className="restaurant-confirmation-gallery-display">
          {!galleryImages || galleryImages?.length === 0 ? (
            <ImageUploader id="no-image" image={null} onUpdate={() => {}} readOnly />
          ) : (
            galleryImages?.map((image) => (
              <ImageUploader
                key={image?.mediaID}
                id={image?.mediaID?.toString()}
                image={getImageForGallery(image)}
                onUpdate={() => {}}
                readOnly
              />
            ))
          )}
        </div>
      </div>
      <ConfirmationDisplayItem label="MENU LAYOUT" value={MENU_LAYOUTS[formik.values.layoutID - 1]} />
      <div className="restaurant-wizard-confirm-buttons">
        <Button className="restaurant-wizard-back-button" onClick={onBack}>
          BACK
        </Button>
        <Button className="restaurant-wizard-next-button" submit isDisabled={!formik.isValid}>
          SUBMIT
        </Button>
      </div>
    </div>
  );
};

export default RestaurantConfirmationPage;
