import { Outlet } from 'react-router-dom';
import InitialMenusView from '../../../components/InitialMenusView';
import MenuTopBar from '../../../components/MenuTopBar';
import { useRestaurantContext } from '../../../contexts/RestaurantContext';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import InitialRestaurantsView from '../../../components/InitialRestaurantsView';
import useResponsive from '../../../hooks/useResponsive';
import MobileMenuTopBar from '../../../components/MobileMenuTopBar';

const MenuPage = () => {
  const { menus, isLoading, restaurants } = useRestaurantContext();
  const { isDesktop } = useResponsive();

  if (menus?.length > 0) {
    return isDesktop ? (
      <div className="menu-page-container">
        <MenuTopBar />
        <Outlet />
      </div>
    ) : (
      <div className="menu-page-container">
        <MobileMenuTopBar />
        <Outlet />
      </div>
    );
  }
  if (isLoading) {
    return (
      <div className="menu-page-loading">
        <LoadingSpinner />
      </div>
    );
  }

  if (restaurants?.length > 0) {
    return <InitialMenusView />;
  }

  return <InitialRestaurantsView />;
};

export default MenuPage;
