export const DAYS_ABBREVIATIONS = { M: false, T: false, W: false, Th: false, F: false, Sa: false, Su: false };

interface DayObject {
  [key: string]: string;
}

export const shortToFullDayName: DayObject = {
  M: 'Monday',
  T: 'Tuesday',
  W: 'Wednesday',
  Th: 'Thursday',
  F: 'Friday',
  Sa: 'Saturday',
  Su: 'Sunday'
};

export const fullToShortDayName: DayObject = {
  Monday: 'M',
  Tuesday: 'T',
  Wednesday: 'W',
  Thursday: 'Th',
  Friday: 'F',
  Saturday: 'Sa',
  Sunday: 'Su'
};
