import { Field, useField } from 'formik';
import React from 'react';
import ErrorText from '../ErrorText';
import Label from '../Label';

interface FormikInputProps {
  className?: string;
  type?: string;
  label?: string;
  name: string;
  placeholder?: string;
  autoComplete?: string;
  disabled?: boolean;
  onBlur?: Function;
  onChange?: Function;
}

const FormikInput = ({
  className,
  label,
  name,
  placeholder,
  type = 'text',
  autoComplete = '',
  disabled,
  onBlur,
  onChange
}: FormikInputProps) => {
  const [field, meta] = useField(name);
  const error = meta.touched ? meta.error : '';
  const handleOnBlur = (e: React.FocusEvent<any>) => {
    onBlur?.();
    field.onBlur(e);
  };

  const handleOnChange = (e: React.ChangeEvent<any>) => {
    onChange?.(e.target.value);
    field.onChange(e);
  };

  return (
    <div className={className}>
      <Label htmlFor={name} label={label} />
      <div className="mt-2">
        <Field
          type={type}
          className="w-full p-4 text-sm rounded-sm border border-neutral-400"
          name={name}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
        />
      </div>
      {error && <ErrorText error={error} />}
    </div>
  );
};

export default FormikInput;
