import React from 'react';

interface CheckBoxProps {
  className?: string;
  checked: boolean;
  name?: string;
  onChange?: Function;
  disabled?: boolean;
}

const CheckBox = ({ checked, onChange, name, className = '', disabled = false }: CheckBoxProps) => {
  const handleClick = () => {
    onChange?.();
  };

  return (
    <input
      name={name}
      type="checkbox"
      className={`${className}`}
      checked={checked}
      onChange={handleClick}
      disabled={disabled}
    />
  );
};

export default CheckBox;
