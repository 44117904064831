import TableCell from './TableCell';
import { PairingInterface } from '../../../types/PairingInterface';

const PairingCell = ({ value }: any) => {
  const pairingDisplay = (pairing: PairingInterface) => <span key={pairing.name}>{pairing.name}</span>;

  return (
    <TableCell
      className={value.isHidden ? 'hidden-cell-value' : ''}
      value={value.pairings}
      mappedFunction={pairingDisplay}
    />
  );
};

export default PairingCell;
