import { createContext, ReactElement, useMemo, useContext, useState, useEffect, useCallback } from 'react';
import { AnnouncementInterface } from '../../types/AnnouncementInterface';
import { getAnnouncements } from '../../api/announcements';
import { useRestaurantContext } from '../RestaurantContext';

interface AnnouncementsContextInterface {
  hasError: boolean;
  isLoading: boolean;
  announcements: AnnouncementInterface[];
  loadAnnouncements: Function;
  addAnnouncement: Function;
  getAnnouncement: Function;
  removeAnnouncement: Function;
  updateAnnouncement: Function;
}

interface AnnouncementsProviderInterface {
  children: ReactElement;
}

const AnnouncementsContext = createContext<AnnouncementsContextInterface>({
  hasError: false,
  isLoading: true,
  announcements: null,
  loadAnnouncements: () => {},
  addAnnouncement: () => {},
  getAnnouncement: () => {},
  removeAnnouncement: () => {},
  updateAnnouncement: () => {}
});

const AnnouncementsProvider = ({ children }: AnnouncementsProviderInterface) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [announcements, setAnnouncements] = useState<AnnouncementInterface[]>(null);
  const [currentRestaurantID, setCurrentRestaurantID] = useState<number>(-1);

  const { currentRestaurant } = useRestaurantContext();

  useEffect(() => {
    let canceled = false;

    if (initialLoad && currentRestaurant && currentRestaurant.restaurantID !== currentRestaurantID) {
      setIsLoading(true);

      getAnnouncements()
        .then((_announcements: AnnouncementInterface[]) => {
          if (canceled) return;
          setAnnouncements(_announcements);
          setCurrentRestaurantID(currentRestaurant.restaurantID);
          setIsLoading(false);
        })
        .catch((error) => {
          if (canceled) return;
          if (error.response.status !== 401) {
            // on non auth related failure indicate failure has occurred
            setHasError(true);
          }
          setIsLoading(false);
        });
    }

    return () => {
      canceled = true;
    };
  }, [initialLoad, announcements, currentRestaurant, currentRestaurantID]);

  const loadAnnouncements = useCallback(() => {
    if (!initialLoad && announcements == null) {
      setInitialLoad(true);
    }
  }, [initialLoad, announcements]);

  const addAnnouncement = useCallback(
    (announcement: AnnouncementInterface) => {
      const _announcements = announcements.slice();
      _announcements.push({ ...announcement } as AnnouncementInterface);
      setAnnouncements(_announcements);
    },
    [announcements]
  );

  const getAnnouncement = useCallback(
    (announcementID: number): AnnouncementInterface =>
      announcements.find((announcement) => announcement.announcementID === announcementID),
    [announcements]
  );

  const removeAnnouncement = useCallback(
    (announcementID: number) => {
      const _announcements = announcements.slice();
      const announcementIndex: number = _announcements.findIndex(
        (section) => section.announcementID === announcementID
      );
      _announcements.splice(announcementIndex, 1);
      setAnnouncements(_announcements);
    },
    [announcements]
  );

  const updateAnnouncement = useCallback(
    (announcementID: number, announcement: AnnouncementInterface) => {
      const _announcements = announcements.slice();
      const announcementIndex: number = _announcements.findIndex(
        (section) => section.announcementID === announcementID
      );
      const _announcement: AnnouncementInterface = { ..._announcements[announcementIndex], ...announcement };

      _announcements.splice(announcementIndex, 1);
      _announcements.splice(announcementIndex, 0, _announcement);
      setAnnouncements(_announcements);
    },
    [announcements]
  );

  const providerValue = useMemo(
    () => ({
      hasError,
      isLoading,
      announcements,
      loadAnnouncements,
      addAnnouncement,
      getAnnouncement,
      removeAnnouncement,
      updateAnnouncement
    }),
    [
      hasError,
      isLoading,
      announcements,
      loadAnnouncements,
      addAnnouncement,
      getAnnouncement,
      removeAnnouncement,
      updateAnnouncement
    ]
  );
  return <AnnouncementsContext.Provider value={providerValue}>{children}</AnnouncementsContext.Provider>;
};

const useAnnouncementsContext = () => {
  const context = useContext(AnnouncementsContext);
  if (!context) {
    throw new Error(`AnnouncementsContext must be used within the AnnouncementsProvider component`);
  }
  return context;
};

export { AnnouncementsProvider as default, useAnnouncementsContext };
