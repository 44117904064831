import React, { ReactNode } from 'react';
import Label from '../Label';
import ErrorText from '../ErrorText';

interface InputProps {
  className?: string;
  label?: string | ReactNode;
  name: string;
  type?: string;
  placeholder?: string;
  onChange: any;
  onBlur?: any;
  onFocus?: any;
  value: any;
  touched?: boolean;
  error?: string;
  disabled?: boolean;
  maxLength?: number;
}

const Input = ({
  value,
  className,
  label,
  name,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  touched,
  error,
  maxLength,
  type = 'text',
  disabled = false
}: InputProps) => {
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(event);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    onFocus?.(event);
  };

  return (
    <div className={className}>
      <Label className="tracking-[1.1375px]" htmlFor={name} label={label} />
      <div className="mt-2">
        <input
          type={type}
          className="w-full p-4 text-sm rounded-sm border border-neutral-400"
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          disabled={disabled}
          maxLength={maxLength}
        />
      </div>
      <ErrorText error={touched ? error : ''} />
    </div>
  );
};

export default Input;
