import React from 'react';

interface ThreeDotIconProps {
  onIconClicked: Function;
}

const ThreeDotIcon = ({ onIconClicked }: ThreeDotIconProps) => {
  const handleIconClicked = (event: React.MouseEvent<HTMLDivElement>) => {
    onIconClicked?.();
    event.stopPropagation();
  };

  const handleIconKeyPressed = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    onIconClicked?.();
    event.stopPropagation();
  };

  return (
    <div
      role="button"
      className="three-dot-icon"
      onClick={handleIconClicked}
      onKeyPress={handleIconKeyPressed}
      tabIndex={0}
    >
      <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="14" r="2" transform="rotate(-90 2 14)" fill="#979797" />
        <circle cx="2" cy="8" r="2" transform="rotate(-90 2 8)" fill="#979797" />
        <circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" fill="#979797" />
      </svg>
    </div>
  );
};

export default ThreeDotIcon;
