import apiClient from './apiClient';
import {
  CREATE_PROFILE_PAGES,
  EDIT_PROFILE_PAGES,
  GET_PROFILE_PAGES,
  GET_PROFILE_PAGES_OVERVIEW
} from '../constants/UriConstants';
import {
  CreateProfilePageRequestInterface,
  CreateProfilePageResponseInterface,
  EditProfilePageRequestInterface,
  GetProfilePagesOverviewResponseInterface,
  ProfilePageInterface
} from '../types/ProfilePageInterface';

export const createProfilePage = async (
  request: CreateProfilePageRequestInterface
): Promise<CreateProfilePageResponseInterface> =>
  apiClient.post(CREATE_PROFILE_PAGES, request).then((response) => response.data);

export const editProfilePage = async (request: EditProfilePageRequestInterface) =>
  apiClient.put(EDIT_PROFILE_PAGES, request);

export const getProfilePage = async (pageID: number): Promise<ProfilePageInterface> =>
  apiClient.get(GET_PROFILE_PAGES + pageID).then((response) => response.data);

export const getProfilePagesOverview = async (): Promise<GetProfilePagesOverviewResponseInterface[]> =>
  apiClient.get(GET_PROFILE_PAGES_OVERVIEW).then((response) => response.data);
