interface ReservationOrderInputLabelProps {
  label: string;
  className?: string;
  text?: string;
}

const ReservationOrderInputLabel = ({ label, text, className }: ReservationOrderInputLabelProps) => (
  <div className={`reservation-order-label ${className && className}`}>
    <span>{label.toUpperCase()}</span>
    <span className="link-prompt">{text}</span>
  </div>
);

export default ReservationOrderInputLabel;
