import { DaysAndHoursPicker, TextArea } from '../../../components/common/Form';
import Button from '../../../components/common/Button';

interface RestaurantHoursPageProps {
  formik: any;
  onNext: Function;
  onBack: Function;
}

const RestaurantHoursPage = ({ formik, onNext, onBack }: RestaurantHoursPageProps) => {
  const handleNextClicked = () => {
    formik.setTouched({ restaurantHours: true, availabilityNotes: true }, true);

    if (!formik.errors?.restaurantHours && !formik.errors?.availabilityNotes) {
      onNext?.();
    }
  };

  return (
    <div className="restaurant-hours-page">
      <DaysAndHoursPicker
        value={formik.values.restaurantHours}
        touched={formik.touched?.restaurantHours}
        error={formik.errors?.restaurantHours}
        name="restaurantHours"
        formik={formik}
        className="restaurant-hours-day-picker"
      />
      <TextArea
        name="availabilityNotes"
        label={
          <span>
            NOTES <i>(Optional)</i>
          </span>
        }
        className="restaurant-wizard-availability-notes-textarea"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.availabilityNotes}
        touched={formik.touched.availabilityNotes}
        error={formik.errors.availabilityNotes}
      />
      <Button className="restaurant-wizard-back-button" onClick={onBack}>
        BACK
      </Button>
      <Button className="restaurant-wizard-next-button" onClick={handleNextClicked}>
        NEXT
      </Button>
    </div>
  );
};

export default RestaurantHoursPage;
